import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

// 下拉选择框使用车辆车型列表接口 参数传入seriesId
export async function list(params) {
  return request(`/api/bmkp-backend/carmodel/list?${stringify(params)}`);
}

// 分页显示
export async function page(params) {
  return request(`/api/bmkp-backend/carmodel/page?${stringify(params)}`);
}

export async function detail(params) {
  return request(`/api/bmkp-backend/carmodel/detail?${stringify(params)}`);
}

export async function submit(params) {
  return request('/api/bmkp-backend/carmodel/submit', {
    method: 'POST',
    body: params,
  });
}

export async function remove(params) {
  return request('/api/bmkp-backend/carmodel/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}

import { message } from 'antd';
import { PASSENGERRULES_NAMESPACE } from '../actions/passengerrules';
import {
    query,
    submit,
} from '../services/passengerrules';

export default {
  namespace: PASSENGERRULES_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: {},
  },
  effects: {
    *query({ payload, callback }, { call }) {
      const response = yield call(query, payload);
      if (response.success) {
        callback(response.data)
      }
    },
    *submit({ payload, callback }, { call }) {
        const response = yield call(submit, payload);
        if (response.success) {
          message.success('保存成功');
          callback(response.data)
        }
      },
  },
  reducers: {
  },
};

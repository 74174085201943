import { stringify } from 'qs';
import request from '../utils/request';
import func from '../utils/Func';

export async function queryActivities() {
  return request('/api/bmkp-backend/order/statistics/top/today');
}

export async function queryRule(params) {
  return request(`/api/rule?${stringify(params)}`);
}

export async function today(params) {
  return request('/api/bmkp-backend/order/statistics/top/today', {
    method: 'POST',
    body: params,
  });
}
export async function orderNum(params) {
  return request('/api/bmkp-backend/order/statistics/num', {
    method: 'POST',
    body: params,
  });
}
export async function orderFlow(params) {
  return request('/api/bmkp-backend/order/statistics/flow', {
    method: 'POST',
    body: params,
  });
}
export async function agentOrderData(params) {
  return request('/api/bmkp-backend/order/statistics/agent/data', {
    method: 'POST',
    body: params,
  });
}
export async function supplierOrderData(params) {
  return request('/api/bmkp-backend/order/statistics/supplier/data', {
    method: 'POST',
    body: params,
  });
}
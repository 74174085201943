export const DISPATCHRULE_NAMESPACE = 'dispatchrule';

// 分页查询
export function DISPATCHRULE_LIST(payload) {
  return {
    type: `${DISPATCHRULE_NAMESPACE}/fetchList`,
    payload,
  };
}

// 查询派单详情
export function DISPATCHRULE_DETAIL(payload, callback) {
  return {
    type: `${DISPATCHRULE_NAMESPACE}/fetchDetail`,
    payload,
    callback
  };
}

// 清除派单详情
export function DISPATCHRULE_CLEAR_DETAIL(id, callback) {
  return {
    type: `${DISPATCHRULE_NAMESPACE}/clearDetail`,
    payload: { id },
    callback
  };
}

// 新增/修改派单规则设置
export function DISPATCHRULE_SUBMIT(payload) {
  return {
    type: `${DISPATCHRULE_NAMESPACE}/submit`,
    payload
  };
}

// 派单规则城市启用和关闭
export function DISPATCHRULE_CHANGE_STATUS(payload, callback) {
  return {
    type: `${DISPATCHRULE_NAMESPACE}/changeStatus`,
    payload,
    callback
  };
}


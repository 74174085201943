import func from '../utils/Func';
import request from '../utils/request';
import { stringify } from 'qs';

export async function list(params) {
  return request(`/api/bmkp-wyc/wyc/message/page`, {
    method: 'POST',
    body: params,
  });
}
export async function submit(params) {
  return request('/api/bmkp-wyc/wyc/message/save', {
    method: 'POST',
    body: params,
  });
}
export async function detail(params) {
  return request('/api/bmkp-wyc/wyc/message/detail', {
    method: 'POST',
    body: params,
  });
}
export async function remove(params) {
  return request('/api/bmkp-backend/activity/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}
export async function repeal(params) {
  return request('/api/bmkp-wyc/wyc/message/repeal', {
    method: 'POST',
    body: params
  });
}

export async function getActivityList(params) {
  return request(`/api/bmkp-backend/message/activity/list?${stringify(params)}`);
}
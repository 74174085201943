import { message } from 'antd';
// import router from 'umi/router';
import { PLACE_NAMESPACE } from '@/actions/dj/djplace';
import { list, update, detail, add, del } from '@/services/dj/djplace';
export default {
  namespace: PLACE_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    placeInfo: null,
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *update({ payload, callback }, { call }) {
      const res = yield call(update, payload);
      if (res.success) {
        message.success(res.msg);
      }
      callback && callback(res.success);
    },
    *add({ payload, callback }, { call }) {
      const res = yield call(add, payload);
      if (res.success) {
        message.success(res.msg);
      }
      callback && callback(res.success);
    },
    *del({ payload, callback }, { call }) {
      const res = yield call(del, payload);
      if (res.success) {
        message.success(res.msg);
      }
      callback && callback(res.success);
    },
    *detail({ payload, callback }, { call, put }) {
      const res = yield call(detail, payload);
      if (res.success) {
        yield put({
          type: 'saveDetail',
          payload: res.data,
        });
      }
    },
    *detailClearn(_, { put }) {
      yield put({
        type: 'saveDetail',
        payload: {},
      });
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        placeInfo: action.payload,
      };
    },
  },
};

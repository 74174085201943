export const PASSEBGEROPERATION_NAMESPACE = 'passengeroperation';

export function DRIVEROPERATION_LIST(payload) {
  return {
    type: `${PASSEBGEROPERATION_NAMESPACE}/fetchList`,
    payload,
  };
}

export function DRIVEROPERATION_DETAIL(payload, callback) {
  return {
    type: `${PASSEBGEROPERATION_NAMESPACE}/fetchDetail`,
    payload,
    callback
  };
}

export function DRIVEROPERATION_UPDATE(payload, callback) {
  return {
    type: `${PASSEBGEROPERATION_NAMESPACE}/update`,
    payload,
    callback
  };
}

export function DRIVEROPERATION_SAVE(payload, callback) {
  return {
    type: `${PASSEBGEROPERATION_NAMESPACE}/save`,
    payload,
    callback
  };
}

export function DRIVEROPERATION_OPEN(payload, callback) {
  return {
    type: `${PASSEBGEROPERATION_NAMESPACE}/open`,
    payload,
    callback
  };
}

export function DRIVEROPERATION_CLOSE(payload, callback) {
  return {
    type: `${PASSEBGEROPERATION_NAMESPACE}/close`,
    payload,
    callback
  };
}

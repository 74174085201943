export const ORDERTRAVE_NAMESPACE = 'ordertrave';

export function ORDERTRAVE_LIST(payload) {
  return {
    type: `${ORDERTRAVE_NAMESPACE}/fetchList`,
    payload,
  };
}

export function ORDERTRAVE_DETAIL(tripNo) {
  return {
    type: `${ORDERTRAVE_NAMESPACE}/fetchDetail`,
    payload: { tripNo },
  };
}

export function ORDERTRAVE_CLEAR_DETAIL() {
  return {
    type: `${ORDERTRAVE_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function ORDERTRAVE_SUBMIT(payload) {
  return {
    type: `${ORDERTRAVE_NAMESPACE}/submit`,
    payload,
  };
}

export function ORDERTRAVE_REMOVE(payload) {
  return {
    type: `${ORDERTRAVE_NAMESPACE}/remove`,
    payload,
  };
}

import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';
// 城内
export async function list(params) {
  return request(`/api/bmkp-backend/business/page?${stringify(params)}`);
}
// 已开通的城市列表，提供给其他需要查询城市的列表
export async function openList(params) {
  return request('/api/bmkp-backend/business/cityList', {
    method: 'POST',
    body: params
  });
}
// 添加城市
export async function submit(params) {
  return request('/api/bmkp-backend/business/save', {
    method: 'POST',
    body: params,
  });
}
// 添加城市的服务类型。普通用车、接送机等
export async function submitBusinessServiceType(params) {
  return request('/api/bmkp-backend/business/addServiceType', {
    method: 'POST',
    body: params,
  });
}
// 添加服务类型下的车型。经济型、舒适型等
export async function submitBusinessServiceConfig(params) {
  return request('/api/bmkp-backend/businessServiceConfig/add', {
    method: 'POST',
    body: params,
  });
}
export async function update(params) {
  return request('/api/bmkp-backend/customize/line/update', {
    method: 'POST',
    body: params,
  });
}
export async function open(params) {
  return request(`/api/bmkp-backend/business/open?${stringify(params)}`, {
    method: 'POST',
  });
}
export async function close(params) {
  return request(`/api/bmkp-backend/business/close?${stringify(params)}`, {
    method: 'POST',
  });
}
export async function openChannelDispatch(params) {
  return request('/api/bmkp-backend/business/openChannelDispatch', {
    method: 'POST',
    body: params,
  });
}
export async function closeChannelDispatch(params) {
  return request('/api/bmkp-backend/business/closeChannelDispatch', {
    method: 'POST',
    body: params,
  });
}

export async function configDetail(params) {
  return request(`/api/bmkp-backend/business/detail?${stringify(params)}`, {
    method: 'POST',
  });
}

export async function removeServiceType(params) {
  return request('/api/bmkp-backend/business/deleteServiceType', {
    method: 'POST',
    body: params,
  });
}
export async function removeBusinessServiceType(params) {
  return request('/api/bmkp-backend/businessServiceConfig/remove', {
    method: 'POST',
    body: params,
  });
}
export async function remove(params) {
  return request('/api/customize/lineconfig/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}

/** ************* 城际 **************** */ 

export async function listIntercity(params) {
  return request(`/api/bmkp-backend/customize/cityconfig/page?${stringify(params)}`);
}
export async function openListIntercity(params) {
  return request(`/api/bmkp-backend/customize/cityconfig/list?${stringify(params)}`);
}

// 添加城市
export async function submitIntercity(params) {
  return request('/api/bmkp-backend/customize/cityconfig/save', {
    method: 'POST',
    body: params,
  });
}
// 更新城市
export async function updateIntercity(params) {
  return request('/api/bmkp-backend/customize/cityconfig/update', {
    method: 'POST',
    body: params,
  });
}

// 更新城际同步配置
export async function syncCityConfig() {
  return request('/api/bmkp-backend/customize/cityconfig/sync', { method: 'GET' })
}

// 更新业务
export async function updateBusiness(params) {
  return request('/api/bmkp-backend/business/update', {
    method: 'POST',
    body: params
  })
}

// 根据城市查询其下所有的车型(id)
export async function getVehicleType(params) {
  return request(`/api/bmkp-backend/business/vehicleType?${stringify(params)}`, { method: 'GET'})
}

// 根据城市查询其下所有的车型(cityCode)
export async function getVehicleTypeByCode(params) {
  return request(`/api/bmkp-backend/business/vehicleType/by/code?${stringify(params)}`, { method: 'GET'})
}

// 城市配置渠道-获取渠道详情
export async function channelBusinessConfigDetail(params) {
  return request(`/api/bmkp-backend/business/channeBusinessConfig/channelbusinessconfig/detail?${stringify(params)}`, { method: 'GET'})
}

// 城市配置渠道-获取渠道分页
export async function channelBusinessConfigPage(params) {
  return request(`/api/bmkp-backend/channeBusinessConfig/channelbusinessconfig/page?${stringify(params)}`, { method: 'GET'})
}

// 城市配置渠道-渠道新增
export async function addChannelBusinessConfig(params) {
  return request(`/api/bmkp-backend/channeBusinessConfig/channelbusinessconfig/batchAdd`, { 
    method: 'POST',
    body: params
  })
}
// 城市配置渠道-渠道修改
export async function editChannelBusinessConfig(params) {
  return request(`/api/bmkp-backend/channeBusinessConfig/channelbusinessconfig/update`, { 
    method: 'POST',
    body: params
  })
}

// 城市配置年费包干-年费包干修改
export async function editAnnualFeeBusinessConfig(params) {
  return request(`/api/bmkp-backend/businessType/update`, { 
    method: 'POST',
    body: params
  })
}

// 代驾城市配置 保存
export async function saveDjConfig(params) {
  return request(`/api/bmkp-backend/business/saveDjBiz`, { 
    method: 'POST',
    body: params
  })
}

// 代驾城市配置 获取
export async function getDjConfig(params) {
  return request(`/api/bmkp-backend/business/getDjBiz?${stringify(params)}`)
}

// import { message } from 'antd';
// import router from 'umi/router';
import { CAPACITYMAP_NAMESPACE } from '@/actions/capacitymap';
import { list, cityList } from '@/services/capacitymap';

export default {
  namespace: CAPACITYMAP_NAMESPACE,
  state: {
    data: {},
    cityList: []
  },
  effects: {
    *fetchList({ payload, callback }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: response.data,
        });
        if (callback) {
          callback(response.data);
        }
      }
    },
    *fetchCityList({ payload, callback }, { call, put }) {
      const response = yield call(cityList, payload);
      if (response.success) {
        yield put({
          type: 'saveCityList',
          payload: response.data,
        });
        if (callback) {
          callback(response.data);
        }
      }
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveCityList(state, action) {
      return {
        ...state,
        cityList: action.payload,
      };
    },
  },
};

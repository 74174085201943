export const CAPACITYAGENT_NAMESPACE = 'capacityagent';

export function CAPACITYAGENT_LIST(params,callback) {
  return {
    type: `${CAPACITYAGENT_NAMESPACE}/fetchList`,
    payload:{...params,callback},
  };
}

export function CAPACITYAGENT_DETAIL(agentId, callback) {
  return {
    type: `${CAPACITYAGENT_NAMESPACE}/fetchDetail`,
    payload: { agentId, callback },
  };
}

export function CAPACITYAGENT_STATEOPEN(params, callback) {
  return {
    type: `${CAPACITYAGENT_NAMESPACE}/stateOpen`,
    payload: params,
    callback
  };
}

export function CAPACITYAGENT_STATECLOSE(params, callback) {
  return {
    type: `${CAPACITYAGENT_NAMESPACE}/stateClose`,
    payload: params,
    callback
  };
}

export function CAPACITYAGENT_CLEAR_DETAIL() {
  return {
    type: `${CAPACITYAGENT_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function CAPACITYAGENT_SUBMIT(payload) {
  return {
    type: `${CAPACITYAGENT_NAMESPACE}/submit`,
    payload,
  };
}

export function CAPACITYAGENT_REMOVE(payload) {
  return {
    type: `${CAPACITYAGENT_NAMESPACE}/remove`,
    payload,
  };
}

// 关联供应商
export function CAPACITYAGENT_CONNECT_SUPPLIER(payload) {
  return {
    type: `${CAPACITYAGENT_NAMESPACE}/connectSupplier`,
    payload,
  };
}

// 解除关联商
export function CAPACITYAGENT_REMOVE_SUPPLIER(payload) {
  return {
    type: `${CAPACITYAGENT_NAMESPACE}/removeSupplier`,
    payload,
  };
}

// 查询代理商关联的所有供应商
export function CAPACITYAGENT_SUPPLIER_LIST(payload) {
  return {
    type: `${CAPACITYAGENT_NAMESPACE}/supplierList`,
    payload,
  };
}

// 封禁代理商
export function CAPACITYAGENT_UNFROZEN(payload) {
  return {
    type: `${CAPACITYAGENT_NAMESPACE}/unfrozen`,
    payload,
  };
}

// 解封代理商frozen
export function CAPACITYAGENT_FROZEN(payload) {
  return {
    type: `${CAPACITYAGENT_NAMESPACE}/frozen`,
    payload,
  };
}

import { message, notification} from 'antd';
import router from 'umi/router';
import { ACTIVITY_BILL_NAMESPACE } from '../actions/activitybill';
import {
  driverAwardBillInside,
  thirdList,
  driverInviteBillInside,
  passengersInviteBillInside
} from '../services/activitybill';

export default {
  namespace: ACTIVITY_BILL_NAMESPACE,
  state: {
    // 司机奖励活动列表
    driverAwardBillDataInside: {
      list: [],
      pagination: false,
    },
    interThirdOrderList: {
      list: [],
      pagination: false,
    },
    driverInviteBillDataRewards: {
      list: [],
      pagination: false,
    },
    driverInviteBillDataCoupon: {
      list: [],
      pagination: false,
    },
    passengersInviteBillDataInside: {
      list: [],
      pagination: false,
    },
  },
  effects: {
    *fetchDriverAwardBillInside({ payload }, { call, put }) {
      const response = yield call(driverAwardBillInside, payload);
      if (response.success) {
        yield put({
          type: 'saveDriverAwardBillDataInside',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchInterThirdOrderList({ payload }, { call, put }) {
      const response = yield call(thirdList, payload);
      if (response.success) {
        yield put({
          type: 'saveInterThirdOrderList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDriverInviteBillRewards({ payload }, { call, put }) {
      const response = yield call(driverInviteBillInside, payload);
      if (response.success) {
        yield put({
          type: 'saveDriverInviteBillDataRewards',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDriverInviteBillCoupon({ payload }, { call, put }) {
      const response = yield call(driverInviteBillInside, payload);
      if (response.success) {
        yield put({
          type: 'saveDriverInviteBillDataCoupon',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchPassengersInviteBillInside({ payload }, { call, put }) {
      const response = yield call(passengersInviteBillInside, payload);
      if (response.success) {
        yield put({
          type: 'savePassengersInviteBillInside',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
  },
  reducers: {
    saveDriverAwardBillDataInside(state, action) {
      return {
        ...state,
        driverAwardBillDataInside: action.payload
      };
    },
    saveInterThirdOrderList(state, action) {
      return {
        ...state,
        interThirdOrderList: action.payload
      };
    },
    saveDriverInviteBillDataRewards(state, action) {
      return {
        ...state,
        driverInviteBillDataRewards: action.payload
      };
    },
    saveDriverInviteBillDataCoupon(state, action) {
      return {
        ...state,
        driverInviteBillDataCoupon: action.payload
      };
    },
    savePassengersInviteBillInside(state, action) {
      return {
        ...state,
        passengersInviteBillDataInside: action.payload
      };
    },
  },
};

import { message } from 'antd';
import router from 'umi/router';
import { DISTRICTDISPATCH_NAMESPACE } from '../actions/districtdispatch';
import { list, add, update, detail } from '../services/districtdispatch';

export default {
  namespace: DISTRICTDISPATCH_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: {},
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *add({ payload }, { call }) {
      const response = yield call(add, payload);
      if (response.success) {
        message.success('提交成功');
        router.push('/district_dispatch/list');
      }
    },
    *update({ payload, callback }, { call }) {
      const response = yield call(update, payload);
      if (response.success) {
        message.success('设置成功');
        callback()
      }
    },
    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          }
        })
        callback(response.data)
      }
    },
    *clearDetail({ payload }, { put }) {
      yield put({
        type: 'removeDetail',
        payload: { payload },
      });
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    },
  },
};

export const CARBRAND_NAMESPACE = 'carbrand';

export function CARBRAND_LIST(payload) {
  return {
    type: `${CARBRAND_NAMESPACE}/fetchList`,
    payload,
  };
}

export function CARBRAND_PAGE(payload) {
  return {
    type: `${CARBRAND_NAMESPACE}/fetchPage`,
    payload,
  };
}


export function CARBRAND_DETAIL(id) {
  return {
    type: `${CARBRAND_NAMESPACE}/fetchDetail`,
    payload: { id },
  };
}

export function CARBRAND_CLEAR_DETAIL() {
  return {
    type: `${CARBRAND_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function CARBRAND_SUBMIT(payload) {
  return {
    type: `${CARBRAND_NAMESPACE}/submit`,
    payload,
  };
}

export function CARBRAND_REMOVE(payload) {
  return {
    type: `${CARBRAND_NAMESPACE}/remove`,
    payload,
  };
}

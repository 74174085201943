export const DRIVERECLUE_NAMESPACE = 'driverclue';

export function DRIVER_CLUE_PAGE(payload, callback) {
  return {
    type: `${DRIVERECLUE_NAMESPACE}/page`,
    payload,
    callback
  };
}
export function DRIVER_CLUE_EXPORT(payload, callback) {
  return {
    type: `${DRIVERECLUE_NAMESPACE}/exportCluePage`,
    payload,
    callback
  };
}
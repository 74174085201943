import { stringify } from 'qs';
import request from '../utils/request';

export async function list(params) {
  return request(`/api/bmkp-backend/finance/adjustmentAccount/page?${stringify(params)}`)
}
export async function detail(params) {
  return request(`/api/bmkp-backend/finance/adjustmentAccount/detail?${stringify(params)}`)
}
export async function submit(params) {
  return request('/api/bmkp-backend/finance/adjustmentAccount/save', {
    method: 'POST',
    body: params,
  });
}
export async function pass(params) {
  return request('/api/bmkp-backend/finance/adjustmentAccount/pass', {
    method: 'POST',
    body: params,
  });
}
export async function reject(params) {
  return request('/api/bmkp-backend/finance/adjustmentAccount/reject', {
    method: 'POST',
    body: params,
  });
}
export async function cancel(params) {
  return request('/api/bmkp-backend/finance/adjustmentAccount/cancel', {
    method: 'POST',
    body: params,
  });
}


import { message } from 'antd';
import { WITHDRAWAL_NAMESPACE } from '../actions/withdrawal';
import { list, batchAudit, detail, 
  configPage, configDetail, configSubmit,
  insideConfigOpen, insideConfigClose
} from '../services/withdrawal';

export default {
  namespace: WITHDRAWAL_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: {},
    // configData: {
    //   list: [],
    //   pagination: false,
    // },
    cityConfigData: {
      list: [],
      pagination: false,
    },  // 分城市配置
    commonConfigDetail: {}, // 通用的配置
    configDetail: null // 具体配置详情
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDetail({ payload }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
      }
    },
    *configDetail({ payload, callback}, { call, put }) {
      const response = yield call(configDetail, payload);
      if (response.success) {
        yield put({
          type: 'saveConfigDetail',
          payload: {
            detail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
    *resetConfigDetail(payload, { put }) {
      yield put({
        type: 'saveConfigDetail',
        payload: {
          detail: null,
        },
      });
    },
    *clearDetail({ payload }, { put }) {
      yield put({
        type: 'removeDetail',
        payload: { payload },
      });
    },
    *batchAudit({ payload }, { call }) {
      const { data, completed } = payload;
      const response = yield call(batchAudit, data);
      if (response.success) {
        completed(true);
        message.success(response.msg)
      } else {
        completed(false);
        message.warn(response.msg)
      }
    },
    *configFetchList({ payload }, { call, put }) {
      const { configType } = payload
      const response = yield call(configPage, payload);
      if (response.success) {
        if (configType === 2) {
          yield put({
            type: 'saveCityConfigList',
            payload: {
              list: response.data.records,
              pagination: {
                total: response.data.total,
                current: response.data.current,
                pageSize: response.data.size,
              },
            },
          });
        } else {
          let detail = {}
          const { records } = response.data
          if (records && records.length > 0) {
            detail = records[0]
          }
          yield put({
            type: 'saveCommonConfigDetail',
            payload: { detail },
          });
        }
      }
    },
    *configFetchListReset({ payload}, { call, put }) {
        const { configType } = payload
        if (configType === 2) {
          yield put({
            type: 'saveCityConfigList',
            payload: {
              list: [],
              pagination: false,
            },
          });
        } else {
          yield put({
            type: 'saveCommonConfigDetail',
            payload: {},
          });
        }
    },
    *configSubmit({ payload, callback }, { call }) {
      const response = yield call(configSubmit, payload);
      if (response.success) {
        message.success('提交成功');
        callback && callback()
      } else {
        // message.error(response.msg); // 接口返回 400，已经在上一层拦截展示，此处不要了
      }
    },
    *insideConfigOpen({ payload, callback }, { call }) {
      const response = yield call(insideConfigOpen, payload);
      if (response.success) {
        message.success('开启成功');
        callback && callback()
      } else {
        message.error(response.msg);
      }
    },
    *insideConfigClose({ payload, callback }, { call }) {
      const response = yield call(insideConfigClose, payload);
      if (response.success) {
        message.success('关闭成功');
        callback && callback()
      } else {
        message.error(response.msg);
      }
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    },
    saveConfigDetail(state, action) {
      return {
        ...state,
        configDetail: action.payload.detail,
      };
    },
    saveCityConfigList(state, action) {
      return {
        ...state,
        cityConfigData: action.payload,
      };
    },
    saveCommonConfigDetail(state, action) {
      return {
        ...state,
        commonConfigDetail: action.payload.detail,
      }
    },
  },
};

import { message } from 'antd';
import { ORDERDISPATCH_NAMESPACE } from '../actions/orderdispatch';
import { list, orderList, orderAllot, orderDetail, driverList, audioOrder, setPlaytState, queryPlaytState } from '../services/orderdispatch';

export default {
  namespace: ORDERDISPATCH_NAMESPACE,
  state: {
    // 某条线路下的订单分页
    order: {
      list: [],
      pagination: false,
    },
    // 所有线路
    data: [],
    hasMore: true,
    // 订单详情
    orderDetail: {},
    // 司机列表分页
    driverData: {
      list: [],
      pagination: false,
    },
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const { success, params } = payload
      const response = yield call(list, params);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: response.data || []
        });
        success(response.data)
      }
    },
    *fetchOrderList({ payload }, { call, put }) {
      const response = yield call(orderList, payload);
      if (response.success) {
        yield put({
          type: 'saveOrderList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDriverList({ payload }, { call, put }) {
      const response = yield call(driverList, payload);
      if (response.success) {
        yield put({
          type: 'saveDriverList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchOrderDetail({ payload }, { call, put }) {
      const response = yield call(orderDetail, payload);
      if (response.success) {
        yield put({
          type: 'saveOrderDetail',
          payload: {
            detail: response.data,
          },
        });
      }
    },
    *clearOrderDetail({ payload }, { put }) {
      yield put({
        type: 'removeOrderDetail',
        payload: { payload },
      });
    },
    *setOrderAllot({ payload }, { call }) {
      const {
        data,
        success,
      } = payload;
      const response = yield call(orderAllot, data);
      if (response.success) {
        message.success('提交成功');
        success()
      }
    },
    *queryPlaytState({ callback }, { call }) {
      const response = yield call(queryPlaytState);
      if (response.success) {
        callback(response.data.isReportOrder)
      }
    },
    *setPlayState({ payload, callback }, { call }) {
      const response = yield call(setPlaytState, payload);
      if (response.success) {
        message.success(response.msg);
        callback(payload)
      }
    },
    *fetchAudioOrders({ payload }, { call, put }) {
      const { success } = payload;
      const response = yield call(audioOrder, {})
      if (response.success && response.data.length > 0) {
        yield put({
          type: 'saveAudioOrderList',
          payload: response.data
        });
        success(true)
      }
    },
    *removeAlreadyReadDot({ payload }, { put }) {
      yield put({
        type: 'saveAlreadyReadLine',
        payload
      })
    }
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveOrderList(state, action) {
      return {
        ...state,
        order: action.payload,
      };
    },
    saveDriverList(state, action) {
      return {
        ...state,
        driverData: action.payload,
      };
    },
    saveOrderDetail(state, action) {
      return {
        ...state,
        orderDetail: action.payload.detail,
      };
    },
    removeOrderDetail(state) {
      return {
        ...state,
        orderDetail: {},
      };
    },
    saveAudioOrderList(state, action) {
      const audioList = action.payload
      const newData = [...state.data]
      return {
        ...state,
        data: newData.map(line => {
          const isExist = audioList.some(i => i.lineId === line.lineId)
          if (isExist) {
            line.isNew = true
          }
          return line
        })
      };
    },
    saveAlreadyReadLine(state, action) {
      const { lineId } = action.payload
      const newData = [...state.data]
      return {
        ...state,
        data: newData.map(line => {
          const isExist = lineId === line.lineId
          if (isExist) {
            delete line.isNew
          }
          return line
        })
      };
    }
  }
};

import { message, notification} from 'antd';
import {
  list,
  submit,
  repeal,
  detail,
  getActivityList
} from '@/services/messagedrivers';
import { MESSAGE_DRIVER_NAMESPACE } from '@/actions/messagedrivers';

export default {
  namespace: MESSAGE_DRIVER_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: null,
    activityList: []
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
        // eslint-disable-next-line no-unused-expressions
        callback && callback(response.data)
      }
    },
    *submit({ payload, callback}, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('提交成功');
      }
      callback && callback(response.success)
    },
    *repeal({ payload, callback}, { call }) {
      const response = yield call(repeal, payload);
      if (response.success) {
        message.success('撤回成功');
      }
      callback && callback(response.success)
    },
    *clearDetail({ callback}, { call, put }) {
      yield put({
        type: 'saveDetail',
        payload: {
          detail: {},
        },
      });
      // eslint-disable-next-line no-unused-expressions
      callback && callback()
    },
    *activityList({payload}, { call, put }) {
      const response = yield call(getActivityList, payload);
      if (response.success) {
        yield put({
          type: 'saveActivityList',
          payload: response.data,
        });
      }
    },
    *activityListClear({}, { call, put }) {
        yield put({
          type: 'saveActivityList',
          payload: [],
        });
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveActivityList(state, action) {
      return {
        ...state,
        activityList: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    }
  },
};

// 正整数
export const positiveInteger = /^([1-9][0-9]*)$/

// 0 和 正整数
export const positiveIntegerAndZero = /^([0]|[1-9][0-9]*)$/

// 手机号正则表达式
export const phoneReg = /^1\d{10}$/

// 金额正则表达式
export const moneyReg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/

// 不能输0金额正则表达式
export const moneyRegOne = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/

// 网站正则表达式
export const webUrlReg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

// 里程正则表达式
export const kilometerReg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/

// 0 - 100 之间的整数
export const zero2hundred = /^([0-9]|[1-9]\d|100)$/

// 身份证格式
export const idCardReg =  /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/

// 手机号  + 座机正则
export const phoneAndLandlineReg = /^1[0-9]{10}|^\d{4}-\d{7}|\d{3}-\d{8}|^\d{10}$/
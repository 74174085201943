import request from '../utils/request';

export async function list(params) {
  return request('/api/bmkp-backend/feedback/type/page', {
    method: 'POST',
    body: params,
  })
}

export async function submit(params) {
  return request('/api/bmkp-backend/feedback/type/add', {
    method: 'POST',
    body: params,
  });
}


import { message } from 'antd';
import { ASSETDRIVER_APPLY_NAMESPACE } from '../actions/assetdriverapply';
import { list, detail, updateStatus} from '../services/assetdriverapply';

export default {
  namespace: ASSETDRIVER_APPLY_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: {},
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDetail({ payload }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
      }
    },
    *updateStatus({ payload }, { call }) {
      const {
        data,
        completed,
      } = payload;
      const response = yield call(updateStatus, data);
      if (response.success) {
        completed(true);
        message.success(response.msg)
      } else {
        completed(false);
        message.warn(response.msg)
      }
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
  },
};

import { message, notification} from 'antd';
import { DISPATCHER_NAMESPACE } from '@/actions/dispatcher';
import {
  list,
  submit
} from '@/services/dispatcher';

export default {
  namespace: DISPATCHER_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: null
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
        // eslint-disable-next-line no-unused-expressions
        callback && callback(response.data)
      }
    },
    *submit({ payload, callback}, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('提交成功');
        // eslint-disable-next-line no-unused-expressions
        callback && callback(true)
      } else {
        message.error(response.msg);
        notification.error({
          message: '错误提示',
          description: response.msg
        })
      }
    }
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    }
  },
};

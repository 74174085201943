export const COMMON_TAB_INDEX_NAMESPACE = 'commonTabIndex';

export function SET_VEHICLE_TAB_INDEX(index) {
  return {
    type: `${COMMON_TAB_INDEX_NAMESPACE}/setVehicleTabIndex`,
    payload: {index},
  };
}
export function SET_ORDER_PROOFREAD_INSIDE_TAB_INDEX(index) {
  return {
    type: `${COMMON_TAB_INDEX_NAMESPACE}/setOrderProofreadInsideTabIndex`,
    payload: {index},
  };
}
export function SET_OPERATE_CONFIG_LIST_TAB_INDEX(index) {
  return {
    type: `${COMMON_TAB_INDEX_NAMESPACE}/setOperateConfigListTabIndex`,
    payload: {index},
  };
}

export function SET_DRIVER_OPERATION_LIST_TAB_INDEX(index) {
  return {
    type: `${COMMON_TAB_INDEX_NAMESPACE}/setDriverOperationListTabIndex`,
    payload: {index}
  }
}

export function SET_INTERCITY_WITHDRAWAL_CONFIG_TAB_INDEX(index) {
  return {
    type: `${COMMON_TAB_INDEX_NAMESPACE}/setIntercityWithdrawalConfigTabIndex`,
    payload: {index}
  }
}

export function SET_VOICE_CONFIG_DRIVE_TAB_INDEX(index) {
  return {
    type: `${COMMON_TAB_INDEX_NAMESPACE}/setVoiceConfigDriveTabIndex`,
    payload: {index}
  }
}

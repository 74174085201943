import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

export async function submit(params) {
  return request('/api/bmkp-backend/customize/linecancelrules/submit', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request(`/api/bmkp-backend/customize/linecancelrules/detail?${stringify(params)}`);
}

/** 城内 */
export async function listInside(params) {
  return request('/api/bmkp-wyc/wyc/driver/cancel/rule/query/v1', {
    method: 'POST',
    body: params,
  });
}
/** 城内保存和更新 */
export async function submitInside(params) {
  return request('/api/bmkp-wyc/wyc/driver/cancel/rule/submit/v1', {
    method: 'POST',
    body: params,
  });
}
export const ORDERTRANSACTION_NAMESPACE = 'ordertransaction';

export function ORDERTRANSACTION_LIST(payload) {
  return {
    type: `${ORDERTRANSACTION_NAMESPACE}/fetchList`,
    payload,
  };
}

export function ORDERTRANSACTION_DETAIL(orderNo) {
  return {
    type: `${ORDERTRANSACTION_NAMESPACE}/fetchDetail`,
    payload: { orderNo },
  };
}

export function ORDERTRANSACTION_CLEAR_DETAIL() {
  return {
    type: `${ORDERTRANSACTION_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

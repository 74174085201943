export const INSIDEORDER_NAMESPACE = 'insideorder';

export function INSIDEORDER_LIST(payload) {
  return {
    type: `${INSIDEORDER_NAMESPACE}/fetchList`,
    payload,
  };
}

export function INSIDEORDER_DETAIL(orderNo, callback) {
  return {
    type: `${INSIDEORDER_NAMESPACE}/fetchDetail`,
    payload: { orderNo },
    callback
  };
}

export function INSIDEORDER_CLEAR_DETAIL() {
  return {
    type: `${INSIDEORDER_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function INSIDEORDER_CREATE(payload) {
  return {
    type: `${INSIDEORDER_NAMESPACE}/create`,
    payload,
  };
}

// export function INSIDEORDER_CANCEL(payload, callback) {
//   return {
//     type: `${INSIDEORDER_NAMESPACE}/cancel`,
//     payload,
//     callback
//   };
// }

// 改派订单
export function INSIDEORDER_CHANGE_ORDER(payload, callback) {
  return {
    type: `${INSIDEORDER_NAMESPACE}/changeOrder`,
    payload,
    callback
  };
}

// 关闭订单
export function INSIDEORDER_CLOSE_ORDER(payload, callback) {
  return {
    type: `${INSIDEORDER_NAMESPACE}/closeOrder`,
    payload,
    callback
  };
}

// 结束行程
export function INSIDEORDER_FINISH_ORDER(payload, callback) {
  return {
    type: `${INSIDEORDER_NAMESPACE}/finishOrder`,
    payload,
    callback
  };
}

// 退款申请
export function INSIDEORDER_REFUND_APPLY(payload, callback) {
  return {
    type: `${INSIDEORDER_NAMESPACE}/refundApply`,
    payload,
    callback
  };
}

// 修改金额
export function INSIDEORDER_UPDATE_FEE(payload, callback) {
  return {
    type: `${INSIDEORDER_NAMESPACE}/updateFee`,
    payload,
    callback
  };
}

// 停止校验乘客手机号
export function INSIDEORDER_STOP_CHECK_PASSENGER_PHONE(payload, callback) {
  return {
    type: `${INSIDEORDER_NAMESPACE}/stopCheckPassengerPhone`,
    payload,
    callback
  }
}

// 查询行程录音
export function INSIDEORDER_QUERY_RECORDING(payload, callback) {
  return {
    type: `${INSIDEORDER_NAMESPACE}/queryRecording`,
    payload,
    callback
  }
}

// 修改金额前查询金额
export function INSIDEORDER_QUERY_PRICE_WITH_UPDATE(payload, callback) {
  return {
    type: `${INSIDEORDER_NAMESPACE}/queryPriceWithUpdate`,
    payload,
    callback
  }
}

// 结束行程前查询金额
export function INSIDEORDER_QUERY_PRICE_WITH_TRIP(payload, callback) {
  return {
    type: `${INSIDEORDER_NAMESPACE}/queryPriceWithTrip`,
    payload,
    callback
  }
}

// 退款申请前查询金额
export function INSIDEORDER_QUERY_PRICE_WITH_APPLY(payload, callback) {
  return {
    type: `${INSIDEORDER_NAMESPACE}/queryPriceWithApply`,
    payload,
    callback
  }
}

export function INSIDEORDER_CLEAR_APPLY_DETAIL() {
  return {
    type: `${INSIDEORDER_NAMESPACE}/clearApplyDetail`,
    payload: {},
  };
}

export function INSIDEORDER_CLEAR_UPDATE_DETAIL() {
  return {
    type: `${INSIDEORDER_NAMESPACE}/clearUpdateFeeDetail`,
    payload: {},
  };
}


export function INSIDEORDER_CLEAR_TRIP_DETAIL() {
  return {
    type: `${INSIDEORDER_NAMESPACE}/clearTripDetail`,
    payload: {},
  };
}

// 修改金额前根据修改的公里数和时间获取行程费用 或 结束行程前根据修改的公里数和时间获取行程费用
export function INSIDEORDER_CALC_PRICE(payload, option) {
  return {
    type: `${INSIDEORDER_NAMESPACE}/calcPriceWithTrip`,
    payload,
    option
  }
}

export function INSIDEORDER_CLEAR_CALC_TRIP_DETAIL() {
  return {
    type: `${INSIDEORDER_NAMESPACE}/clearCalcTripDetail`,
    payload: {},
  };
}


import { message } from 'antd';
import router from 'umi/router';
import { COUPON_NAMESPACE } from '../actions/invitepassengers';
import {
  list,
} from '../services/invitepassengers';

export default {
  namespace: COUPON_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: {}
  },
  effects: {
    *fetchList({ payload, callback }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
        if(callback) {
          callback(response.data)
        }
      }
    },
    
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
  },
};

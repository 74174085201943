import request from '../utils/request';

// 订单交易记录分页
export async function list(params) {
  return request('/api/bmkp-backend/blade-order/order/trade/page', {
    method: 'POST',
    body: params,
  });
}

// 订单交易记录详情
export async function detail(params) {
  return request('/api/bmkp-backend/blade-order/order/trade/detail', {
    method: 'POST',
    body: params,
  });
}

import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

export async function list(params) {
  return request('/api/bmkp-backend/finance/driver/balance/page', {
    method: 'POST',
    body: params,
  })
}

export async function submit(params) {
  return request('/api/order/dispatch/submit', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request(`/api/order/dispatch/detail?${stringify(params)}`);
}
// 冻结
export async function freeze(params) {
  return request('/api/bmkp-backend/finance/driver/wallet/freeze', {
    method: 'POST',
    body: params,
  });
}
// 解冻
export async function unfreeze(params) {
  return request('/api/bmkp-backend/finance/driver/wallet/unfreeze', {
    method: 'POST',
    body: params,
  });
}

export async function remove(params) {
  return request('/api/order/dispatch/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}

import { message } from 'antd';
import router from 'umi/router';
import { ASSETCAR_NAMESPACE } from '../actions/assetcar';
import {
  list,
  // 删除车辆
  carDelete,
  usableCarList,
  submit,
  detail,
  remove,

  submitOtherInfo,

  getCarhailinglicense,
  submitCarhailinglicense,

  getDriverhailinglicense,
  submitDriverhailinglicense,

  getCarlicense,
  submitCarlicense,

  getCartransport,
  submitCartransport,

  importHistoryList,
  importErrorDetail,

  detailCarinsurance,
  submitCarinsurance
} from '../services/assetcar';

export default {
  namespace: ASSETCAR_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    // 删除车辆
    carDeleteData: {},
    importErrorDetail: {
      list: [],
      pagination: false,
    },
    detail: {},
    // 网约车运输证
    carhailinglicense: {},
    // 网约车驾驶证
    driverhailinglicense: {},
    // 车辆行驶证
    carlicense: {},
    // 道路运营证
    cartransport: {},
    // 商业险
    carinsurance: {},
    // usableCarList: [] // 可用车辆,
    historyData: [] // 历史记录
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },

    // 删除车辆
    *fetchDelete({ payload, callback }, { call, put }) {
      const response = yield call(carDelete, payload);
      if (response.success) {
        message.success('删除成功');
      }
      callback && callback(response)
    },

    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
    *fetchUsableCarList({ payload, callback }, { call, put }) {
      const response = yield call(usableCarList, payload);
      if (response.success) {
        // yield put({
        //   type: 'saveUsableCarList',
        //   payload: {
        //     list: response.data.records,
        //     pagination: {
        //       total: response.data.total,
        //       current: response.data.current,
        //       pageSize: response.data.size,
        //     },
        //   },
        // });
        callback && callback(response.data)
      }
    },
    *fetchCarhailinglicense({ payload, callback }, { call, put }) {
      const response = yield call(getCarhailinglicense, payload);
      if (response.success) {
        yield put({
          type: 'saveCarhailinglicenseDetail',
          payload: {
            carhailinglicense: response.data,
          },
        })
        callback && callback(response.data)
      }
    },
    *submitCarhailinglicense({ payload }, { call }) {
      const response = yield call(submitCarhailinglicense, payload);
      if (response.success) {
        message.success('提交成功');
      }
    },
    *fetchDriverhailinglicense({ payload, callback }, { call, put }) {
      const response = yield call(getDriverhailinglicense, payload);
      if (response.success) {
        yield put({
          type: 'saveDriverhailinglicenseDetail',
          payload: {
            driverhailinglicense: response.data,
          },
        })
        callback && callback(response.data)
      }
    },
    *submitDriverhailinglicense({ payload }, { call }) {
      const response = yield call(submitDriverhailinglicense, payload);
      if (response.success) {
        message.success('提交成功');
      }
    },
    *fetchCarlicense({ payload }, { call, put }) {
      const response = yield call(getCarlicense, payload);
      if (response.success) {
        yield put({
          type: 'saveCarlicense',
          payload: {
            carlicense: response.data,
          },
        })
      }
    },
    *submitCarlicense({ payload }, { call }) {
      const response = yield call(submitCarlicense, payload);
      if (response.success) {
        message.success('提交成功');
      }
    },
    *fetchCartransport({ payload, callback }, { call, put }) {
      const response = yield call(getCartransport, payload);
      if (response.success) {
        yield put({
          type: 'saveCartransport',
          payload: {
            cartransport: response.data,
          },
        })
        callback && callback(response.data)
      }
    },
    *submitCartransport({ payload }, { call }) {
      const response = yield call(submitCartransport, payload);
      if (response.success) {
        message.success('提交成功');
      }
    },
    *clearDetail({ payload }, { put }) {
      yield put({
        type: 'removeDetail',
        payload: { payload },
      });
    },
    *clearCarlicenseDetail({ payload }, { put }) {
      yield put({
        type: 'removeCarlicenseDetail',
        payload: { payload },
      });
    },
    *clearCarhailinglicenseDetail({ payload }, { put }) {
      yield put({
        type: 'removeCarhailinglicenseDetail',
        payload: { payload },
      });
    },
    *clearDriverhailinglicenseDetail({ payload }, { put }) {
      yield put({
        type: 'removeDriverhailinglicenseDetail',
        payload: { payload },
      });
    },
    *clearCartransportDetail({ payload }, { put }) {
      yield put({
        type: 'removeCartransportDetail',
        payload: { payload },
      });
    },
    *submit({ payload }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('提交成功');
        // const { id } = response.data
        router.goBack()
      }
    },
    *remove({ payload }, { call }) {
      const {
        data: { keys },
        success,
      } = payload;
      const response = yield call(remove, { ids: keys });
      if (response.success) {
        success();
      }
    },
    *importHistory({ payload }, { put, call }) {
      const response = yield call(importHistoryList, payload);
      if (response.success) {
        yield put({
          type: 'saveImportHistoryList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchBindErrorDetail({ payload, callback }, { call, put }) {
      const response = yield call(importErrorDetail, payload);
      if (response.success) {
        yield put({
          type: 'saveImportErrorDetail',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
        callback && callback(response.data)
      }
    },
    *fetchCarinsuranceDetail({ payload, callback }, { call, put }) {
      const response = yield call(detailCarinsurance, payload);
      if (response.success) {
        yield put({
          type: 'saveCarinsuranceDetail',
          payload: response.data,
        });
        callback && callback(response.data)
      }
    },
    *carinsuranceSubmit({ payload, callback }, { call }) {
      const response = yield call(submitCarinsurance, payload);
      if (response.success) {
        message.success('提交成功');
        callback && callback?.(response.data)
      }
    },
    *otherInfoSubmit({ payload, callback }, { call }) {
      const response = yield call(submitOtherInfo, payload);
      if (response.success) {
        message.success('提交成功');
        callback && callback?.(response.data)
      }
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveImportHistoryList(state, action) {
      return {
        ...state,
        historyData: action.payload
      }
    },
    // saveUsableCarList(state, action) {
    //   return {
    //     ...state,
    //     usableCarList: action.payload,
    //   };
    // },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    saveCarhailinglicenseDetail(state, action) {
      return {
        ...state,
        carhailinglicense: action.payload.carhailinglicense || {
          licenseNo: '',
          serviceType: '',
          reportDate: '',
          registDate: '',
          issuingOrganization: '',
          frontPhoto: '',
          expirateDate: '',
          effectiveDate: '',
          businessArea: ''
        }
      }
    },
    saveDriverhailinglicenseDetail(state, action) {
      return {
        ...state,
        driverhailinglicense: action.payload.driverhailinglicense || {
          licenseNo: '',
          serviceType: '',
          reportDate: '',
          registDate: '',
          issuingOrganization: '',
          frontPhoto: '',
          expirateDate: '',
          effectiveDate: '',
          businessArea: ''
        }
      }
    },
    saveCarlicense(state, action) {
      return {
        ...state,
        carlicense: action.payload.carlicense || {
          carType: '',
          platePhoto: '',
          registDate: '',
          useType: '',
          vinCode: '',
          licensePhotoFront: '',
          licensePhotoBack: '',
          issueDate: '',
          groupPhoto: '',
          engineNo: '',
          colourType: '',
        }
      }
    },
    saveCartransport(state, action) {
      return {
        ...state,
        cartransport: action.payload.cartransport || {
          effectiveDate: '',
          expirateDate: '',
          frontPhoto: '',
          businessName: '',
          businessScope: '',
          address: '',
          licenseNo: '',
          sizeHeight: '',
          sizeLength: '',
          sizeWidth: '',
          tonPosition: ''
        }
      }
    },
    saveImportErrorDetail(state, action) {
      return {
        ...state,
        importErrorDetail: action.payload,
      };
    },
    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    },
    removeCarlicenseDetail(state) {
      return {
        ...state,
        carlicense: {},
      };
    },
    removeCarhailinglicenseDetail(state) {
      return {
        ...state,
        carhailinglicense: {},
      };
    },
    removeDriverhailinglicenseDetail(state) {
      return {
        ...state,
        driverhailinglicense: {},
      };
    },
    removeCartransportDetail(state) {
      return {
        ...state,
        cartransport: {},
      };
    },
    saveCarinsuranceDetail(state, action) {
      return {
        ...state,
        carinsurance: action.payload,
      };
    },
  },
};

import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

export async function submit(params) {
  return request('/api/bmkp-backend/customize/lineshiftconfig/submit', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  // return request(`/api/bmkp-backend/customize/lineshiftconfig/detail?${stringify(params)}`);
  return request('/api/bmkp-backend/customize/lineshiftconfig/detail', {
    method: 'POST',
    body: params,
  });
}

export async function switchActivedType(params) {
  return request('/api/bmkp-backend/customize/lineshiftconfig/changeType', {
    method: 'POST',
    body: func.toFormData(params),
  });
}
export async function removeSomedaySchedule(params) {
  return request('/api/bmkp-backend/customize/lineshiftconfig/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}

// 获取班次
export async function queryShiftbyline(params) {
  return request(`/api/bmkp-backend/customize/lineshiftconfig/shiftbyline?${stringify(params)}`, {
    method: 'POST',
  })
}
import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

// 城际活动列表(司机)
export async function list(params) {
  return request('/api/bmkp-backend/activity/page', {
    method: 'POST',
    body: params,
  });
}

// 城际活动添加（司机)
export async function submit(params) {
  return request('/api/bmkp-backend/activity/add', {
    method: 'POST',
    body: params,
  });
}

export async function update(params) {
  return request('/api/bmkp-backend/activity/edit', {
    method: 'POST',
    body: params,
  });
}

export async function pass(params) {
  return request('/api/bmkp-backend/activity/pass', {
    method: 'POST',
    body: params,
  });
}
export async function reject(params) {
  return request('/api/bmkp-backend/activity/reject', {
    method: 'POST',
    body: params,
  });
}
export async function reApply(params) {
  return request('/api/bmkp-backend/activity/reApply', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request('/api/bmkp-backend/activity/detail', {
    method: 'POST',
    body: params,
  });
}
export async function remove(params) {
  return request('/api/bmkp-backend/activity/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}
export async function start(params) {
  return request('/api/bmkp-backend/activity/start', {
    method: 'POST',
    body: params,
  });
}

export async function stop(params) {
  return request('/api/bmkp-backend/activity/stop', {
    method: 'POST',
    body: params,
  });
}

// 城内活动 
export async function listInside(params) {
  return request(`/api/bmkp-backend/wycActivityDriverAward/page?${stringify(params)}`);
}

export async function submitInside(params) {
  return request('/api/bmkp-backend/wycActivityDriverAward/save', {
    method: 'POST',
    body: params,
  });
}
export async function updateInside(params) {
  return request('/api/bmkp-backend/wycActivityDriverAward/update', {
    method: 'POST',
    body: params,
  });
}
export async function openInside(params) {
  return request('/api/bmkp-backend/wycActivityDriverAward/open', {
    method: 'POST',
    body: params,
  });
}
export async function closeInside(params) {
  return request('/api/bmkp-backend/wycActivityDriverAward/close', {
    method: 'POST',
    body: params,
  });
}
export async function detailInside(params) {
  return request('/api/bmkp-backend/wycActivityDriverAward/detail', {
    method: 'POST',
    body: params,
  });
}
export async function removeInside(params) {
  return request('/api/bmkp-backend/wycActivityDriverAward/delete', {
    method: 'POST',
    body: params,
  });
}

// 司机活动账单
export async function driverAwardBillInside(params) {
  return request(`/api/bmkp-backend/wycActivityDriverAwardBill/page?${stringify(params)}`);
}

// --- 城际乘客活动 --
// 城际活动添加（乘客)
export async function submitPassenger(params) {
  return request('/api/bmkp-backend/activitynew/add', {
    method: 'POST',
    body: params,
  });
}
export async function updatePassenger(params) {
  return request('/api/bmkp-backend/activitynew/edit', {
    method: 'POST',
    body: params,
  });
}
export async function deleteLinkCouponPassenger(params) {
  return request('/api/bmkp-backend/activitynewlinkcoupon/delete', {
    method: 'POST',
    body: func.toFormData(params),
  });
}

// 城际活动列表(乘客)
export async function listPassenger(params) {
  return request('/api/bmkp-backend/activitynew/list', {
    method: 'POST',
    body: params,
  });
}

export async function detailPassenger(params) {
  return request('/api/bmkp-backend/activitynew/detail', {
    method: 'POST',
    body: params,
  });
}
export async function openPassenger(params) {
  return request('/api/bmkp-backend/activitynew/open', {
    method: 'POST',
    body: params,
  });
}
export async function closePassenger(params) {
  return request('/api/bmkp-backend/activitynew/close', {
    method: 'POST',
    body: params,
  });
}
export async function removePassenger(params) {
  return request('/api/bmkp-backend/activitynew/delete', {
    method: 'POST',
    body: params,
  });
}
export async function statisticsPassenger(params) {
  return request('/api/bmkp-backend/activitynew/showData', {
    method: 'POST',
    body: params,
  });
}

// 城际营销活动账单
export async function activitynewcouponbill(params) {
  return request('/api/bmkp-backend/finance/activitynewcouponbill/list', {
    method: 'POST',
    body: params,
  });
}

// 城内乘客邀请活动列表
export async function activityInvitationList(params) {
  return request('/api/bmkp-backend/finance/activitynewcouponbill/list', {
    method: 'POST',
    body: params,
  });
}

// 城内司机邀请活动-分页
export async function getDriverInvitePage(params) {
  return request(`/api/bmkp-backend/driverInvite/driverinvite/page?${stringify(params)}`);
}

// 城内司机邀请活动-详情
export async function getDriverInviteDetail(params) {
  return request(`/api/bmkp-backend/driverInvite/driverinvite/detail?${stringify(params)}`);
}

// 城内司机邀请活动-新增
export async function activityInvitationAdd(params) {
  return request('/api/bmkp-backend/driverInvite/driverinvite/save', {
    method: 'POST',
    body: params,
  });
}

// 城内司机邀请活动-修改
export async function activityInvitationUpdate(params) {
  return request('/api/bmkp-backend/driverInvite/driverinvite/update', {
    method: 'POST',
    body: params,
  });
}

// 城内司机邀请活动-关闭
export async function activityInvitationClose(params) {
  return request(`/api/bmkp-backend/driverInvite/driverinvite/close?${stringify(params)}`, {
    method: 'POST',
  });
}

// 城内司机邀请活动-开启
export async function activityInvitationOpen(params) {
  return request(`/api/bmkp-backend/driverInvite/driverinvite/open?${stringify(params)}`, {
    method: 'POST',
  });
}

// 城内司机邀请活动-删除
export async function activityInvitationDelete(params) {
  return request(`/api/bmkp-backend/driverInvite/driverinvite/remove?${stringify(params)}`, {
    method: 'POST'
  });
}
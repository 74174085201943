export const VEHICLETYPE_NAMESPACE = 'vehicleType';

export function VEHICLETYPE_LIST(payload, callback) {
  return {
    type: `${VEHICLETYPE_NAMESPACE}/fetchList`,
    payload,
    callback
  };
}

export function VEHICLETYPE_DETAIL(id) {
  return {
    type: `${VEHICLETYPE_NAMESPACE}/fetchDetail`,
    payload: { id },
  };
}

export function VEHICLETYPE_CLEAR_DETAIL() {
  return {
    type: `${VEHICLETYPE_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function VEHICLETYPE_SUBMIT(payload) {
  return {
    type: `${VEHICLETYPE_NAMESPACE}/submit`,
    payload,
  };
}
export function VEHICLETYPE_UPDATE(payload, callback) {
  return {
    type: `${VEHICLETYPE_NAMESPACE}/update`,
    payload,
    callback
  };
}

export function VEHICLETYPE_REMOVE(payload) {
  return {
    type: `${VEHICLETYPE_NAMESPACE}/remove`,
    payload,
  };
}

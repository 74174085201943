import { message } from 'antd';
import router from 'umi/router';
import { AUTO_NAMESPACE } from '../actions/autoreceive';
import { list, bind, unbind, driverList } from '../services/autoreceive';

export default {
  namespace: AUTO_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    driverList: {
      list: [],
      pagination: false,
    },
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDriverList({ payload, callback }, { call, put }) {
      const response = yield call(driverList, payload);
      if (response.success) {
        yield put({
          type: 'saveDriverList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
        callback && callback(response.data.records);
      }
    },
    *clearList({}, { put }) {
      yield put({
        type: 'saveList',
        payload: {
          list: [],
          pagination: null,
        },
      });
    },
    *clearDriverList({}, { put }) {
      yield put({
        type: 'saveDriverList',
        payload: {
          list: [],
          pagination: null,
        },
      });
    },
    *bind({ payload, callback }, { call }) {
      const response = yield call(bind, payload);
      if (response.success) {
        message.success(response.msg);
      }
      callback && callback(response.success);
    },
    *unbind({ payload, callback }, { call }) {
      const response = yield call(unbind, payload);
      if (response.success) {
        message.success(response.msg);
      }
      callback && callback(response.success);
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDriverList(state, action) {
      return {
        ...state,
        driverList: action.payload,
      };
    },
  },
};

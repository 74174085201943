import { stringify } from 'qs';
import request from '../utils/request'

// 运营位管理列表
export async function list(params) {
  return request(`api/bmkp-wyc/wyc/ad/page?${stringify(params)}`)
}

// 新增
export async function save(params) {
  return request('/api/bmkp-wyc/wyc/ad/save', {
    method: 'POST',
    body: params,
  });
}

// 修改
export async function update(params) {
  return request('/api/bmkp-wyc/wyc/ad/update', {
    method: 'POST',
    body: params,
  });
}

// 详情
export async function detail(params) {
  return request(`/api/bmkp-wyc/wyc/ad/detail?${stringify(params)}`, {
    method: 'POST'
  });
}

// 新增运营位约束
export async function saveLink(params) {
  return request('/api/bmkp-wyc/wyc/ad/saveLink', {
    method: 'POST',
    body: params,
  });
}

// 修改运营位约束
export async function updateLink(params) {
  return request('/api/bmkp-wyc/wyc/ad/updateLink', {
    method: 'POST',
    body: params,
  });
}

// 删除运营位约束
export async function removeLink(params) {
  return request(`/api/bmkp-wyc/wyc/ad/removeLink?${stringify(params)}`, {
    method: 'POST',
  });
}
import { message, notification } from 'antd';
import { INSIDECITY_INVITE_ACTIVITY } from '../actions/inviteactivity';

import { detail,
  page,
  open,
  close,
  invitedelete,
  save,
  update,
  copy,
  couponDelete } from '../services/inviteactivity';

export default {
  namespace: INSIDECITY_INVITE_ACTIVITY,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: {}
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(page, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        })
        callback && callback(response.data)
      }
    },
    // 清除城际乘客活动
    *clearDetailPassenger({}, { put }) {
      yield put({
        type: 'saveDetail',
        payload: {
          detail: {},
        },
      });
    },
    *open({ payload, callback }, { call }) {
      const response = yield call(open, payload);
      if (response.success) {
        callback && callback(response.data)
      }
    },
    *close({ payload, callback }, { call }) {
      const response = yield call(close, payload);
      if (response.success) {
        callback && callback(response.data)
      }
    },
    *invitedelete({ payload, callback }, { call }) {
      const response = yield call(invitedelete, payload);
      if (response.success) {
        callback && callback(response.data)
      }
    },
    *save({ payload, callback }, { call }) {
      const response = yield call(save, payload);
      if (response.success) {
        message.success('添加成功');
        callback && callback(response.data)
      }
    },
    *update({ payload, callback }, { call }) {
      const response = yield call(update, payload);
      if (response.success) {
        message.success('更新成功');
        callback && callback(response.data)
      }
    },
    *copy({ payload, callback }, { call }) {
      const response = yield call(copy, payload);
      if (response.success) {
        callback && callback(response.data)
      }
    },
    *couponDelete({ payload, callback }, { call }) {
      const response = yield call(couponDelete, payload);
      if (response.success) {
        callback(true)
      }else{
        callback(false)
        notification.error({
          message: '错误提示',
          description: response.msg
        })
      }
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      }
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      }
    },
  },
};

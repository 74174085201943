import request from '../utils/request';

// 城内订单退款记录分页
export async function list(params) {
  return request('/api/bmkp-wyc/wyc/order/refund/page', {
    method: 'POST',
    body: params,
  });
}

// 城内订单退款记录详情
export async function detail(params) {
  // TODO: 未配置
  return request('/api/bmkp-backend/blade-order/order/trade/detail', {
    method: 'POST',
    body: params,
  });
}

// 售后查询退款时的记录
export async function record(params) {
  return request('/api/bmkp-wyc/wyc/order/operation/query/refund/apply/record', {
    method: 'POST',
    body: params
  })
}
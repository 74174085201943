import func from '../utils/Func';
import request from '../utils/request';

export async function list(params) {
  return request('/api/bmkp-backend/afterSales/page', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request('/api/bmkp-backend/afterSales/detail', {
    method: 'POST',
    body: params,
  });
}

// 客服操作
export async function serviceCheck(params) {
  return request('/api/bmkp-backend/afterSales/customerServiceCheck', {
    method: 'POST',
    body: params,
  });
}

// 审核员通过
export async function appleResolve(params) {
  return request('/api/bmkp-backend/afterSales/checkerPass', {
    method: 'POST',
    body: params,
  });
}

// 审核员驳回
export async function appleReject(params) {
  return request('/api/bmkp-backend/afterSales/checkerReject', {
    method: 'POST',
    body: params,
  });
}

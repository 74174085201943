import { 
  today,
  orderNum,
  orderFlow,
  agentOrderData,
  supplierOrderData
} from '@/services/dashboard';

export default {
  namespace: 'dashboard',

  state: {
    todayData: [],
    // [{x: '1月',y: 100}],
    orderChartDataNum: [],
    orderChartDataFlow: [],
    agentOrderData: [],
    supplierOrderData: [],
    searchData: [],
    offlineData: [],
    offlineChartData: [],
    salesTypeData: [],
    salesTypeDataOnline: [],
    salesTypeDataOffline: [],
    radarData: [],
    loading: false,
  },

  effects: {
    *fetchTodayData({ payload, callback }, { call, put }) {
      const response = yield call(today, payload);
      if(response.success) {
        yield put({
          type: 'saveTodayData',
          payload: response.data,
        });
      }
      callback && callback()
    },
    *fetchOrderChartDataNum({ payload }, { call, put }) {
      const response = yield call(orderNum, payload);
      yield put({
        type: 'saveOrderChartDataNum',
        payload: response.data.map(k => {
          return ({
              x: k.dateStr,
              y: k.orderNum
            })
        })
      })
    },
    *fetchOrderChartDataFlow({ payload }, { call, put }) {
      const response = yield call(orderFlow, payload);
      yield put({
        type: 'saveOrderChartDataFlow',
        payload: response.data.map(k => ({x: k.dateStr, y: k.flowNum}))
      });
    },
    *fetchAgentOrderData({ payload }, { call, put }) {
      const response = yield call(agentOrderData, payload);
      yield put({
        type: 'saveAgentOrderData',
        payload: response.data
      });
    },
    *fetchSupplierOrderData({ payload }, { call, put }) {
      const response = yield call(supplierOrderData, payload);
      yield put({
        type: 'saveSupplierOrderData',
        payload: response.data
      });
    },
  },

  reducers: {
    saveTodayData(state, { payload }) {
      return {
        ...state,
        todayData: payload,
      };
    },
    saveOrderChartDataNum(state, { payload }) {
      return {
        ...state,
        orderChartDataNum: payload,
      };
    },
    saveOrderChartDataFlow(state, { payload }) {
      return {
        ...state,
        orderChartDataFlow: payload
      };
    },
    saveAgentOrderData(state, { payload }) {
      return {
        ...state,
        agentOrderData: payload
      };
    },
    saveSupplierOrderData(state, { payload }) {
      return {
        ...state,
        supplierOrderData: payload
      };
    },
    clear() {
      return {
        visitData: [],
        visitData2: [],
        orderChartData: [],
        searchData: [],
        offlineData: [],
        offlineChartData: [],
        salesTypeData: [],
        salesTypeDataOnline: [],
        salesTypeDataOffline: [],
        radarData: [],
      };
    },
  },
};

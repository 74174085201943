export default {
  'table.columns.action': '操作',
  'button.add.name': '新增',
  'button.submit.name': '提交',
  'button.save.name': '保存',
  'button.view.name': '查看',
  'button.edit.name': '编辑',
  'button.delete.name': '删除',
  'button.remove.name': '删除',
  'button.search.name': '查询',
  'button.reset.name': '重置',
  'button.recycle.name': '回收站',
  'button.recovery.name': '恢复',
  'button.repeal.name': '撤回',
  'button.expand.name': '展开',
  'button.role.name': '角色配置',
  'button.reset-password.name': '密码重置',
  'button.disabled.name': '封号',
  'button.back.name': '返回',
  'button.deploy.name': '部署',
  'button.download.name': '下载',
  'button.create.name': '创建',
  'button.update.name': '配置',
  'button.state.name': '变更状态',
  'button.image.name': '流程图',
  'button.flow.name': '发起',
  'button.sign.name': '签收',
  'button.handle.name': '处理',
  'button.detail.name': '详情',
  'button.follow.name': '跟踪',
  'button.enable.name': '启用',
  'button.datascope.name': '数据权限',
  'button.setting.name': '配置',
  'button.upload.name': '上传',
  'button.close.name': '关闭',
  'button.open.name': '开启',
  'button.operation_config_list.name': '运营配置',
  'button.audit.name': '审核',
  'button.config_edit.name': '运营配置',
  'button.line_apply_list.name': '开线申请记录',
  'button.driver_binding_batch.name': '批量绑定司机',
  'button.line_service_phone_batch.name': '批量修改客服电话',
  'button.line_privacy_batch.name': '批量修改隐私号',
  'button.line_realname_batch.name': '批量修改乘车实名',
  'button.capacity_settlement_custom.name': '修改配置',
  'button.order_cancel.name': '取消订单',
  'button.order_proofread_check.name': '结算',
  'button.order_proofread_invalid.name': '作废',
  'button.capacity_settlement_delete.name': '删除',
};

import { message } from 'antd';
import { ORDERFEEDBACK_NAMESPACE } from '@/actions/orderfeedback';
import { list, detail, deal} from '@/services/orderfeedback';

export default {
  namespace: ORDERFEEDBACK_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: {}
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDetail({ payload }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
      }
    },
    *deal({ payload, callback }, { call }) {
      const response = yield call(deal, payload);
      if (response.success) {
        message.success('提交成功')
        callback()
      }
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
  },
};

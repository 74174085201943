import { message } from 'antd';
import router from 'umi/router';
import { LINE_DISPATCH_RULE_NAMESPACE } from '../actions/linedispatchrule';
import {
  detail,
  submit
} from '../services/linedispatchrule';

export default {
  namespace: LINE_DISPATCH_RULE_NAMESPACE,
  state: {
    detail: []
  },
  effects: {
    *fetchDetail({ payload }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
      }
    },
    *submit({ payload }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('提交成功');
        router.goBack();
      }
    },
  },
  reducers: {
    saveDetail(state, action) {
      const verhicleTypes = []
      const { detail: detailInfo } = action.payload
      // eslint-disable-next-line no-restricted-syntax
      for(const item in detailInfo){
        if(Number(item) === 10) {
          verhicleTypes.push({name: '城际拼车', key: 'menu-10', ...detailInfo[item]})
        } else if(Number(item) === 11) {
          verhicleTypes.push({name: '城际包车', key: 'menu-11', ...detailInfo[item]})
        } else {
          verhicleTypes.push({name: '城际拼车(线下)', key: 'menu-12', ...detailInfo[item]})
        }
      }
      return {
        ...state,
        detail: verhicleTypes
      };
    }
  },
};

import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

export async function list(params) {
  return request(`/api/bmkp-backend/customize/vehicletype/list?${stringify(params)}`);
}

export async function submit(params) {
  return request('/api/bmkp-backend/customize/vehicletype/save', {
    method: 'POST',
    body: params,
  });
}
export async function update(params) {
  return request('/api/bmkp-backend/customize/vehicletype/update', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request(`/api/bmkp-backend/customize/vehicletype/detail?${stringify(params)}`);
}

export async function remove(params) {
  return request('/api/customize/vehicletype/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}

export const LINE_PRICE_RULE_NAMESPACE = 'linePriceRule';

export function LINE_PRICE_RULE_DETAIL(id, callback) {
  return {
    type: `${LINE_PRICE_RULE_NAMESPACE}/fetchDetail`,
    payload: { serviceId: id },
    callback
  };
}

export function LINE_PRICE_RULE_SUBMIT(payload) {
  return {
    type: `${LINE_PRICE_RULE_NAMESPACE}/submit`,
    payload
  };
}
export function LINE_PRICE_RULE_DETAIL_CLEAR(id, callback) {
  return {
    type: `${LINE_PRICE_RULE_NAMESPACE}/clearDetail`,
    payload: null,
    callback
  };
}
export function LINE_PRICE_RULE_DETAIL_SPECIAL(id, callback) {
  return {
    type: `${LINE_PRICE_RULE_NAMESPACE}/fetchDetailSpecial`,
    payload: { serviceId: id },
    callback
  };
}

export function LINE_PRICE_RULE_SUBMIT_SPECIAL(payload) {
  return {
    type: `${LINE_PRICE_RULE_NAMESPACE}/submitSpecial`,
    payload
  };
}

import moment from 'moment';
import React from 'react';
import nzh from 'nzh/cn';
import { parse, stringify } from 'qs';
import { func } from 'prop-types';
import { message } from 'antd';
import { webUrlReg } from './reg';

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'sevenDays') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    const beginTime = now.getTime() - 7 * oneDay;

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    ];
  }

  const year = now.getFullYear();
  return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
}

export function getPlainNode(nodeList, parentPath = '') {
  const arr = [];
  nodeList.forEach(node => {
    const item = node;
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/');
    item.exact = true;
    if (item.children && !item.component) {
      arr.push(...getPlainNode(item.children, item.path));
    } else {
      if (item.children && item.component) {
        item.exact = false;
      }
      arr.push(item);
    }
  });
  return arr;
}

export function digitUppercase(n) {
  return nzh.toMoney(n);
}

function getRelation(str1, str2) {
  if (str1 === str2) {
    console.warn('Two path are equal!'); // eslint-disable-line
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  }
  if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    // 去重
    renderArr = renderArr.filter(item => getRelation(item, routes[i]) !== 1);
    // 是否包含
    const isAdd = renderArr.every(item => getRelation(item, routes[i]) === 3);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(
    routePath => routePath.indexOf(path) === 0 && routePath !== path
  );
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map(item => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map(item => {
    const exact = !routes.some(route => route !== item && getRelation(route, item) === 1);
    return {
      exact,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`,
    };
  });
  return renderRoutes;
}

export function getPageQuery() {
  return parse(window.location.href.split('?')[1]);
}

export function getQueryPath(path = '', query = {}) {
  const search = stringify(query);
  if (search.length) {
    return `${path}?${search}`;
  }
  return path;
}

export function isUrl(path) {
  return webUrlReg.test(path);
}

export function formatWan(val) {
  const v = val * 1;
  if (!v || Number.isNaN(v)) return '';

  let result = val;
  if (val > 10000) {
    result = Math.floor(val / 10000);
    result = (
      <span>
        {result}
        <span
          style={{
            position: 'relative',
            top: -2,
            fontSize: 14,
            fontStyle: 'normal',
            marginLeft: 2,
          }}
        >
          万
        </span>
      </span>
    );
  }
  return result;
}

export const importCDN = (url, name) =>
  new Promise(resolve => {
    const dom = document.createElement('script');
    dom.src = url;
    dom.type = 'text/javascript';
    dom.onload = () => {
      resolve(window[name]);
    };
    document.head.appendChild(dom);
  });

export function formatTopMenus(menus) {
  return menus.map((item, index) => {
    return {
      id: item.id,
      name: item.name, 
      code: item.code,
      source: item.source,
      isSelected: index === 0
    };
  });
}

export function formatRoutes(routes) {
  function format(arr) {
    arr.forEach(node => {
      const item = node;
      if (item.children && Array.isArray(item.children)) {
        item.routes = item.children;
        format(item.routes);
      }
      if (item.isOpen === 2) {
        item.target = '_blank';
      }
      item.name = item.code;
      if (item.source) {
        item.icon = item.source;
      }
      delete item.id;
      delete item.parentId;
      delete item.sort;
      delete item.code;
      delete item.source;
      delete item.children;
    });
    return arr;
  }
  return format(routes);
}

export function formatButtons(buttons) {
  return buttons.map(item => {
    return {
      code: item.code,
      buttons: item.children,
    };
  });
}

/**
 * 判断是否为空
 */
export function validateNull(val) {
  if (typeof val === 'boolean') {
    return false;
  }
  if (typeof val === 'number') {
    return false;
  }
  if (val instanceof Array) {
    if (val.length === 0) return true;
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true;
  } else {
    if (val === 'null' || val == null || val === 'undefined' || val === undefined || val === '') {
      return true;
    }
    return false;
  }
  return false;
}

// 转base64
export function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

/**
 * 关闭当前窗口
 */
export function closeWindow() {
  window.opener=null;
  window.open('','_self');
  window.close();
}

/**
 * 获取顶部地址栏地址
 */
export function getTopUrl() {
  return window.location.href.split('/#/')[0];
}

/**
 * 获取url参数
 * @param name 参数名
 */
export function getQueryString(name) {
  // eslint-disable-next-line no-shadow
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(decodeURI(r[2]));
  return null;
}
/**
 * 手机号脱敏
 * @param {string}} val 
 * @returns 
 */
export function encryPhone(val) {
  if(!val){
    return ''
  }
  const reg = /^(\d{3})\d{4}(\d{4})$/;
  return val.replace(reg, "$1****$2");
}

/**
 * 身份证号脱敏
 * @param {string}} val 
 * @returns 
 */
 export function encryIDCard(val) {
  if(!val){
    return ''
  }
  const reg = /^(\d{4})\d{10}(.{4})$/;
  return val.replace(reg, "$1**********$2");
}

export function provinceShortName() {
  return ['京','津','沪','渝','辽','吉','黑','蒙','冀','晋','苏','浙','皖','闽',
  '赣','鲁','豫','鄂','湘','粤','桂','琼','川','贵','云','陕','甘','青','宁','新','藏']
}
export function getCarColor() {
  return ['黑色','白色', '红色', '银色', '蓝色', '棕色', '绿色', '灰色', '黄色','橙色','紫色','粉色','金色']
}

// 车牌基本校验
export function verhicleNumberReg() {
  const provinceName = provinceShortName().join('')
  const regStr = `[${provinceName}]{1}[A-HJ-NP-Z]{1}[A-HJ-NP-Z0-9]{5,6}`
  return new RegExp(`^${  regStr  }$`)
}

/**
 * 防抖
 * @param {obj}} fn  回调函数
 * @param {int} delay  点击几秒后执行回调函数 默认200ms
 */
 export function debounce(fn, delay) {
  let timer = null
  return function () {
    const args = arguments
    const context = this
    if (timer) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay || 200)
    } else {
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay || 200)
    }
  }
}


/**
 * 节流 立即执行
 * @param {obj}} fn 回调函数
 * @param {int} delay 连续点击，每几秒后间隔 执行回调函数
 */
 export function throttle(fn,wait){
  let flag = true;
  let timer = null;
  return function(){
    if(flag) {
      fn.apply(this,arguments);
      flag = false;
      timer = setTimeout(() => {
        flag = true
      },wait)
    }
  }
}
/**
 * 全国城市插件，获取完整的6位编码
 * @param {array}} arr   arr 可能值为['42']或['42','4201']或['42','4201', '420100']
 * @param {string} cityCode  420100 6位编码
 */
export function getFullCityCode(arr) {
  if(!arr) {
    return ''
  }
  const lastCityCode = arr[arr.length -1] // 获取最后一项
  return lastCityCode.padEnd(6,'0') // 不足6位的，则末尾加0
}

/**
 * 判断时间段是否叠加
 * @param {*} arr [['09:00','12:00'], ['09:15','11:45'], ['19:00','21:15']]
 * @returns 
 */
export function timeRangeIsOverlap(arr) {
  // startArr = ['09:00','09:15','19:00''12:00'], 
  // endArr = ['09:15','11:45','21:15']]
  const startArr = arr.map(item => item[0]).sort()
  const endArr = arr.map(item => item[1]).sort()
  for(let i = 1; i < startArr.length; i++) {
    if(startArr[i] < endArr[i - 1]) {
      return true
    }
  }
  return false
}

export const formatNumber = (n) => {
  n = n.toString()
  return n[1] ? n : `0${  n}`
}

/**
 * 最近的 半点、整点时间
 * @returns number 时间戳
 */
 export function nearlyAvailableTime() {
  // 获取最近可用时间
  const nowTime = moment().format('YYYY-MM-DD HH:mm:ss') // 获取当前时间
  let nowHourTime = nowTime.replace(/:.*/, ':00:00') // 获取当前小时 00 分 00 秒时间
  const miniteus = moment().format('mm')
  if(Number(miniteus) < 20) {
    nowHourTime = nowHourTime.replace(/:00:00/, ':30:00')
  } else {
    const nextHour = moment(nowHourTime).valueOf() + 60 * 60 * 1000
    nowHourTime = moment(nextHour).format('YYYY-MM-DD HH:mm:ss')
  }
  return moment(nowHourTime).valueOf() // 最近的 半点、整点时间
}

/**
 * 获取n天信息
 * @param n 天数，默认30天
 * @param specailTodayFlag 是否特别标识今天
 * @param specailTomorrowFlag 是否特别标识明天
 * @returns 
 */
 export function getDaysFromToday(n = 30, specailTodayFlag = true, specailTomorrowFlag = true) {
  const todayZero = moment().startOf('day').valueOf() // 获取今天零点的时间戳
  const formatArr = [] // 格式化后时间字符信息
  const timeStickerArr = [] // 时间戳
  const baseDaysArr = ['周日','周一','周二','周三','周四','周五','周六']
  for(let i =0 ; i < n; i++) {
    const tmpSticker = todayZero + i * 24 * 3600 * 1000
    timeStickerArr.push(tmpSticker)
    const dateTime = new Date(tmpSticker)
    const month = `${formatNumber(dateTime.getMonth() + 1)  }月`
    const date = `${formatNumber(dateTime.getDate())  }日`
    const day = baseDaysArr[dateTime.getDay()]
    if(i === 0 && specailTodayFlag) {
      formatArr.push(`今天 ${day}`)
    } else if(i === 1 && specailTomorrowFlag) {
      formatArr.push(`明天 ${day}`)
    } else {
      formatArr.push(`${month}${date} ${day}`)
    }
  }
  return {
    lastlyAvailableTime: nearlyAvailableTime(), // 最近的 30分钟
    formatArr,
    timeStickerArr
  }
}

// 计算两个number值之间的乘积, 避免出现计算出来的结果等于 0.2999999999这样
export function numberMulti(number1, number2){
  let baseNum = 0
  try {
    baseNum += number1.toString().split('.')[1].length
  } catch (e) {}

  try {
    baseNum += number2.toString().split('.')[1].length
  } catch (e) {}

  return Number(number1.toString().replace('.', '')) * Number(number2.toString().replace('.', '')) / Math.pow(10, baseNum)
}

export function formatCalc(number, fixedCount = 2) {
  // eslint-disable-next-line no-restricted-properties
  const tmpNumber = Math.pow(10,fixedCount)
  return (Math.round(number * tmpNumber)/tmpNumber).toFixed(fixedCount)
}

/**
 * 字典类型的数据变成checkbox 的option格式
 * @param {array} dict 
 * [{id: '123131, dictValue: 1, dictKey: '城际'}]
 * @returns [{value: 1, label: '城际'}]
 */
export function getCheckboxOptions (dict) {
  return dict.map(item => ({value: Number(item.dictValue), label: item.dictKey}))
}
/**
 * 检测文件是否符合要求，尺寸
 * @param {file} file 文件流
 * @param {array} extensions 拓展名，数组['jpg', 'png]
 * @param {number} megaByte 体积限制。单位M
 * @param {object} size 像素尺寸限制。包括 width: height
 * @returns 
 */
export function isFileValidate (file, extensions, megaByte, size) {
  return new Promise((resolve, reject) => {
    if(!file || !extensions) {
      message.error(`缺少文件内容和拓展名限制参数`)
      return reject();
    }
    // 拓展名
    const { name } = file
    const tmpArr = name.split('.')
    const isValidteExtension = extensions.includes(tmpArr[tmpArr.length -1])
    if (!isValidteExtension) {
      const extensionsTmp = extensions.map(k => k.toLowerCase())
      const newTmpArr = Array.from(new Set(extensionsTmp))
      message.error(`文件类型错误。请选择${newTmpArr.join(',')}类型文件`)
      return reject();
    }
    // 文件大小。单位M
    if(megaByte) {
      const isLtM = file.size / 1024 / 1024 <= megaByte;
      if (!isLtM) {
        message.error(`文件大小错误。请选择小于${megaByte}M的文件`)
        return reject();
      }
    }
    const isImg = extensions.includes('png') || extensions.includes('jpg') || extensions.includes('jpeg')
    if(!(size && isImg)) {
      return resolve();
    } 
    const { width, height } = size
    const URL = window.URL || window.webkitURL
    const img = new Image()
    img.onload = () => {
      const valid = img.width === width && img.height === height
      if(!valid){
        message.error(`图片尺寸不符合要求，请修改后重新上传！`)
        return reject();
      }
      return resolve();
    };
    img.src = URL.createObjectURL(file)
  }).then(
    () => {
      return file;
    },
    () => {
      return Promise.reject();
    }
  )
}

export const exportExcel = async (path) => {
  try {
    const response = await fetch(path, { headers: { 'Host': 'admin.sanjiangchuxing.com' } })
    const filename = response.headers.get("content-disposition")?.split("filename=")[1]
    if (!filename) {
      message.error('导出失败')
      return
    }
    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)
    const fileLink = document.createElement('a')
    fileLink.href = url
    fileLink.download = decodeURIComponent(filename)
    fileLink.click()
    setTimeout(() => window.URL.revokeObjectURL(url), 300)
  } catch (error) {
    console.log(error)
  }
}


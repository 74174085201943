import { message, notification} from 'antd';
import router from 'umi/router';
import { CANCEL_RULE_NAMESPACE } from '../actions/cancelrule';
import {
  listInside,
  submitInside,
  detail,
  submit
} from '../services/cancelrule';

export default {
  namespace: CANCEL_RULE_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: {
      cancelPrices: [],
      cancelRule: ''
    },
    commonCancelRuleInside: {}
  },
  effects: {
    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
    *submit({ payload, callback }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('提交成功');
        callback && callback()
      }
    },
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchListInside({ payload, callback }, { call, put }) {
      const response = yield call(listInside, payload);
      if (response.success) {
        yield put({
          type: 'saveDetailInside',
          payload: {
            detail: response.data || null,
          },
        });
        callback && callback(response.data)
      }
    },
    *submitInside({ payload, callback }, { call }) {
      const response = yield call(submitInside, payload);
      if (response.success) {
        message.success('提交成功');
        callback && callback()
      }
    },
  },
  reducers: {
    saveDetailInside(state, action) {
      return {
        ...state,
        commonCancelRuleInside: action.payload.detail,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    }
  },
};


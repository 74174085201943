import { message } from 'antd';
import router from 'umi/router';
import { VEHICLETYPE_NAMESPACE } from '../actions/vehicletype';
import { list, submit, detail, remove, update } from '../services/vehicletype';

export default {
  namespace: VEHICLETYPE_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: null
  },
  effects: {
    *fetchList({ payload, callback }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
        if (callback) {
          callback(response.data.records)
        }
      } else {
        message.error(response.msg);
      }
    },
    *fetchDetail({ payload }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
      } else {
        message.error(response.msg);
      }
    },
    *clearDetail({ payload }, { put }) {
      yield put({
        type: 'removeDetail',
        payload: { payload },
      });
    },
    *submit({ payload }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('提交成功');
        router.push('/intercity_config/vehicle_type');
      } else {
        message.error(response.msg);
      }
    },
    *update({ payload, callback }, { call }) {
      const response = yield call(update, payload);
      if (response.success) {
        message.success('更新成功');
        callback && callback()
      } else {
        message.error(response.msg);
      }
    },
    *remove({ payload }, { call }) {
      const {
        data: { keys },
        success,
      } = payload;
      const response = yield call(remove, { ids: keys });
      if (response.success) {
        success();
      } else {
        message.error(response.msg);
      }
    }
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    },
  },
};

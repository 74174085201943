export default {
  'menu.home': '首页',
  'menu.login': '登录',
  'menu.register': '注册',
  'menu.register.result': '注册结果',
  'menu.dashboard': '仪表盘',
  'menu.dashboard.analysis': '分析页',
  'menu.dashboard.monitor': '监控页',
  'menu.dashboard.workplace': '工作台',
  'menu.desk': '工作台',
  'menu.desk.notice': '通知公告',
  'menu.work': '我的事务',
  'menu.work.work_start': '发起事务',
  'menu.work.work_claim': '待签事务',
  'menu.work.work_todo': '待办事务',
  'menu.work.work_send': '已发事务',
  'menu.work.work_done': '办结事务',
  'menu.base': '基础配置',
  'menu.base.jiguangConfig': '极光配置',
  'menu.base.region': '行政区划',
  'menu.base.car_series': '车系',
  'menu.base.car_model': '车辆型号',
  'menu.base.car_brand': '车辆型号',
  'menu.authority': '权限管理',
  'menu.authority.role': '角色管理',
  'menu.authority.data_scope': '数据权限',
  'menu.authority.api_scope': '接口权限',
  'menu.system': '系统管理',
  'menu.system.user': '用户管理',
  'menu.system.dept': '机构管理',
  'menu.system.dict': '系统字典',
  'menu.system.post': '岗位管理',
  'menu.system.dictbiz': '业务字典',
  'menu.system.menu': '菜单管理',
  'menu.system.topmenu': '顶部菜单',
  'menu.system.param': '参数管理',
  'menu.system.tenant': '租户管理',
  'menu.system.client': '应用管理',
  'menu.monitor': '系统监控',
  'menu.monitor.log': '日志管理',
  'menu.monitor.log.log_usual': '通用日志',
  'menu.monitor.log.log_api': '接口日志',
  'menu.monitor.log.log_error': '错误日志',
  'menu.monitor.admin': '服务治理',
  'menu.monitor.doc': '接口文档',
  'menu.monitor.elk': 'ELK监控',
  'menu.monitor.zipkin': 'Zipkin监控',
  'menu.monitor.turbine': 'Turbine监控',
  'menu.monitor.sentinel': 'Sentinel管理',
  'menu.monitor.es': 'Elasticsearch管理',
  'menu.tool': '研发工具',
  'menu.tool.code': '代码生成',
  'menu.tool.datasource': '数据源管理',
  'menu.flow': '流程管理',
  'menu.flow.flow_model': '模型管理',
  'menu.flow.flow_deploy': '流程部署',
  'menu.flow.flow_manager': '流程管理',
  'menu.flow.flow_follow': '流程跟踪',
  'menu.report': '报表管理',
  'menu.report.report_setting': '报表配置',
  'menu.report.report_list': '报表列表',
  'menu.report.report_notice': '报表公告',
  'menu.resource': '资源管理',
  'menu.resource.oss': '对象存储',
  'menu.resource.attach': '附件管理',
  'menu.resource.sms': '短信配置',
  'menu.resource.xxljob': '任务调度',
  'menu.result': '结果页',
  'menu.result.success': '成功页',
  'menu.result.fail': '失败页',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '触发错误',
  'menu.account': '个人页',
  'menu.account.center': '个人中心',
  'menu.account.settings': '个人设置',
  'menu.account.password': '密码修改',
  'menu.account.trigger': '触发报错',
  'menu.account.clear': '清除缓存',
  'menu.account.logout': '退出登录',
  'menu.editor': '图形编辑器',
  'menu.editor.flow': '流程编辑器',
  'menu.editor.mind': '脑图编辑器',
  'menu.editor.koni': '拓扑编辑器',

  'menu.intercity_config': '城际运营管理配置',
  'menu.intercity_common_config': '城际通用配置',
  'menu.line_config': '线路管理',
  'menu.vehicle_type': '车型管理',

  'menu.asset': '资产管理',

  'menu.driver': '司机管理',
  // 'menu.driver.driver_base': '城际司机列表',
  // 'menu.driver.driver_apply': '城际司机资料审核',
  'menu.driver.driver_binding': '司机绑定',
  'menu.driver.driver_list_insidecity': '司机列表',
  'menu.driver.driver_apply_inside': '司机资料审核',
  'menu.driver.driver_supplement_inside': '司机资料补录',
  'menu.driver.driver_recruit_config_list': '司机招募配置',
  'menu.driver.driver_clue_list': '线索司机',
  'menu.driver.driver_certificates_list': '司机证件',

  'menu.car': '车辆资产',
  'menu.car.car_manage': '车辆管理',
  'menu.car.car_record': '操作记录日志',
  'menu.car.driver_base': '司机管理',
  

  'menu.order': '订单管理',
  'menu.order.order_travel_list': '城际行程列表',
  'menu.order.order_record_list': '城际订单列表',
  'menu.order.order_dispatch_list': '城际订单调度',
  'menu.order.order_dispatch_rule': '城内派单规则',
  'menu.insideorder': '城内订单列表',

  'menu.district_dispatch': '区域调度控制',
  'menu.driver_operation': '司机运营位',
  'menu.passenger_operation_list': '乘客运营位',

  'menu.capacity': '运力管理',
  'menu.capacity.capacityagent': '代理商管理',
  'menu.capacity.capacitysupplier': '供应商信息管理',
  'menu.capacity.capacity_platform': '平台运力管理',
  'menu.capacity.capacity_settlement': '运力公司结算规则',
  'menu.capacity.capacity_settlement.capacity_settlement_list': '结算抽佣配置',
  'menu.capacity.capacity_settlement.capacity_settlement_list.delete': '删除',
  'menu.capacity.capacity_settlement.capacity_settlement_cancel': '司乘取消结算配置',

  'menu.finance': '财务管理',
  'menu.finance.driver_balance': '司机账户',
  'menu.finance.withdrawal': '提现管理',
  'menu.finance.withdrawal.list': '城际提现记录',
  'menu.finance.withdrawal.withdrawal_list_inside': '城内提现记录',
  'menu.finance.intercity-record': '城际订单记录',
  'menu.finance.intercity-record.intercity_record_refund_apply': '订单退款记录',
  'menu.finance.intercity-record.transaction': '订单交易',
  'menu.finance.intercity-record.travel': '行程记录',
  'menu.finance.inside-record': '城内订单记录',
  'menu.finance.inside-record.inside_record_trade': '订单交易',
  'menu.finance.inside-record.inside_record_refund_apply': '订单退款记录',
  'menu.finance.inside-record.inside_record_update_fee': '订单改价记录',
  'menu.finance_statistical': '流水统计',
  'menu.finance_billing': '计费规则',
  'menu.finance_billing.finance_billing_list': '计费规则配置',
  'menu.finance_billing.finance_billing_change_list': '计费规则变更列表',
  'menu.advance_config': '城内基础配置',
  'menu.adjustmentaccount': '调账',

  'menu.dj_finance_withdrawal': '提现管理(代驾)',
  'menu.dj_finance_withdrawal.dj_finance_withdrawal_list': '提现记录',

  'menu.finance_proofread': '业务账单',
  'menu.finance_proofread.order_proofread_list_inside': '城内运力公司账单',
  'menu.finance_proofread.order_proofread_list_intercity': '城际运力公司账单',
  'menu.finance_proofread.activity_insidecity_driver_award_bill': '城内营销活动账单',
  'menu.finance_proofread.activity_intercity_passenger_award_bill': '优惠券账单',
  'menu.finance_proofread.activity_insidecity_marketing_invite_passengers': '乘客邀请乘客账单',
  'menu.finance_proofread.activity_insidecity_marketing_invite_drive': '乘客邀请司机账单',
  'menu.finance_proofread.activity_bill_driverFreeCommissionBill': '司机免佣活动账单',
  'menu.finance_proofread.activity_bill_driverInviteBill': '司机邀请活动账单',
  'menu.finance_proofread.activity_bill_passengerInviteBill': '乘客邀请活动账单',
  'menu.finance_proofread.activity_bill_driverRewardBill': '司机奖励活动账单',

  'menu.dj_finance_proofread': '业务账单(代驾)',
  'menu.dj_finance_proofread.dj_order_proofread_list_intercity': '运力公司账单(代驾)',
  'menu.dj_finance_proofread.dj_activity_insidecity_driver_award_bill': '营销活动账单(代驾)',
  'menu.dj_finance_proofread.dj_activity_intercity_passenger_award_bill': '优惠券账单(代驾)',
  'menu.dj_finance_proofread.dj_activity_bill_driverFreeCommissionBill': '司机免佣活动账单(代驾)',
  'menu.dj_finance_proofread.dj_activity_bill_driverInviteBill': '司机邀请活动账单(代驾)',


  'menu.line': '线路通用配置',
  'menu.line.line_list': '线路通用配置',

  'menu.invoice': '发票管理',
  'menu.invoice.invoice_list': '发票管理',

  'menu.after-sales': '售后管理',
  'menu.after-sales.after_sales_list': '城际退款审核',
  'menu.after-sales.after_sales_inside_list': '城内退款审核',

  'menu.dj_after-sales.after_sales_inside_list': '退款审核(代驾)',

  'menu.passenger': '乘客管理',
  'menu.passenger.passenger_list': '乘客管理',

  'menu.coupon': '优惠券管理',

  'menu.activity_intercity': '城际活动',
  'menu.activity_intercity.activity_list_intercity': '司机活动列表',
  'menu.activity_intercity.activity_passenger_list_intercity': '乘客活动列表',
  
  'menu.activity_insidecity': '城内活动',
  'menu.activity_insidecity.activity_insidecity_driver_list': '司机活动列表',
  'menu.activity_insidecity.activity_insidecity_passengers_list': '乘客活动列表',
  'menu.activity_insidecity.activity__insidecity_invitation_list': '乘客邀请活动',
  'menu.activity_insidecity.activity_insidecity_passenger_list': '乘客邀请活动列表',
  'menu.activity_insidecity.activity_insidecity_driver_invite_list': '司机邀请活动列表',
  'menu.activity_insidecity.activity_insidecity_freecharge_list': '司机免佣活动',

  'menu.dj_activity_insidecity': '代驾活动',
  'menu.dj_activity_insidecity.dj_activity_insidecity_driver_list': '司机活动列表',
  'menu.dj_activity_insidecity.dj_activity_insidecity_passengers_list': '乘客活动列表',
  'menu.dj_activity_insidecity.dj_activity__insidecity_invitation_list': '乘客邀请活动',
  'menu.dj_activity_insidecity.dj_activity_insidecity_passenger_list': '乘客邀请活动列表',
  'menu.dj_activity_insidecity.dj_activity_insidecity_driver_invite_list': '司机邀请活动列表',
  'menu.dj_activity_insidecity.dj_activity_insidecity_freecharge_list': '司机免佣活动',


  'menu.dispatch': '调度管理',
  'menu.dispatch.dispatcher': '调度员列表',
  'menu.dispatch.dispatcher_line': '调度路线管理',
  'menu.dispatch.dispatch_channel_list': '渠道管理',

  'menu.service_phone': '客服电话设置',
  'menu.driver_feedback': '司机意见反馈',
  'menu.customer_service_order': '订单查询',
  'menu.passengerfeedback': '用户意见反馈',
  'menu.orderFeedback': '订单反馈',
  'menu.orderFeedbackType': '订单反馈类型配置',

  'menu.dj_customer_service': '客服管理(代驾)',
  'menu.dj_customer_service.dj_customer_service_modify': '订单修改',
  'menu.dj_customer_service.dj_customer_service_modify_price': '改订单价格',
  'menu.dj_customer_service.dj_customer_service_modify_price_record': '改价记录',
  'menu.dj_customer_service.dj_customer_service_modify_status': '改订单状态',
  'menu.dj_customer_service.dj_customer_service_modify_status_record': '改状态记录',

  'menu.message_push': '消息推送',
  'menu.message_push.moblie_message': '手机短信',
  'menu.message_push.message_push_driver': '司机消息',
  'menu.message_push.message_push_passenger': '乘客消息',

  'menu.city_manage': '城市管理',
  // 'menu.city_manage_intercity': '城市管理',

  'menu.driver_rule': '司机规则',
  'menu.driver_rule.driver_rule_cancel': '取消规则',
  'menu.driver_rule.withdrawal_config': '城际提现配置',
  'menu.driver_rule.withdrawal_config_inside': '城内提现配置',


  'menu.passenger_cancel': '客户端配置',
  'menu.menu.passenger_cancel.passenger_cancel_list': '乘客取消原因',



  'menu.appversion': 'App版本控制',

  'menu.dashboard_intercity': '城际数据看板',

  'menu.passenger_rules': '乘客规则',
  'menu.passenger_rules.risk': '乘客风控',


  'menu.common_config': 'APP配置',
  'menu.common_config.common_config_cancel_list': '取消原因',

  'menu.assets_admin': '资产管理',
  'menu.assets_admin.assets_admin_driverAdmin': '司机管理',
  'menu.assets_admin.assets_admin_driverDetails': '司机详情',
  'menu.assets_admin.assets_admin_supplierInfo': '供应商信息',

  'menu.channel_list': '渠道管理',
  'menu.drive_config': '司机端APP配置',
  'menu.drive_config.voice_config_drive_list': '司机端语音播报配置',
  'menu.inter_third_orderList': '城际订单列表(第三方)',
  'menu.brand_series': '品牌车系',
  'menu.capacity_map': '运力分布图',
  /**
   * 代驾相关
   * 
   */
  'menu.dj_driver': '司机管理代驾',
  'menu.dj_driver.dj_driver_list': '司机列表',
  'menu.dj_driver.dj_driver_pedding': '待审核列表',
  'menu.dj_driver.dj_driver_check': '审核记录列表',
  'menu.dj_order': '订单管理代驾',
  'menu.dj_order.dj_order_list': '订单列表',
  'menu.dj_adjustment': '调帐代驾',
  'menu.dj_adjustment.dj_adjustment_list': '调帐列表',
  'menu.dj_price_rule': '计价管理代驾',
  'menu.dj_price_rule.dj_price_rule_list': '计价模板列表',
  'menu.dj_place': '合作场地管理',
  'menu.dj_place.dj_place_list': '合作场地列表',

  'menu.auto_receive': '自动接单配置',
  'menu.charteredcar_attraction': '景点推荐',
  'menu.charteredcar_order': '包车订单'
};

import { stringify } from 'qs';
// import func from '@/utils/Func';
import request from '@/utils/request';

/**
 *
 * @param params
 * 订单列表
 * @returns
 */
export async function list(params) {
  return request(`/api/bmkp-wyc/hire/order/page`,{
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request(`/api/bmkp-wyc/hire/order/detail?${stringify(params)}`);
}
export async function getCityConfig(params) {
  return request(`/api/bmkp-wyc/hire/order/query/city/config/rule?${stringify(params)}`);
}

export async function getCityList(params) {
  return request('/api/bmkp-wyc/asset/driver/blockAccount', {
    method: 'POST',
    body: params,
  });
}

export async function getPrice(params) {
  return request('/api/bmkp-wyc/hire/order/get/estimatedPrice', {
    method: 'POST',
    body: params,
  });
}
export async function cancelOrder(params) {
  return request('/api/bmkp-wyc/hire/order/cancel', {
    method: 'POST',
    body: params,
  });
}
export async function changeReply(params) {
  return request('/api/bmkp-wyc/hire/order/point/driver', {
    method: 'POST',
    body: params,
  });
}

export async function getNearDriverList(params) {
  return request('/api/bmkp-wyc/hire/order/query/near/by/driver', {
    method: 'POST',
    body: params,
  });
}

export async function queryOrderList(params) {
  return request(`/api/bmkp-wyc/hire/order/customer/page`, {
    method: 'POST',
    body: params,
  });
}

export async function updatePrice(params) {
  return request(`/api/bmkp-wyc/hire/order/customer/update/price`, {
    method: 'POST',
    body: params,
  });
}

export async function updateStatus(params) {
  return request(`/api/bmkp-wyc/hire/order/customer/update/status`, {
    method: 'POST',
    body: params,
  });
}

export async function updatePriceRecord(params) {
  return request(`/api/bmkp-wyc/hire/order/customer/update/price/record/page`, {
    method: 'POST',
    body: params,
  });
}

export async function updateStatusRecord(params) {
  return request(`/api/bmkp-wyc/hire/order/customer/update/status/record/page`, {
    method: 'POST',
    body: params,
  });
}

export async function addOrder(params) {
  return request(`/api/bmkp-wyc/hire/order/create/order`, {
    method: 'POST',
    body: params,
  });
}

export async function allCityList(params) {
  return request(`/api/blade-system/region/queryList`, {
    method: 'POST',
    body: params,
  });
}
export const DISPATCHER_NAMESPACE = 'dispatcher';

export function DISPATCHER_LIST(payload) {
  return {
    type: `${DISPATCHER_NAMESPACE}/fetchList`,
    payload,
  };
}
export function DISPATCHER_DETAIL(id, callback) {
  return {
    type: `${DISPATCHER_NAMESPACE}/fetchDetail`,
    payload: { id },
    callback
  };
}

export function DISPATCHER_SUBMIT(payload, callback) {
  return {
    type: `${DISPATCHER_NAMESPACE}/submit`,
    payload,
    callback
  };
}
export function DISPATCHER_UPDATE(payload) {
  return {
    type: `${DISPATCHER_NAMESPACE}/update`,
    payload
  };
}

export function DISPATCHER_REMOVE(payload) {
  return {
    type: `${DISPATCHER_NAMESPACE}/remove`,
    payload,
  };
}

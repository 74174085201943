export const MESSAGE_DRIVER_NAMESPACE = 'messageDriver';

export function MESSAGE_DRIVER_LIST(payload) {
  return {
    type: `${MESSAGE_DRIVER_NAMESPACE}/fetchList`,
    payload,
  };
}
export function MESSAGE_DRIVER_DETAIL(payload, callback) {
  return {
    type: `${MESSAGE_DRIVER_NAMESPACE}/fetchDetail`,
    payload,
    callback
  };
}

export function MESSAGE_DRIVER_CLEAR_DETAIL(callback) {
  return {
    type: `${MESSAGE_DRIVER_NAMESPACE}/clearDetail`,
    callback
  };
}

export function MESSAGE_DRIVER_SUBMIT(payload, callback) {
  return {
    type: `${MESSAGE_DRIVER_NAMESPACE}/submit`,
    payload,
    callback
  };
}
export function MESSAGE_DRIVER_REPEAL(payload, callback) {
  return {
    type: `${MESSAGE_DRIVER_NAMESPACE}/repeal`,
    payload,
    callback
  };
}
export function MESSAGE_DRIVER_UPDATE(payload) {
  return {
    type: `${MESSAGE_DRIVER_NAMESPACE}/update`,
    payload
  };
}

export function MESSAGE_DRIVER_REMOVE(payload) {
  return {
    type: `${MESSAGE_DRIVER_NAMESPACE}/remove`,
    payload,
  };
}

/**
 *  根据活动类型获取活动 
 * 
 */

export function MESSAGE_ACTIVITY_LIST(payload) {
  return {
    type: `${MESSAGE_DRIVER_NAMESPACE}/activityList`,
    payload,
  };
}

export function MESSAGE_ACTIVITY_LIST_CLEAR(payload) {
  return {
    type: `${MESSAGE_DRIVER_NAMESPACE}/activityListClear`,
    payload,
  };
}
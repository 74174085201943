import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

export async function submit(params) {
  return request('/api/bmkp-backend/customize/linecancelrules/autoSubmit', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request(`/api/bmkp-backend/customize/linecancelrules/autoDetail?${stringify(params)}`);
}

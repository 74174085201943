export const COUPON_NAMESPACE = 'coupon';

export function COUPON_LIST(payload, callback) {
  return {
    type: `${COUPON_NAMESPACE}/fetchList`,
    payload,
    callback
  };
}
export function COUPON_DETAIL(id, callback) {
  return {
    type: `${COUPON_NAMESPACE}/fetchDetail`,
    payload: { id },
    callback
  };
}

export function COUPON_CLEAR_DETAIL() {
  return {
    type: `${COUPON_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function COUPON_SUBMIT(payload) {
  return {
    type: `${COUPON_NAMESPACE}/submit`,
    payload
  };
}
export function COUPON_ENABLE(payload, callback) {
  return {
    type: `${COUPON_NAMESPACE}/enable`,
    payload,
    callback
  };
}
export function COUPON_DISABLE(payload, callback) {
  return {
    type: `${COUPON_NAMESPACE}/disable`,
    payload,
    callback
  };
}

export function COUPON_REMOVE(payload) {
  return {
    type: `${COUPON_NAMESPACE}/remove`,
    payload,
  };
}

export function COUPON_LINE_OPERATION_CONFIG_DETAIL(id) {
  return {
    type: `${COUPON_NAMESPACE}/fetchLineOperationConfigDetail`,
    payload: { id },
  };
}

export function CHECK_LINE_OPERATION_STATUS(id) {
  return {
    type: `${COUPON_NAMESPACE}/checkLineOperationStatus`,
    payload: { id },
  };
}

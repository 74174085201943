import { message } from 'antd';
import { CHARTEREDCAR_NAMESPACE } from '../actions/charteredcar';
import { addAttraction, editAttraction, removeAttraction, attractionPages, attractionDetail, vehicleConfigDetail, addVehicleConfig, removeVehicleConfig, editVehicleConfig, vehicleConfigList, getDispatchOrderPage, driverList, getOrderPage, getOrderDetail, allotDriver, createOrder, getWaitDispatchOrderList } from '../services/charteredcar';

export default {
    namespace: CHARTEREDCAR_NAMESPACE,
    state: {
      attractionData: {
        list: [],
        pagination: false,
      },
      // 景点详情
      attractionDetail: null,
      // 车型列表
      vehicleConfigList: [],
      // 车型详情
      vehicleConfigDetail: null,
      // 待派单列表
      waitReceiveOrderList: [],
      // 司机列表分页
      driverData: {
        list: [],
        pagination: false,
      },
      // 订单列表
      orderData: {
        list: [],
        pagination: false,
      },
      // 订单详情
      // orderDetail: {},
    },
    effects: {
      *attractionFetchList({ payload }, { call, put }) {
        const response = yield call(attractionPages, payload);
        if (response.success) {
          yield put({
            type: 'saveAttractionList',
            payload: {
              list: response.data.records,
              pagination: {
                total: response.data.total,
                current: response.data.current,
                pageSize: response.data.size,
              },
            },
          });
        }
      },
      *attractionFetchDetail({ payload, callback }, { call, put }) {
        const response = yield call(attractionDetail, payload);
        if (response.success) {
          yield put({
            type: 'saveAttractionDetail',
            payload: response.data
          });
          callback && callback(response.data)
        }
      },
      *attractionClearDetail({}, { put }) {
        yield put({
          type: 'saveAttractionDetail',
          payload: null
        });
      },
      *attractionSubmit({payload, callback}, {call}) {
        const res = yield call(addAttraction, payload);
        if (res.success) {
          callback && callback(res)
        }
      },
      *attractionDelete({payload, callback}, {call}) {
        const res = yield call(removeAttraction, payload);
        if (res.success) {
          callback && callback()
        }
      },
      *attractionUpdate({payload, callback}, {call}) {
        const res = yield call(editAttraction, payload);
        if (res.success) {
          callback && callback(res.data)
        }
      },
      *vehicleConfigFetchList({ payload, callback }, { call, put }) {
        const response = yield call(vehicleConfigList, payload);
        if (response.success) {
          yield put({
            type: 'saveVehicleConfigList',
            payload: response.data.records,
          });
          callback && callback(response.data.records)
        }
      },
      *vehicleConfigFetchDetail({ payload, callback }, { call, put }) {
        const response = yield call(vehicleConfigDetail, payload);
        if (response.success) {
          yield put({
            type: 'saveVehicleConfigDetail',
            payload: response.data
          });
          callback && callback(response.data)
        }
      },
      *vehicleConfigClearDetail({}, { put }) {
        yield put({
          type: 'saveVehicleConfigDetail',
          payload: null
        });
      },
      *vehicleConfigSubmit({payload, callback}, {call}) {
        const res = yield call(addVehicleConfig, payload);
        if (res.success) {
          message.success('保存成功')
          callback && callback(res)
        }
      },
      *vehicleConfigDelete({payload, callback}, {call}) {
        const res = yield call(removeVehicleConfig, payload);
        if (res.success) {
          callback && callback()
        }
      },
      *vehicleConfigUpdate({payload, callback}, {call}) {
        const res = yield call(editVehicleConfig, payload);
        if (res.success) {
          message.success('保存成功')
          callback && callback(res.data)
        }
      },
      *fetchOrderList({ payload }, { call, put }) {
        const response = yield call(getOrderPage, payload);
        if (response.success) {
          yield put({
            type: 'saveOrderData',
            payload: {
              list: response.data.records,
              pagination: {
                total: response.data.total,
                current: response.data.current,
                pageSize: response.data.size,
              },
            },
          });
        }
      },
      *fetchDriverList({ payload }, { call, put }) {
        const response = yield call(driverList, payload);
        if (response.success) {
          yield put({
            type: 'saveDriverData',
            payload: {
              list: response.data.records,
              pagination: {
                total: response.data.total,
                current: response.data.current,
                pageSize: response.data.size,
              },
            },
          });
        }
      },
      // *fetchOrderDetail({ payload }, { call, put }) {
      //   const response = yield call(getOrderDetail, payload);
      //   if (response.success) {
      //     yield put({
      //       type: 'saveOrderDetail',
      //       payload: {
      //         detail: response.data,
      //       },
      //     });
      //   }
      // },
      // *clearOrderDetail(_, { put }) {
      //   yield put({
      //     type: 'removeOrderDetail'
      //   });
      // },
      *setOrderAllot({ payload }, { call }) {
        const {
          data,
          success,
        } = payload;
        const response = yield call(allotDriver, data);
        if (response.success) {
          message.success('提交成功');
          success()
        }
      },
      *createOrder({ payload, callback }, { call }) {
        const response = yield call(createOrder, payload);
        if (response.success) {
          message.success(response.msg);
          callback(payload)
        }
      },
      *fetchWaitDispatchOrderList({ payload }, { call, put }) {
        const response = yield call(getWaitDispatchOrderList, payload);
        if (response.success) {
          yield put({
            type: 'saveWaitReceiveOrderList',
            payload: response.data.records
          });
        }
      },
    },
    reducers: {
      saveAttractionList(state, action) {
        return {
          ...state,
          attractionData: action.payload,
        };
      },
      saveAttractionDetail(state, action) {
        return {
          ...state,
          attractionDetail: action.payload,
        };
      },
      saveVehicleConfigList(state, action) {
        return {
          ...state,
          vehicleConfigList: action.payload,
        };
      },
      saveVehicleConfigDetail(state, action) {
        return {
          ...state,
          vehicleConfigDetail: action.payload,
        };
      },
      saveWaitReceiveOrderList(state, action) {
        return {
          ...state,
          waitReceiveOrderList: action.payload,
        };
      },
      saveDriverData(state, action) {
        return {
          ...state,
          driverData: action.payload,
        };
      },
      saveOrderData(state, action) {
        return {
          ...state,
          orderData: action.payload,
        };
      },
      // saveOrderDetail(state, action) {
      //   return {
      //     ...state,
      //     orderDetail: action.payload.detail,
      //   };
      // },
      // clearOrderDetail(state) {
      //   return {
      //     ...state,
      //     orderDetail: null,
      //   };
      // },
    }
}
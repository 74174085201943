import { ACTIVITY_PREVIEW_NAMESPACE } from '../actions/activitypreview';

const ACTIVITY_PREVIEW_DEFAULT_INFO = {
  areaLimitType: 0,
  awardCycle: 1,
  awardLimitNum: 0,
  awardMoney: 0,
  bizType: "", // '城际, 城内'
  bizTypeList: null,
  city: "",
  content: "",
  districtName: '', // '海港区,山海关区,北戴河区,抚宁区,青龙满族自治县,昌黎县,卢龙县',
  driverId: null,
  driverType:'', //  "1,2,3", 对应  '有车加盟, 无车加盟'
  endTime: '', // "2021-12-31 00:00:00",
  innerName: '', // "00111号活动",
  maxAwardMoney: null,
  maxLadderDesc: null,
  nextLadder: null,
  nextLadderDesc: null,
  openTime: null,
  outerName: '', // "12.11 ~ 12.31 新手奖励d",
  realAwardMoney: null,
  realReachData: null,
  startTime: '', // "2021-12-11 00:00:00",
  timeRange: "", // "00:00:00-06:00:00,20:00:00-23:00:00,07:00:00-12:00:00,12:00:00-18:00:00"
  timeRangeType: 0,
  type: 0,
  awardLadderList: [] // 奖励阶梯列表 [{money: 1, orderFee: 111, orderNum: 1}]
}
export default {
  namespace: ACTIVITY_PREVIEW_NAMESPACE,
  state: {
    detail: ACTIVITY_PREVIEW_DEFAULT_INFO
  },
  effects: {
    *clearDetail({payload}, { put }) {
      yield put({
        type: 'removeDetail',
        payload: {payload},
      });
    },
    *update({ payload }, { call, put }) {
      yield put({
        type: 'saveDetail',
        payload: {
          detail: payload,
        },
      });
    }
  },
  
  reducers: {
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    removeDetail(state) {
      return {
        ...state,
        detail: ACTIVITY_PREVIEW_DEFAULT_INFO,
      };
    }
  }
}
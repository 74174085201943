import { message } from 'antd';
import { CITY_MANAGE_NAMESPACE } from '../actions/citymanage';
import {
  list,
  submit,
  close,
  open,
  openChannelDispatch,
  closeChannelDispatch,
  configDetail,
  remove,
  removeServiceType,
  removeBusinessServiceType,
  submitBusinessServiceType,
  submitBusinessServiceConfig,
  openList,
  listIntercity,
  submitIntercity,
  updateIntercity,
  openListIntercity,
  syncCityConfig,
  updateBusiness,
  getVehicleType,
  getVehicleTypeByCode,
  channelBusinessConfigPage,
  addChannelBusinessConfig,
  editChannelBusinessConfig,
  saveDjConfig,
  getDjConfig,
  editAnnualFeeBusinessConfig
} from '../services/citymanage';

export default {
  namespace: CITY_MANAGE_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    openList: [],
    configDetail: null,
    configDjDetail: null,
    dataIntercity: {
      list: [],
      pagination: false,
    },
    openListIntercity: [],
    // 城市关联的渠道列表 key: bizType  value: []
    channelBusinessConfigList: {},
  },
  effects: {
    *fetchList({ payload, callback }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
        callback && callback(response.data.records);
      }
    },
    *fetchOpenList({ payload, callback }, { call, put }) {
      const response = yield call(openList, payload);
      if (response.success) {
        yield put({
          type: 'saveOpenList',
          payload: response.data,
        });
        callback && callback(response.data);
      } else {
        if (response.code === 400) {
          message.error('城市列表获取失败,请重试');
        }
        callback && callback([]);
      }
    },
    *clearnOpenList({ callback }, { put }) {
      yield put({
        type: 'saveOpenList',
        payload: [],
      });
      callback && callback(true);
    },
    *fetchConfigDetail({ payload, callback }, { call, put }) {
      const response = yield call(configDetail, payload);
      if (response.success) {
        yield put({
          type: 'saveConfigDetail',
          payload: {
            detail: response.data,
          },
        });
        // eslint-disable-next-line no-unused-expressions
        callback && callback(response.data);
      } else {
        message.error(response.msg);
      }
    },
    *submit({ payload, callback }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('新增成功');
        callback && callback(response.data);
      }
    },
    *submitBusinessServiceType({ payload, callback }, { call }) {
      const response = yield call(submitBusinessServiceType, payload);
      if (response.success) {
        message.success('提交成功');
        callback && callback();
      }
    },
    *submitBusinessServiceConfig({ payload, callback }, { call }) {
      const response = yield call(submitBusinessServiceConfig, payload);
      if (response.success) {
        message.success('提交成功');
        callback && callback();
      }
    },
    *close({ payload, callback }, { call }) {
      const response = yield call(close, payload);
      if (response.success) {
        message.success('关闭成功');
        // eslint-disable-next-line no-unused-expressions
        callback && callback(true);
      }
    },
    *open({ payload, callback }, { call }) {
      const response = yield call(open, payload);
      if (response.success) {
        message.success('开启成功');
        // eslint-disable-next-line no-unused-expressions
        callback && callback(true);
      }
    },
    *closeChannelDispatch({ payload, callback }, { call }) {
      const response = yield call(closeChannelDispatch, payload);
      if (response.success) {
        message.success('关闭成功');
        // eslint-disable-next-line no-unused-expressions
        callback && callback(true);
      }
    },
    *openChannelDispatch({ payload, callback }, { call }) {
      const response = yield call(openChannelDispatch, payload);
      if (response.success) {
        message.success('开启成功');
        // eslint-disable-next-line no-unused-expressions
        callback && callback(true);
      }
    },
    *removeServiceType({ payload, callback }, { call }) {
      const response = yield call(removeServiceType, payload);
      if (response.success) {
        message.success('操作成功');
        callback && callback(true);
      }
    },
    *removeBusinessServiceType({ payload, callback }, { call }) {
      const response = yield call(removeBusinessServiceType, payload);
      if (response.success) {
        message.success('操作成功');
        callback && callback(true);
      }
    },
    *remove({ payload }, { call }) {
      const {
        data: { keys },
        success,
      } = payload;
      const response = yield call(remove, { ids: keys });
      if (response.success) {
        success();
      } else {
        message.error(response.msg);
      }
    },

    // 城际
    *fetchListIntercity({ payload, callback }, { call, put }) {
      const response = yield call(listIntercity, payload);
      if (response.success) {
        yield put({
          type: 'saveListIntercity',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
        callback && callback(response.data.records);
      }
    },
    *submitIntercity({ payload, callback }, { call }) {
      const response = yield call(submitIntercity, payload);
      if (response.success) {
        message.success('提交成功');
        callback && callback(response.data);
      } else {
        response.msg !== '该城市已存在' && message.error(response.msg);
      }
    },
    *updateIntercity({ payload, callback }, { call }) {
      const response = yield call(updateIntercity, payload);
      if (response.success) {
        message.success('更新成功');
        callback && callback(true);
      } else {
        message.error(response.msg);
      }
    },
    *fetchOpenListIntercity({ payload, callback }, { call, put }) {
      const response = yield call(openListIntercity, payload);
      if (response.success) {
        yield put({
          type: 'saveOpenListIntercity',
          payload: response.data,
        });
        callback && callback(response.data);
      }
    },
    *syncCityConfig({ callback }, { call }) {
      const response = yield call(syncCityConfig, {});
      if (response.success) {
        callback && callback(response.data);
      }
    },
    *updateBusiness({ payload, callback }, { call }) {
      const response = yield call(updateBusiness, payload);
      if (response.success) {
        callback && callback(true);
      } else {
        message.error(response.msg);
      }
    },
    *getVehicleType({ payload, callback }, { call }) {
      const response = yield call(getVehicleType, payload);
      if (response.success) {
        callback && callback(response.data);
      }
    },
    *getVehicleTypeByCode({ payload, callback }, { call }) {
      const response = yield call(getVehicleTypeByCode, payload);
      if (response.success) {
        callback && callback(response.data);
      }
    },
    *getChannelBusinessConfigPage({ payload }, { call, put }) {
      const response = yield call(channelBusinessConfigPage, payload);
      const { bizType } = payload;
      if (response.success) {
        yield put({
          type: 'saveChannelBusinessConfig',
          payload: { [bizType]: response.data.records },
        });
      }
    },
    *addChannelBusinessConfig({ payload, callback }, { call }) {
      const response = yield call(addChannelBusinessConfig, payload);
      if (callback) {
        message.success('新增成功!');
        callback(response.success);
      }
    },
    *editChannelBusinessConfig({ payload, callback }, { call }) {
      const response = yield call(editChannelBusinessConfig, payload);
      if (callback) {
        message.success('修改成功!');
        callback(response.success);
      }
    },
    *editAnnualFeeBusinessConfig({ payload, callback }, { call }) {
      const response = yield call(editAnnualFeeBusinessConfig, payload);
      if (callback) {
        message.success('修改成功!');
        callback(response.success);
      }
    },
    *djConfig({ payload, callback }, { call }) {
      const response = yield call(saveDjConfig, payload);
      if (response.success) {
        message.success('修改成功!');
      }
      callback && callback(response.success);
    },
    *djGetConfig({ payload, callback }, { call, put }) {
      const response = yield call(getDjConfig, payload);
      if (response.success) {
        yield put({
          type: 'saveDjConfigDetail',
          payload: response.data,
        });
      }
      callback(response.success);
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveOpenList(state, action) {
      return {
        ...state,
        openList: action.payload,
      };
    },
    saveConfigDetail(state, action) {
      return {
        ...state,
        configDetail: action.payload.detail,
      };
    },
    // 城际
    saveListIntercity(state, action) {
      return {
        ...state,
        dataIntercity: action.payload,
      };
    },
    // 开通的列表
    saveOpenListIntercity(state, action) {
      return {
        ...state,
        openListIntercity: action.payload,
      };
    },
    saveChannelBusinessConfig(state, action) {
      return {
        ...state,
        channelBusinessConfigList: {
          ...state.channelBusinessConfigList,
          ...action.payload,
        },
      };
    },
    saveDjConfigDetail(state, action) {
      return {
        ...state,
        configDjDetail: action.payload,
      };
    },
  },
};

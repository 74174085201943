import { message } from 'antd';
import { AFTERSALES_NAMESPACE } from '../actions/aftersales';
import { list, logList, detail, serviceCheck, appleResolve, appleReject } from '../services/aftersales';

export default {
  namespace: AFTERSALES_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: {},
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDetail({ payload }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
      }
    },
    *clearDetail({ payload }, { put }) {
      yield put({
        type: 'removeDetail',
        payload: { payload },
      });
    },
    *serviceCheck({ payload, callback }, { call }) {
      const response = yield call(serviceCheck, payload);
      if (response.success) {
        message.success(response.msg)
        callback(true);
      } else {
        callback(false);
      }
    },
    *appleResolve({ payload }, { call }) {
      const {
        data,
        completed,
      } = payload;
      const response = yield call(appleResolve, data);
      if (response.success) {
        completed(true);
        message.success(response.msg)
      } else {
        completed(false);
      }
    },
    *appleReject({ payload }, { call, put }) {
      const {
        data,
        completed,
      } = payload;
      const response = yield call(appleReject, data);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        })
        completed(true);
        message.success(response.msg)
      } else {
        completed(false);
      }
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveLogList(state, action) {
      return {
        ...state,
        logs: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    },
  },
};

import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

// 渠道列表
export async function list(params) {
  return request(`/api/bmkp-backend/channel/channel/page?${stringify(params)}`);
}

// 新增渠道
export async function submit(params) {
  return request('/api/bmkp-backend/channel/channel/submit', {
    method: 'POST',
    body: params,
  });
}

// 删除渠道
export async function del(params) {
  return request('/api/bmkp-backend/channel/channel/remove', {
    method: 'POST',
    body: params,
  });
}

// 获取详情
export async function info(params) {
  return request(`/api/bmkp-backend/channel/channel/detail?${stringify(params)}`);
}
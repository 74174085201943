import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

export async function list(params) {
  return request(`/api/bmkp-backend/customize/dispatcher/list?${stringify(params)}`);
}
export async function submit(params) {
  return request('/api/bmkp-backend/customize/dispatcher/submit', {
    method: 'POST',
    body: params,
  });
}
export async function remove(params) {
  return request('/api/bmkp-backend/activity/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}
import { message } from 'antd';
import { router } from 'umi';
import { INSIDEORDER_NAMESPACE } from '../actions/insideorder';
import { 
  list,
  submit,
  detail,
  // remove, 
  // cancel, 
  // calcPrice, 
  changeOrder,
  closeOrder,
  finishOrder,
  refundApply,
  updateFee,
  stopCheckPassengerPhone,
  queryRecording,
  queryPriceWithUpdate,
  queryPriceWithTrip, 
  queryPriceWithApply, 
  calcPriceWithTrip, 
  calcPriceWithUpdate
} from '@/services/insideorder';
import Func from '@/utils/Func';

export default {
  namespace: INSIDEORDER_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: {},
    costDetail: {},
    updateFeeDetail: {},  // 修改金额详情
    tripDetail: {}, // 结束行程详情
    applyDetail: {},// 申请退款详情
    calcTripDetail: {},  // 行程费计算结果详情
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
    *clearDetail({ payload }, { put }) {
      yield put({
        type: 'removeDetail',
        payload: { payload },
      });
    },
    *create({ payload }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success(response.msg)
        const { orderNo } = response.data
        router.replace(`/order/record/detail/${orderNo}`)
      }
    },
    // *calcPrice({ payload }, { put, call }) {
    //   const response = yield call(calcPrice, payload);
    //   if (response.success) {
    //     yield put({
    //       type: 'saveCalcPriceDetail',
    //       payload: {
    //         costDetail: response.data,
    //       },
    //     });
    //   }
    // },
    // *cancel({ payload, callback }, { call }) {
    //   const response = yield call(cancel, payload);
    //   if (response.success) {
    //     message.success(response.msg)
    //     callback(true)
    //   } else {
    //     message.warning(response.msg)
    //     callback(false)
    //   }
    // },
    // *remove({ payload }, { call }) {
    //   const {
    //     data: { keys },
    //     success,
    //   } = payload;
    //   const response = yield call(remove, { ids: keys });
    //   if (response.success) {
    //     success();
    //   }
    // },
    *changeOrder({ payload, callback }, { call }) {
      const response = yield call(changeOrder, payload);
      if (response.success) {
        message.success(response.msg)
        callback(true)
      } else {
        message.error(response.msg)
        callback(false)
      }
    },
    *closeOrder({ payload, callback }, { call }) {
      const response = yield call(closeOrder, payload);
      if (response.success) {
        message.success(response.msg)
        callback(true)
      } else {
        message.error(response.msg)
        callback(false)
      }
    },
    *finishOrder({ payload, callback }, { call }) {
      const response = yield call(finishOrder, payload);
      if (response.success) {
        message.success(response.msg)
        callback(true)
      } else {
        message.error(response.msg)
        callback(false)
      }
    },
    *refundApply({ payload, callback }, { call }) {
      const response = yield call(refundApply, payload);
      if (response.success) {
        message.success(response.msg)
        callback(true)
      } else {
        callback(false)
      }
    },
    *updateFee({ payload, callback }, { call }) {
      const response = yield call(updateFee, payload);
      if (response.success) {
        message.success(response.msg)
        callback(true)
      } else {
        callback(false)
      }
    },
    *stopCheckPassengerPhone({ payload, callback }, { call }) {
      const response = yield call(stopCheckPassengerPhone, payload);
      if (response.success) {
        message.success(response.msg)
        callback(true)
      } else {
        message.error(response.msg)
        callback(false)
      }
    },
    *queryRecording({ payload, callback }, { call }) {
      const response = yield call(queryRecording, payload);
      if (response.success) {
        if (Func.notEmpty(response.data)) {
          callback(response.data)
          message.success(response.msg)
        } else {
          message.error('不存在录音文件')
        }
      } else {
        message.error(response.msg)
      }
    },
    *queryPriceWithUpdate({ payload, callback }, { call, put }) {
      const response = yield call(queryPriceWithUpdate, payload);
      if (response.success) {
        yield put({
          type: 'saveUpdateFeeDetail',
          payload: {
            updateFeeDetail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
    *queryPriceWithTrip({ payload, callback }, { call, put }) {
      const response = yield call(queryPriceWithTrip, payload);
      if (response.success) {
        yield put({
          type: 'saveTripDetail',
          payload: {
            tripDetail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
    *queryPriceWithApply({ payload, callback }, { call, put }) {
      const response = yield call(queryPriceWithApply, payload);
      if (response.success) {
        yield put({
          type: 'saveApplyDetail',
          payload: {
            applyDetail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
    *clearApplyDetail({ payload }, { put }) {
      yield put({
        type: 'removeApplyDetail',
        payload: { payload },
      });
    },
    *clearUpdateFeeDetail({ payload }, { put }) {
      yield put({
        type: 'removeUpdateFeeDetail',
        payload: { payload },
      });
    },
    *clearTripDetail({ payload }, { put }) {
      yield put({
        type: 'removeTripDetail',
        payload: { payload },
      });
    },
    *calcPriceWithTrip({ payload, option }, { call, put }) {
      let fn = null
      switch (option) {
        case 'finish':
          fn = calcPriceWithTrip
          break
        case 'updateFee':
          fn = calcPriceWithUpdate
          break
        default:
          break
      }
      const response = yield call(fn, payload);
      if (response.success) {
        yield put({
          type: 'saveCalcTripDetail',
          payload: {
            calcTripDetail: response.data,
          },
        });
      }
    },
    *clearCalcTripDetail({ payload }, { put }) {
      yield put({
        type: 'removeCalcTripDetail',
        payload: { payload },
      });
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    saveCalcPriceDetail(state, action) {
      return {
        ...state,
        costDetail: action.payload.costDetail,
      };
    },
    saveUpdateFeeDetail(state, action) {
      return {
        ...state,
        updateFeeDetail: action.payload.updateFeeDetail,
      };
    },
    saveTripDetail(state, action) {
      return {
        ...state,
        tripDetail: action.payload.tripDetail,
      };
    },
    saveApplyDetail(state, action) {
      return {
        ...state,
        applyDetail: action.payload.applyDetail,
      };
    },
    saveCalcTripDetail(state, action) {
      return {
        ...state,
        calcTripDetail: action.payload.calcTripDetail,
      };
    },
    removeCalcTripDetail(state) {
      return {
        ...state,
        calcTripDetail: {}
      };
    },
    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    },
    removeUpdateFeeDetail(state) {
      return {
        ...state,
        updateFeeDetail: {},
      };
    },
    removeTripDetail(state) {
      return {
        ...state,
        tripDetail: {},
      };
    },
    removeApplyDetail(state) {
      return {
        ...state,
        applyDetail: {},
      };
    },
  },
};



import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

// 分页
export async function page(params) {
  return request(`/api/bmkp-backend/wycActivityInviteAward/page?${stringify(params)}`);
}

// 详情
export async function detail(params) {
  return request(`/api/bmkp-backend/wycActivityInviteAward/detail?${stringify(params)}`);
}

// 开启
export async function open(params) {
  return request(`/api/bmkp-backend/wycActivityInviteAward/open?${stringify(params)}`);
}

// 关闭
export async function close(params) {
  return request(`/api/bmkp-backend/wycActivityInviteAward/close?${stringify(params)}`);
}

// 删除
export async function invitedelete(params) {
  return request(`/api/bmkp-backend/wycActivityInviteAward/delete?${stringify(params)}`);
}

// 新增
export async function save(params) {
  return request('/api/bmkp-backend/wycActivityInviteAward/save', {
    method: 'POST',
    body: params,
  });
}

// 修改
export async function update(params) {
  return request('/api/bmkp-backend/wycActivityInviteAward/update', {
    method: 'POST',
    body: params,
  });
}

// 复制
export async function copy(params) {
  return request('/api/bmkp-backend/wycActivityInviteAward/copy', {
    method: 'POST',
    body: func.toFormData(params),
  });
}

// 复制
export async function couponDelete(params) {
  return request('/api/bmkp-backend/wyc_activity_invite_link_coupon/wycactivityinvitelinkcoupon/delete', {
    method: 'POST',
    body: func.toFormData(params),
  });
}
import { message } from 'antd';
import { CUSTOMER_SERVICE_NAMESPACE } from '../actions/customerservice';
import {
  getPrivacyNumber,
  setPrivacyNumberStatus
} from '../services/customerservice';

export default {
  namespace: CUSTOMER_SERVICE_NAMESPACE,
  state: {
    privacyNumberDetail: {}
  },
  effects: {
    *fetchPrivacyNumber({ payload }, { call, put }) {
      const response = yield call(getPrivacyNumber, payload);
      if (response.success) {
        yield put({
          type: 'savePrivacyNumberDetail',
          payload: {
            detail: response.data,
          },
        });
      }
    },
    *savePrivacyNumberStatus({ payload, callbck }, { call }) {
      const response = yield call(setPrivacyNumberStatus, payload);
      if (response.success) {
        message.success('提交成功');
        callbck(true)
      } else {
        callbck(false)
      }
    },
  },
  reducers: {
    savePrivacyNumberDetail(state, action) {
      return {
        ...state,
        privacyNumberDetail: action.payload.detail,
      };
    }
  },
};

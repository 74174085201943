import request from '../utils/request';

// 行程记录分页查询
export async function list(params) {
  return request('/api/bmkp-backend/blade-trip/trip/record/page', {
    method: 'POST',
    body: params,
  });
}

// 行程记录详情
export async function detail(params) {
  return request('/api/bmkp-backend/blade-trip/trip/record/detail', {
    method: 'POST',
    body: params,
  });
}

import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

// 下拉选择框使用车辆车系列表接口 参数传入brandId
export async function list(params) {
  return request(`/api/bmkp-backend/carseries/list?${stringify(params)}`);
}

export async function page(params) {
  return request(`/api/bmkp-backend/carseries/page?${stringify(params)}`);
}

export async function submit(params) {
  return request('/api/bmkp-backend/carseries/submit', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request(`/api/bmkp-backend/carseries/detail?${stringify(params)}`);
}

export async function remove(params) {
  return request('/api/bmkp-backend/carseries/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}

import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';


// 司机奖励活动账单
export async function driverAwardBillInside(params) {
  return request(`/api/bmkp-backend/wycActivityDriverAwardBill/page?${stringify(params)}`);
}

// 城际订单列表(司机)
export async function thirdList(params) {
  return request('/api/bmkp-backend/blade-order/order/page/third', {
    method: 'POST',
    body: params,
  });
}

// 司机邀请活动账单
export async function driverInviteBillInside(params) {
  return request(`/api/bmkp-backend/driverInvite/driverinvitebill/page?${stringify(params)}`);
}

// 乘客邀请活动账单
export async function passengersInviteBillInside(params) {
  return request('/api/bmkp-backend/finance/activitynewcouponbill/list', {
    method: 'POST',
    body: params,
  });
}

export const CITY_MANAGE_NAMESPACE = 'cityManage';

// 城内
export function CITY_MANAGE_LIST(payload, callback = null) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/fetchList`,
    payload,
    callback
  };
}

export function CITY_MANAGE_DETAIL(id, callback) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/fetchDetail`,
    payload: { id },
    callback
  };
}
export function CITY_MANAGE_CONFIG_DETAIL(id, callback) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/fetchConfigDetail`,
    payload: { id },
    callback
  };
}

export function CITY_MANAGE_OPEN(payload, callback) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/open`,
    payload,
    callback
  };
}
export function CITY_MANAGE_CLOSE(payload, callback) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/close`,
    payload,
    callback
  };
}
export function CITY_MANAGE_OPEN_CHANNEL_DISPATCH(payload, callback) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/openChannelDispatch`,
    payload,
    callback
  };
}
export function CITY_MANAGE_CLOSE_CHANNEL_DISPATCH(payload, callback) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/closeChannelDispatch`,
    payload,
    callback
  };
}

export function CITY_MANAGE_CITYS_INFO_LIST(payload, callback) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/fetchCitysInfo`,
    payload,
    callback
  }
}

export function CITY_MANAGE_CLEAR_DETAIL() {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/clearDetail`,
    payload: null,
  };
}

export function CITY_MANAGE_SUBMIT_SERVICE_CONFIG(payload, callback) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/submitBusinessServiceConfig`,
    payload,
    callback
  };
}

export function CITY_MANAGE_SUBMIT_SERVICE_TYPE(payload, callback) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/submitBusinessServiceType`,
    payload,
    callback
  };
}

export function CITY_MANAGE_SUBMIT(payload, callback) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/submit`,
    payload,
    callback
  };
}

export function CITY_MANAGE_REMOVE(payload) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/remove`,
    payload,
  };
}

export function CITY_MANAGE_REMOVE_SERVICE_TYPE(payload, callback) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/removeServiceType`,
    payload,
    callback
  };
}
export function CITY_MANAGE_REMOVE_BUSINESS_SERVICE_TYPE(payload, callback) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/removeBusinessServiceType`,
    payload,
    callback
  };
}

export function CITY_MANAGE_OPEN_LIST(payload, callback = null) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/fetchOpenList`,
    payload,
    callback
  };
}

export function CITY_MANAGE_OPEN_LIST_CLEARN(callback = null) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/clearnOpenList`,
    callback
  };
}


// 城际
export function CITY_MANAGE_LIST_INTERCITY(payload, callback = null) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/fetchListIntercity`,
    payload,
    callback
  };
}

export function CITY_MANAGE_SUBMIT_INTERCITY(payload, callback) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/submitIntercity`,
    payload,
    callback
  };
}
export function CITY_MANAGE_SUBMIT_UPDATE_INTERCITY(payload, callback) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/updateIntercity`,
    payload,
    callback
  };
}


export function CITY_MANAGE_OPEN_LIST_INTERCITY(payload, callback = null) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/fetchOpenListIntercity`,
    payload,
    callback
  };
}

export function CITY_MANAGE_SYNC_CITY_CONFIG(callback = null) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/syncCityConfig`,
    callback
  };
}

export function CITY_MANAGE_UPDATE_BUSINESS(payload, callback = null) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/updateBusiness`,
    payload,
    callback
  };
}

// 根据城市获取其下所有的车型
export function CITY_MANAGE_GET_VEHICLETYPE(payload, callback = null) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/getVehicleType`,
    payload,
    callback
  };
}
// 根据城市获取其下所有的车型
export function CITY_MANAGE_GET_VEHICLETYPE_BY_CODE(payload, callback = null) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/getVehicleTypeByCode`,
    payload,
    callback
  };
}
// 获取城市管理的渠道列表
export function CITY_MANAGE_GET_CHANNEL_BUSINESS_CONFIG_PAGE(payload, callback = null) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/getChannelBusinessConfigPage`,
    payload,
    callback
  };
}
// 提交城市关联的渠道
export function CITY_MANAGE_ADD_CHANNEL_BUSINESS_CONFIG(payload, callback = null) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/addChannelBusinessConfig`,
    payload,
    callback
  };
}
// 提交城市关联的渠道
export function CITY_MANAGE_EDIT_CHANNEL_BUSINESS_CONFIG(payload, callback = null) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/editChannelBusinessConfig`,
    payload,
    callback
  };
}

// 提交城市年费包干配置
export function CITY_MANAGE_EDIT_ANNUALFEE_BUSINESS_CONFIG(payload, callback = null) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/editAnnualFeeBusinessConfig`,
    payload,
    callback
  };
}

// 代驾城市配置
export function CITY_MANAGE_SAVE_DJ_CONFIG(payload, callback = null) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/djConfig`,
    payload,
    callback
  };
}

// 代驾城市配置
export function CITY_MANAGE_GET_DJ_CONFIG(payload, callback = null) {
  return {
    type: `${CITY_MANAGE_NAMESPACE}/djGetConfig`,
    payload,
    callback
  };
}
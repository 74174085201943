import request from '../utils/request'

// 查询
export async function query(params) {
  return request('/api/bmkp-wyc/wyc/passenger/risk/query/v1', {
    method: 'POST',
    body: params,
  });
}

// 保持
export async function submit(params) {
    return request('/api/bmkp-wyc/wyc/passenger/risk/submit/v1', {
      method: 'POST',
      body: params,
    });
  }
import { stringify } from 'qs';
// import func from '../utils/Func';
import request from '../utils/request';

// 城市列表
export async function cityList(params) {
  return request('/api/bmkp-backend/business/currentUserCityList', {
    method: 'POST',
    body: params,
  });
}

// 根据条件查询司机列表
export async function list(params) {
  return request(`/api/bmkp-backend/asset/driver/driverMap?${stringify(params)}`);
}

export const LINE_SERVICE_SCOPE = 'lineServiceScope';

export function LINECONFIG_LIST(payload) {
  return {
    type: `${LINE_SERVICE_SCOPE}/fetchList`,
    payload,
  };
}

export function LINE_SERVICE_SCOPE_DETAIL(payload) {
  return {
    type: `${LINE_SERVICE_SCOPE}/fetchDetail`,
    payload,
  };
}

export function LINE_SERVICE_SCOPE_RESET(payload) {
  return {
    type: `${LINE_SERVICE_SCOPE}/resetDetail`,
    payload,
  };
}

export function LINE_SERVICE_SCOPE_SUBMIT(payload, callback) {
  return {
    type: `${LINE_SERVICE_SCOPE}/submit`,
    payload,
    callback
  };
}
export function LINE_SERVICE_SCOPE_UPDATE(payload, callback) {
  return {
    type: `${LINE_SERVICE_SCOPE}/update`,
    payload,
    callback
  };
}

export function LINE_SERVICE_SCOPE_REMOVE(payload) {
  return {
    type: `${LINE_SERVICE_SCOPE}/removeone`,
    payload,
  };
}
export function SPECIAL_LINE_FENCE_MATCH_LIST(payload) {
  return {
    type: `${LINE_SERVICE_SCOPE}/fetchSpecialLineFenceMatchList`,
    payload,
  };
}
export function SPECIAL_LINE_FENCE_MATCH_SUBMIT(payload, callback) {
  return {
    type: `${LINE_SERVICE_SCOPE}/specialLineFenceMatchSubmit`,
    payload,
    callback
  };
}
export function SPECIAL_LINE_FENCE_MATCH_REMEVE_ONE(payload, callback) {
  return {
    type: `${LINE_SERVICE_SCOPE}/specialLineFenceMatchRemoveOne`,
    payload,
    callback
  };
}


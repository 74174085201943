export const PRICE_NAMESPACE = 'djpricerule';

/**
 *
 * @param payload
 * 计价管理
 * @returns
 */
export function PRICE_RULE_LIST(payload) {
  return {
    type: `${PRICE_NAMESPACE}/fetchList`,
    payload,
  };
}

export function PRICE_RULE_ADD(payload, callback) {
  return {
    type: `${PRICE_NAMESPACE}/submit`,
    payload,
    callback,
  };
}

export function PRICE_RULE_UPDATE(payload, callback) {
  return {
    type: `${PRICE_NAMESPACE}/update`,
    payload,
    callback,
  };
}

export function PRICE_RULE_DEL(payload, callback) {
  return {
    type: `${PRICE_NAMESPACE}/del`,
    payload,
    callback,
  };
}

export function PRICE_RULE_DETAIL(payload, callback?) {
  return {
    type: `${PRICE_NAMESPACE}/detail`,
    payload,
    callback,
  };
}

export function PRICE_RULE_DETAIL_CLEARN() {
  return {
    type: `${PRICE_NAMESPACE}/detailClearn`,
  };
}

export function PRICE_RULE_REGION(payload, callback = null) {
  return {
    type: `${PRICE_NAMESPACE}/getRegion`,
    payload,
    callback,
  };
}

export function PRICE_RULE_CHECKAREA(payload, callback = null) {
  return {
    type: `${PRICE_NAMESPACE}/checkArea`,
    payload,
    callback,
  };
}

export default {
  'table.columns.action': '操作',
  'button.add.name': '新增',
  'button.submit.name': '提交',
  'button.view.name': '查看',
  'button.edit.name': '修改',
  'button.delete.name': '刪除',
  'button.remove.name': '刪除',
  'button.search.name': '查詢',
  'button.reset.name': '重置',
  'button.recycle.name': '回收站',
  'button.recovery.name': '恢複',
  'button.expand.name': '展開',
  'button.role.name': '角色配置',
  'button.reset-password.name': '密碼重置',
  'button.back.name': '返回',
  'button.deploy.name': '部署',
  'button.download.name': '下載',
  'button.create.name': '創建',
  'button.update.name': '配置',
  'button.state.name': '變更狀態',
  'button.image.name': '流程圖',
  'button.flow.name': '發起',
  'button.sign.name': '簽收',
  'button.handle.name': '處理',
  'button.detail.name': '詳情',
  'button.follow.name': '跟蹤',
  'button.enable.name': '啟用',
  'button.datascope.name': '數據權限',
  'button.setting.name': '配置',
  'button.upload.name': '上傳',
};

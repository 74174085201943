import { message, notification } from 'antd';
import router from 'umi/router';
import { DRIVER_NAMESPACE } from '@/actions/dj/djdriver';
import {
  list,
  updateClose,
  updateOpen,
  detail,
  submit,
  checkList,
  peddingDetail,
  peddingList,
  updatePass,
  updateReject,
} from '@/services/dj/djdriver';
export default {
  namespace: DRIVER_NAMESPACE,
  state: {
    // 司机管理列表
    data: {
      list: [],
      pagination: false,
    },
    checkData: {
      list: [],
      pagination: false,
    },
    peddingData: {
      list: [],
      pagination: false,
    },
    driverInfo: null,
    peddingInfo: null,
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *submit({ payload, callback }, { call }) {
      const res = yield call(submit, payload);
      if (res.success) {
        message.success(res.msg);
        callback && callback(res.data);
      }
    },
    *detail({ payload, callback }, { call, put }) {
      const res = yield call(detail, payload);
      if (res.success) {
        yield put({
          type: 'saveDetail',
          payload: res.data,
        });
      }
    },
    *updateOpen({ payload, callback }, { call }) {
      const res = yield call(updateOpen, payload);
      if (res.success) {
        message.success(res.msg);
        callback && callback(res.success);
      }
    },
    *updateClose({ payload, callback }, { call }) {
      const res = yield call(updateClose, payload);
      if (res.success) {
        message.success(res.msg);
        callback && callback(res.success);
      }
    },
    *detailClearn(_, { put }) {
      yield put({
        type: 'saveDetail',
        payload: {},
      });
    },
    *fetchPeddingList({ payload }, { call, put }) {
      const response = yield call(peddingList, payload);
      if (response.success) {
        yield put({
          type: 'savePeddingList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *peddingDetail({ payload, callback }, { call, put }) {
      const res = yield call(peddingDetail, payload);
      if (res.success) {
        yield put({
          type: 'savePeddingDetail',
          payload: res.data,
        });
      }
    },
    *peddingReject({ payload, callback }, { call }) {
      const res = yield call(updateReject, payload);
      if (res.success) {
        message.success(res.msg);
      }
      callback && callback(res.success);
    },
    *peddingPass({ payload, callback }, { call }) {
      const res = yield call(updatePass, payload);
      if (res.success) {
        message.success(res.msg);
      }
      callback && callback(res.success);
    },
    *peddingDetailClearn(_, { put }) {
      yield put({
        type: 'savePeddingDetail',
        payload: {},
      });
    },
    *fetchCheckList({ payload }, { call, put }) {
      const response = yield call(checkList, payload);
      if (response.success) {
        yield put({
          type: 'saveCheckList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        driverInfo: action.payload,
      };
    },
    savePeddingList(state, action) {
      return {
        ...state,
        peddingData: action.payload,
      };
    },
    savePeddingDetail(state, action) {
      return {
        ...state,
        peddingInfo: action.payload,
      };
    },
    saveCheckList(state, action) {
      return {
        ...state,
        checkData: action.payload,
      };
    },
  },
};

import { message } from 'antd';
import router from 'umi/router';
import { CAPACITYAGENT_NAMESPACE } from '../actions/capacityagent';
import { list, submit, detail, remove, unfrozen, frozen, stateOpen, stateClose } from '../services/capacityagent';
import { relevance, removeConnect, getRelevanceSuppliers } from '../services/capacityagentsupplier';

export default {
  namespace: CAPACITYAGENT_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: {},
    relevanceSuppliers: []
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const { callback } = payload
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
        callback(response.data.records)
      }
    },
    *fetchDetail({ payload }, { call, put }) {
      const { callback } = payload
      const response = yield call(detail, payload);
      if (response.success) {

        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
        
        callback(response.data)
      }
    },
    *clearDetail({ payload }, { put }) {
      yield put({
        type: 'removeDetail',
        payload: { payload },
      });
    },
    *submit({ payload }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('提交成功');
        router.push('/capacity/capacityAgent');
      }
    },
    *stateOpen({ payload, callback }, { call }) {
      const response = yield call(stateOpen, payload);
      if (response.success) {
        message.success('开启成功');
        callback()
      }
    },
    *stateClose({ payload, callback }, { call }) {
      const response = yield call(stateClose, payload);
      if (response.success) {
        message.success('关闭成功');
        callback()
      }
    },
    *connectSupplier({ payload }, { call }) {
      const { data, success } = payload
      const response = yield call(relevance, data);
      if (response.success) {
        message.success('关联成功');
        success()
      }
    },
    *removeSupplier({ payload }, { call }) {
      const { relevanceId, success } = payload
      const response = yield call(removeConnect, { relevanceId });
      if (response.success) {
        message.success('解除关联成功');
        success()
      }
    },
    *supplierList({ payload }, { call, put }) {
      const response = yield call(getRelevanceSuppliers, payload);
      if (response.success) {
        yield put({
          type: 'saveSupplierList',
          payload: response.data
        })
      }
    },
    *remove({ payload }, { call }) {
      const {
        data: { keys },
        success,
      } = payload;
      const response = yield call(remove, { ids: keys });
      if (response.success) {
        success();
      }
    },
    *unfrozen({ payload }, { call }) {
      const {
        data: { agentId },
        success,
      } = payload;
      const response = yield call(unfrozen, { agentId });
      if (response.success) {
        success();
      }
    },
    *frozen({ payload }, { call }) {
      const {
        data: { agentId },
        success,
      } = payload;
      const response = yield call(frozen, { agentId });
      if (response.success) {
        success();
      }
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveSupplierList(state, action) {
      return {
        ...state,
        relevanceSuppliers: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    },
  },
};

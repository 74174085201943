
import { stringify } from 'qs';
import request from '../utils/request';

// 区域调度列表
export async function list(params) {
  return request(`/api/bmkp-wyc/wyc/fence/page?${stringify(params)}`);
}

// 提交
export async function add(params) {
  return request('/api/bmkp-wyc/wyc/fence/save', {
    method: 'POST',
    body: params,
  });
}

// 修改
export async function update(params) {
  return request('/api/bmkp-wyc/wyc/fence/update', {
    method: 'POST',
    body: params,
  });
}

// 查询详情
export async function detail(params) {
  return request(`/api/bmkp-wyc/wyc/fence/detail?${stringify(params)}`,{
    method: 'POST',
  });
}

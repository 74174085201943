export const CAPACITYSUPPLIEROPERATECONFIG_NAMESPACE = 'capacitysupplieroperateconfig';

export function CAPACITYSUPPLIEROPERATECONFIG_DETAIL(payload, callback = null) {
  return {
    type: `${CAPACITYSUPPLIEROPERATECONFIG_NAMESPACE}/fetchDetail`,
    payload,
    callback
  };
}

export function CAPACITYSUPPLIEROPERATECONFIG_SET_DETAIL(payload, callback = null) {
  return {
    type: `${CAPACITYSUPPLIEROPERATECONFIG_NAMESPACE}/setDetail`,
    payload,
    callback
  };
}

export function CAPACITYSUPPLIEROPERATECONFIG_INSIDE_DETAIL(payload, callback = null) {
  return {
    type: `${CAPACITYSUPPLIEROPERATECONFIG_NAMESPACE}/fetchInsideDetail`,
    payload,
    callback
  };
}

export function CAPACITYSUPPLIEROPERATECONFIG_SET_INSIDE_DETAIL(payload, callback = null) {
  return {
    type: `${CAPACITYSUPPLIEROPERATECONFIG_NAMESPACE}/setInsideDetail`,
    payload,
    callback
  };
}
export function CAPACITYSUPPLIEROPERATECONFIG_DJ_DETAIL(payload, callback = null) {
  return {
    type: `${CAPACITYSUPPLIEROPERATECONFIG_NAMESPACE}/fetchDjDetail`,
    payload,
    callback
  };
}

export function CAPACITYSUPPLIEROPERATECONFIG_SET_DJ_DETAIL(payload, callback = null) {
  return {
    type: `${CAPACITYSUPPLIEROPERATECONFIG_NAMESPACE}/setDjDetail`,
    payload,
    callback
  };
}

export function CAPACITYSUPPLIEROPERATECONFIG_CLEAR_DETAIL() {
  return {
    type: `${CAPACITYSUPPLIEROPERATECONFIG_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function CAPACITYSUPPLIEROPERATECONFIG_SUBMIT(payload, callback) {
  return {
    type: `${CAPACITYSUPPLIEROPERATECONFIG_NAMESPACE}/submit`,
    payload,
    callback
  };
}

export function CAPACITYSUPPLIEROPERATECONFIG_INSIDE_SUBMIT(payload, callback) {
  return {
    type: `${CAPACITYSUPPLIEROPERATECONFIG_NAMESPACE}/insideSubmit`,
    payload,
    callback
  };
}

export function CAPACITYSUPPLIEROPERATECONFIG_REMOVE(payload) {
  return {
    type: `${CAPACITYSUPPLIEROPERATECONFIG_NAMESPACE}/remove`,
    payload,
  };
}

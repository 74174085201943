export const DRIVERCONTRACT_NAMESPACE = 'driverContract';

export function DRIVERCONTRACT_LIST(payload) {
  return {
    type: `${DRIVERCONTRACT_NAMESPACE}/fetchList`,
    payload,
  };
}

export function DRIVERCONTRACT_DETAIL(driverId) {
  return {
    type: `${DRIVERCONTRACT_NAMESPACE}/fetchDetail`,
    payload: { driverId },
  };
}

export function DRIVERCONTRACT_CLEAR_DETAIL() {
  return {
    type: `${DRIVERCONTRACT_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function DRIVERCONTRACT_SUBMIT(payload) {
  return {
    type: `${DRIVERCONTRACT_NAMESPACE}/submit`,
    payload,
  };
}

export function DRIVERCONTRACT_REMOVE(payload) {
  return {
    type: `${DRIVERCONTRACT_NAMESPACE}/remove`,
    payload,
  };
}

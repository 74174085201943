import { message } from 'antd';
import router from 'umi/router';
import { COMMON_DICT_NAMESPACE } from '../actions/commondict';
import { dict } from '../services/dictbiz';
import { dict as sysDict } from '../services/dict';

export default {
  namespace: COMMON_DICT_NAMESPACE,
  state: {
    // 注意，下面的所有key和value值对应的内容，都可以到后台管理系统的业务字典中查找
    bizTypeDict: [],
    serviceTypeDict: [],
    // 数据对接服务类型
    commercialTypeDict: [],
    carUseTypeDict: [],
    carTypeDict: [],
    supplierTypeDict: [],
    carBelongTypeDict: [],
    carColorTypeDict: [],
    energyTypeDict: [],
    verhicleLevelDict: [],
    verhicleImgDict: [],
    verhicleChannelTypeDict: [],
    verhicleChannelCarTypeDict: [],
    verhicleSeatNumberDict: [],
    driverTypeDict: [],
    sexTypeDict: [],
    couponTypeDict: [],
    realTimeTypeDict: [],
    vehicleLicenseTypeDict: [],
    mobileMessageTargetTypeDict: [],
    provinceVehicleNumberTypeDict: [],
    specialDistrictInfoDict: [],
    idCardRejectReasonDict: [],
    driverLicenseRejectReasonDict: [],
    carLicenseRejectReasonDict: [],
    driverCertificateTypeDict: [],
    responsibilityTypeDict: [],
    driverActivityInsideTypeDict: [],
    passengerActivityIntercityTypeDict: [],
    orderSourceTypeDict: [],
    activityCostShareTypeDict: [],
    activityUserTypeDict: [],
    couponSendTypeDict: [],
    passengerCancelReasonType: [],
    licensePlateColorDict: []
  },
  effects: {
    *fetchBizTypeDict({ payload, callback }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveBizTypeDict',
          payload: {
            detail: response.data
          },
        });
        callback && callback(response.data)
      }
    },
    *fetchServiceTypeDict({ payload, callback}, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveServiceTypeDict',
          payload: {
            detail: response.data
          },
        });
        // eslint-disable-next-line no-unused-expressions
        callback && callback(response.data)
      }
    },
    *fetchCommercialTypeDict({ payload, callback}, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveCommercialTypeDict',
          payload: {
            detail: response.data
          },
        });
        // eslint-disable-next-line no-unused-expressions
        callback && callback(response.data)
      }
    },
    *fetchSexTypeDict({ payload }, { call, put }) {
      const response = yield call(sysDict, payload);
      if (response.success) {
        yield put({
          type: 'saveSexTypeDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchSupplierTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveSupplierTypeDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchCarBelongTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveCarBelongTypeDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchCarColorTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveCarColorTypeDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchEnergyTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveEnergyTypeDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchVerhicleLevelDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveVerhicleLevelDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchVehicleChannelTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveVerhicleChannelTypeDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchVehicleChannelCarTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveVerhicleChannelCarTypeDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchVehicleSeatNumberDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveVerhicleSeatNumberDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchVerhicleImgDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveVerhicleImgDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchDriverTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveDriverTypeDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchCouponTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveCouponTypeDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchRealTimeTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveRealTimeTypeDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchVehicleLicenseTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveVehicleLicenseTypeDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchMobileMessageTargetTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveMobileMessageTargetTypeDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchCarUseTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveCarUseTypeDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchCarTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveCarTypeDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchProvinceVehicleNumberTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveProvinceVehicleNumberTypeDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchSpecialDistrictInfoDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveSpecialDistrictInfoDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchIdCardRejectReasonDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveIdCardRejectReasonDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchDriverLicenseRejectReasonDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveDriverLicenseRejectReasonDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchCarLicenseRejectReasonDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveCarLicenseRejectReasonDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchDriverCertificateTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveDriverCertificateDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchDriverActivityInsideTypeDict({ payload, callback }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveDriverActivityInsideTypeDict',
          payload: {
            detail: response.data
          },
        });
        callback && callback(response.data)
      }
    },
    *fetchPassengerActivityIntercityTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'savePassengerActivityIntercityTypeDict',
          payload: {
            detail: response.data
          },
        });
      }
    },
    *fetchResponsibilityTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveResponsibilityTypeDict',
          payload: {
            detail: response.data
          },
        })
      }
    },
    *fetchOrderSourceTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveOrderSourceTypeDict',
          payload: {
            detail: response.data
          },
        })
      }
    },
    *fetchActivityCostShareTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveActivityCostShareTypeDict',
          payload: {
            detail: response.data
          },
        })
      }
    },
    *fetchActivityUserTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveActivityUserTypeDict',
          payload: {
            detail: response.data
          },
        })
      }
    },
    *fetchCouponSendTypeDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveCouponSendTypeDict',
          payload: {
            detail: response.data
          },
        })
      }
    },
    *fetchPassengerCancelReasonDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'savePassengerCancelReasonDict',
          payload: {
            detail: response.data
          },
        })
      }
    },
    *fetchLicensePlateColorDict({ payload }, { call, put }) {
      const response = yield call(dict, payload);
      if (response.success) {
        yield put({
          type: 'saveLicensePlateColorDict',
          payload: {
            detail: response.data
          },
        })
      }
    },
  },
  reducers: {
    saveBizTypeDict(state, action) {
      return {
        ...state,
        bizTypeDict: action.payload.detail,
      };
    },
    saveServiceTypeDict(state, action) {
      return {
        ...state,
        serviceTypeDict: action.payload.detail,
      };
    },
    saveCommercialTypeDict(state, action) {
      return {
        ...state,
        commercialTypeDict: action.payload.detail,
      };
    },
    saveSexTypeDict(state, action) {
      return {
        ...state,
        sexTypeDict: action.payload.detail,
      };
    },
    saveSupplierTypeDict(state, action) {
      return {
        ...state,
        supplierTypeDict: action.payload.detail,
      };
    },
    saveCarBelongTypeDict(state, action) {
      return {
        ...state,
        carBelongTypeDict: action.payload.detail,
      };
    },
    saveCarColorTypeDict(state, action) {
      return {
        ...state,
        carColorTypeDict: action.payload.detail,
      };
    },
    saveEnergyTypeDict(state, action) {
      return {
        ...state,
        energyTypeDict: action.payload.detail,
      };
    },
    saveVerhicleLevelDict(state, action) {
      return {
        ...state,
        verhicleLevelDict: action.payload.detail,
      };
    },
    saveVerhicleSeatNumberDict(state, action) {
      return {
        ...state,
        verhicleSeatNumberDict: action.payload.detail,
      };
    },
    saveVerhicleImgDict(state, action) {
      return {
        ...state,
        verhicleImgDict: action.payload.detail,
      };
    },
    saveVerhicleChannelTypeDict(state, action) {
      return {
        ...state,
        verhicleChannelTypeDict: action.payload.detail,
      };
    },
    saveVerhicleChannelCarTypeDict(state, action) {
      return {
        ...state,
        verhicleChannelCarTypeDict: action.payload.detail,
      };
    },
    saveDriverTypeDict(state, action) {
      return {
        ...state,
        driverTypeDict: action.payload.detail,
      };
    },
    saveCouponTypeDict(state, action) {
      return {
        ...state,
        couponTypeDict: action.payload.detail,
      };
    },
    saveRealTimeTypeDict(state, action) {
      return {
        ...state,
        realTimeTypeDict: action.payload.detail,
      };
    },
    saveVehicleLicenseTypeDict(state, action) {
      return {
        ...state,
        vehicleLicenseTypeDict: action.payload.detail,
      };
    },
    saveMobileMessageTargetTypeDict(state, action) {
      return {
        ...state,
        mobileMessageTargetTypeDict: action.payload.detail,
      };
    },
    saveCarUseTypeDict(state, action) {
      return {
        ...state,
        carUseTypeDict: action.payload.detail,
      };
    },
    saveCarTypeDict(state, action) {
      return {
        ...state,
        carTypeDict: action.payload.detail,
      };
    },
    saveProvinceVehicleNumberTypeDict(state, action) {
      return {
        ...state,
        provinceVehicleNumberTypeDict: action.payload.detail,
      };
    },
    saveSpecialDistrictInfoDict(state, action) {
      return {
        ...state,
        specialDistrictInfoDict: action.payload.detail,
      };
    },
    saveIdCardRejectReasonDict(state, action) {
      return {
        ...state,
        idCardRejectReasonDict: action.payload.detail,
      };
    },
    saveDriverLicenseRejectReasonDict(state, action) {
      return {
        ...state,
        driverLicenseRejectReasonDict: action.payload.detail,
      };
    },
    saveCarLicenseRejectReasonDict(state, action) {
      return {
        ...state,
        carLicenseRejectReasonDict: action.payload.detail,
      };
    },
    saveDriverCertificateDict(state, action) {
      return {
        ...state,
        driverCertificateTypeDict: action.payload.detail,
      };
    },
    saveResponsibilityTypeDict(state, action) {
      return {
        ...state,
        responsibilityTypeDict: action.payload.detail,
      };
    },
    saveDriverActivityInsideTypeDict(state, action) {
      return {
        ...state,
        driverActivityInsideTypeDict: action.payload.detail,
      };
    },
    savePassengerActivityIntercityTypeDict(state, action) {
      return {
        ...state,
        passengerActivityIntercityTypeDict: action.payload.detail,
      };
    },
    saveOrderSourceTypeDict(state, action) {
      return {
        ...state,
        orderSourceTypeDict: action.payload.detail,
      };
    },
    saveActivityCostShareTypeDict(state, action) {
      return {
        ...state,
        activityCostShareTypeDict: action.payload.detail,
      };
    },
    saveActivityUserTypeDict(state, action) {
      return {
        ...state,
        activityUserTypeDict: action.payload.detail,
      };
    },
    saveCouponSendTypeDict(state, action) {
      return {
        ...state,
        couponSendTypeDict: action.payload.detail,
      };
    },
    savePassengerCancelReasonDict(state, action) {
      return {
        ...state,
        passengerCancelReasonType: action.payload.detail,
      };
    },
    saveLicensePlateColorDict(state, action) {
      return {
        ...state,
        licensePlateColorDict: action.payload.detail,
      };
    },
  },
};

import { stringify } from 'qs';
import request from '../utils/request';

// export async function list(params) {
//   return request(`/api/bmkp-backend/feedback/page?${stringify(params)}`)
// }


export async function list(params) {
  return request('/api/bmkp-backend/suggest/page', {
    method: 'POST',
    body: params,
  })
}

export const SYSTEM_NAMESPACE = 'system';

export function SYSTEM_QUERY(payload, callback = null) {
  return {
    type: `${SYSTEM_NAMESPACE}/fetchDetail`,
    payload,
    callback
  };
}

export function SYSTEM_CLEAR_QUERY() {
  return {
    type: `${SYSTEM_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function SYSTEM_SAVE(payload, callback) {
  return {
    type: `${SYSTEM_NAMESPACE}/save`,
    payload,
    callback
  }
}
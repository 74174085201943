export const ORDERPROOFREAD_NAMESPACE = 'orderproofread';

// 待核算列表
export function ORDERPROOFREAD_WAIT_SETTLEMENT_LIST(payload) {
  return {
    type: `${ORDERPROOFREAD_NAMESPACE}/fetchWaitSettlementPage`,
    payload,
  };
}

// 待核算列表-代驾
export function ORDERPROOFREAD_WAIT_SETTLEMENT_LIST_DJ(payload) {
  return {
    type: `${ORDERPROOFREAD_NAMESPACE}/fetchWaitSettlementDjPage`,
    payload,
  };
}


// 待核算详情
export function ORDERPROOFREAD_WAIT_SETTLEMENT_DETAIL(orderNo) {
  return {
    type: `${ORDERPROOFREAD_NAMESPACE}/fetchWaitSettlementDetail`,
    payload: { orderNo },
  };
}

// 待核算详情 代驾
export function ORDERPROOFREAD_WAIT_SETTLEMENT_DETAIL_DJ(orderNo) {
  return {
    type: `${ORDERPROOFREAD_NAMESPACE}/fetchWaitSettlementDjDetail`,
    payload: { orderNo },
  };
}

// 清空详情
export function ORDERPROOFREAD_WAIT_SETTLEMENT_CLEAR_DETAIL() {
  return {
    type: `${ORDERPROOFREAD_NAMESPACE}/clearWaitSettlementDetail`,
    payload: {},
  };
}

// 待核账/已结算列表
export function ORDERPROOFREAD_RECONCILIATION_LIST(payload) {
  return {
    type: `${ORDERPROOFREAD_NAMESPACE}/fetchReconciliationList`,
    payload,
  };
}

// 批量生成核账单 城际
export function ORDERPROOFREAD_GENERATE_BATC(payload) {
  return {
    type: `${ORDERPROOFREAD_NAMESPACE}/generateBatc`,
    payload,
  };
}

// 批量生成核账单 代驾
export function ORDERPROOFREAD_GENERATE_BATC_DJ(payload) {
  return {
    type: `${ORDERPROOFREAD_NAMESPACE}/generateBatcDj`,
    payload,
  };
}

// 核账单结算
export function ORDERPROOFREAD_RECONCILIATION_CHECK(payload) {
  return {
    type: `${ORDERPROOFREAD_NAMESPACE}/checkReconciliation`,
    payload,
  };
}

// 核账单作废
export function ORDERPROOFREAD_RECONCILIATION_INVALID(payload) {
  return {
    type: `${ORDERPROOFREAD_NAMESPACE}/invalidReconciliation`,
    payload,
  };
}

// 已结算订单明细 城际
export function ORDERPROOFREAD_FETCH_ORDER_DETAIL_PAGE(payload) {
  return {
    type: `${ORDERPROOFREAD_NAMESPACE}/fetchOrderDetailReconciliationPage`,
    payload,
  };
}

// 已结算订单明细 代驾
export function ORDERPROOFREAD_FETCH_ORDER_DETAIL_PAGE_DJ(payload) {
  return {
    type: `${ORDERPROOFREAD_NAMESPACE}/fetchOrderDetailReconciliationPageDj`,
    payload,
  };
}

// 待核算列表城内
export function ORDERPROOFREAD_WAIT_SETTLEMENT_LIST_INSIDE(payload) {
  return {
    type: `${ORDERPROOFREAD_NAMESPACE}/fetchWaitSettlementPageInside`,
    payload,
  };
}

// 批量生成核账单 城内
export function ORDERPROOFREAD_GENERATE_BATCH_INSIDE(payload) {
  return {
    type: `${ORDERPROOFREAD_NAMESPACE}/generateBatchInside`,
    payload,
  };
}

// 已结算订单明细 城内
export function ORDER_DETAIL_RECON_INSIDE(payload) {
  return {
    type: `${ORDERPROOFREAD_NAMESPACE}/orderDetailReconPageInside`,
    payload,
  };
}

// 待核算详情 城内
export function ORDERPROOFREAD_WAIT_SETTLEMENT_DETAIL_INSIDE(orderNo) {
  return {
    type: `${ORDERPROOFREAD_NAMESPACE}/fetchWaitSettlementDetailInside`,
    payload: { orderNo },
  };
}

import { stringify } from 'qs';
import request from '../utils/request'

// 运营位管理列表
export async function list(params) {
  return request(`api/bmkp-backend/wycUserAd/page?${stringify(params)}`)
}

// 新增
export async function save(params) {
  return request('/api/bmkp-backend/wycUserAd/save', {
    method: 'POST',
    body: params,
  });
}

// 修改
export async function update(params) {
  return request('/api/bmkp-backend/wycUserAd/update', {
    method: 'POST',
    body: params,
  });
}

// 详情
export async function detail(params) {
  return request(`/api/bmkp-backend/wycUserAd/detail?${stringify(params)}`, {
    method: 'get'
  });
}

// 开启活动
export async function open(params) {
  return request(`/api/bmkp-backend/wycUserAd/open?${stringify(params)}`, {
    method: 'get'
  });
}

// 关闭活动
export async function close(params) {
  return request(`/api/bmkp-backend/wycUserAd/close?${stringify(params)}`, {
    method: 'get'
  });
}
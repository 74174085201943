import { message } from 'antd';
import { SERVICE_PHONE_NAMESPACE } from '@/actions/servicephone';
import {
  detail,
  submit
} from '@/services/servicephone';

export default {
  namespace: SERVICE_PHONE_NAMESPACE,
  state: {
    detail: {
      status: 0,
      name: '',
      minutes: 10
    }
  },
  effects: {
    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
        // eslint-disable-next-line no-unused-expressions
        callback && callback(response.data)
      }
    },
    *submit({ payload }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('提交成功');
        // router.push('/customize/lineConfig');
      }
    },
  },
  reducers: {
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    }
  },
};

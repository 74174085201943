import { message } from 'antd';
import { SYSTEM_NAMESPACE } from '../actions/system';
import { query, save } from '@/services/system';

export default {
  namespace: SYSTEM_NAMESPACE,
  state: {
    detail: {}
  },
  effects: {
    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(query, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
    *save({ payload, callback }, { call }) {
      const response = yield call(save, payload)
      if (response.success) {
        message.success(response.msg)
        callback()
      } else {
        message.error(response.msg)
      }
    },
    *clearDetail({ payload }, { put }) {
      yield put({
        type: 'removeDetail',
        payload: { payload },
      });
    },
  },
  reducers: {
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    },
  },
};



export const DRIVER_NAMESPACE = 'djdriver';

/**
 * 
 * @param payload 
 * 司机列表
 * @returns 
 */
export function DRIVER_LIST(payload) {
  return {
    type: `${DRIVER_NAMESPACE}/fetchList`,
    payload,
  };
}

export function DRIVER_DETAIL(payload) {
  return {
    type: `${DRIVER_NAMESPACE}/detail`,
    payload,
  };
}

export function DRIVER_DETAIL_CLEARN() {
  return {
    type: `${DRIVER_NAMESPACE}/detailClearn`
  };
}

export function DRIVER_ADD(payload, callback) {
  return {
    type: `${DRIVER_NAMESPACE}/submit`,
    payload,
    callback,
  };
}

export function DRIVER_UPDATE_CLOSE(payload,callback) {
  return {
    type: `${DRIVER_NAMESPACE}/updateClose`,
    payload,
    callback
  };
}

export function DRIVER_UPDATE_OPEN(payload, callback) {
  return {
    type: `${DRIVER_NAMESPACE}/updateOpen`,
    payload,
    callback
  };
}

/**
 * 
 * 待审核司机
 * 
 */
export function DRIVER_LIST_PEDDING(payload, callback?) {
  return {
    type: `${DRIVER_NAMESPACE}/fetchPeddingList`,
    payload,
    callback
  };
}

export function DRIVER_DETAIL_PEDDING(payload, callback?) {
  return {
    type: `${DRIVER_NAMESPACE}/peddingDetail`,
    payload,
    callback
  };
}
export function DRIVER_DETAIL_PEDDING_CLEARN(payload?, callback?) {
  return {
    type: `${DRIVER_NAMESPACE}/peddingDetailClearn`,
    payload,
    callback
  };
}

export function DRIVER_REJECT_PEDDING(payload, callback) {
  return {
    type: `${DRIVER_NAMESPACE}/peddingReject`,
    payload,
    callback
  };
}

export function DRIVER_PASS_PEDDING(payload, callback) {
  return {
    type: `${DRIVER_NAMESPACE}/peddingPass`,
    payload,
    callback
  };
}

/**
 * 
 * 审核记录
 * 
 */
export function DRIVER_LIST_CHECK(payload, callback?) {
  return {
    type: `${DRIVER_NAMESPACE}/fetchCheckList`,
    payload,
    callback
  };
}
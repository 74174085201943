import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/.umi/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/user',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__UserLayout" */ '../../layouts/UserLayout'),
          LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/UserLayout').default,
    routes: [
      {
        path: '/user',
        redirect: '/user/login',
        exact: true,
      },
      {
        path: '/user/login',
        name: 'login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__Login__models__register.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Login/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__Login__Login" */ '../Login/Login'),
              LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                .default,
            })
          : require('../Login/Login').default,
        exact: true,
      },
      {
        path: '/user/register',
        name: 'register',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__Login__models__register.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Login/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__Login__Register" */ '../Login/Register'),
              LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                .default,
            })
          : require('../Login/Register').default,
        exact: true,
      },
      {
        path: '/user/register-result',
        name: 'register.result',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__Login__models__register.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Login/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__Login__RegisterResult" */ '../Login/RegisterResult'),
              LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                .default,
            })
          : require('../Login/RegisterResult').default,
        exact: true,
      },
      {
        component: () =>
          React.createElement(
            require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BasicLayout'),
          LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BasicLayout').default,
    Routes: [require('../Authorized').default],
    routes: [
      {
        path: '/',
        redirect: '/dashboard/workplace',
        exact: true,
      },
      {
        path: '/result',
        routes: [
          {
            path: '/result/success',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Result__Success" */ '../Result/Success'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Result/Success').default,
            exact: true,
          },
          {
            path: '/result/fail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Result__Error" */ '../Result/Error'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Result/Error').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/exception',
        routes: [
          {
            path: '/exception/403',
            name: 'not-permission',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Exception__models__error.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Exception/models/error.js').then(
                      m => {
                        return { namespace: 'error', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__403" */ '../Exception/403'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/403').default,
            exact: true,
          },
          {
            path: '/exception/404',
            name: 'not-find',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Exception__models__error.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Exception/models/error.js').then(
                      m => {
                        return { namespace: 'error', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__404" */ '../Exception/404'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/404').default,
            exact: true,
          },
          {
            path: '/exception/500',
            name: 'server-error',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Exception__models__error.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Exception/models/error.js').then(
                      m => {
                        return { namespace: 'error', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__500" */ '../Exception/500'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/500').default,
            exact: true,
          },
          {
            path: '/exception/trigger',
            name: 'trigger',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Exception__models__error.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Exception/models/error.js').then(
                      m => {
                        return { namespace: 'error', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__TriggerException" */ '../Exception/TriggerException'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/TriggerException').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/account',
        routes: [
          {
            path: '/account/center',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Account__Center__models__list.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Account/Center/models/list.js').then(
                      m => {
                        return { namespace: 'list', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Account__Center__Center" */ '../Account/Center/Center'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Account/Center/Center').default,
            routes: [
              {
                path: '/account/center',
                redirect: '/account/center/articles',
                exact: true,
              },
              {
                path: '/account/center/articles',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Account__Center__models__list.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Account/Center/models/list.js').then(
                          m => {
                            return { namespace: 'list', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "p__Account__Center__Center" */ '../Account/Center/Articles'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Account/Center/Articles').default,
                exact: true,
              },
              {
                path: '/account/center/applications',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Account__Center__models__list.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Account/Center/models/list.js').then(
                          m => {
                            return { namespace: 'list', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "p__Account__Center__Center" */ '../Account/Center/Applications'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Account/Center/Applications').default,
                exact: true,
              },
              {
                path: '/account/center/projects',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Account__Center__models__list.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Account/Center/models/list.js').then(
                          m => {
                            return { namespace: 'list', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "p__Account__Center__Center" */ '../Account/Center/Projects'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Account/Center/Projects').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/account/settings',
            routes: [
              {
                path: '/account/settings',
                redirect: '/account/settings/base',
                exact: true,
              },
              {
                path: '/account/settings/base',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Account__Settings__models__geographic.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Account/Settings/models/geographic.js').then(
                          m => {
                            return { namespace: 'geographic', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Account/Settings/BaseView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Account/Settings/BaseView').default,
                exact: true,
              },
              {
                path: '/account/settings/password',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Account__Settings__models__geographic.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Account/Settings/models/geographic.js').then(
                          m => {
                            return { namespace: 'geographic', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Account/Settings/PasswordView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Account/Settings/PasswordView').default,
                exact: true,
              },
              {
                path: '/account/settings/notification',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Account__Settings__models__geographic.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Account/Settings/models/geographic.js').then(
                          m => {
                            return { namespace: 'geographic', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Account/Settings/NotificationView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Account/Settings/NotificationView').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        name: 'editor',
        icon: 'highlight',
        path: '/editor',
        routes: [
          {
            path: '/editor/flow',
            name: 'flow',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Editor__GGEditor__Flow" */ '../Editor/GGEditor/Flow'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Editor/GGEditor/Flow').default,
            exact: true,
          },
          {
            path: '/editor/mind',
            name: 'mind',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Editor__GGEditor__Mind" */ '../Editor/GGEditor/Mind'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Editor/GGEditor/Mind').default,
            exact: true,
          },
          {
            path: '/editor/koni',
            name: 'koni',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Editor__GGEditor__Koni" */ '../Editor/GGEditor/Koni'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Editor/GGEditor/Koni').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/dashboard',
        routes: [
          {
            path: '/dashboard/intercity',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Dashboard__models__activities.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Dashboard/models/activities.js').then(
                      m => {
                        return { namespace: 'activities', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Dashboard__models__chart.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Dashboard/models/chart.js').then(
                      m => {
                        return { namespace: 'chart', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Dashboard__models__monitor.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Dashboard/models/monitor.js').then(
                      m => {
                        return { namespace: 'monitor', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Dashboard__Intercity__Intercity" */ '../Dashboard/Intercity/Intercity'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Dashboard/Intercity/Intercity').default,
            exact: true,
          },
          {
            path: '/dashboard/analysis',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Dashboard__models__activities.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Dashboard/models/activities.js').then(
                      m => {
                        return { namespace: 'activities', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Dashboard__models__chart.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Dashboard/models/chart.js').then(
                      m => {
                        return { namespace: 'chart', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Dashboard__models__monitor.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Dashboard/models/monitor.js').then(
                      m => {
                        return { namespace: 'monitor', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Dashboard__Analysis" */ '../Dashboard/Analysis'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Dashboard/Analysis').default,
            exact: true,
          },
          {
            path: '/dashboard/monitor',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Dashboard__models__activities.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Dashboard/models/activities.js').then(
                      m => {
                        return { namespace: 'activities', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Dashboard__models__chart.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Dashboard/models/chart.js').then(
                      m => {
                        return { namespace: 'chart', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Dashboard__models__monitor.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Dashboard/models/monitor.js').then(
                      m => {
                        return { namespace: 'monitor', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Dashboard__Monitor" */ '../Dashboard/Monitor'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Dashboard/Monitor').default,
            exact: true,
          },
          {
            path: '/dashboard/workplace',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Dashboard__models__activities.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Dashboard/models/activities.js').then(
                      m => {
                        return { namespace: 'activities', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Dashboard__models__chart.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Dashboard/models/chart.js').then(
                      m => {
                        return { namespace: 'chart', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Dashboard__models__monitor.js' */ '/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/pages/Dashboard/models/monitor.js').then(
                      m => {
                        return { namespace: 'monitor', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Dashboard__Workplace" */ '../Dashboard/Workplace'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Dashboard/Workplace').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/desk',
        routes: [
          {
            path: '/desk/notice',
            routes: [
              {
                path: '/desk/notice',
                redirect: '/desk/notice/list',
                exact: true,
              },
              {
                path: '/desk/notice/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Desk/Notice/Notice'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Desk/Notice/Notice').default,
                exact: true,
              },
              {
                path: '/desk/notice/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Desk/Notice/NoticeAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Desk/Notice/NoticeAdd').default,
                exact: true,
              },
              {
                path: '/desk/notice/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Desk/Notice/NoticeEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Desk/Notice/NoticeEdit').default,
                exact: true,
              },
              {
                path: '/desk/notice/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Desk/Notice/NoticeView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Desk/Notice/NoticeView').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/base',
        routes: [
          {
            path: '/base/region',
            routes: [
              {
                path: '/base/region',
                redirect: '/base/region/detail',
                exact: true,
              },
              {
                path: '/base/region/detail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Base/Region/Region'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Base/Region/Region').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/base/car/brand',
            routes: [
              {
                path: '/base/car/brand',
                redirect: '/base/car/brand/list',
                exact: true,
              },
              {
                path: '/base/car/brand/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Asset/SystemCarBrand/CarBrand'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Asset/SystemCarBrand/CarBrand').default,
                exact: true,
              },
              {
                path: '/base/car/brand/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Asset/SystemCarBrand/CarBrandAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Asset/SystemCarBrand/CarBrandAdd').default,
                exact: true,
              },
              {
                path: '/base/car/brand/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Asset/SystemCarBrand/CarBrandEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Asset/SystemCarBrand/CarBrandEdit').default,
                exact: true,
              },
              {
                path: '/base/car/brand/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Asset/SystemCarBrand/CarBrandView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Asset/SystemCarBrand/CarBrandView').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/base/car/model',
            routes: [
              {
                path: '/base/car/model',
                redirect: '/base/car/model/list',
                exact: true,
              },
              {
                path: '/base/car/model/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Asset/SystemCarModel/CarModel'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Asset/SystemCarModel/CarModel').default,
                exact: true,
              },
              {
                path: '/base/car/model/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Asset/SystemCarModel/CarModelAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Asset/SystemCarModel/CarModelAdd').default,
                exact: true,
              },
              {
                path: '/base/car/model/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Asset/SystemCarModel/CarModelEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Asset/SystemCarModel/CarModelEdit').default,
                exact: true,
              },
              {
                path: '/base/car/model/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Asset/SystemCarModel/CarModelView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Asset/SystemCarModel/CarModelView').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/base/car/series',
            routes: [
              {
                path: '/base/car/series',
                redirect: '/base/car/series/list',
                exact: true,
              },
              {
                path: '/base/car/series/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Asset/SystemCarSeries/CarSeries'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Asset/SystemCarSeries/CarSeries').default,
                exact: true,
              },
              {
                path: '/base/car/series/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Asset/SystemCarSeries/CarSeriesAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Asset/SystemCarSeries/CarSeriesAdd').default,
                exact: true,
              },
              {
                path: '/base/car/series/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Asset/SystemCarSeries/CarSeriesEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Asset/SystemCarSeries/CarSeriesEdit').default,
                exact: true,
              },
              {
                path: '/base/car/series/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Asset/SystemCarSeries/CarSeriesView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Asset/SystemCarSeries/CarSeriesView').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/base/jiguangConfig',
            routes: [
              {
                path: '/base/jiguangConfig',
                redirect: '/base/jiguangConfig/list',
                exact: true,
              },
              {
                path: '/base/jiguangConfig/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/JiguangConfig/JiguangConfig'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/JiguangConfig/JiguangConfig').default,
                exact: true,
              },
              {
                path: '/base/jiguangConfig/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/JiguangConfig/JiguangConfigAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/JiguangConfig/JiguangConfigAdd').default,
                exact: true,
              },
              {
                path: '/base/jiguangConfig/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/JiguangConfig/JiguangConfigEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/JiguangConfig/JiguangConfigEdit')
                      .default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/authority',
        routes: [
          {
            path: '/authority/role',
            routes: [
              {
                path: '/authority/role',
                redirect: '/authority/role/list',
                exact: true,
              },
              {
                path: '/authority/role/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Authority/Role/Role'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Authority/Role/Role').default,
                exact: true,
              },
              {
                path: '/authority/role/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Authority/Role/RoleAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Authority/Role/RoleAdd').default,
                exact: true,
              },
              {
                path: '/authority/role/add/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Authority/Role/RoleAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Authority/Role/RoleAdd').default,
                exact: true,
              },
              {
                path: '/authority/role/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Authority/Role/RoleEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Authority/Role/RoleEdit').default,
                exact: true,
              },
              {
                path: '/authority/role/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Authority/Role/RoleView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Authority/Role/RoleView').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/authority/datascope',
            routes: [
              {
                path: '/authority/datascope',
                redirect: '/authority/datascope/list',
                exact: true,
              },
              {
                path: '/authority/datascope/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Authority/DataScope/DataScope'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Authority/DataScope/DataScope').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/authority/apiscope',
            routes: [
              {
                path: '/authority/apiscope',
                redirect: '/authority/apiscope/list',
                exact: true,
              },
              {
                path: '/authority/apiscope/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Authority/ApiScope/ApiScope'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Authority/ApiScope/ApiScope').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/system',
        routes: [
          {
            path: '/system/user',
            routes: [
              {
                path: '/system/user',
                redirect: '/system/user/list',
                exact: true,
              },
              {
                path: '/system/user/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/User/User'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/User/User').default,
                exact: true,
              },
              {
                path: '/system/user/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/User/UserAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/User/UserAdd').default,
                exact: true,
              },
              {
                path: '/system/user/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/User/UserEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/User/UserEdit').default,
                exact: true,
              },
              {
                path: '/system/user/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/User/UserView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/User/UserView').default,
                exact: true,
              },
              {
                path: '/system/user/platform/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/User/UserPlatform'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/User/UserPlatform').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/system/dict',
            routes: [
              {
                path: '/system/dict',
                redirect: '/system/dict/list',
                exact: true,
              },
              {
                path: '/system/dict/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Dict/Dict'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Dict/Dict').default,
                exact: true,
              },
              {
                path: '/system/dict/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Dict/DictAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Dict/DictAdd').default,
                exact: true,
              },
              {
                path: '/system/dict/add/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Dict/DictAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Dict/DictAdd').default,
                exact: true,
              },
              {
                path: '/system/dict/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Dict/DictEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Dict/DictEdit').default,
                exact: true,
              },
              {
                path: '/system/dict/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Dict/DictView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Dict/DictView').default,
                exact: true,
              },
              {
                path: '/system/dict/sub/:parentId',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Dict/DictSub'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Dict/DictSub').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/system/dictbiz',
            routes: [
              {
                path: '/system/dictbiz',
                redirect: '/system/dictbiz/list',
                exact: true,
              },
              {
                path: '/system/dictbiz/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/DictBiz/DictBiz'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/DictBiz/DictBiz').default,
                exact: true,
              },
              {
                path: '/system/dictbiz/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/DictBiz/DictBizAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/DictBiz/DictBizAdd').default,
                exact: true,
              },
              {
                path: '/system/dictbiz/add/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/DictBiz/DictBizAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/DictBiz/DictBizAdd').default,
                exact: true,
              },
              {
                path: '/system/dictbiz/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/DictBiz/DictBizEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/DictBiz/DictBizEdit').default,
                exact: true,
              },
              {
                path: '/system/dictbiz/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/DictBiz/DictBizView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/DictBiz/DictBizView').default,
                exact: true,
              },
              {
                path: '/system/dictbiz/sub/:parentId',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/DictBiz/DictBizSub'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/DictBiz/DictBizSub').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/system/dept',
            routes: [
              {
                path: '/system/dept',
                redirect: '/system/dept/list',
                exact: true,
              },
              {
                path: '/system/dept/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Dept/Dept'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Dept/Dept').default,
                exact: true,
              },
              {
                path: '/system/dept/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Dept/DeptAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Dept/DeptAdd').default,
                exact: true,
              },
              {
                path: '/system/dept/add/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Dept/DeptAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Dept/DeptAdd').default,
                exact: true,
              },
              {
                path: '/system/dept/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Dept/DeptEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Dept/DeptEdit').default,
                exact: true,
              },
              {
                path: '/system/dept/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Dept/DeptView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Dept/DeptView').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/system/post',
            routes: [
              {
                path: '/system/post',
                redirect: '/system/post/list',
                exact: true,
              },
              {
                path: '/system/post/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Post/Post'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Post/Post').default,
                exact: true,
              },
              {
                path: '/system/post/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Post/PostAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Post/PostAdd').default,
                exact: true,
              },
              {
                path: '/system/post/add/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Post/PostAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Post/PostAdd').default,
                exact: true,
              },
              {
                path: '/system/post/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Post/PostEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Post/PostEdit').default,
                exact: true,
              },
              {
                path: '/system/post/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Post/PostView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Post/PostView').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/system/menu',
            routes: [
              {
                path: '/system/menu',
                redirect: '/system/menu/list',
                exact: true,
              },
              {
                path: '/system/menu/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Menu/Menu'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Menu/Menu').default,
                exact: true,
              },
              {
                path: '/system/menu/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Menu/MenuAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Menu/MenuAdd').default,
                exact: true,
              },
              {
                path: '/system/menu/add/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Menu/MenuAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Menu/MenuAdd').default,
                exact: true,
              },
              {
                path: '/system/menu/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Menu/MenuEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Menu/MenuEdit').default,
                exact: true,
              },
              {
                path: '/system/menu/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Menu/MenuView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Menu/MenuView').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/system/topmenu',
            routes: [
              {
                path: '/system/topmenu',
                redirect: '/system/topmenu/list',
                exact: true,
              },
              {
                path: '/system/topmenu/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/TopMenu/TopMenu'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/TopMenu/TopMenu').default,
                exact: true,
              },
              {
                path: '/system/topmenu/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/TopMenu/TopMenuAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/TopMenu/TopMenuAdd').default,
                exact: true,
              },
              {
                path: '/system/topmenu/add/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/TopMenu/TopMenuAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/TopMenu/TopMenuAdd').default,
                exact: true,
              },
              {
                path: '/system/topmenu/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/TopMenu/TopMenuEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/TopMenu/TopMenuEdit').default,
                exact: true,
              },
              {
                path: '/system/topmenu/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/TopMenu/TopMenuView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/TopMenu/TopMenuView').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/system/param',
            routes: [
              {
                path: '/system/param',
                redirect: '/system/param/list',
                exact: true,
              },
              {
                path: '/system/param/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Param/Param'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Param/Param').default,
                exact: true,
              },
              {
                path: '/system/param/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Param/ParamAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Param/ParamAdd').default,
                exact: true,
              },
              {
                path: '/system/param/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Param/ParamEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Param/ParamEdit').default,
                exact: true,
              },
              {
                path: '/system/param/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Param/ParamView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Param/ParamView').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/system/tenant',
            routes: [
              {
                path: '/system/tenant',
                redirect: '/system/tenant/list',
                exact: true,
              },
              {
                path: '/system/tenant/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Tenant/Tenant'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Tenant/Tenant').default,
                exact: true,
              },
              {
                path: '/system/tenant/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Tenant/TenantAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Tenant/TenantAdd').default,
                exact: true,
              },
              {
                path: '/system/tenant/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Tenant/TenantEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Tenant/TenantEdit').default,
                exact: true,
              },
              {
                path: '/system/tenant/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Tenant/TenantView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Tenant/TenantView').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/system/client',
            routes: [
              {
                path: '/system/client',
                redirect: '/system/client/list',
                exact: true,
              },
              {
                path: '/system/client/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Client/Client'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Client/Client').default,
                exact: true,
              },
              {
                path: '/system/client/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Client/ClientAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Client/ClientAdd').default,
                exact: true,
              },
              {
                path: '/system/client/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Client/ClientEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Client/ClientEdit').default,
                exact: true,
              },
              {
                path: '/system/client/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Client/ClientView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Client/ClientView').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/resource',
        routes: [
          {
            path: '/resource/oss',
            routes: [
              {
                path: '/resource/oss',
                redirect: '/resource/oss/list',
                exact: true,
              },
              {
                path: '/resource/oss/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Resource/Oss/Oss'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Resource/Oss/Oss').default,
                exact: true,
              },
              {
                path: '/resource/oss/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Resource/Oss/OssAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Resource/Oss/OssAdd').default,
                exact: true,
              },
              {
                path: '/resource/oss/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Resource/Oss/OssEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Resource/Oss/OssEdit').default,
                exact: true,
              },
              {
                path: '/resource/oss/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Resource/Oss/OssView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Resource/Oss/OssView').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/resource/sms',
            routes: [
              {
                path: '/resource/sms',
                redirect: '/resource/sms/list',
                exact: true,
              },
              {
                path: '/resource/sms/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Resource/Sms/Sms'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Resource/Sms/Sms').default,
                exact: true,
              },
              {
                path: '/resource/sms/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Resource/Sms/SmsAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Resource/Sms/SmsAdd').default,
                exact: true,
              },
              {
                path: '/resource/sms/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Resource/Sms/SmsEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Resource/Sms/SmsEdit').default,
                exact: true,
              },
              {
                path: '/resource/sms/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Resource/Sms/SmsView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Resource/Sms/SmsView').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/resource/attach',
            routes: [
              {
                path: '/resource/attach',
                redirect: '/resource/attach/list',
                exact: true,
              },
              {
                path: '/resource/attach/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Resource/Attach/Attach'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Resource/Attach/Attach').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/resource/passenger',
            routes: [
              {
                path: '/resource/passenger',
                redirect: '/resource/passenger/list',
                exact: true,
              },
              {
                path: '/resource/passenger/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Passenger/PassengerList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Passenger/PassengerList').default,
                exact: true,
              },
              {
                path: '/resource/passenger/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Passenger/PassengerDetail'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Passenger/PassengerDetail').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/intercity_config',
        routes: [
          {
            path: '/intercity_config/line_config',
            routes: [
              {
                path: '/intercity_config/line_config',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../LineConfig/LineConfig'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../LineConfig/LineConfig').default,
                exact: true,
              },
              {
                path: '/intercity_config/line_config/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../LineConfig/LineConfigAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../LineConfig/LineConfigAdd').default,
                exact: true,
              },
              {
                path: '/intercity_config/line_config/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../LineConfig/LineConfigEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../LineConfig/LineConfigEdit').default,
                exact: true,
              },
              {
                path: '/intercity_config/line_config/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../LineConfig/LineConfigView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../LineConfig/LineConfigView').default,
                exact: true,
              },
              {
                path: '/intercity_config/line_config/operation_config/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../LineConfig/OperationConfig'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../LineConfig/OperationConfig').default,
                exact: true,
              },
              {
                path: '/intercity_config/line_config/service/:id/:cityCode',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../LineConfig/ServiceConfig'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../LineConfig/ServiceConfig').default,
                exact: true,
              },
              {
                path: '/intercity_config/line_config/service_scope/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../ServiceScope/ScopeConfig'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../ServiceScope/ScopeConfig').default,
                exact: true,
              },
              {
                path: '/intercity_config/line_config/price_rule/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../LinePriceRule/LinePriceRule'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../LinePriceRule/LinePriceRule').default,
                exact: true,
              },
              {
                path: '/intercity_config/line_config/price_rule_special/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../LinePriceRule/LinePriceRuleSpecial'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../LinePriceRule/LinePriceRuleSpecial').default,
                exact: true,
              },
              {
                path:
                  '/intercity_config/line_config/special_line_fence_match/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../LineFenceMatchSpecial/LineFenceMatchSpecial'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../LineFenceMatchSpecial/LineFenceMatchSpecial')
                      .default,
                exact: true,
              },
              {
                path: '/intercity_config/line_config/schedule/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Schedule/Schedule'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Schedule/Schedule').default,
                exact: true,
              },
              {
                path: '/intercity_config/line_config/cancel_rule/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CancelRule/CancelRule'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CancelRule/CancelRule').default,
                exact: true,
              },
              {
                path: '/intercity_config/line_config/dispatch_rule/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../LineDispatchRule/LineDispatchRule'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../LineDispatchRule/LineDispatchRule').default,
                exact: true,
              },
              {
                path: '/intercity_config/line_config/grabs_rule/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../LineGrabsRule/LineGrabsRule'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../LineGrabsRule/LineGrabsRule').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/intercity_config/vehicle_type',
            routes: [
              {
                path: '/intercity_config/vehicle_type',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../VehicleType/VehicleType'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../VehicleType/VehicleType').default,
                exact: true,
              },
              {
                path: '/intercity_config/vehicle_type/add/:businessType',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../VehicleType/VehicleTypeAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../VehicleType/VehicleTypeAdd').default,
                exact: true,
              },
              {
                path: '/intercity_config/vehicle_type/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../VehicleType/VehicleTypeEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../VehicleType/VehicleTypeEdit').default,
                exact: true,
              },
              {
                path: '/intercity_config/vehicle_type/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../VehicleType/VehicleTypeView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../VehicleType/VehicleTypeView').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/intercity_config/common',
            routes: [
              {
                path: '/intercity_config/common',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../IntercityCommonConfig/IntercityCommonConfig'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../IntercityCommonConfig/IntercityCommonConfig')
                      .default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/intercity_config/line_common_config',
            routes: [
              {
                path: '/intercity_config/line_common_config',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../LineCommon/LineCommonConfig'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../LineCommon/LineCommonConfig').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/service_phone',
        routes: [
          {
            path: '/service_phone',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__ServicePhone__ServicePhone" */ '../ServicePhone/ServicePhone'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../ServicePhone/ServicePhone').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/car',
        routes: [
          {
            path: '/car',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__AssetCar__AssetCar" */ '../Asset/AssetCar/AssetCar'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/AssetCar/AssetCar').default,
            exact: true,
          },
          {
            path: '/car/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__AssetCar__AssetCarAdd" */ '../Asset/AssetCar/AssetCarAdd'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/AssetCar/AssetCarAdd').default,
            exact: true,
          },
          {
            path: '/car/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__AssetCar__AssetCarEdit" */ '../Asset/AssetCar/AssetCarEdit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/AssetCar/AssetCarEdit').default,
            exact: true,
          },
          {
            path: '/car/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__AssetCar__AssetCarView" */ '../Asset/AssetCar/AssetCarView'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/AssetCar/AssetCarView').default,
            exact: true,
          },
          {
            path: '/car/detail/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__AssetCar__AssetCarDetail" */ '../Asset/AssetCar/AssetCarDetail'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/AssetCar/AssetCarDetail').default,
            exact: true,
          },
          {
            path: '/car/import/history',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__AssetCar__History" */ '../Asset/AssetCar/History'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/AssetCar/History').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/carBrand',
        routes: [
          {
            path: '/carBrand/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__CustomCarBrandAndSeries__List" */ '../Asset/CustomCarBrandAndSeries/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/CustomCarBrandAndSeries/List').default,
            exact: true,
          },
          {
            path: '/carBrand/detail/:name/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__CustomCarBrandAndSeries__Detail" */ '../Asset/CustomCarBrandAndSeries/Detail'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/CustomCarBrandAndSeries/Detail').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/dispatch',
        routes: [
          {
            path: '/dispatch',
            redirect: '/dispatch/dispatch/dispatcher',
            exact: true,
          },
          {
            path: '/dispatch/dispatcher',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Dispatch__Dispatcher" */ '../Dispatch/Dispatcher'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Dispatch/Dispatcher').default,
            exact: true,
          },
          {
            path: '/dispatch/dispatcher/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Dispatch__DispatcherAdd" */ '../Dispatch/DispatcherAdd'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Dispatch/DispatcherAdd').default,
            exact: true,
          },
          {
            path: '/dispatch/dispatcher_line',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Dispatch__DispatcherAndLine" */ '../Dispatch/DispatcherAndLine'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Dispatch/DispatcherAndLine').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/driver',
        routes: [
          {
            path: '/driver/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__DriverIntercity__Add" */ '../Asset/DriverIntercity/Add'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/DriverIntercity/Add').default,
            exact: true,
          },
          {
            path: '/driver/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__DriverIntercity__Edit" */ '../Asset/DriverIntercity/Edit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/DriverIntercity/Edit').default,
            exact: true,
          },
          {
            path: '/driver/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__DriverIntercity__BaseView" */ '../Asset/DriverIntercity/BaseView'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/DriverIntercity/BaseView').default,
            exact: true,
          },
          {
            path: '/driver/moreDetail/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__DriverIntercity__MoreDetailView" */ '../Asset/DriverIntercity/MoreDetailView'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/DriverIntercity/MoreDetailView').default,
            exact: true,
          },
          {
            path: '/driver/binding',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__DriverBinding__DriverBinding" */ '../Asset/DriverBinding/DriverBinding'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/DriverBinding/DriverBinding').default,
            exact: true,
          },
          {
            path: '/driver/binding/add/:driverId/:cityCode/:cityName',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__DriverBinding__DriverBindingAdd" */ '../Asset/DriverBinding/DriverBindingAdd'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/DriverBinding/DriverBindingAdd').default,
            exact: true,
          },
          {
            path: '/driver/binding/history',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__DriverBinding__History" */ '../Asset/DriverBinding/History'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/DriverBinding/History').default,
            exact: true,
          },
          {
            path: '/driver/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__DriverInside__List" */ '../Asset/DriverInside/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/DriverInside/List').default,
            exact: true,
          },
          {
            path: '/driver/apply',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__DriverInside__Apply" */ '../Asset/DriverInside/Apply'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/DriverInside/Apply').default,
            exact: true,
          },
          {
            path: '/driver/apply/audit/:id/:operationType',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__DriverInside__Audit" */ '../Asset/DriverInside/Audit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/DriverInside/Audit').default,
            exact: true,
          },
          {
            path: '/driver/supplement',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__DriverInside__Supplement" */ '../Asset/DriverInside/Supplement'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/DriverInside/Supplement').default,
            exact: true,
          },
          {
            path: '/driver/supplement/audit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__DriverInside__SupplementAudit" */ '../Asset/DriverInside/SupplementAudit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/DriverInside/SupplementAudit').default,
            exact: true,
          },
          {
            path: '/driver/batchImportHistory',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__DriverInside__BatchImportHistory" */ '../Asset/DriverInside/BatchImportHistory'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/DriverInside/BatchImportHistory').default,
            exact: true,
          },
          {
            path: '/driver/recruitConfig',
            routes: [
              {
                path: '/driver/recruitConfig',
                redirect: '/driver/recruitConfig/list',
                exact: true,
              },
              {
                path: '/driver/recruitConfig/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Asset/DriverRecruitConfig/DriverRecruitConfigList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Asset/DriverRecruitConfig/DriverRecruitConfigList')
                      .default,
                exact: true,
              },
              {
                path: '/driver/recruitConfig/add/:data',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Asset/DriverRecruitConfig/DriverRecruitConfigAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Asset/DriverRecruitConfig/DriverRecruitConfigAdd')
                      .default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/driver/clue/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Asset__DriveClue__List" */ '../Asset/DriveClue/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Asset/DriveClue/List').default,
            exact: true,
          },
          {
            path: '/driver/certificates',
            routes: [
              {
                path: '/driver/certificates/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Asset/DriverCertificates/List'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Asset/DriverCertificates/List').default,
                exact: true,
              },
              {
                path: '/driver/certificates/details/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Asset/DriverCertificates/Details'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Asset/DriverCertificates/Details').default,
                exact: true,
              },
              {
                path: '/driver/certificates/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Asset/DriverCertificates/Edit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Asset/DriverCertificates/Edit').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/order',
        routes: [
          {
            path: '/order/travel',
            routes: [
              {
                path: '/order/travel',
                redirect: '/order/travel/list',
                exact: true,
              },
              {
                path: '/order/travel/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Order/OrderTravel/OrderTravelList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Order/OrderTravel/OrderTravelList').default,
                exact: true,
              },
              {
                path: '/order/travel/detail/:tripNo',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Order/OrderTravel/OrderTravelDetail'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Order/OrderTravel/OrderTravelDetail').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/order/record',
            routes: [
              {
                path: '/order/record',
                redirect: '/order/record/list',
                exact: true,
              },
              {
                path: '/order/record/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Order/Intercity/OrderList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Order/Intercity/OrderList').default,
                exact: true,
              },
              {
                path: '/order/record/detail/:orderNo',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Order/Intercity/OrderDetail'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Order/Intercity/OrderDetail').default,
                exact: true,
              },
              {
                path: '/order/record/create',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Order/Intercity/CreateOrder'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Order/Intercity/CreateOrder').default,
                exact: true,
              },
              {
                path: '/order/record/third',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Activity/Bill/InterOrderList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Activity/Bill/InterOrderList').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/order/inside',
            routes: [
              {
                path: '/order/inside',
                redirect: '/order/inside/list',
                exact: true,
              },
              {
                path: '/order/inside/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Order/Inside/OrderList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Order/Inside/OrderList').default,
                exact: true,
              },
              {
                path: '/order/inside/detail/:orderNo',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Order/Inside/OrderDetail'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Order/Inside/OrderDetail').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/order/dispatch',
            routes: [
              {
                path: '/order/dispatch',
                redirect: '/order/dispatch/list',
                exact: true,
              },
              {
                path: '/order/dispatch/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Order/OrderDispatch/OrderDispatchList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Order/OrderDispatch/OrderDispatchList').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/order/dispatchRule',
            routes: [
              {
                path: '/order/dispatchRule',
                redirect: '/order/dispatchRule/list',
                exact: true,
              },
              {
                path: '/order/dispatchRule/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Order/DispatchRule/DispatchRuleList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Order/DispatchRule/DispatchRuleList').default,
                exact: true,
              },
              {
                path: '/order/dispatchRule/detail/:ruleId',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Order/DispatchRule/DispatchRuleDetail'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Order/DispatchRule/DispatchRuleDetail').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/capacity',
        routes: [
          {
            path: '/capacity/capacityAgent',
            routes: [
              {
                path: '/capacity/capacityAgent',
                redirect: '/capacity/capacityAgent/list',
                exact: true,
              },
              {
                path: '/capacity/capacityAgent/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Capacity/CapacityAgent/CapacityAgent'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Capacity/CapacityAgent/CapacityAgent').default,
                exact: true,
              },
              {
                path: '/capacity/capacityAgent/list/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Capacity/CapacityAgent/CapacityAgentAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Capacity/CapacityAgent/CapacityAgentAdd')
                      .default,
                exact: true,
              },
              {
                path: '/capacity/capacityAgent/list/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Capacity/CapacityAgent/CapacityAgentEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Capacity/CapacityAgent/CapacityAgentEdit')
                      .default,
                exact: true,
              },
              {
                path: '/capacity/capacityAgent/list/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Capacity/CapacityAgent/CapacityAgentView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Capacity/CapacityAgent/CapacityAgentView')
                      .default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/capacity/capacitySupplier',
            routes: [
              {
                path: '/capacity/capacitySupplier',
                redirect: '/capacity/capacitySupplier/list',
                exact: true,
              },
              {
                path: '/capacity/capacitySupplier/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Capacity/CapacitySupplier/CapacitySupplier'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Capacity/CapacitySupplier/CapacitySupplier')
                      .default,
                exact: true,
              },
              {
                path: '/capacity/capacitySupplier/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Capacity/CapacitySupplier/CapacitySupplierAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Capacity/CapacitySupplier/CapacitySupplierAdd')
                      .default,
                exact: true,
              },
              {
                path: '/capacity/capacitySupplier/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Capacity/CapacitySupplier/CapacitySupplierEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Capacity/CapacitySupplier/CapacitySupplierEdit')
                      .default,
                exact: true,
              },
              {
                path: '/capacity/capacitySupplier/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Capacity/CapacitySupplier/CapacitySupplierView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Capacity/CapacitySupplier/CapacitySupplierView')
                      .default,
                exact: true,
              },
              {
                path: '/capacity/capacitySupplier/result/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Capacity/CapacitySupplier/CapacitySupplierResult'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Capacity/CapacitySupplier/CapacitySupplierResult')
                      .default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/capacity/platform',
            routes: [
              {
                path: '/capacity/platform',
                redirect: '/capacity/platform/list',
                exact: true,
              },
              {
                path: '/capacity/platform/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Capacity/Platform/PlatformList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Capacity/Platform/PlatformList').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/capacity/operateConfig',
            routes: [
              {
                path: '/capacity/operateConfig/list/:bizTypes/:supplierId',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Capacity/CapacityOperateConfig/OperateConfigList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Capacity/CapacityOperateConfig/OperateConfigList')
                      .default,
                exact: true,
              },
              {
                path: '/capacity/operateConfig/view/:bizTypes',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Capacity/CapacityOperateConfig/OperateConfigView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Capacity/CapacityOperateConfig/OperateConfigView')
                      .default,
                exact: true,
              },
              {
                path: '/capacity/operateConfig/inside/add/:supplierId',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Capacity/CapacityOperateConfig/Inside/OperateConfigAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Capacity/CapacityOperateConfig/Inside/OperateConfigAdd')
                      .default,
                exact: true,
              },
              {
                path: '/capacity/operateConfig/intercity/add/:supplierId',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Capacity/CapacityOperateConfig/Intercity/OperateConfigAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Capacity/CapacityOperateConfig/Intercity/OperateConfigAdd')
                      .default,
                exact: true,
              },
              {
                path: '/capacity/operateConfig/dj/add/:supplierId',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Capacity/CapacityOperateConfig/DJ/OperateConfig'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Capacity/CapacityOperateConfig/DJ/OperateConfig')
                      .default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/capacity/settlement',
            routes: [
              {
                path: '/capacity/settlement',
                redirect: '/capacity/settlement/list',
                exact: true,
              },
              {
                path: '/capacity/settlement/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Capacity/CapacityCommissionConfig/CapacityCommissionConfig'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Capacity/CapacityCommissionConfig/CapacityCommissionConfig')
                      .default,
                exact: true,
              },
              {
                path: '/capacity/settlement/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Capacity/CapacityCommissionConfig/CapacityCommissionConfigEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Capacity/CapacityCommissionConfig/CapacityCommissionConfigEdit')
                      .default,
                exact: true,
              },
              {
                path: '/capacity/settlement/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Capacity/CapacityCommissionConfig/CapacityCommissionConfigAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Capacity/CapacityCommissionConfig/CapacityCommissionConfigAdd')
                      .default,
                exact: true,
              },
              {
                path: '/capacity/settlement/cancel',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Capacity/CapacityCommissionConfig/PassengerCancel'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Capacity/CapacityCommissionConfig/PassengerCancel')
                      .default,
                exact: true,
              },
              {
                path: '/capacity/settlement/connectSupplier',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Capacity/CapacityCommissionConfig/ConnectCapacitySupplier'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Capacity/CapacityCommissionConfig/ConnectCapacitySupplier')
                      .default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/finance',
        routes: [
          {
            path: '/finance/intercity-record',
            routes: [
              {
                path: '/finance/intercity-record/travel',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/IntercityOrderRecord/TravelRecordList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/IntercityOrderRecord/TravelRecordList')
                      .default,
                exact: true,
              },
              {
                path: '/finance/intercity-record/transaction',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/IntercityOrderRecord/OrderTransactionList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/IntercityOrderRecord/OrderTransactionList')
                      .default,
                exact: true,
              },
              {
                path: '/finance/intercity-record/refund-apply',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/IntercityOrderRecord/OrderRefundApplyList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/IntercityOrderRecord/OrderRefundApplyList')
                      .default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/finance/inside-record',
            routes: [
              {
                path: '/finance/inside-record/trade',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/InsideOrderRecord/OrderTransactionList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/InsideOrderRecord/OrderTransactionList')
                      .default,
                exact: true,
              },
              {
                path: '/finance/inside-record/update-fee',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/InsideOrderRecord/OrderUpdateFeeList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/InsideOrderRecord/OrderUpdateFeeList')
                      .default,
                exact: true,
              },
              {
                path: '/finance/inside-record/refund-apply',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/InsideOrderRecord/OrderRefundApplyList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/InsideOrderRecord/OrderRefundApplyList')
                      .default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/finance/withdrawal',
            routes: [
              {
                path: '/financial/withdrawal',
                redirect: '/finance/withdrawal/list',
                exact: true,
              },
              {
                path: '/finance/withdrawal/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/Withdrawal/WithdrawalList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/Withdrawal/WithdrawalList').default,
                exact: true,
              },
              {
                path: '/finance/withdrawal/config',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/Withdrawal/WithdrawalConfig'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/Withdrawal/WithdrawalConfig').default,
                exact: true,
              },
              {
                path: '/finance/withdrawal/config_inside',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/Withdrawal/Inside/ConfigList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/Withdrawal/Inside/ConfigList').default,
                exact: true,
              },
              {
                path: '/finance/withdrawal/config_inside/add/:configType',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/Withdrawal/Inside/ConfigAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/Withdrawal/Inside/ConfigAdd').default,
                exact: true,
              },
              {
                path: '/finance/withdrawal/config_inside/edit/:id/:configType',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/Withdrawal/Inside/ConfigEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/Withdrawal/Inside/ConfigEdit').default,
                exact: true,
              },
              {
                path: '/finance/withdrawal/list_inside',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/Withdrawal/Inside/WithdrawalList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/Withdrawal/Inside/WithdrawalList')
                      .default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/finance/driver',
            routes: [
              {
                path: '/finance/driver',
                redirect: '/finance/driver/balance',
                exact: true,
              },
              {
                path: '/finance/driver/balance',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/Driver/DriverBalanceList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/Driver/DriverBalanceList').default,
                exact: true,
              },
              {
                path: '/finance/driver/income/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/Driver/DriverIncomeList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/Driver/DriverIncomeList').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/finance/proofread',
            routes: [
              {
                path: '/finance/proofread',
                redirect: '/finance/proofread/list',
                exact: true,
              },
              {
                path: '/finance/proofread/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/OrderProofread/OrderProofreadList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/OrderProofread/OrderProofreadList')
                      .default,
                exact: true,
              },
              {
                path: '/finance/proofread/list_inside',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/OrderProofreadInside/OrderProofreadList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/OrderProofreadInside/OrderProofreadList')
                      .default,
                exact: true,
              },
              {
                path: '/finance/proofread/insidecity/driver/awardBill',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Activity/Insidecity/Driver/AwardBill'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Activity/Insidecity/Driver/AwardBill').default,
                exact: true,
              },
              {
                path: '/finance/proofread/intercity/passenger/awardBill',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Activity/Intercity/Passenger/AwardBill'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Activity/Intercity/Passenger/AwardBill')
                      .default,
                exact: true,
              },
              {
                path:
                  '/finance/proofread/insidecity/marketing/InvitePassengers',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Activity/Insidecity/InvitePassengerBill/InvitePassengerBill'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Activity/Insidecity/InvitePassengerBill/InvitePassengerBill')
                      .default,
                exact: true,
              },
              {
                path: '/finance/proofread/insidecity/marketing/InviteDriver',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Activity/Insidecity/InviteDriverBill/InviteDriverBill'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Activity/Insidecity/InviteDriverBill/InviteDriverBill')
                      .default,
                exact: true,
              },
              {
                path: '/finance/proofread/driver/freeCommission/bill',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Activity/Bill/DriverFreeCommissionBill'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Activity/Bill/DriverFreeCommissionBill')
                      .default,
                exact: true,
              },
              {
                path: '/finance/proofread/driver/driverInvite/bill',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Activity/Bill/DriverInviteBill'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Activity/Bill/DriverInviteBill').default,
                exact: true,
              },
              {
                path: '/finance/proofread/passenger/passengerInvite/bill',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Activity/Bill/PassengerInviteBill'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Activity/Bill/PassengerInviteBill').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/finance/invoice',
            routes: [
              {
                path: '/finance/invoice',
                redirect: '/finance/invoice/list',
                exact: true,
              },
              {
                path: '/finance/invoice/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/Invoice/InvoiceList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/Invoice/InvoiceList').default,
                exact: true,
              },
              {
                path: '/finance/invoice/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/Invoice/InvoiceEdit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/Invoice/InvoiceEdit').default,
                exact: true,
              },
              {
                path: '/finance/invoice/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/Invoice/InvoiceDetail'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/Invoice/InvoiceDetail').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/finance/after-sales',
            routes: [
              {
                path: '/finance/after-sales',
                redirect: '/finance/after-sales/list',
                exact: true,
              },
              {
                path: '/finance/after-sales/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../AfterSales/AfterSalesList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../AfterSales/AfterSalesList').default,
                exact: true,
              },
              {
                path: '/finance/after-sales/insideList',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../AfterSales/AfterSalesListInside'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../AfterSales/AfterSalesListInside').default,
                exact: true,
              },
              {
                path: '/finance/after-sales/intercity-view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../AfterSales/Intercity/PassengerApply'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../AfterSales/Intercity/PassengerApply').default,
                exact: true,
              },
              {
                path:
                  '/finance/after-sales/intercity-customservice/:id/:orderNo/:applyId',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../AfterSales/Intercity/CustomServiceApply'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../AfterSales/Intercity/CustomServiceApply')
                      .default,
                exact: true,
              },
              {
                path:
                  '/finance/after-sales/inside-customservice/:id/:orderNo/:applyId',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../AfterSales/Inside/CustomServiceApply'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../AfterSales/Inside/CustomServiceApply').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/finance/statistical',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Finance__Statistical__StatisticalView" */ '../Finance/Statistical/StatisticalView'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Finance/Statistical/StatisticalView').default,
            exact: true,
          },
          {
            path: '/finance/advance/config',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Finance__Advance__Config" */ '../Finance/Advance/Config'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Finance/Advance/Config').default,
            exact: true,
          },
          {
            path: '/finance/billing',
            routes: [
              {
                path: '/finance/billing',
                redirect: '/finance/billing/list',
                exact: true,
              },
              {
                path: '/finance/billing/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/BillingConfig/BillingRuleList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/BillingConfig/BillingRuleList').default,
                exact: true,
              },
              {
                path:
                  '/finance/billing/config/:vehicleTypeId/:cityCode/:businessType/:businessServiceType',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/BillingConfig/BillingRuleConfig'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/BillingConfig/BillingRuleConfig')
                      .default,
                exact: true,
              },
              {
                path: '/finance/billing/config/:ruleId',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/BillingConfig/BillingRuleConfig'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/BillingConfig/BillingRuleConfig')
                      .default,
                exact: true,
              },
              {
                path: '/finance/billing/district',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/BillingConfig/DistrictBillingConfig'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/BillingConfig/DistrictBillingConfig')
                      .default,
                exact: true,
              },
              {
                path: '/finance/billing/changeList',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Finance/BillingConfig/BillingRuleChangeList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Finance/BillingConfig/BillingRuleChangeList')
                      .default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/coupon',
        routes: [
          {
            path: '/coupon',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Coupon__Coupon" */ '../Coupon/Coupon'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Coupon/Coupon').default,
            exact: true,
          },
          {
            path: '/coupon/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Coupon__CouponAdd" */ '../Coupon/CouponAdd'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Coupon/CouponAdd').default,
            exact: true,
          },
          {
            path: '/coupon/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Coupon__CouponView" */ '../Coupon/CouponView'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Coupon/CouponView').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/activity',
        routes: [
          {
            path: '/activity/intercity/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Intercity__Driver__List" */ '../Activity/Intercity/Driver/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Intercity/Driver/List').default,
            exact: true,
          },
          {
            path: '/activity/intercity/passenger',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Intercity__Passenger__List" */ '../Activity/Intercity/Passenger/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Intercity/Passenger/List').default,
            exact: true,
          },
          {
            path: '/activity/intercity/passenger/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Intercity__Passenger__Add" */ '../Activity/Intercity/Passenger/Add'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Intercity/Passenger/Add').default,
            exact: true,
          },
          {
            path: '/activity/intercity/passenger/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Intercity__Passenger__Edit" */ '../Activity/Intercity/Passenger/Edit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Intercity/Passenger/Edit').default,
            exact: true,
          },
          {
            path: '/activity/intercity/passenger/copy/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Intercity__Passenger__Copy" */ '../Activity/Intercity/Passenger/Copy'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Intercity/Passenger/Copy').default,
            exact: true,
          },
          {
            path: '/activity/intercity/passenger/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Intercity__Passenger__View" */ '../Activity/Intercity/Passenger/View'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Intercity/Passenger/View').default,
            exact: true,
          },
          {
            path: '/activity/intercity/passenger/statistics/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Intercity__Passenger__Statistics" */ '../Activity/Intercity/Passenger/Statistics'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Intercity/Passenger/Statistics').default,
            exact: true,
          },
          {
            path: '/activity/intercity/driver/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Intercity__Driver__Add" */ '../Activity/Intercity/Driver/Add'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Intercity/Driver/Add').default,
            exact: true,
          },
          {
            path: '/activity/intercity/driver/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Intercity__Driver__Edit" */ '../Activity/Intercity/Driver/Edit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Intercity/Driver/Edit').default,
            exact: true,
          },
          {
            path: '/activity/intercity/driver/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Intercity__Driver__View" */ '../Activity/Intercity/Driver/View'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Intercity/Driver/View').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/driver/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__Driver__List" */ '../Activity/Insidecity/Driver/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/Driver/List').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/driver/add/:type',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__Driver__Add" */ '../Activity/Insidecity/Driver/Add'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/Driver/Add').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/driver/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__Driver__Edit" */ '../Activity/Insidecity/Driver/Edit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/Driver/Edit').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/driver/copy/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__Driver__Copy" */ '../Activity/Insidecity/Driver/Copy'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/Driver/Copy').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/driver/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__Driver__View" */ '../Activity/Insidecity/Driver/View'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/Driver/View').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/driver/awardBill',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__Driver__AwardBill" */ '../Activity/Insidecity/Driver/AwardBill'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/Driver/AwardBill').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/driver',
            routes: [
              {
                path: '/activity/insidecity/driver/invite',
                redirect: '/activity/insidecity/driver/invite/list',
                exact: true,
              },
              {
                path: '/activity/insidecity/driver/invite/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Activity/Insidecity/DriverInvite/List'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Activity/Insidecity/DriverInvite/List').default,
                exact: true,
              },
              {
                path: '/activity/insidecity/driver/invite/add/:type',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Activity/Insidecity/DriverInvite/Add'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Activity/Insidecity/DriverInvite/Add').default,
                exact: true,
              },
              {
                path: '/activity/insidecity/driver/invite/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Activity/Insidecity/DriverInvite/Edit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Activity/Insidecity/DriverInvite/Edit').default,
                exact: true,
              },
              {
                path: '/activity/insidecity/driver/invite/copy/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Activity/Insidecity/DriverInvite/Copy'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Activity/Insidecity/DriverInvite/Copy').default,
                exact: true,
              },
              {
                path: '/activity/insidecity/driver/invite/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Activity/Insidecity/DriverInvite/View'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Activity/Insidecity/DriverInvite/View').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/activity/insidecity/passengers',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__Passenger__List" */ '../Activity/Insidecity/Passenger/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/Passenger/List').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/passengers/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__Passenger__Add" */ '../Activity/Insidecity/Passenger/Add'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/Passenger/Add').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/passengers/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__Passenger__Edit" */ '../Activity/Insidecity/Passenger/Edit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/Passenger/Edit').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/passengers/copy/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__Passenger__Copy" */ '../Activity/Insidecity/Passenger/Copy'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/Passenger/Copy').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/passengers/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__Passenger__View" */ '../Activity/Insidecity/Passenger/View'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/Passenger/View').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/passengers/statistics/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__Passenger__Statistics" */ '../Activity/Insidecity/Passenger/Statistics'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/Passenger/Statistics').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/passenger',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__PassengerInvite__List" */ '../Activity/Insidecity/PassengerInvite/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/PassengerInvite/List').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/passenger/add/:type',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__PassengerInvite__Add" */ '../Activity/Insidecity/PassengerInvite/Add'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/PassengerInvite/Add').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/passenger/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__PassengerInvite__Edit" */ '../Activity/Insidecity/PassengerInvite/Edit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/PassengerInvite/Edit').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/passenger/copy/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__PassengerInvite__Copy" */ '../Activity/Insidecity/PassengerInvite/Copy'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/PassengerInvite/Copy').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/passenger/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__PassengerInvite__View" */ '../Activity/Insidecity/PassengerInvite/View'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/PassengerInvite/View').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/passenger/statistics/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__PassengerInvite__Statistics" */ '../Activity/Insidecity/PassengerInvite/Statistics'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/PassengerInvite/Statistics')
                  .default,
            exact: true,
          },
          {
            path: '/activity/insidecity/freecharge/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__FreeCharge__List" */ '../Activity/Insidecity/FreeCharge/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/FreeCharge/List').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/freecharge/add/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__FreeCharge__Add" */ '../Activity/Insidecity/FreeCharge/Add'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/FreeCharge/Add').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/freecharge/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__FreeCharge__Edit" */ '../Activity/Insidecity/FreeCharge/Edit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/FreeCharge/Edit').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/freecharge/copy/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__FreeCharge__Copy" */ '../Activity/Insidecity/FreeCharge/Copy'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/FreeCharge/Copy').default,
            exact: true,
          },
          {
            path: '/activity/insidecity/freecharge/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Activity__Insidecity__FreeCharge__View" */ '../Activity/Insidecity/FreeCharge/View'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Activity/Insidecity/FreeCharge/View').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/dj-activity',
        routes: [
          {
            path: '/dj-activity/intercity/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Intercity__Driver__List" */ '../DJ/DJ-Activity/Intercity/Driver/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Intercity/Driver/List').default,
            exact: true,
          },
          {
            path: '/dj-activity/intercity/passenger',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Intercity__Passenger__List" */ '../DJ/DJ-Activity/Intercity/Passenger/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Intercity/Passenger/List').default,
            exact: true,
          },
          {
            path: '/dj-activity/intercity/passenger/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Intercity__Passenger__Add" */ '../DJ/DJ-Activity/Intercity/Passenger/Add'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Intercity/Passenger/Add').default,
            exact: true,
          },
          {
            path: '/dj-activity/intercity/passenger/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Intercity__Passenger__Edit" */ '../DJ/DJ-Activity/Intercity/Passenger/Edit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Intercity/Passenger/Edit').default,
            exact: true,
          },
          {
            path: '/dj-activity/intercity/passenger/copy/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Intercity__Passenger__Copy" */ '../DJ/DJ-Activity/Intercity/Passenger/Copy'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Intercity/Passenger/Copy').default,
            exact: true,
          },
          {
            path: '/dj-activity/intercity/passenger/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Intercity__Passenger__View" */ '../DJ/DJ-Activity/Intercity/Passenger/View'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Intercity/Passenger/View').default,
            exact: true,
          },
          {
            path: '/dj-activity/intercity/passenger/statistics/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Intercity__Passenger__Statistics" */ '../DJ/DJ-Activity/Intercity/Passenger/Statistics'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Intercity/Passenger/Statistics')
                  .default,
            exact: true,
          },
          {
            path: '/dj-activity/intercity/driver/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Intercity__Driver__Add" */ '../DJ/DJ-Activity/Intercity/Driver/Add'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Intercity/Driver/Add').default,
            exact: true,
          },
          {
            path: '/dj-activity/intercity/driver/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Intercity__Driver__Edit" */ '../DJ/DJ-Activity/Intercity/Driver/Edit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Intercity/Driver/Edit').default,
            exact: true,
          },
          {
            path: '/dj-activity/intercity/driver/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Intercity__Driver__View" */ '../DJ/DJ-Activity/Intercity/Driver/View'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Intercity/Driver/View').default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/driver/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__Driver__List" */ '../DJ/DJ-Activity/Insidecity/Driver/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/Driver/List').default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/driver/add/:type',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__Driver__Add" */ '../DJ/DJ-Activity/Insidecity/Driver/Add'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/Driver/Add').default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/driver/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__Driver__Edit" */ '../DJ/DJ-Activity/Insidecity/Driver/Edit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/Driver/Edit').default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/driver/copy/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__Driver__Copy" */ '../DJ/DJ-Activity/Insidecity/Driver/Copy'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/Driver/Copy').default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/driver/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__Driver__View" */ '../DJ/DJ-Activity/Insidecity/Driver/View'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/Driver/View').default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/driver/awardBill',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__Driver__AwardBill" */ '../DJ/DJ-Activity/Insidecity/Driver/AwardBill'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/Driver/AwardBill')
                  .default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/driver',
            routes: [
              {
                path: '/dj-activity/insidecity/driver/invite',
                redirect: '/dj-activity/insidecity/driver/invite/list',
                exact: true,
              },
              {
                path: '/dj-activity/insidecity/driver/invite/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../DJ/DJ-Activity/Insidecity/DriverInvite/List'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../DJ/DJ-Activity/Insidecity/DriverInvite/List')
                      .default,
                exact: true,
              },
              {
                path: '/dj-activity/insidecity/driver/invite/add/:type',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../DJ/DJ-Activity/Insidecity/DriverInvite/Add'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../DJ/DJ-Activity/Insidecity/DriverInvite/Add')
                      .default,
                exact: true,
              },
              {
                path: '/dj-activity/insidecity/driver/invite/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../DJ/DJ-Activity/Insidecity/DriverInvite/Edit'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../DJ/DJ-Activity/Insidecity/DriverInvite/Edit')
                      .default,
                exact: true,
              },
              {
                path: '/dj-activity/insidecity/driver/invite/copy/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../DJ/DJ-Activity/Insidecity/DriverInvite/Copy'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../DJ/DJ-Activity/Insidecity/DriverInvite/Copy')
                      .default,
                exact: true,
              },
              {
                path: '/dj-activity/insidecity/driver/invite/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../DJ/DJ-Activity/Insidecity/DriverInvite/View'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../DJ/DJ-Activity/Insidecity/DriverInvite/View')
                      .default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/dj-activity/insidecity/passengers',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__Passenger__List" */ '../DJ/DJ-Activity/Insidecity/Passenger/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/Passenger/List').default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/passengers/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__Passenger__Add" */ '../DJ/DJ-Activity/Insidecity/Passenger/Add'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/Passenger/Add').default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/passengers/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__Passenger__Edit" */ '../DJ/DJ-Activity/Insidecity/Passenger/Edit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/Passenger/Edit').default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/passengers/copy/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__Passenger__Copy" */ '../DJ/DJ-Activity/Insidecity/Passenger/Copy'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/Passenger/Copy').default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/passengers/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__Passenger__View" */ '../DJ/DJ-Activity/Insidecity/Passenger/View'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/Passenger/View').default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/passengers/statistics/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__Passenger__Statistics" */ '../DJ/DJ-Activity/Insidecity/Passenger/Statistics'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/Passenger/Statistics')
                  .default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/passenger',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__PassengerInvite__List" */ '../DJ/DJ-Activity/Insidecity/PassengerInvite/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/PassengerInvite/List')
                  .default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/passenger/add/:type',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__PassengerInvite__Add" */ '../DJ/DJ-Activity/Insidecity/PassengerInvite/Add'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/PassengerInvite/Add')
                  .default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/passenger/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__PassengerInvite__Edit" */ '../DJ/DJ-Activity/Insidecity/PassengerInvite/Edit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/PassengerInvite/Edit')
                  .default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/passenger/copy/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__PassengerInvite__Copy" */ '../DJ/DJ-Activity/Insidecity/PassengerInvite/Copy'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/PassengerInvite/Copy')
                  .default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/passenger/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__PassengerInvite__View" */ '../DJ/DJ-Activity/Insidecity/PassengerInvite/View'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/PassengerInvite/View')
                  .default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/passenger/statistics/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__PassengerInvite__Statistics" */ '../DJ/DJ-Activity/Insidecity/PassengerInvite/Statistics'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/PassengerInvite/Statistics')
                  .default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/freecharge/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__FreeCharge__List" */ '../DJ/DJ-Activity/Insidecity/FreeCharge/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/FreeCharge/List').default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/freecharge/add/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__FreeCharge__Add" */ '../DJ/DJ-Activity/Insidecity/FreeCharge/Add'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/FreeCharge/Add').default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/freecharge/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__FreeCharge__Edit" */ '../DJ/DJ-Activity/Insidecity/FreeCharge/Edit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/FreeCharge/Edit').default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/freecharge/copy/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__FreeCharge__Copy" */ '../DJ/DJ-Activity/Insidecity/FreeCharge/Copy'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/FreeCharge/Copy').default,
            exact: true,
          },
          {
            path: '/dj-activity/insidecity/freecharge/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Activity__Insidecity__FreeCharge__View" */ '../DJ/DJ-Activity/Insidecity/FreeCharge/View'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Activity/Insidecity/FreeCharge/View').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/dj-customer-service',
        routes: [
          {
            path: '/dj-customer-service/modify-price',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-CustomerService__ModifyOrder__ModifyPrice" */ '../DJ/DJ-CustomerService/ModifyOrder/ModifyPrice.tsx'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-CustomerService/ModifyOrder/ModifyPrice.tsx')
                  .default,
            exact: true,
          },
          {
            path: '/dj-customer-service/modify',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-CustomerService__ModifyOrder__ModifyOrder" */ '../DJ/DJ-CustomerService/ModifyOrder/ModifyOrder.tsx'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-CustomerService/ModifyOrder/ModifyOrder.tsx')
                  .default,
            exact: true,
          },
          {
            path: '/dj-customer-service/modify-status',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-CustomerService__ModifyOrder__ModifyStatus" */ '../DJ/DJ-CustomerService/ModifyOrder/ModifyStatus.tsx'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-CustomerService/ModifyOrder/ModifyStatus.tsx')
                  .default,
            exact: true,
          },
          {
            path: '/dj-customer-service/modify-price-record',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-CustomerService__ModifyOrder__ModifyPriceRecord" */ '../DJ/DJ-CustomerService/ModifyOrder/ModifyPriceRecord.tsx'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-CustomerService/ModifyOrder/ModifyPriceRecord.tsx')
                  .default,
            exact: true,
          },
          {
            path: '/dj-customer-service/modify-status-record',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-CustomerService__ModifyOrder__ModifyStatusRecord" */ '../DJ/DJ-CustomerService/ModifyOrder/ModifyStatusRecord.tsx'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-CustomerService/ModifyOrder/ModifyStatusRecord.tsx')
                  .default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/dj-finance',
        routes: [
          {
            path: '/dj-finance/withdrawal',
            routes: [
              {
                path: '/dj-finance/withdrawal/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../DJ/DJ-Finance/Withdrawal/WithdrawalList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../DJ/DJ-Finance/Withdrawal/WithdrawalList')
                      .default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/dj-finance/proofread',
            routes: [
              {
                path: '/dj-finance/proofread',
                redirect: '/dj-finance/proofread/list',
                exact: true,
              },
              {
                path: '/dj-finance/proofread/intercity/passenger/awardBill',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../DJ/DJ-Activity/Intercity/Passenger/AwardBill'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../DJ/DJ-Activity/Intercity/Passenger/AwardBill')
                      .default,
                exact: true,
              },
              {
                path: '/dj-finance/proofread/driver/freeCommission/bill',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../DJ/DJ-Activity/Bill/DriverFreeCommissionBill'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../DJ/DJ-Activity/Bill/DriverFreeCommissionBill')
                      .default,
                exact: true,
              },
              {
                path: '/dj-finance/proofread/driver/driverInvite/bill',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../DJ/DJ-Activity/Bill/DriverInviteBill'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../DJ/DJ-Activity/Bill/DriverInviteBill').default,
                exact: true,
              },
              {
                path: '/dj-finance/proofread/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../DJ/DJ-Finance/OrderProofread/OrderProofreadList'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../DJ/DJ-Finance/OrderProofread/OrderProofreadList')
                      .default,
                exact: true,
              },
              {
                path: '/dj-finance/proofread/insidecity/driver/awardBill',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../DJ/DJ-Activity/Insidecity/Driver/AwardBill'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../DJ/DJ-Activity/Insidecity/Driver/AwardBill')
                      .default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/dj-finance/after-sales',
            routes: [
              {
                path: '/dj-finance/after-sales/insideList',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../AfterSales/AfterSalesListInsideDJ'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../AfterSales/AfterSalesListInsideDJ').default,
                exact: true,
              },
              {
                path:
                  '/dj-finance/after-sales/inside-customservice/:id/:orderNo/:applyId',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../AfterSales/Inside/CustomServiceApplyDJ'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../AfterSales/Inside/CustomServiceApplyDJ')
                      .default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/message_push',
        routes: [
          {
            path: '/message_push/mobile',
            routes: [
              {
                path: '/message_push/mobile',
                redirect: '/message_push/mobile/list',
                exact: true,
              },
              {
                path: '/message_push/mobile/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../MessagePush/MobileMessage/MobileMessage'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../MessagePush/MobileMessage/MobileMessage')
                      .default,
                exact: true,
              },
              {
                path: '/message_push/mobile/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../MessagePush/MobileMessage/MobileMessageAdd'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../MessagePush/MobileMessage/MobileMessageAdd')
                      .default,
                exact: true,
              },
              {
                path: '/message_push/mobile/detail/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../MessagePush/MobileMessage/MobileMessageView'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../MessagePush/MobileMessage/MobileMessageView')
                      .default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/message_push/driver',
            routes: [
              {
                path: '/message_push/driver',
                redirect: '/message_push/driver/list',
                exact: true,
              },
              {
                path: '/message_push/driver/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../MessagePush/Driver/List'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../MessagePush/Driver/List').default,
                exact: true,
              },
              {
                path: '/message_push/driver/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../MessagePush/Driver/Add'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../MessagePush/Driver/Add').default,
                exact: true,
              },
              {
                path: '/message_push/driver/copy/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../MessagePush/Driver/Copy'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../MessagePush/Driver/Copy').default,
                exact: true,
              },
              {
                path: '/message_push/driver/detail/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../MessagePush/Driver/View'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../MessagePush/Driver/View').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/message_push/passenger',
            routes: [
              {
                path: '/message_push/passenger',
                redirect: '/message_push/Passenger/list',
                exact: true,
              },
              {
                path: '/message_push/passenger/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../MessagePush/Passenger/List'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../MessagePush/Passenger/List').default,
                exact: true,
              },
              {
                path: '/message_push/passenger/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../MessagePush/Passenger/Add'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../MessagePush/Passenger/Add').default,
                exact: true,
              },
              {
                path: '/message_push/passenger/copy/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../MessagePush/Passenger/Copy'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../MessagePush/Passenger/Copy').default,
                exact: true,
              },
              {
                path: '/message_push/passenger/fromActivity/:activityId',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../MessagePush/Passenger/FromActivity'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../MessagePush/Passenger/FromActivity').default,
                exact: true,
              },
              {
                path: '/message_push/passenger/detail/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../MessagePush/Passenger/View'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../MessagePush/Passenger/View').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/city_manage',
        routes: [
          {
            path: '/city_manage/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__CityManage__CityList" */ '../CityManage/CityList'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../CityManage/CityList').default,
            exact: true,
          },
          {
            path: '/city_manage/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__CityManage__CityAdd" */ '../CityManage/CityAdd'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../CityManage/CityAdd').default,
            exact: true,
          },
          {
            path: '/city_manage/config/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__CityManage__CityConfigGroup" */ '../CityManage/CityConfigGroup'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../CityManage/CityConfigGroup').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/customer-service',
        routes: [
          {
            path: '/customer-service/driver',
            routes: [
              {
                path: '/customer-service/driver/feedback',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CustomerService/DriverFeedBack/DriverFeedBack'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CustomerService/DriverFeedBack/DriverFeedBack')
                      .default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/customer-service/order',
            routes: [
              {
                path: '/customer-service/order/manage',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CustomerService/OrderManage/OrderManage'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CustomerService/OrderManage/OrderManage')
                      .default,
                exact: true,
              },
              {
                path: '/customer-service/order/feedback',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CustomerService/OrderFeedback/OrderFeedback'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CustomerService/OrderFeedback/OrderFeedback')
                      .default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/customer-service/passenger',
            routes: [
              {
                path: '/customer-service/passenger/feedback',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CustomerService/PassengerFeedBack/PassengerFeedBack'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CustomerService/PassengerFeedBack/PassengerFeedBack')
                      .default,
                exact: true,
              },
              {
                path: '/customer-service/passenger/orderFeedbackType',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CustomerService/OrderFeedback/FeedbackType/OrderFeedbackType'),
                      LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CustomerService/OrderFeedback/FeedbackType/OrderFeedbackType')
                      .default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/driver_rule',
        routes: [
          {
            path: '/driver_rule/cancel',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DriverRule__Cancel" */ '../DriverRule/Cancel'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DriverRule/Cancel').default,
            exact: true,
          },
          {
            path: '/driver_rule/withdrawal/config',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Finance__Withdrawal__WithdrawalConfig" */ '../Finance/Withdrawal/WithdrawalConfig'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Finance/Withdrawal/WithdrawalConfig').default,
            exact: true,
          },
          {
            path: '/driver_rule/withdrawal/config_inside',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Finance__Withdrawal__Inside__ConfigList" */ '../Finance/Withdrawal/Inside/ConfigList'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Finance/Withdrawal/Inside/ConfigList').default,
            exact: true,
          },
          {
            path: '/driver_rule/withdrawal/config_inside/add/:configType',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Finance__Withdrawal__Inside__ConfigAdd" */ '../Finance/Withdrawal/Inside/ConfigAdd'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Finance/Withdrawal/Inside/ConfigAdd').default,
            exact: true,
          },
          {
            path: '/driver_rule/withdrawal/config_inside/edit/:id/:configType',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Finance__Withdrawal__Inside__ConfigEdit" */ '../Finance/Withdrawal/Inside/ConfigEdit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Finance/Withdrawal/Inside/ConfigEdit').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/driver_operation',
        routes: [
          {
            path: '/driver_operation/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DriverOperation__List" */ '../DriverOperation/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DriverOperation/List').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/passenger_operation',
        routes: [
          {
            path: '/passenger_operation/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__PassengerOperation__List" */ '../PassengerOperation/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../PassengerOperation/List').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/district_dispatch',
        routes: [
          {
            path: '/district_dispatch',
            redirect: '/district_dispatch/list',
            exact: true,
          },
          {
            path: '/district_dispatch/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DistrictDispatch__DistrictDispatchList" */ '../DistrictDispatch/DistrictDispatchList'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DistrictDispatch/DistrictDispatchList').default,
            exact: true,
          },
          {
            path: '/district_dispatch/detail/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DistrictDispatch__DistrictDispatchView" */ '../DistrictDispatch/DistrictDispatchView'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DistrictDispatch/DistrictDispatchView').default,
            exact: true,
          },
          {
            path: '/district_dispatch/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DistrictDispatch__DistrictDispatchAdd" */ '../DistrictDispatch/DistrictDispatchAdd'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DistrictDispatch/DistrictDispatchAdd').default,
            exact: true,
          },
          {
            path: '/district_dispatch/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DistrictDispatch__DistrictDispatchEdit" */ '../DistrictDispatch/DistrictDispatchEdit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DistrictDispatch/DistrictDispatchEdit').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/adjustmentaccount',
        routes: [
          {
            path: '/adjustmentaccount',
            redirect: '/adjustmentaccount/list',
            exact: true,
          },
          {
            path: '/adjustmentaccount/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Finance__AdjustmentAccount__AdjustmentAccountList" */ '../Finance/AdjustmentAccount/AdjustmentAccountList'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Finance/AdjustmentAccount/AdjustmentAccountList')
                  .default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/app',
        routes: [
          {
            path: '/app/version',
            redirect: '/app/list',
            exact: true,
          },
          {
            path: '/app/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__AppVersion__List" */ '../AppVersion/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../AppVersion/List').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/passenger_rules',
        routes: [
          {
            path: '/passenger_rules',
            redirect: '/passenger_rules/risk',
            exact: true,
          },
          {
            path: '/passenger_rules/risk',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__PassengerRules__Risk" */ '../PassengerRules/Risk'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../PassengerRules/Risk').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/common_config',
        routes: [
          {
            path: '/common_config',
            redirect: '/common_config/list',
            exact: true,
          },
          {
            path: '/common_config/cancel/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__CommonCancel__List" */ '../CommonCancel/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../CommonCancel/List').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/channel',
        routes: [
          {
            path: '/channel',
            redirect: '/channel/list',
            exact: true,
          },
          {
            path: '/channel/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Channel__List" */ '../Channel/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Channel/List').default,
            exact: true,
          },
          {
            path: '/channel/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Channel__Add" */ '../Channel/Add'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Channel/Add').default,
            exact: true,
          },
          {
            path: '/channel/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Channel__Edit" */ '../Channel/Edit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Channel/Edit').default,
            exact: true,
          },
          {
            path: '/channel/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Channel__View" */ '../Channel/View'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Channel/View').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/drive_config',
        routes: [
          {
            path: '/drive_config/voice_config/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__VoiceConfig__Drive__List" */ '../VoiceConfig/Drive/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../VoiceConfig/Drive/List').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/dj-driver',
        routes: [
          {
            path: '/dj-driver',
            redirect: '/dj-driver/list',
            exact: true,
          },
          {
            path: '/dj-driver/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Driver__Index" */ '../DJ/DJ-Driver/Index'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Driver/Index').default,
            exact: true,
          },
          {
            path: '/dj-driver/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Driver__Add" */ '../DJ/DJ-Driver/Add'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Driver/Add').default,
            exact: true,
          },
          {
            path: '/dj-driver/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Driver__Edit" */ '../DJ/DJ-Driver/Edit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Driver/Edit').default,
            exact: true,
          },
          {
            path: '/dj-driver/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Driver__View" */ '../DJ/DJ-Driver/View'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Driver/View').default,
            exact: true,
          },
          {
            path: '/dj-driver/pedding',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Driver__CheckDriver__PeddingList" */ '../DJ/DJ-Driver/CheckDriver/PeddingList'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Driver/CheckDriver/PeddingList').default,
            exact: true,
          },
          {
            path: '/dj-driver/check',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Driver__CheckDriver__CheckDriverHistory" */ '../DJ/DJ-Driver/CheckDriver/CheckDriverHistory'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Driver/CheckDriver/CheckDriverHistory')
                  .default,
            exact: true,
          },
          {
            path: '/dj-driver/pedding/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Driver__CheckDriver__PeddingView" */ '../DJ/DJ-Driver/CheckDriver/PeddingView'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Driver/CheckDriver/PeddingView').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/dj-order',
        routes: [
          {
            path: '/dj-order',
            redirect: '/dj-order/list',
            exact: true,
          },
          {
            path: '/dj-order/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Order__Index" */ '../DJ/DJ-Order/Index'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Order/Index').default,
            exact: true,
          },
          {
            path: '/dj-order/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Order__Add" */ '../DJ/DJ-Order/Add'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Order/Add').default,
            exact: true,
          },
          {
            path: '/dj-order/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Order__Edit" */ '../DJ/DJ-Order/Edit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Order/Edit').default,
            exact: true,
          },
          {
            path: '/dj-order/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Order__View" */ '../DJ/DJ-Order/View'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Order/View').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/dj-adjustment',
        routes: [
          {
            path: '/dj-adjustment',
            redirect: '/dj-adjustment/list',
            exact: true,
          },
          {
            path: '/dj-adjustment/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Adjustment__Index" */ '../DJ/DJ-Adjustment/Index'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Adjustment/Index').default,
            exact: true,
          },
          {
            path: '/dj-adjustment/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Adjustment__Add" */ '../DJ/DJ-Adjustment/Add'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Adjustment/Add').default,
            exact: true,
          },
          {
            path: '/dj-adjustment/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Adjustment__Edit" */ '../DJ/DJ-Adjustment/Edit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Adjustment/Edit').default,
            exact: true,
          },
          {
            path: '/dj-adjustment/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Adjustment__View" */ '../DJ/DJ-Adjustment/View'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Adjustment/View').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/dj-pricerule',
        routes: [
          {
            path: '/dj-pricerule',
            redirect: '/dj-pricerule/list',
            exact: true,
          },
          {
            path: '/dj-pricerule/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-PriceRule__Index" */ '../DJ/DJ-PriceRule/Index'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-PriceRule/Index').default,
            exact: true,
          },
          {
            path: '/dj-pricerule/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-PriceRule__Add" */ '../DJ/DJ-PriceRule/Add'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-PriceRule/Add').default,
            exact: true,
          },
          {
            path: '/dj-pricerule/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-PriceRule__Edit" */ '../DJ/DJ-PriceRule/Edit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-PriceRule/Edit').default,
            exact: true,
          },
          {
            path: '/dj-pricerule/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-PriceRule__View" */ '../DJ/DJ-PriceRule/View'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-PriceRule/View').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/dj-place',
        routes: [
          {
            path: '/dj-place',
            redirect: '/dj-place/list',
            exact: true,
          },
          {
            path: '/dj-place/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Place__Index" */ '../DJ/DJ-Place/Index'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Place/Index').default,
            exact: true,
          },
          {
            path: '/dj-place/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Place__Add" */ '../DJ/DJ-Place/Add'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Place/Add').default,
            exact: true,
          },
          {
            path: '/dj-place/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Place__Edit" */ '../DJ/DJ-Place/Edit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Place/Edit').default,
            exact: true,
          },
          {
            path: '/dj-place/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DJ__DJ-Place__View" */ '../DJ/DJ-Place/View'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../DJ/DJ-Place/View').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/auto_receive',
        routes: [
          {
            path: '/auto_receive',
            redirect: '/auto_receive/list',
            exact: true,
          },
          {
            path: '/auto_receive/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__AutoReceive__Index" */ '../AutoReceive/Index'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../AutoReceive/Index').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/capacityMap',
        routes: [
          {
            path: '/capacityMap',
            redirect: '/capacityMap/index',
            exact: true,
          },
          {
            path: '/capacityMap/index',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Capacity__DistributionMap__Index" */ '../Capacity/DistributionMap/Index'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../Capacity/DistributionMap/Index').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/charteredcar/attraction',
        routes: [
          {
            path: '/charteredcar/attraction',
            redirect: '/charteredcar/attraction/list',
            exact: true,
          },
          {
            path: '/charteredcar/attraction/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__CharteredCar__Attraction__List" */ '../CharteredCar/Attraction/List'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../CharteredCar/Attraction/List').default,
            exact: true,
          },
          {
            path: '/charteredcar/attraction/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__CharteredCar__Attraction__Add" */ '../CharteredCar/Attraction/Add'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../CharteredCar/Attraction/Add').default,
            exact: true,
          },
          {
            path: '/charteredcar/attraction/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__CharteredCar__Attraction__Edit" */ '../CharteredCar/Attraction/Edit'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../CharteredCar/Attraction/Edit').default,
            exact: true,
          },
          {
            path: '/charteredcar/attraction/view/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__CharteredCar__Attraction__View" */ '../CharteredCar/Attraction/View'),
                  LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                    .default,
                })
              : require('../CharteredCar/Attraction/View').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/charteredcar/order/dispatch/list',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__CharteredCar__Order__Dispatch__List" */ '../CharteredCar/Order/Dispatch/List'),
              LoadingComponent: require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/components/PageLoading/index')
                .default,
            })
          : require('../CharteredCar/Order/Dispatch/List').default,
        exact: true,
      },
      {
        component: () =>
          React.createElement(
            require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    component: () =>
      React.createElement(
        require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
          .default,
        { pagesPath: 'src/pages', hasRoutesInConfig: true },
      ),
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}

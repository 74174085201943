export const DRIVERCERTIFICATES_NAMESPACE = 'drivercertificates';

export function DRIVER_CERTIFICATES_PAGE(payload, callback) {
  return {
    type: `${DRIVERCERTIFICATES_NAMESPACE}/fetchPage`,
    payload,
    callback
  };
}

export function DRIVER_CERTIFICATES_DETAIL(payload, callback) {
  return {
    type: `${DRIVERCERTIFICATES_NAMESPACE}/driverDetail`,
    payload,
    callback
  };
}
export function DRIVER_CERTIFICATES_IDCARD_UPDATE(payload, callback) {
  return {
    type: `${DRIVERCERTIFICATES_NAMESPACE}/idCardUpdate`,
    payload,
    callback
  };
}

export function DRIVER_CERTIFICATES_LICENSE_DETAIL(payload, callback) {
  return {
    type: `${DRIVERCERTIFICATES_NAMESPACE}/licenseDetail`,
    payload,
    callback
  };
}

export function DRIVER_CERTIFICATES_HAILINGLICENSE_DETAIL(payload, callback) {
  return {
    type: `${DRIVERCERTIFICATES_NAMESPACE}/hailingLicenseDetail`,
    payload,
    callback
  };
}

export function DRIVER_CERTIFICATES_LICENSE_SUBMIT(payload, callback) {
  return {
    type: `${DRIVERCERTIFICATES_NAMESPACE}/licenseSubmit`,
    payload,
    callback
  };
}

export function DRIVER_CERTIFICATES_HAILINGLICENSE_SUBMIT(payload, callback) {
  return {
    type: `${DRIVERCERTIFICATES_NAMESPACE}/hailinglicenseSubmit`,
    payload,
    callback
  };
}

export function DRIVER_CERTIFICATES_OTHER_INFO_SUBMIT(payload, callback) {
  return {
    type: `${DRIVERCERTIFICATES_NAMESPACE}/otherInfoSubmit`,
    payload,
    callback
  };
}

export function DRIVER_CERTIFICATES_CONTRACT_SUBMIT(payload, callback) {
  return {
    type: `${DRIVERCERTIFICATES_NAMESPACE}/contractSubmit`,
    payload,
    callback
  };
}
export const SCHEDULE_NAMESPACE = 'schedule';

export function SCHEDULE_DETAIL(params, callback) {
  return {
    type: `${SCHEDULE_NAMESPACE}/fetchDetail`,
    payload: params,
    callback
  };
}

export function SCHEDULE_CLEAR_DETAIL() {
  return {
    type: `${SCHEDULE_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function SCHEDULE_SUBMIT(payload, callback) {
  return {
    type: `${SCHEDULE_NAMESPACE}/submit`,
    payload,
    callback
  };
}
export function SCHEDULE_SWITCH_TYPE(payload, callback) {
  return {
    type: `${SCHEDULE_NAMESPACE}/switchActivedType`,
    payload,
    callback
  };
}
export function REMOVE_SOMEDAY_SCHEDULE(payload, callback) {
  return {
    type: `${SCHEDULE_NAMESPACE}/removeSomedaySchedule`,
    payload,
    callback
  };
}

// 获取班次信息
export function SCHDULE_SHIFTBYLINE_LIST(payload, callback) {
  return {
    type: `${SCHEDULE_NAMESPACE}/fetchShiftbyline`,
    payload,
    callback
  };
}


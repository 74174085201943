import { message } from 'antd';
// import router from 'umi/router';
import { PRICE_NAMESPACE } from '@/actions/dj/djpricerule';
import { list, detail, add, update, del, checkFence } from '@/services/dj/djpricerule';
import { getLazyTree } from '@/services/region';
export default {
  namespace: PRICE_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: null
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *submit({ payload, callback }, { call }) {
      const res = yield call(add, payload);
      if (res.success) {
        message.success(res.msg);
      }
      callback && callback(res.success);
    },
    *update({ payload, callback }, { call }) {
      const res = yield call(update, payload);
      if (res.success) {
        message.success(res.msg);
      }
      callback && callback(res.success);
    },
    *getRegion({ payload, callback }, { call }) {
      const res = yield call(getLazyTree, payload);
      if (res.success) {
        callback && callback(res.data);
      }
    },
    *del({ payload, callback }, { call }) {
      const res = yield call(del, payload);
      if (res.success) {
        message.success(res.msg);
      }
      callback && callback(res.success);
    },
    *detail({ payload }, { call, put }) {
      const res = yield call(detail, payload);
      if (res.success) {
        yield put({
          type: 'saveDetail',
          payload: res.data,
        });
      }
    },
    *checkArea({ payload, callback }, { call, put }) {
      const res = yield call(checkFence, payload);
      message[res.success? 'success' : 'warning'](res.msg)
      callback && callback(res.success)
    },
    *detailClearn(_, { put }) {
      yield put({
        type: 'saveDetail',
        payload: {},
      });
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload,
      };
    },
  },
};

import { stringify } from 'qs';
import request from '../utils/request';

// 订单核算-待结算的列表
export async function waitSettlementPage(params) {
  return request('/api/bmkp-backend/finance/settlement/waitSettlement/page', {
    method: 'POST',
    body: params,
  });
}
// 订单核算-待结算的列表-代驾
export async function waitSettlementDjPage(params) {
  return request('/api/bmkp-backend/finance/settlement/waitSettlement/djPage', {
    method: 'POST',
    body: params,
  });
}
// 订单核算-待结算的列表
export async function waitSettlementPageInside(params) {
  return request('/api/bmkp-backend/finance/settlement/waitSettlement/insideCityPage', {
    method: 'POST',
    body: params,
  });
}

// 订单核算-待结算订单详情 城际
export async function waitSettlementDetail(params) {
  return request(`/api/bmkp-backend/finance/settlement/waitSettlement/detail?${stringify(params)}`);
}

// 订单核算-待结算订单详情 代驾
export async function waitSettlementDjDetail(params) {
  return request(`/api/bmkp-backend/finance/settlement/waitSettlement/djDetail?${stringify(params)}`);
}

// 订单核算-待核账-已结算
export async function reconciliationList(params) {
  return request('/api/bmkp-backend/finance/settlement/reconciliation/page', {
    method: 'POST',
    body: params,
  });
}

// 批量生成核账单 城际
export async function batcGenerate(params) {
  return request('/api/bmkp-backend/finance/settlement/reconciliation/batcGenerate', {
    method: 'POST',
    body: params,
  });
}

// 批量生成核账单 代驾
export async function batcGenerateDj(params) {
  return request('/api/bmkp-backend/finance/settlement/reconciliation/djBatcGenerate', {
    method: 'POST',
    body: params,
  });
}

// 批量生成核账单 城内
export async function batchGenerateInside(params) {
  return request('/api/bmkp-backend/finance/settlement/reconciliation/insideCityBatcGenerate', {
    method: 'POST',
    body: params,
  });
}

// 核账单结算
export async function checkReconciliation(params) {
  return request(`/api/bmkp-backend/finance/settlement/reconciliation/check?${stringify(params)}`);
}

// 核账单作废
export async function invalidReconciliation(params) {
  return request(`/api/bmkp-backend/finance/settlement/reconciliation/invalid?${stringify(params)}`);
}

// 已核账-结算订单明细 城际
export async function detailReconciliation(params) {
  return request(`/api/bmkp-backend/finance/settlement/reconciliation/orderDetail?${stringify(params)}`);
}

// 已核账-结算订单明细 代驾
export async function detailReconciliationDj(params) {
  return request(`/api/bmkp-backend/finance/settlement/reconciliation/djOrderDetail?${stringify(params)}`);
}

// 已核账-结算订单明细 城内
export async function detailReconInside(params) {
  return request(`/api/bmkp-backend/finance/settlement/reconciliation/insideCityOrderDetail?${stringify(params)}`);
}

// 订单核算-待结算订单详情 城内
export async function waitSettlementDetailInside(params) {
  return request(`/api/bmkp-backend/finance/settlement/waitSettlement/insideCityDetail?${stringify(params)}`);
}

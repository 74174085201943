export const ACTIVITY_PREVIEW_NAMESPACE = 'activitypreview';

export function ACTIVITY_DETAIL(id, callback) {
  return {
    type: `${ACTIVITY_PREVIEW_NAMESPACE}/fetchDetail`,
    payload: { id },
    callback
  };
}

export function ACTIVITY_PREVIEW_CLEAR_DETAIL() {
  return {
    type: `${ACTIVITY_PREVIEW_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function ACTIVITY_PREVIEW_UPDATE(payload) {
  return {
    type: `${ACTIVITY_PREVIEW_NAMESPACE}/update`,
    payload
  };
}
import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

export async function list(params) {
  // return request(`/api/bmkp-backend/coupon/page?${stringify(params)}`);
  return request('/api/bmkp-backend/finance/wycactivityinvitecouponbill/list', {
    method: 'POST',
    body: params,
  });
}

export async function submit(params) {
  return request('/api/bmkp-backend/coupon/add', {
    method: 'POST',
    body: params,
  });
}

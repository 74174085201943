export const BRAND_SERIES_NAMESPACE = 'brandAndSeries';

// 分页查询
export function BRAND_SERIES_PAGE(payload) {
  return {
    type: `${BRAND_SERIES_NAMESPACE}/fetchPage`,
    payload,
  };
}

// 品牌列表
export function BRAND_SERIES_LIST(payload) {
  return {
    type: `${BRAND_SERIES_NAMESPACE}/fetchList`,
    payload,
  };
}

// 新增品牌
export function BRAND_SERIES_ADD(payload, callback) {
  return {
    type: `${BRAND_SERIES_NAMESPACE}/add`,
    payload,
    callback
  };
}

// 品牌详情
export function BRAND_SERIES_DETAIL(payload, callback) {
  return {
    type: `${BRAND_SERIES_NAMESPACE}/fetchDetail`,
    payload,
    callback
  };
}

export function BRAND_SERIES_CLEAR_DETAIL() {
  return {
    type: `${BRAND_SERIES_NAMESPACE}/clearDetail`
  }
}

// 删除
export function BRAND_SERIES_DELETE(payload, callback) {
  return {
    type: `${BRAND_SERIES_NAMESPACE}/remove`,
    payload,
    callback
  };
}

// 为品牌新增一个车系
export function BRAND_SERIES_ADD_SERIES(payload, callback) {
  return {
    type: `${BRAND_SERIES_NAMESPACE}/addSeries`,
    payload,
    callback
  };
}

// 为品牌删除一个车系
export function BRAND_SERIES_REMOVE_SERIES(payload, callback) {
  return {
    type: `${BRAND_SERIES_NAMESPACE}/removeSeries`,
    payload,
    callback
  };
}

// 为品牌批量新增一些车系
export function BRAND_SERIES_BATCH_ADD_SERIES(payload, callback) {
  return {
    type: `${BRAND_SERIES_NAMESPACE}/batchAddSeries`,
    payload,
    callback
  };
}
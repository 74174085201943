import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

// export async function list(params) {
//   return request(`/api/bmkp-backend/capacity/supplieroperateconfig/page?${stringify(params)}`);
// }

// 新增-修改供应商运营配置
export async function submit(params) {
  return request('/api/bmkp-backend/capacity/supplieroperateconfig/submit', {
    method: 'POST',
    body: params,
  });
}

// 供应商运营配置信息
export async function detail(params) {
  return request('/api/bmkp-backend/capacity/supplieroperateconfig/detail', {
    method: 'POST',
    body: params,
  });
}

// 逻辑删除
export async function remove(params) {
  return request(`/api/bmkp-backend/capacity/supplieroperateconfig/remove?${stringify(params)}`, {
    method: 'POST',
    // body: func.toFormData(params),
  });
}

// 城内供应商运营配置信息
export async function insideDetail(params) {
  return request('/api/bmkp-backend/capacity/supplieroperateconfig/insideCityDetail', {
    method: 'POST',
    body: params,
  });
}

export async function insideSubmit(params) {
  return request('/api/bmkp-backend/capacity/supplieroperateconfig/batchUpdate', {
    method: 'POST',
    body: params,
  });
}

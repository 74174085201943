import { message, notification} from 'antd';
import router from 'umi/router';
import { CHANNEL_NAMESPACE } from '../actions/channel';
import {list, del, info, submit} from '../services/channel';
export default {
    namespace: CHANNEL_NAMESPACE,
    state: {
      // 城际司机活动列表
      data: {
        list: [],
        pagination: false,
      },
    },
    effects: {
      *fetchList({ payload }, { call, put }) {
        const response = yield call(list, payload);
        if (response.success) {
          yield put({
            type: 'saveList',
            payload: {
              list: response.data.records,
              pagination: {
                total: response.data.total,
                current: response.data.current,
                pageSize: response.data.size,
              },
            },
          });
        }
      },
      *submit({payload, callback}, {call}) {
        const res = yield call(submit, payload);
        if (res.success) {
          callback && callback(res)
        }
      },
      *delete({payload, callback}, {call}) {
        const res = yield call(del, payload);
        if (res.success) {
          callback && callback()
        }
      },
      *info({payload, callback}, {call}) {
        const res = yield call(info, payload);
        if (res.success) {
          callback && callback(res.data)
        }
      }
    },
    reducers: {
        saveList(state, action) {
          return {
            ...state,
            data: action.payload,
          };
        },
    }
}
export const AFTERSALES_NAMESPACE = 'aftersales';

export function AFTERSALES_LIST(payload) {
  return {
    type: `${AFTERSALES_NAMESPACE}/fetchList`,
    payload,
  };
}

export function AFTERSALES_DETAIL(id) {
  return {
    type: `${AFTERSALES_NAMESPACE}/fetchDetail`,
    payload: { id },
  };
}

export function AFTERSALES_CLEAR_DETAIL() {
  return {
    type: `${AFTERSALES_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function AFTERSALES_SERVICE_CHECK(payload, callback) {
  return {
    type: `${AFTERSALES_NAMESPACE}/serviceCheck`,
    payload,
    callback
  };
}

export function AFTERSALES_APPLE_RESOLVE(payload) {
  return {
    type: `${AFTERSALES_NAMESPACE}/appleResolve`,
    payload,
  };
}

export function AFTERSALES_APPLE_REJECT(payload) {
  return {
    type: `${AFTERSALES_NAMESPACE}/appleReject`,
    payload,
  };
}

import { message } from 'antd';
import { VOICECONFIG_NAMESPACE } from '../actions/voiceconfig';
import {
  pageDriver,
  addDriver,
  updateDriver,
  changeStatusDriver,
  getConfig,
} from '../services/voiceconfig';

export default {
  namespace: VOICECONFIG_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    configData: {
      list: [],
      pagination: false,
    },
    detail: null,
  },
  effects: {
    *pageDriver({ payload }, { call, put }) {
      const response = yield call(pageDriver, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *getConfig({ payload }, { call, put }) {
      const response = yield call(getConfig, payload);
      if (response.success) {
        yield put({
          type: 'saveConfigList',
          payload: {
            list: response.data,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *addDriver({ payload, callback }, { call }) {
      const response = yield call(addDriver, payload);
      if (response.success) {
        message.success('保存成功');
        callback(response.data)
      }
    },
    *updateDriver({ payload, callback }, { call }) {
      const response = yield call(updateDriver, payload);
      if (response.success) {
        callback(payload.id)
      }
    },
    *changeStatusDriver({ payload, callback }, { call }) {
      const response = yield call(changeStatusDriver, payload);
      if (response.success) {
        callback(response.success)
      }
    },
    *setDetail({ payload, callback }, { put }) {
      yield put({
        type: 'saveDetail',
        payload
      })
      callback()
    },
    *setClearDetail({ payload }, { put }) {
      yield put({
        type: 'clearDetail',
        payload
      })
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveConfigList(state, action) {
      return {
        ...state,
        configData: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload,
      };
    },
    clearDetail(state) {
      return {
        ...state,
        detail: null
      }
    }
  },
};

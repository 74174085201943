export const ORDERFEEDBACKTYPE_NAMESPACE = 'orderFeedbackType';

export function ORDERFEEDBACKTYPE_LIST(payload) {
  return {
    type: `${ORDERFEEDBACKTYPE_NAMESPACE}/fetchList`,
    payload,
  };
}

export function ORDERFEEDBACKTYPE_SUBMIT(payload, callback) {
  return {
    type: `${ORDERFEEDBACKTYPE_NAMESPACE}/submit`,
    payload,
    callback
  };
}

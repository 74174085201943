export const PASSENGER_NAMESPACE = 'passenger';

export function PASSENGER_LIST(payload) {
  return {
    type: `${PASSENGER_NAMESPACE}/fetchList`,
    payload,
  };
}

export function PASSENGER_DETAIL(id) {
  return {
    type: `${PASSENGER_NAMESPACE}/fetchDetail`,
    payload: { id },
  };
}

export function PASSENGER_UPDATE_STATUS(payload) {
  return {
    type: `${PASSENGER_NAMESPACE}/updateStatus`,
    payload,
  };
}

export function PASSENGER_CANCEL_IDENTITY(payload) {
  return {
    type: `${PASSENGER_NAMESPACE}/cancelIdentity`,
    payload,
  };
}

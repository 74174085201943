export const CHARTEREDCAR_NAMESPACE = 'charteredcar';

export function CHARTEREDCAR_ATTRACTION_LIST(payload) {
  return {
    type: `${CHARTEREDCAR_NAMESPACE}/attractionFetchList`,
    payload,
  };
}

export function CHARTEREDCAR_ATTRACTION_DETAIL(payload, callback) {
  return {
    type: `${CHARTEREDCAR_NAMESPACE}/attractionFetchDetail`,
    payload,
    callback
  };
}

export function CHARTEREDCAR_ATTRACTION_CLEAR_DETAIL() {
  return {
    type: `${CHARTEREDCAR_NAMESPACE}/attractionClearDetail`,
  };
}

export function CHARTEREDCAR_ATTRACTION_SAVE(payload, callback) {
  return {
    type: `${CHARTEREDCAR_NAMESPACE}/attractionSubmit`,
    payload,
    callback
  };
}

export function CHARTEREDCAR_ATTRACTION_DELETE(payload, callback) {
  return {
    type: `${CHARTEREDCAR_NAMESPACE}/attractionDelete`,
    payload,
    callback
  };
}

export function CHARTEREDCAR_ATTRACTION_UPDATE(payload, callback) {
  return {
    type: `${CHARTEREDCAR_NAMESPACE}/attractionUpdate`,
    payload,
    callback
  };
}

// 暂时没有用到该方法
export function CHARTEREDCAR_ATTRACTION_VEHICLE_CONFIG_LIST(payload, callback) {
  return {
    type: `${CHARTEREDCAR_NAMESPACE}/vehicleConfigFetchList`,
    payload,
    callback
  };
}

export function CHARTEREDCAR_ATTRACTION_VEHICLE_CONFIG_DETAIL(payload, callback) {
  return {
    type: `${CHARTEREDCAR_NAMESPACE}/vehicleConfigFetchDetail`,
    payload,
    callback
  };
}

export function CHARTEREDCAR_ATTRACTION_VEHICLE_CONFIG_CLEAR_DETAIL() {
  return {
    type: `${CHARTEREDCAR_NAMESPACE}/vehicleConfigClearDetail`,
  };
}

export function CHARTEREDCAR_ATTRACTION_VEHICLE_CONFIG_SAVE(payload, callback) {
  return {
    type: `${CHARTEREDCAR_NAMESPACE}/vehicleConfigSubmit`,
    payload,
    callback
  };
}

export function CHARTEREDCAR_ATTRACTION_VEHICLE_CONFIG_DELETE(payload, callback) {
  debugger
  return {
    type: `${CHARTEREDCAR_NAMESPACE}/vehicleConfigDelete`,
    payload,
    callback
  };
}

export function CHARTEREDCAR_ATTRACTION_VEHICLE_CONFIG_UPDATE(payload, callback) {
  return {
    type: `${CHARTEREDCAR_NAMESPACE}/vehicleConfigUpdate`,
    payload,
    callback
  };
}

export function CHARTEREDCAR_ORDER_PAGE(payload) {
  return {
    type: `${CHARTEREDCAR_NAMESPACE}/fetchOrderList`,
    payload,
  };
}

export function CHARTEREDCAR_DRIVER_PAGE(payload) {
  return {
    type: `${CHARTEREDCAR_NAMESPACE}/fetchDriverList`,
    payload,
  };
}

export function CHARTEREDCAR_ORDER_DETAIL(payload) {
  return {
    type: `${CHARTEREDCAR_NAMESPACE}/fetchOrderDetail`,
    payload,
  };
}

export function CHARTEREDCAR_CLEAR_ORDER_DETAIL() {
  return {
    type: `${CHARTEREDCAR_NAMESPACE}/clearOrderDetail`,
  };
}

export function CHARTEREDCAR_CREATE_ORDER(payload, callback) {
  return {
    type: `${CHARTEREDCAR_NAMESPACE}/createOrder`,
    payload,
    callback
  };
}

export function CHARTEREDCAR_WAIT_DISPATCH_ORDER_LIST(payload) {
  return {
    type: `${CHARTEREDCAR_NAMESPACE}/fetchWaitDispatchOrderList`,
    payload
  };
}

export function CHARTEREDCAR_ALLOT_DRIVER(payload) {
  return {
    type: `${CHARTEREDCAR_NAMESPACE}/setOrderAllot`,
    payload
  };
}


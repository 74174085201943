import { stringify } from 'qs';
import request from '../utils/request';

// 分页查询
export async function page(params) {
  return request(`/api/bmkp-backend/carbrand/page?${stringify(params)}`, {
    method: 'GET',
  });
}

// 列表查询
export async function list(params) {
  return request(`/api/bmkp-backend/carbrand/list?${stringify(params)}`, {
    method: 'GET',
  });
}

// 添加
export async function add(params) {
  return request('/api/bmkp-backend/carbrand/add', {
    method: 'POST',
    body: params,
  });
}

// 移除
export async function remove(params) {
  return request('/api/bmkp-backend/carbrand/remove', {
    method: 'POST',
    body: params,
  });
}

// 根据品牌获取车系列表
export async function seriesList(params) {
  return request(`/api/bmkp-backend/carseries/list?${stringify(params)}`, {
    method: 'GET',
  });
}

// 为品牌新增一个车系
export async function addSeries(params) {
  return request('/api/bmkp-backend/carseries/submit', {
    method: 'POST',
    body: params,
  });
}

// 为品牌删除一个车系
export async function removeSeries(params) {
  return request('/api/bmkp-backend/carseries/remove', {
    method: 'POST',
    body: params,
  });
}

// 为品牌批量新增一些车系
export async function batchAddSeries(params) {
  return request('/api/bmkp-backend/carseries/batchAdd', {
    method: 'POST',
    body: params,
  });
}
export const INSIDECITY_INVITE_ACTIVITY = 'inviteactivity';

export function INSIDECITY_INVITE_LIST(payload) {
  return {
    type: `${INSIDECITY_INVITE_ACTIVITY}/fetchList`,
    payload,
  };
}

// 城内乘客活动详情
export function INSIDECITY_INVITE_DETAIL(payload, callback) {
  return {
    type: `${INSIDECITY_INVITE_ACTIVITY}/fetchDetail`,
    payload,
    callback
  };
}

// 城内乘客活动详情清除
export function INSIDECITY_INVITE_DETAIL_CLEAR() {
  return {
    type: `${INSIDECITY_INVITE_ACTIVITY}/clearDetailPassenger`,
    payload: {},
  };
}

export function INSIDECITY_INVITE_OPEN(payload, callback) {
  return {
    type: `${INSIDECITY_INVITE_ACTIVITY}/open`,
    payload,
    callback
  };
}

export function INSIDECITY_INVITE_CLOSE(payload, callback) {
  return {
    type: `${INSIDECITY_INVITE_ACTIVITY}/close`,
    payload,
    callback
  };
}

export function INSIDECITY_INVITE_INVITEDELETE(payload, callback) {
  return {
    type: `${INSIDECITY_INVITE_ACTIVITY}/invitedelete`,
    payload,
    callback
  };
}

export function INSIDECITY_INVITE_SAVE(payload, callback) {
  return {
    type: `${INSIDECITY_INVITE_ACTIVITY}/save`,
    payload,
    callback
  };
}

export function INSIDECITY_INVITE_UPDATE(payload, callback) {
  return {
    type: `${INSIDECITY_INVITE_ACTIVITY}/update`,
    payload,
    callback
  };
}

export function INSIDECITY_INVITE_COPY(payload, callback) {
  return {
    type: `${INSIDECITY_INVITE_ACTIVITY}/copy`,
    payload,
    callback
  };
}

export function INSIDECITY_INVITE_COUPON_DELETE(payload, callback) {
  return {
    type: `${INSIDECITY_INVITE_ACTIVITY}/couponDelete`,
    payload,
    callback
  };
}
export const INSIDEREFUNDAPPLY_NAMESPACE = 'insiderefundapply';

export function INSIDEREFUNDAPPLY_LIST(payload) {
  return {
    type: `${INSIDEREFUNDAPPLY_NAMESPACE}/fetchList`,
    payload,
  };
}

export function INSIDEREFUNDAPPLY_DETAIL(orderNo) {
  return {
    type: `${INSIDEREFUNDAPPLY_NAMESPACE}/fetchDetail`,
    payload: { orderNo },
  };
}

export function INSIDEREFUNDAPPLY_RECORD(payload, callback) {
  return {
    type: `${INSIDEREFUNDAPPLY_NAMESPACE}/fetchRecord`,
    payload,
    callback
  };
}

export function INSIDEREFUNDAPPLY_CLEAR_DETAIL() {
  return {
    type: `${INSIDEREFUNDAPPLY_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function INSIDEREFUNDAPPLY_CLEAR_RECORD() {
  return {
    type: `${INSIDEREFUNDAPPLY_NAMESPACE}/clearRecord`,
    payload: {},
  };
}
import request from '../utils/request';

// 城内订单改价记录分页
export async function list(params) {
  return request('/api/bmkp-wyc/wyc/order/change/price/page', {
    method: 'POST',
    body: params,
  });
}

// 城内订单改价记录详情
export async function detail(params) {
  // TODO: 未配置
  return request('/api/bmkp-backend/blade-order/order/trade/detail', {
    method: 'POST',
    body: params,
  });
}

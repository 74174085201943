import { stringify } from 'qs';
import request from '../utils/request';

export async function list(params) {
  return request('/api/bmkp-backend/asset/driverArchives/audit/page', {
    method: 'POST',
    body: params,
  });
}

export async function updateStatus(params) {
  return request('/api/bmkp-backend/asset/driverArchives/audit/submit', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request(`/api/bmkp-backend/asset/driverArchives/audit/detail?${stringify(params)}`);
}
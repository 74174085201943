import { message } from 'antd';
// import router from 'umi/router';
import { ADJUSTMENT_NAMESPACE } from '@/actions/dj/djadjustment';
import { list, submit, detail, cancel, reject, pass } from '@/services/dj/djadjustment';
export default {
  namespace: ADJUSTMENT_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *add({ payload, callback }, { call }) {
      const res = yield call(submit, payload);
      if (res.success) {
        message.success(res.msg);
      }
      callback && callback(res.success);
    },
    *detail({ payload, callback }, { call, put }) {
      const res = yield call(detail, payload);
      if (res.success) {
        yield put({
          type: 'saveDetail',
          payload: res.data,
        });
      }
    },
    *detailClearn(_, { put }) {
      yield put({
        type: 'saveDetail',
        payload: {},
      });
    },
    *cancel({ payload, callback }, { call, put }) {
      const res = yield call(cancel, payload);
      if(res.success) {
        message.success(res.msg)
      }
      callback && callback(res.success);
    },
    *reject({ payload, callback }, { call, put }) {
      const res = yield call(reject, payload);
      if(res.success) {
        message.success(res.msg)
      }
      callback && callback(res.success);
    },
    *pass({ payload, callback }, { call, put }) {
      const res = yield call(pass, payload);
      if(res.success) {
        message.success(res.msg)
      }
      callback && callback(res.success);
    }
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        driverInfo: action.payload,
      };
    },
  },
};

import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

export async function newList(params) {
  return request(`/api/bmkp-backend/customize/line/getLineAgentPage?${stringify(params)}`
  );
}

export async function list(params) {
  return request(`/api/bmkp-backend/customize/line/page?${stringify(params)}`);
}

export async function listBySupplier(params) {
  return request('/api/bmkp-backend/customize/line/getLineSupplier',{
    method: 'POST',
    body: func.toFormData(params),
  });
}

// 通过起终点信息匹配线路
export async function getLineByCode(params) {
  return request('/api/bmkp-backend/customize/line/getLineByCode', {
    method: 'POST',
    body: params
  })
}

export async function listByAgent(params) {
  return request('/api/bmkp-backend/customize/line/getLineAgent',{
    method: 'POST',
    body: func.toFormData(params),
  });
}

export async function originList(params) {
  return request(`/api/customize/lineconfig/originList?${stringify(params)}`);
}

export async function destinationList(params) {
  return request(`/api/customize/lineconfig/destinationList?${stringify(params)}`);
}

export async function submit(params) {
  return request('/api/bmkp-backend/customize/line/save', {
    method: 'POST',
    body: params,
  });
}
export async function update(params) {
  return request('/api/bmkp-backend/customize/line/update', {
    method: 'POST',
    body: params,
  });
}
export async function updateOperationStatus(params) {
  return request('/api/bmkp-backend/customize/line/updateOpStatus', {
    method: 'POST',
    body: func.toFormData(params),
  });
}
export async function updateBatch(params) {
  return request('/api/bmkp-backend/customize/line/updateBatch', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request(`/api/bmkp-backend/customize/line/detail?${stringify(params)}`);
}

export async function remove(params) {
  return request('/api/customize/lineconfig/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}

export async function lineOperationConfigDetail(params) {
  return request(`/api/customize/lineconfig/operationConfigDetail?${stringify(params)}`);
}
export async function lineOperationStatus(params) {
  return request('/api/bmkp-backend/customize/line/checkStatus', {
    method: 'POST',
    body: func.toFormData(params),
  });
}

// 线路通用配置, 批量修改线路的(修改隐私号码, 是否实名, 客服电话)信息
export async function lineUpdateBatch(params) {
  return request('/api/bmkp-backend/customize/line/updateBatch', {
    method: 'POST',
    body: params
  })
} 

// 获取开通城市
export async function getCitysInfo(params) {
  return request(`/api/bmkp-backend/customize/line/operatecity?${stringify(params)}`);
}
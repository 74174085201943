import { stringify } from 'qs';
import request from '../utils/request';

// 语音播报配置
// 司机端

export async function pageDriver(params) {
  return request('/api/bmkp-backend/voice/config/page', {
    method: 'POST',
    body: params,
  });
}
export async function addDriver(params) {
  return request('/api/bmkp-backend/voice/config/add', {
    method: 'POST',
    body: params,
  });
}
export async function updateDriver(params) {
  return request('/api/bmkp-backend/voice/config/update', {
    method: 'POST',
    body: params,
  });
}
export async function changeStatusDriver(params) {
  return request('/api/bmkp-backend/voice/config/change/status', {
    method: 'POST',
    body: params,
  });
}
export async function getConfig(params) { // 获取所有场景配置
  return request(`/api/bmkp-backend/voice/config/get/config?${stringify(params)}`);
}

import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

export async function list(params) {
  return request(`/api/bmkp-backend/channelSupplierDriverConfig/config/page?${stringify(params)}`);
}

export async function bind(params) {
  return request('/api/bmkp-backend/channelSupplierDriverConfig/config/bind', {
    method: 'POST',
    body: params,
  });
}

export async function unbind(params) {
  return request(`/api/bmkp-backend/channelSupplierDriverConfig/config/unBind`, {
    method: 'POST',
    body: params,
  });
}

export async function driverList(params) {
  return request(`/api/bmkp-backend/asset/driver/page?${stringify(params)}`);
}

import { message } from 'antd';
import router from 'umi/router';
import { LINE_PRICE_RULE_NAMESPACE } from '../actions/linepricerule';
import {
  submit,
  detail,
  detailSpecial,
  submitSpecial
} from '../services/linepricerule';

export default {
  namespace: LINE_PRICE_RULE_NAMESPACE,
  state: {
    detail: null,
    detailSpecial: {
      specialFence: [],
      splitPrices: []
    }
  },
  effects: {
    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
    *clearDetail({  }, { call, put }) {
      yield put({
        type: 'saveDetail',
        payload: {
          detail: null,
        },
      });
    },
    *submit({ payload }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('提交成功');
        router.goBack();
      }
    },
    *remove({ payload }, { call }) {
      const {
        data: { keys },
        success,
      } = payload;
      const response = yield call(remove, { ids: keys });
      if (response.success) {
        success();
      }
    },
    *fetchDetailSpecial({ payload, callback }, { call, put }) {
      const response = yield call(detailSpecial, payload);
      if (response.success) {
        yield put({
          type: 'saveDetailSpecial',
          payload: {
            detail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
    *submitSpecial({ payload }, { call }) {
      const response = yield call(submitSpecial, payload);
      if (response.success) {
        message.success('提交成功');
        router.goBack();
      }
    },
  },
  reducers: {
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    },
    saveDetailSpecial(state, action) {
      return {
        ...state,
        detailSpecial: action.payload.detail,
      };
    },
  },
};

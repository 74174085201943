import { message } from 'antd';
import { ORDERPROOFREAD_NAMESPACE } from '../actions/orderproofread';
import {
  waitSettlementPage,
  waitSettlementDjPage,  // 代驾
  waitSettlementPageInside,
  waitSettlementDetail,
  waitSettlementDjDetail, // 代驾详情 
  reconciliationList,
  batcGenerate,
  batcGenerateDj,
  batchGenerateInside,
  checkReconciliation,
  invalidReconciliation,
  detailReconciliation,
  detailReconciliationDj, // 代驾 已核算明细
  detailReconInside,
  waitSettlementDetailInside
} from '../services/orderproofread';

export default {
  namespace: ORDERPROOFREAD_NAMESPACE,
  state: {
    waitSettlementData: {
      list: [],
      pagination: false,
    },

    // 代驾
    waitSettlementDataDj: {
      list: [],
      pagination: false,
    },

    waitSettlementDataInside: {
      list: [],
      pagination: false,
    },
    reconciliationWaitData: {
      list: [],
      pagination: false,
    },
    reconciliationOverData: {
      list: [],
      pagination: false,
    },
    // 待结算订单详情 城际
    waitSettlementDetail: {},

    waitSettlementDjDetail: {}, //代驾 详情

    // 待结算订单详情 城内
    waitSettlementDetailInside: {},
    // 订单明细 城际
    reconciliationOverOrderData: {
      list: [],
      pagination: false,
    },

    // 订单明细 代驾
    reconciliationOverOrderDataDj: {
      list: [],
      pagination: false,
    },

    // 订单明细 城内
    reconOverOrderDataInside: {
      list: [],
      pagination: false,
    }
  },
  effects: {
    *fetchWaitSettlementPage({ payload }, { call, put }) {
      const response = yield call(waitSettlementPage, payload);
      if (response.success) {
        yield put({
          type: 'saveWaitSettlementList',
          payload: {
            waitSettlementList: {
              list: response.data.records,
              pagination: {
                total: response.data.total,
                current: response.data.current,
                pageSize: response.data.size,
              },
            }
          },
        });
      }
    },
    // 代驾
    *fetchWaitSettlementDjPage({ payload }, { call, put }) {
      const response = yield call(waitSettlementDjPage, payload);
      if (response.success) {
        yield put({
          type: 'saveWaitSettlementListDj',
          payload: {
            waitSettlementList: {
              list: response.data.records,
              pagination: {
                total: response.data.total,
                current: response.data.current,
                pageSize: response.data.size,
              },
            }
          },
        });
      }
    },
    *fetchWaitSettlementPageInside({ payload }, { call, put }) {
      const response = yield call(waitSettlementPageInside, payload);
      if (response.success) {
        yield put({
          type: 'saveWaitSettlementListInside',
          payload: {
            waitSettlementList: {
              list: response.data.records,
              pagination: {
                total: response.data.total,
                current: response.data.current,
                pageSize: response.data.size,
              },
            }
          },
        });
      }
    },
    *fetchWaitSettlementDetail({ payload }, { call, put }) {
      const response = yield call(waitSettlementDetail, payload);
      if (response.success) {
        yield put({
          type: 'saveWaitSettlementDetail',
          payload: {
            waitSettlementDetail: response.data,
          },
        });
      }
    },
    // 代驾详情
    *fetchWaitSettlementDjDetail({ payload }, { call, put }) {
      const response = yield call(waitSettlementDjDetail, payload);
      if (response.success) {
        yield put({
          type: 'saveWaitSettlementDjDetail',
          payload: {
            waitSettlementDetail: response.data,
          },
        });
      }
    },
    *fetchWaitSettlementDetailInside({ payload }, { call, put }) {
      const response = yield call(waitSettlementDetailInside, payload);
      if (response.success) {
        yield put({
          type: 'saveWaitSettlementDetailInside',
          payload: {
            waitSettlementDetail: response.data,
          },
        });
      }
    },
    *clearWaitSettlementDetail({ payload }, { put }) {
      yield put({
        type: 'removeWaitSettlementDetail',
        payload: { payload },
      });
    },
    *fetchReconciliationList({ payload }, { call, put }) {
      const { settStatus } = payload
      const response = yield call(reconciliationList, payload);
      if (response.success) {

        const reconciliationList = {
          list: response.data.records,
          pagination: {
            total: response.data.total,
            current: response.data.current,
            pageSize: response.data.size,
          },
        }

        if (settStatus === 0) {
          yield put({
            type: 'saveReconciliationWaitList',
            payload: { reconciliationList },
          });
        } else {
          yield put({
            type: 'saveReconciliationOverList',
            payload: { reconciliationList },
          });
        }
      }
    },
    *generateBatc({ payload }, { call }) {
      const {
        data,
        completed,
      } = payload;
      const response = yield call(batcGenerate, data);
      if (response.success) {
        message.success(response.msg)
        completed(true);
      } else {
        message.warning(response.msg || '未知错误')
        completed(false)
      }
    },

    // 代驾 批量生成核账单
    *generateBatcDj({ payload }, { call }) {
      const {
        data,
        completed,
      } = payload;
      const response = yield call(batcGenerateDj, data);
      if (response.success) {
        message.success(response.msg)
        completed(true);
        // setTimeout(() => {
        //   window.location.reload()
        // }, 600)
      } else {
        message.warning(response.msg || '未知错误')
        completed(false)
      }
    },

    *generateBatchInside({ payload }, { call }) {
      const {
        data,
        completed,
      } = payload;
      const response = yield call(batchGenerateInside, data);
      if (response.success) {
        message.success(response.msg)
        completed(true);
      } else {
        message.warning(response.msg || '未知错误')
        completed(false)
      }
    },
    *checkReconciliation({ payload }, { call }) {
      const {
        data,
        completed,
      } = payload;
      const response = yield call(checkReconciliation, data);
      if (response.success) {
        message.success(response.msg)
        completed(true);
      } else {
        message.warning(response.msg || '未知错误')
        completed(false)
      }
    },
    *invalidReconciliation({ payload }, { call }) {
      const {
        data,
        completed,
      } = payload;
      const response = yield call(invalidReconciliation, data);
      if (response.success) {
        message.success(response.msg)
        completed(true);
      } else {
        message.warning(response.msg || '未知错误')
        completed(false)
      }
    },
    *fetchOrderDetailReconciliationPage({ payload }, { call, put }) {
      const response = yield call(detailReconciliation, payload);
      if (response.success) {
        yield put({
          type: 'saveOrderRecords',
          payload: {
            reconciliationOverOrderData: {
              list: response.data.records,
              pagination: {
                total: response.data.total,
                current: response.data.current,
                pageSize: response.data.size,
              },
            }
          },
        });
      }
    },

    // 代驾 已核算明细
    *fetchOrderDetailReconciliationPageDj({ payload }, { call, put }) {
      const response = yield call(detailReconciliationDj, payload);
      if (response.success) {
        yield put({
          type: 'saveOrderRecordsDj',
          payload: {
            reconciliationOverOrderData: {
              list: response.data.records,
              pagination: {
                total: response.data.total,
                current: response.data.current,
                pageSize: response.data.size,
              },
            }
          },
        });
      }
    },

    *orderDetailReconPageInside({ payload }, { call, put }) {
      const response = yield call(detailReconInside, payload);
      if (response.success) {
        yield put({
          type: 'saveReconOverOrderDataInside',
          payload: {
            orderData: {
              list: response.data.records,
              pagination: {
                total: response.data.total,
                current: response.data.current,
                pageSize: response.data.size,
              },
            }
          },
        });
      }
    },
  },
  reducers: {
    saveWaitSettlementList(state, action) {
      return {
        ...state,
        waitSettlementData: action.payload.waitSettlementList,
      }
    },
    // 代驾
    saveWaitSettlementListDj(state, action) {
      return {
        ...state,
        waitSettlementDataDj: action.payload.waitSettlementList,
      }
    },
    saveWaitSettlementListInside(state, action) {
      return {
        ...state,
        waitSettlementDataInside: action.payload.waitSettlementList,
      }
    },
    saveWaitSettlementDetail(state, action) {
      return {
        ...state,
        waitSettlementDetail: action.payload.waitSettlementDetail,
      }
    },
    // 代驾 详情
    saveWaitSettlementDjDetail(state, action) {
      return {
        ...state,
        waitSettlementDjDetail: action.payload.waitSettlementDetail,
      }
    },
    saveWaitSettlementDetailInside(state, action) {
      return {
        ...state,
        waitSettlementDetailInside: action.payload.waitSettlementDetail,
      }
    },
    saveReconciliationWaitList(state, action) {
      return {
        ...state,
        reconciliationWaitData: action.payload.reconciliationList,
      }
    },
    saveReconciliationOverList(state, action) {
      return {
        ...state,
        reconciliationOverData: action.payload.reconciliationList,
      }
    },
    removeWaitSettlementDetail(state) {
      return {
        ...state,
        waitSettlementDetail: {},
      }
    },
    saveOrderRecords(state, action) {
      return {
        ...state,
        reconciliationOverOrderData: action.payload.reconciliationOverOrderData,
      }
    },
    // 代驾已核算明细
    saveOrderRecordsDj(state, action) {
      return {
        ...state,
        reconciliationOverOrderDataDj: action.payload.reconciliationOverOrderData,
      }
    },
    saveReconOverOrderDataInside(state, action) {
      return {
        ...state,
        reconOverOrderDataInside: action.payload.orderData,
      }
    }
  },
};

import request from '../utils/request';

// 城际订单退款记录分页
export async function list(params) {
  return request('/api/bmkp-backend/cj/order/refund/page', {
    method: 'POST',
    body: params,
  })
}

// 城际订单退款记录详情
export async function detail({ refundNo }) {
  return request(`/api/bmkp-backend/cj/order/refund/detail/${refundNo}`);
}

import { message, notification} from 'antd';
import router from 'umi/router';
import { ADJUSTMENT_ACCOUNT_NAMESPACE } from '../actions/adjustmentaccount';
import {
  list,
  submit,
  detail,
  cancel,
  pass,
  reject,
} from '../services/adjustmentaccount';

export default {
  namespace: ADJUSTMENT_ACCOUNT_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: {}
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
        callback(response.data)
      }
    },
    *clearDetail({ payload }, { put }) {
      yield put({
        type: 'removeDetail',
        payload: { payload },
      });
    },
    *reject({ payload, callback }, { call }) {
      const response = yield call(reject, payload);
      if (response.success) {
        message.success(response.msg);
        callback()
      } else {
        message.error(response.msg);
      }
    },
    *cancel({ payload, callback }, { call }) {
      const response = yield call(cancel, payload);
      if (response.success) {
        message.success(response.msg);
        callback(true)
      } else {
        message.error(response.msg);
        callback(false)
      }
    },
    *pass({ payload, callback }, { call }) {
      const response = yield call(pass, payload);
      if (response.success) {
        message.success(response.msg);
        callback()
      } else {
        message.error(response.msg);
      }
    },
    *submit({ payload, callback }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('提交成功');
        callback()
      } else {
        message.error(response.msg);
        notification.error({
          message: '错误提示',
          description: response.msg
        })
      }
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    }
  },
};

export const CARMODEL_NAMESPACE = 'carmodel';

export function CARMODEL_LIST(payload) {
  return {
    type: `${CARMODEL_NAMESPACE}/fetchList`,
    payload,
  };
}

export function CARMODEL_PAGE(payload) {
  return {
    type: `${CARMODEL_NAMESPACE}/fetchPage`,
    payload,
  };
}

export function CARMODEL_DETAIL(id) {
  return {
    type: `${CARMODEL_NAMESPACE}/fetchDetail`,
    payload: { id },
  };
}

export function CARMODEL_CLEAR_DETAIL() {
  return {
    type: `${CARMODEL_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function CARMODEL_SUBMIT(payload) {
  return {
    type: `${CARMODEL_NAMESPACE}/submit`,
    payload,
  };
}

export function CARMODEL_REMOVE(payload) {
  return {
    type: `${CARMODEL_NAMESPACE}/remove`,
    payload,
  };
}

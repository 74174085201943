
import func from '../utils/Func';
import request from '../utils/request';

export async function list(params) {
  return request('/api/bmkp-backend/blade-order/order/page', {
    method: 'POST',
    body: params,
  });
}

// 创建订单
export async function submit(params) {
  return request('/api/bmkp-backend/blade-order/order/create', {
    method: 'POST',
    body: params,
  });
}

// 计算订单价格
export async function calcPrice(params) {
  return request('/api/bmkp-backend/blade-order/order/calculate', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request('/api/bmkp-backend/blade-order/order/detail', {
    method: 'POST',
    body: params,
  });
}

// 未使用
export async function remove(params) {
  return request('/api/bmkp-backend/blade-order/order/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}

// 取消订单
export async function cancel(params) {
  return request('/api/bmkp-backend/blade-order/order/cancel', {
    method: 'POST',
    body: params
  });
}

// 订单改价详情
export async function queryUpdateOrderPrice(params) {
  return request('/api/bmkp-backend/order/update/price/query', {
    method: 'POST',
    body: params
  });
}

// 订单改价保存
export async function saveUpdateOrderPrice(params) {
  return request('/api/bmkp-backend/order/update/price/save', {
    method: 'POST',
    body: params
  });
}

// 城际订单退款查询
export async function queryRefundOrder({ orderNo }) {
  return request(`/api/bmkp-backend/cj/order/refund/query?orderNo=${orderNo}`, {
    method: 'POST'
  });
}

// 城际订单退款申请
export async function applyRefundOrder(params) {
  return request('/api/bmkp-backend/cj/order/refund/apply', {
    method: 'POST',
    body: params
  });
}

// 关闭订单
export async function closeOrder(params) {
  return request('/api/bmkp-backend/cj/order/refund/close', {
    method: 'POST',
    body: params
  });
}
// import { stringify } from 'qs';
// import func from '../utils/Func';
import request from '../utils/request';

export async function list(params) {
  return request('/api/bmkp-backend/dzfpApply/page', {
    method: 'POST',
    body: params,
  });
}

// 未使用
// export async function submit(params) {
//   return request('/api/bmkp-backend/blade-order/order/submit', {
//     method: 'POST',
//     body: params,
//   });
// }

export async function detail(params) {
  return request('/api/bmkp-backend/dzfpApply/detail', {
    method: 'POST',
    body: params,
  });
}

// 发送到邮箱
export async function sendMail(params) {
  return request('/api/bmkp-backend/dzfpApply/sendMail', {
    method: 'POST',
    body: params,
  })
}

// 未使用
// export async function remove(params) {
//   return request('/api/bmkp-backend/blade-order/order/remove', {
//     method: 'POST',
//     body: func.toFormData(params),
//   });
// }

// export async function logList(params) {
//   return request(`/api/bmkp-backend/dzfpLog/dzfplog/list?${stringify(params)}`);
// }

// export async function logDetail(params) {
//   return request(`/api/bmkp-backend/dzfpLog/dzfplog/detail?${stringify(params)}`);
// }

// export async function orderList(params) {
//   return request(`/api/bmkp-backend/dzfpDetail/dzfpdetail/page?${stringify(params)}`);
// }

// export async function orderDetail(params) {
//   return request(`/api/bmkp-backend/dzfpDetail/dzfpdetail/detail?${stringify(params)}`);
// }
export const INSIDEUPDATEFEE_NAMESPACE = 'insideupdatefee';

export function INSIDEUPDATEFEE_LIST(payload) {
  return {
    type: `${INSIDEUPDATEFEE_NAMESPACE}/fetchList`,
    payload,
  };
}

export function INSIDEUPDATEFEE_DETAIL(orderNo) {
  return {
    type: `${INSIDEUPDATEFEE_NAMESPACE}/fetchDetail`,
    payload: { orderNo },
  };
}

export function INSIDEUPDATEFEE_CLEAR_DETAIL() {
  return {
    type: `${INSIDEUPDATEFEE_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

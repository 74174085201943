import dva from 'dva';
import { Component } from 'react';
import createLoading from 'dva-loading';
import history from '@tmp/history';

let app = null;

export function _onCreate() {
  const plugins = require('umi/_runtimePlugin');
  const runtimeDva = plugins.mergeConfig('dva');
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    ...(window.g_useSSR ? { initialState: window.g_initialData } : {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach(plugin => {
    app.use(plugin);
  });
  
  app.model({ namespace: 'activity', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/activity.js').default) });
app.model({ namespace: 'activitybill', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/activitybill.js').default) });
app.model({ namespace: 'activitypreview', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/activitypreview.js').default) });
app.model({ namespace: 'adjustmentaccount', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/adjustmentaccount.js').default) });
app.model({ namespace: 'aftersales', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/aftersales.js').default) });
app.model({ namespace: 'appversion', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/appversion.js').default) });
app.model({ namespace: 'assetcar', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/assetcar.js').default) });
app.model({ namespace: 'assetdriverapply', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/assetdriverapply.js').default) });
app.model({ namespace: 'attach', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/attach.js').default) });
app.model({ namespace: 'autoreceive', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/autoreceive.js').default) });
app.model({ namespace: 'billingconfig', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/billingconfig.js').default) });
app.model({ namespace: 'brandAndSeries', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/brandAndSeries.js').default) });
app.model({ namespace: 'cancelrule', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/cancelrule.js').default) });
app.model({ namespace: 'capacityagent', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/capacityagent.js').default) });
app.model({ namespace: 'capacitycommissionconfig', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/capacitycommissionconfig.js').default) });
app.model({ namespace: 'capacitymap', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/capacitymap.js').default) });
app.model({ namespace: 'capacitysupplier', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/capacitysupplier.js').default) });
app.model({ namespace: 'capacitysupplieroperateconfig', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/capacitysupplieroperateconfig.js').default) });
app.model({ namespace: 'carbrand', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/carbrand.js').default) });
app.model({ namespace: 'carmodel', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/carmodel.js').default) });
app.model({ namespace: 'carseries', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/carseries.js').default) });
app.model({ namespace: 'channel', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/channel.js').default) });
app.model({ namespace: 'charteredcar', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/charteredcar.js').default) });
app.model({ namespace: 'citymanage', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/citymanage.js').default) });
app.model({ namespace: 'client', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/client.js').default) });
app.model({ namespace: 'code', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/code.js').default) });
app.model({ namespace: 'commondict', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/commondict.js').default) });
app.model({ namespace: 'commontab', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/commontab.js').default) });
app.model({ namespace: 'coupon', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/coupon.js').default) });
app.model({ namespace: 'customerservice', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/customerservice.js').default) });
app.model({ namespace: 'dashboard', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/dashboard.js').default) });
app.model({ namespace: 'datasource', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/datasource.js').default) });
app.model({ namespace: 'dept', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/dept.js').default) });
app.model({ namespace: 'dict', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/dict.js').default) });
app.model({ namespace: 'dictbiz', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/dictbiz.js').default) });
app.model({ namespace: 'dispatcher', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/dispatcher.js').default) });
app.model({ namespace: 'dispatcherandline', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/dispatcherandline.js').default) });
app.model({ namespace: 'dispatchrule', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/dispatchrule.js').default) });
app.model({ namespace: 'districtdispatch', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/districtdispatch.js').default) });
app.model({ namespace: 'djadjustment', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/dj/djadjustment.ts').default) });
app.model({ namespace: 'djdriver', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/dj/djdriver.ts').default) });
app.model({ namespace: 'djorder', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/dj/djorder.ts').default) });
app.model({ namespace: 'djplace', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/dj/djplace.ts').default) });
app.model({ namespace: 'djpricerule', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/dj/djpricerule.ts').default) });
app.model({ namespace: 'driverbalance', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/driverbalance.js').default) });
app.model({ namespace: 'driverbase', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/driverbase.js').default) });
app.model({ namespace: 'driverbinding', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/driverbinding.js').default) });
app.model({ namespace: 'drivercertificates', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/drivercertificates.js').default) });
app.model({ namespace: 'driverclue', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/driverclue.js').default) });
app.model({ namespace: 'drivercontract', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/drivercontract.js').default) });
app.model({ namespace: 'driverfeedback', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/driverfeedback.js').default) });
app.model({ namespace: 'driverhailinglicense', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/driverhailinglicense.js').default) });
app.model({ namespace: 'driverinside', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/driverinside.js').default) });
app.model({ namespace: 'driverlicense', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/driverlicense.js').default) });
app.model({ namespace: 'driveroperation', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/driveroperation.js').default) });
app.model({ namespace: 'driverrecruitconfig', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/driverrecruitconfig.js').default) });
app.model({ namespace: 'finance', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/finance.js').default) });
app.model({ namespace: 'global', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/global.js').default) });
app.model({ namespace: 'insideorder', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/insideorder.js').default) });
app.model({ namespace: 'insideordertransaction', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/insideordertransaction.js').default) });
app.model({ namespace: 'insiderefundapply', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/insiderefundapply.js').default) });
app.model({ namespace: 'insideupdatefee', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/insideupdatefee.js').default) });
app.model({ namespace: 'intercitycommonconfig', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/intercitycommonconfig.js').default) });
app.model({ namespace: 'intercityrefundapply', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/intercityrefundapply.js').default) });
app.model({ namespace: 'inviteactivity', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/inviteactivity.js').default) });
app.model({ namespace: 'invitepassengers', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/invitepassengers.js').default) });
app.model({ namespace: 'invoice', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/invoice.js').default) });
app.model({ namespace: 'jiguangconfig', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/jiguangconfig.js').default) });
app.model({ namespace: 'lineconfig', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/lineconfig.js').default) });
app.model({ namespace: 'linedispatchrule', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/linedispatchrule.js').default) });
app.model({ namespace: 'linegrabsrule', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/linegrabsrule.js').default) });
app.model({ namespace: 'linepricerule', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/linepricerule.js').default) });
app.model({ namespace: 'lineservice', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/lineservice.js').default) });
app.model({ namespace: 'lineservicescope', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/lineservicescope.js').default) });
app.model({ namespace: 'log', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/log.js').default) });
app.model({ namespace: 'login', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/login.js').default) });
app.model({ namespace: 'menu', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/menu.js').default) });
app.model({ namespace: 'messageDrivers', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/messageDrivers.js').default) });
app.model({ namespace: 'messagemobile', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/messagemobile.js').default) });
app.model({ namespace: 'notice', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/notice.js').default) });
app.model({ namespace: 'order', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/order.js').default) });
app.model({ namespace: 'orderdispatch', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/orderdispatch.js').default) });
app.model({ namespace: 'orderfeedback', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/orderfeedback.js').default) });
app.model({ namespace: 'orderfeedbacktype', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/orderfeedbacktype.js').default) });
app.model({ namespace: 'orderincome', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/orderincome.js').default) });
app.model({ namespace: 'orderproofread', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/orderproofread.js').default) });
app.model({ namespace: 'ordertransaction', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/ordertransaction.js').default) });
app.model({ namespace: 'ordertrave', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/ordertrave.js').default) });
app.model({ namespace: 'oss', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/oss.js').default) });
app.model({ namespace: 'param', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/param.js').default) });
app.model({ namespace: 'passenger', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/passenger.js').default) });
app.model({ namespace: 'passengercancel', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/passengercancel.js').default) });
app.model({ namespace: 'passengerfeedback', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/passengerfeedback.js').default) });
app.model({ namespace: 'passengeroperation', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/passengeroperation.js').default) });
app.model({ namespace: 'passengerrules', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/passengerrules.js').default) });
app.model({ namespace: 'post', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/post.js').default) });
app.model({ namespace: 'project', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/project.js').default) });
app.model({ namespace: 'report', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/report.js').default) });
app.model({ namespace: 'role', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/role.js').default) });
app.model({ namespace: 'schedule', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/schedule.js').default) });
app.model({ namespace: 'servicephone', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/servicephone.js').default) });
app.model({ namespace: 'setting', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/setting.js').default) });
app.model({ namespace: 'sms', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/sms.js').default) });
app.model({ namespace: 'system', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/system.js').default) });
app.model({ namespace: 'tenant', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/tenant.js').default) });
app.model({ namespace: 'topmenu', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/topmenu.js').default) });
app.model({ namespace: 'travelrecord', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/travelrecord.js').default) });
app.model({ namespace: 'user', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/user.js').default) });
app.model({ namespace: 'vehicletype', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/vehicletype.js').default) });
app.model({ namespace: 'voiceconfig', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/voiceconfig.js').default) });
app.model({ namespace: 'withdrawal', ...(require('/Volumes/T7SSD/project/网约车/sanjiang-backend-master/src/models/withdrawal.js').default) });
  return app;
}

export function getApp() {
  return app;
}

export class _DvaContainer extends Component {
  render() {
    const app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}

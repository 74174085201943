export const PLACE_NAMESPACE = 'djplace';

/**
 *
 * @param payload
 * 返佣列表
 * @returns
 */
export function PLACE_LIST(payload) {
  return {
    type: `${PLACE_NAMESPACE}/fetchList`,
    payload,
  };
}

export function PLACE_ADD(payload, callback?) {
  return {
    type: `${PLACE_NAMESPACE}/add`,
    payload,
    callback,
  };
}

export function PLACE_UPDATE(payload, callback?) {
  return {
    type: `${PLACE_NAMESPACE}/update`,
    payload,
    callback,
  };
}

export function PLACE_DEL(payload, callback?) {
  return {
    type: `${PLACE_NAMESPACE}/del`,
    payload,
    callback,
  };
}

export function PLACE_DETAIL(payload) {
  return {
    type: `${PLACE_NAMESPACE}/detail`,
    payload,
  };
}

export function PLACE_DETAIL_CLEARN() {
  return {
    type: `${PLACE_NAMESPACE}/detailClearn`
  };
}

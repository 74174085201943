export const ORDERFEEDBACK_NAMESPACE = 'orderFeedback';

export function ORDERFEEDBACK_LIST(payload) {
  return {
    type: `${ORDERFEEDBACK_NAMESPACE}/fetchList`,
    payload,
  };
}

export function ORDERFEEDBACK_DETAIL(payload) {
  return {
    type: `${ORDERFEEDBACK_NAMESPACE}/detail`,
    payload,
  };
}

export function ORDERFEEDBACK_DEAL(payload, callback) {
  return {
    type: `${ORDERFEEDBACK_NAMESPACE}/deal`,
    payload,
    callback
  };
}

export const FINANCE_NAMESPACE = 'finance';

export function FINANCE_BALANCE_LIST(payload) {
  return {
    type: `${FINANCE_NAMESPACE}/fetchBalanceList`,
    payload,
  };
}

export function FINANCE_WALLET_LIST(payload) {
  return {
    type: `${FINANCE_NAMESPACE}/fetchWalletList`,
    payload,
  };
}

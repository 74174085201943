export const ACTIVITY_NAMESPACE = 'activity';

export function ACTIVITY_LIST(payload) {
  return {
    type: `${ACTIVITY_NAMESPACE}/fetchList`,
    payload,
  };
}
export function ACTIVITY_DETAIL(id, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/fetchDetail`,
    payload: { id },
    callback
  };
}
export function ACTIVITY_CLEAR_DETAIL() {
  return {
    type: `${ACTIVITY_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function ACTIVITY_PASS(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/pass`,
    payload,
    callback
  };
}
export function ACTIVITY_REJECT(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/reject`,
    payload,
    callback
  };
}
export function ACTIVITY_RE_APPLY(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/reApply`,
    payload,
    callback
  };
}
export function ACTIVITY_SUBMIT(payload) {
  return {
    type: `${ACTIVITY_NAMESPACE}/submit`,
    payload
  };
}
export function ACTIVITY_UPDATE(payload) {
  return {
    type: `${ACTIVITY_NAMESPACE}/update`,
    payload
  };
}

export function ACTIVITY_START(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/start`,
    payload,
    callback
  };
}
export function ACTIVITY_STOP(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/stop`,
    payload,
    callback
  };
}

export function ACTIVITY_REMOVE(payload) {
  return {
    type: `${ACTIVITY_NAMESPACE}/remove`,
    payload,
  };
}

// 城内
export function ACTIVITY_LIST_INSIDE(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/fetchListInside`,
    payload,
    callback
  };
}
export function ACTIVITY_SUBMIT_INSIDE(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/submitInside`,
    payload,
    callback
  };
}
export function ACTIVITY_UPDATE_INSIDE(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/updateInside`,
    payload,
    callback
  };
}
export function ACTIVITY_OPEN_INSIDE(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/openInside`,
    payload,
    callback
  };
}
export function ACTIVITY_CLOSE_INSIDE(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/closeInside`,
    payload,
    callback
  };
}
export function ACTIVITY_REMOVE_INSIDE(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/removeInside`,
    payload,
    callback
  };
}

export function ACTIVITY_DETAIL_INSIDE(id, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/fetchDetailInside`,
    payload: { id },
    callback
  };
}

export function ACTIVITY_CLEAR_DETAIL_INSIDE() {
  return {
    type: `${ACTIVITY_NAMESPACE}/clearDetailInside`,
    payload: {},
  };
}

export function ACTIVITY_DRIVER_AWARD_BILL_INSIDE(payload) {
  return {
    type: `${ACTIVITY_NAMESPACE}/fetchDriverAwardBillInside`,
    payload
  };
}

// 城际乘客活动详情
export function ACTIVITY_DETAIL_PASSENGER(id, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/fetchDetailPassenger`,
    payload: { id },
    callback
  };
}
// 城际乘客活动详情清除
export function ACTIVITY_CLEAR_DETAIL_PASSENGER() {
  return {
    type: `${ACTIVITY_NAMESPACE}/clearDetailPassenger`,
    payload: {},
  };
}

// 城际活动提交（乘客）
export function ACTIVITY_SUBMIT_PASSENGER(payload) {
  return {
    type: `${ACTIVITY_NAMESPACE}/submitPassenger`,
    payload
  };
}

// 城际乘客活动列表
export function ACTIVITY_LIST_PASSENGER(payload) {
  return {
    type: `${ACTIVITY_NAMESPACE}/fetchListPassenger`,
    payload,
  };
}
// 城际活动更新（乘客）
export function ACTIVITY_UPDATE_PASSENGER(payload) {
  return {
    type: `${ACTIVITY_NAMESPACE}/updatePassenger`,
    payload
  };
}
// 城际活动优惠券删除（乘客）
export function ACTIVITY_LINK_COUPON_DELETE_PASSENGER(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/deleteLinkCouponPassenger`,
    payload,
    callback
  };
}
export function ACTIVITY_OPEN_PASSENGER(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/openPassenger`,
    payload,
    callback
  };
}
export function ACTIVITY_CLOSE_PASSENGER(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/closePassenger`,
    payload,
    callback
  };
}
export function ACTIVITY_REMOVE_PASSENGER(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/removePassenger`,
    payload,
    callback
  };
}

export function ACTIVITY_STATISTICS_PASSENGER(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/statisticsPassenger`,
    payload,
    callback
  };
}

// 城际营销活动账单
export function ACTIVITY_PASSENGE_AWARD_BILL_INSIDE(payload) {
  return {
    type: `${ACTIVITY_NAMESPACE}/activitynewcouponbill`,
    payload
  };
}
// 城内优惠券账单
export function ACTIVITY_MARKETING_COUPON_BILL_INSIDE(payload) {
  return {
    type: `${ACTIVITY_NAMESPACE}/fetchDriverAwardBillInside`,
    payload
  };
}

// 城内乘客邀请列表
export function ACTIVITY_INVITATION_LIST(payload) {
  return {
    type: `${ACTIVITY_NAMESPACE}/fetchDriverAwardBillInside`,
    payload
  };
}

// 城内司机邀请活动-分页
export function ACTIVITY_DRIVER_INVITATION_LIST(payload) {
  return {
    type: `${ACTIVITY_NAMESPACE}/fetchDriverInvitationList`,
    payload
  };
}

// 城内司机邀请活动-详情
export function ACTIVITY_DRIVER_INVITATION_DETAIL(payload) {
  return {
    type: `${ACTIVITY_NAMESPACE}/fetchDriverInvitationDetail`,
    payload
  };
}

// 城内司机邀请活动-新增
export function ACTIVITY_DRIVER_INVITATION_ADD(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/driverInvitationAdd`,
    payload,
    callback
  };
}

// 城内司机邀请活动-修改
export function ACTIVITY_DRIVER_INVITATION_UPDATE(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/driverInvitationUpdate`,
    payload,
    callback
  };
}

// 城内司机邀请活动-删除
export function ACTIVITY_DRIVER_INVITATION_DELETE(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/driverInvitationDelete`,
    payload,
    callback
  };
}

// 城内司机邀请活动-开启
export function ACTIVITY_DRIVER_INVITATION_OPEN(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/driverInvitationOpen`,
    payload,
    callback
  };
}

// 城内司机邀请活动-关闭
export function ACTIVITY_DRIVER_INVITATION_CLOSE(payload, callback) {
  return {
    type: `${ACTIVITY_NAMESPACE}/driverInvitationClose`,
    payload,
    callback
  };
}

export function ACTIVITY_DRIVER_INVITATION_CLEAR_DETAIL() {
  return {
    type: `${ACTIVITY_NAMESPACE}/clearDriverInvitationDetail`,
    payload: {}
  };
}
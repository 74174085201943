export const ACTIVITY_BILL_NAMESPACE = 'activitybill';

export function ACTIVITY_DRIVER_AWARD_BILL_INSIDE(payload) {
  return {
    type: `${ACTIVITY_BILL_NAMESPACE}/fetchDriverAwardBillInside`,
    payload
  };
}

export function INTER_THIRD_ORDER_LIST(payload) {
  return {
    type: `${ACTIVITY_BILL_NAMESPACE}/fetchInterThirdOrderList`,
    payload
  };
}

export function ACTIVITY_DRIVER_INVITE_BILL_REWARDS(payload) {
  return {
    type: `${ACTIVITY_BILL_NAMESPACE}/fetchDriverInviteBillRewards`,
    payload
  };
}

export function ACTIVITY_DRIVER_INVITE_BILL_COUPON(payload) {
  return {
    type: `${ACTIVITY_BILL_NAMESPACE}/fetchDriverInviteBillCoupon`,
    payload
  };
}

export function ACTIVITY_PASSENGERS_INVITE_BILL_INSIDE(payload) {
  return {
    type: `${ACTIVITY_BILL_NAMESPACE}/fetchPassengersInviteBillInside`,
    payload
  };
}

export const INVOICE_NAMESPACE = 'invoice';

export function INVOICE_LIST(payload) {
  return {
    type: `${INVOICE_NAMESPACE}/fetchList`,
    payload,
  };
}

export function INVOICE_DETAIL(id) {
  return {
    type: `${INVOICE_NAMESPACE}/fetchDetail`,
    payload: { id },
  };
}

export function INVOICE_SEND_MAIL(payload) {
  return {
    type: `${INVOICE_NAMESPACE}/sendMail`,
    payload
  };
}

// export function INVOICE_REMOVE(payload) {
//   return {
//     type: `${INVOICE_NAMESPACE}/remove`,
//     payload,
//   };
// }

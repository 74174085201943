export const DRIVERBALANCE_NAMESPACE = 'driverbalance';

export function DRIVERBALANCE_LIST(payload) {
  return {
    type: `${DRIVERBALANCE_NAMESPACE}/fetchList`,
    payload,
  };
}

export function DRIVERBALANCE_DETAIL(id) {
  return {
    type: `${DRIVERBALANCE_NAMESPACE}/fetchDetail`,
    payload: { id },
  };
}

export function DRIVERBALANCE_CLEAR_DETAIL() {
  return {
    type: `${DRIVERBALANCE_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function DRIVERBALANCE_SUBMIT(payload) {
  return {
    type: `${DRIVERBALANCE_NAMESPACE}/submit`,
    payload,
  };
}

export function DRIVERBALANCE_REMOVE(payload) {
  return {
    type: `${DRIVERBALANCE_NAMESPACE}/remove`,
    payload,
  };
}

export function DRIVERBALANCE_UNFREEZE(payload, callback) {
  return {
    type: `${DRIVERBALANCE_NAMESPACE}/unfreeze`,
    payload,
    callback
  };
}

export function DRIVERBALANCE_FREEZE(payload, callback) {
  return {
    type: `${DRIVERBALANCE_NAMESPACE}/freeze`,
    payload,
    callback
  };
}



export const INTERCITY_FUNDAPPLY_NAMESPACE = 'intercityrefundapply';

export function INTERCITY_FUNDAPPLY_LIST(payload) {
  return {
    type: `${INTERCITY_FUNDAPPLY_NAMESPACE}/fetchList`,
    payload,
  };
}

export function INTERCITY_FUNDAPPLY_DETAIL(refundNo, callback) {
  return {
    type: `${INTERCITY_FUNDAPPLY_NAMESPACE}/fetchDetail`,
    payload: { refundNo },
    callback
  };
}

export function INTERCITY_FUNDAPPLY_CLEAR_DETAIL() {
  return {
    type: `${INTERCITY_FUNDAPPLY_NAMESPACE}/clearDetail`,
    payload: {},
  };
}


import request from '../utils/request';

export async function query() {
  return request('/api/bmkp-wyc/wyc/system/config/query', {
    method: 'POST',
    body: {},
  });
}

export async function save(params) {
  return request('/api/bmkp-wyc/wyc/system/config/save', {
    method: 'POST',
    body: params,
  });
}

import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

export async function list(params) {
  return request(`/api/bmkp-backend/capacity/commissionconfig/page?${stringify(params)}`);
}

export async function submit(params) {
  return request('/api/bmkp-backend/capacity/commissionconfig/submit', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request(`/api/bmkp-backend/capacity/commissionconfig/detail?${stringify(params)}`);
}

export async function remove(params) {
  return request('/api/bmkp-backend/capacity/commissionconfig/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}

// 获取司乘取消结算配置
export async function orderCancelRatio() {
  return request(`/api/bmkp-backend/capacity/commissionconfig/orderCancelRatio`);
}

// 司乘取消结算配置
export async function setOrderCancelRatio(params) {
  return request('/api/bmkp-backend/capacity/commissionconfig/setOrderCancelRatio', {
    method: 'POST',
    body: params,
  });
}

// 批量关联运力公司
export async function batchBindEnterprise(params) {
  return request('/api/bmkp-backend/capacity/commissionconfig/batchBindEnterprise', {
    method: 'POST',
    body: params,
  });
}

// 批量关联运力公司代驾
export async function djBatchBindEnterprise(params) {
  return request('/api/bmkp-backend/capacity/supplieroperateconfig/submitDjConfig', {
    method: 'POST',
    body: params,
  });
}

import func from '../utils/Func';
import request from '../utils/request';

export async function list(params) {
  return request('/api/bmkp-backend/blade-trip/trip/page', {
    method: 'POST',
    body: params,
  });
}

// 未使用
export async function submit(params) {
  return request('/api/order/trave/submit', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request('/api/bmkp-backend/blade-trip/trip/detail', {
    method: 'POST',
    body: params,
  });
}


// 未使用
export async function remove(params) {
  return request('/api/order/trave/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}

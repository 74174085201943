import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

export async function list(params) {
  // return request(`/api/customize/lineconfig/list?${stringify(params)}`);
  return request(`/api/bmkp-backend/customize/line/list?${stringify(params)}`);
}

export async function submit(params) {
  return request('/api/bmkp-backend/customize/operateregionconfig/save', {
    method: 'POST',
    body: params,
  });
}
export async function update(params) {
  return request('/api/bmkp-backend/customize/operateregionconfig/update', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request(`/api/bmkp-backend/customize/operateregionconfig/list?${stringify(params)}`);
}

export async function remove(params) {
  return request('/api/bmkp-backend/customize/operateregionconfig/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}
export async function removeone(params) {
  return request('/api/bmkp-backend/customize/operateregionconfig/removeone', {
    method: 'POST',
    body: func.toFormData(params),
  });
}

/**
 * 专线围栏匹配对列表
 * @param {*} params 
 * @returns 
 */
export async function specialLineFenceMatchList(params) {
  return request('/api/bmkp-backend/customize/linefencelink/list', {
    method: 'POST',
    // body: params,
    body: func.toFormData(params),
  });
}
/**
 * 专线围栏匹配对提交
 * @param {*} params 
 * @returns 
 */
export async function specialLineFenceMatchSubmit(params) {
  return request('/api/bmkp-backend/customize/linefencelink/submit', {
    method: 'POST',
    body: params,
  });
}

export async function specialLineFenceMatchRemoveOne(params) {
  return request('/api/bmkp-backend/customize/linefencelink/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}
import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

export async function submit(params) {
  return request('/api/bmkp-backend/customize/lineservice/submit', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request(`/api/bmkp-backend/customize/lineservice/detail?${stringify(params)}`);
}

export async function serviceVehicle(params) {
  return request(`/api/bmkp-backend/customize/lineservice/getServiceVehicle?${stringify(params)}`);
}

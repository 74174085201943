import request from '../utils/request'
import func from '../utils/Func';

// 列表
export async function page(params) {
  return request('/api/bmkp-wyc/wyc/passenger/cancel/reason/page', {
    method: 'POST',
    body: func.toFormData(params),
  });
}

// 查询
export async function query(params) {
  return request('/api/bmkp-wyc/wyc/system/config/query', {
    method: 'POST',
    body: params,
  });
}

// 添加
export async function add(params) {
  return request('/api/bmkp-wyc/wyc/passenger/cancel/reason/add', {
    method: 'POST',
    body: params,
  });
}

// 保存
export async function save(params) {
  return request('/api/bmkp-wyc/wyc/system/config/save', {
    method: 'POST',
    body: params,
  });
}

// 编辑
export async function update(params) {
  return request('/api/bmkp-wyc/wyc/passenger/cancel/reason/update', {
    method: 'POST',
    body: params,
  });
}

// 删除
export async function close(params) {
  return request('/api/bmkp-wyc/wyc/passenger/cancel/reason/close', {
    method: 'POST',
    body: params,
  });
}

// 移动
export async function move(params) {
  return request('/api/bmkp-wyc/wyc/passenger/cancel/reason/move', {
    method: 'POST',
    body: params,
  });
}
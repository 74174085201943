import { stringify } from 'qs';
// import func from '@/utils/Func';
import request from '@/utils/request';

/**
 *
 * @param params
 * 计价管理
 * @returns
 */
export async function list(params) {
  return request(`/api/bmkp-backend/back/feeconf/page?${stringify(params)}`);
}

export async function detail(params) {
  return request(`/api/bmkp-backend/back/feeconf/detail/id?${stringify(params)}`);
}

export async function add(params) {
  return request('/api/bmkp-backend/back/feeconf/create', {
    method: 'POST',
    body: params,
  });
}

export async function update(params) {
  return request('/api/bmkp-backend/back/feeconf/update', {
    method: 'POST',
    body: params,
  });
}

export async function checkFence(params) {
  return request('/api/bmkp-backend/back/feeconf/checkFenceInArea', {
    method: 'POST',
    body: params,
  });
}

export async function del(params) {
  return request(`/api/bmkp-backend/back/feeconf/delete/id?${stringify(params)}`);
}

import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

export async function submit(params) {
  return request('/api/bmkp-backend/customize/linepricerules/submit', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request(`/api/bmkp-backend/customize/linepricerules/detail?${stringify(params)}`);
}

export async function remove(params) {
  return request('/api/customize/lineconfig/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}

export async function detailSpecial(params) {
  return request(`/api/bmkp-backend/customize/linepricerules/specialDetail?${stringify(params)}`);
}

export async function submitSpecial(params) {
  return request('/api/bmkp-backend/customize/linepricerules/specialSubmit', {
    method: 'POST',
    body: params,
  });
}

import { message } from 'antd';
// import router from 'umi/router';
import { ORDER_NAMESPACE } from '@/actions/dj/djorder';
import { list, getPrice, detail, cancelOrder, changeReply, getNearDriverList, queryOrderList, updatePrice, updateStatus,updatePriceRecord,updateStatusRecord, addOrder, getCityConfig, allCityList } from '@/services/dj/djorder';
import { openList } from '@/services/citymanage';

export default {
  namespace: ORDER_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    orderInfo: null,
    driverList: [],
    cityList: [],
    allCityList: [],
    rebateInfo: null,
    queryOrderList: {
      list: [],
      pagination: false,
    },
    updatePriceRecord:{
      list: [],
      pagination: false,
    },
    updateStatusRecord:{
      list: [],
      pagination: false,
    },
    cityConfig: {}
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *getCityList({ payload, callback }, { call, put }) {
      const res = yield call(openList, payload);
      if (res.success) {
        yield put({
          type: 'saveCityList',
          payload: res.data
        });
      }
    },
    *getPrice({ payload, callback }, { call }) {
      const res = yield call(getPrice, payload);
      callback && callback(res);
    },
    *cancel({ payload, callback }, { call }) {
      const res = yield call(cancelOrder, payload);
      if (res.success) {
        message.success(res.msg);
      } 
      callback && callback(res.success);
    },
    *changeReply({ payload, callback }, { call }) {
      const res = yield call(changeReply, payload);
      if (res.success) {
        message.success(res.msg);
      }
      callback && callback(res.success);
    },
    *detail({ payload, callback }, { call, put }) {
      const res = yield call(detail, payload);
      if (res.success) {
        yield put({
          type: 'saveDetail',
          payload: res.data,
        });
      }
    },
    *getNearDriver({ payload, callback }, { call, put }) {
      const res = yield call(getNearDriverList, payload);
      if (res.success) {
        yield put({
          type: 'saveDriverList',
          payload: res.data,
        });
        callback && callback(res.data)
      }
    },
    *cityConfig({ payload, callback }, { call, put }) {
      const res = yield call(getCityConfig, payload);
      if (res.success) {
        yield put({
          type: 'saveCityConfig',
          payload: res.data,
        });
        callback && callback(res.data)
      }
    },
    *getAllCityList({ payload, callback }, { call, put }) {
      const res = yield call(allCityList, payload);
      if (res.success) {
        yield put({
          type: 'saveAllCityList',
          payload: res.data,
        });
        callback && callback(res.data)
      }
    },
    *detailClear(_, { put }) {
      yield put({
        type: 'saveDetail',
        payload: {},
      });
    },
    *queryOrderList({ payload,callback }, { call, put }) {
      const response = yield call(queryOrderList, payload);
      callback && callback(response);
      if (response.success) {
        yield put({
          type: 'saveQueryOrderList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *updatePrice({ payload, callback }, { call }) {
      const response = yield call(updatePrice, payload);
      callback && callback(response);
    },
    *updateStatus({ payload, callback }, { call }) {
      const response = yield call(updateStatus, payload);
      callback && callback(response);
    },
    *updatePriceRecord({ payload }, { call, put }) {
      const response = yield call(updatePriceRecord, payload);
      if (response.success) {
        yield put({
          type: 'saveUpdatePriceRecord',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *updateStatusRecord({ payload }, { call, put }) {
      const response = yield call(updateStatusRecord, payload);
      if (response.success) {
        yield put({
          type: 'saveUpdateStatusRecord',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *add({ payload, callback }, { call }) {
      const response = yield call(addOrder, payload);
      if (response.success) {
        message.success(response.msg)
      }
      callback && callback(response.success);
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveCityConfig(state, action) {
      return {
        ...state,
        cityConfig: action.payload,
      };
    },
    saveAllCityList(state, action) {
      let {payload} = action
      payload.forEach(item => {
        item.city = item.cityName
        item.cityCode = item.cityCode + '00'
      })
      return {
        ...state,
        allCityList: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        orderInfo: action.payload,
      };
    },
    saveDriverList(state, action) {
      return {
        ...state,
        driverList: action.payload,
      };
    },
    saveCityList(state, action) {
      return {
        ...state,
        cityList: action.payload,
      };
    },
    saveQueryOrderList(state, action) {
      return {
        ...state,
        queryOrderList: action.payload,
      };
    },
    saveUpdatePriceRecord(state, action) {
      return {
        ...state,
        updatePriceRecord: action.payload,
      };
    },
    saveUpdateStatusRecord(state, action) {
      return {
        ...state,
        updateStatusRecord: action.payload,
      };
    },
  },
};

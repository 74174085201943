export const AUTO_NAMESPACE = 'autoReceive';

export function AUTO_RECEIVE_LIST(payload, callback = null) {
  return {
    type: `${AUTO_NAMESPACE}/fetchList`,
    payload,
    callback,
  };
}

export function AUTO_RECEIVE_DRIVER_LIST(payload, callback = null) {
  return {
    type: `${AUTO_NAMESPACE}/fetchDriverList`,
    payload,
    callback,
  };
}

export function AUTO_RECEIVE_BIND(payload, callback = null) {
  return {
    type: `${AUTO_NAMESPACE}/bind`,
    payload,
    callback,
  };
}

export function AUTO_RECEIVE_UNBIND(payload, callback = null) {
  return {
    type: `${AUTO_NAMESPACE}/unbind`,
    payload,
    callback,
  };
}

export const PASSENGERRULES_NAMESPACE = 'passengerrules';

export function PASSENGERRULES_QUERY(payload, callback) {
  return {
    type: `${PASSENGERRULES_NAMESPACE}/query`,
    payload,
    callback
  };
}

export function PASSENGERRULES_SUBMIT(payload, callback) {
  return {
    type: `${PASSENGERRULES_NAMESPACE}/submit`,
    payload,
    callback
  };
}
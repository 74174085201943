import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

export async function list(params) {
  return request('/api/bmkp-backend/capacity/agent/page', {
    method: 'POST',
    body: params,
  })
} 

export async function submit(params) {
  return request('/api/bmkp-backend/capacity/agent/submit', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request(`/api/bmkp-backend/capacity/agent/detail?${stringify(params)}`);
}

export async function stateOpen(params) {
  return request('/api/bmkp-backend/capacity/supplier/enable', {
    method: 'POST',
    body: params,
  });
}

export async function stateClose(params) {
  return request('/api/bmkp-backend/capacity/supplier/disable', {
    method: 'POST',
    body: params,
  });
}

export async function remove(params) {
  return request('/api/bmkp-backend/capacity/agent/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}

// 代理商封号
export async function frozen(params) {
  return request('/api/bmkp-backend/capacity/agent/frozen', {
    method: 'PUT',
    body: func.toFormData(params),
  });
}

// 代理商解封
export async function unfrozen(params) {
  return request('/api/bmkp-backend/capacity/agent/unfrozen', {
    method: 'PUT',
    body: func.toFormData(params),
  });
}


import { message } from 'antd';
import { router } from 'umi';
import { ORDER_NAMESPACE } from '../actions/order';
import { list, submit, detail, remove, cancel, calcPrice, 
  queryUpdateOrderPrice, saveUpdateOrderPrice,
  queryRefundOrder, applyRefundOrder,
  closeOrder
} from '../services/order';

export default {
  namespace: ORDER_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: {},
    updatePriceDetail: {},  // 查询修改订单价格的详情
    refundOrderDetail: {}, // 退款订单的详情
    costDetail: {}
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        if (response.data) {
          yield put({
            type: 'saveList',
            payload: {
              list: response.data.records.map((k, index) => ({ newRowKey: index, ...k })), // newRowKey 为了解决表格记录重复的问题而自定义的字段，具体看model
              pagination: {
                total: response.data.total,
                current: response.data.current,
                pageSize: response.data.size,
              },
            },
          });
        } else {
          yield put({
            type: 'saveList',
            payload: {
              list: [],
              pagination: {
                total: 0,
                current: 1,
                pageSize: 0,
              },
            },
          });
        }
      }
    },
    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
    *clearDetail({ payload }, { put }) {
      yield put({
        type: 'removeDetail',
        payload: { payload },
      });
    },
    *create({ payload }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success(response.msg)
        const { orderNo } = response.data
        router.replace(`/order/record/detail/${orderNo}`)
      } else {
        message.warning(response.msg)
      }
    },
    *calcPrice({ payload }, { put, call }) {
      const response = yield call(calcPrice, payload);
      if (response.success) {
        yield put({
          type: 'saveCalcPriceDetail',
          payload: {
            costDetail: response.data,
          },
        });
      }
    },
    *cancel({ payload, callback }, { call }) {
      const response = yield call(cancel, payload);
      if (response.success) {
        message.success(response.msg)
        callback(true)
      } else {
        message.warning(response.msg)
        callback(false)
      }
    },
    *remove({ payload }, { call }) {
      const {
        data: { keys },
        success,
      } = payload;
      const response = yield call(remove, { ids: keys });
      if (response.success) {
        success();
      }
    },
    *queryUpdateOrderPrice({ payload, callback }, { call, put }) {
      const response = yield call(queryUpdateOrderPrice, payload);
      if (response.success) {
        yield put({
          type: 'saveUpdateOrderPriceDetail',
          payload: {
            detail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
    *saveUpdateOrderPrice({ payload, callback }, { call }) {
      const response = yield call(saveUpdateOrderPrice, payload);
      if (response.success) {
        message.success(response.msg)
        callback(true)
      } else {
        callback(false)
      }
    },
    *queryRefundOrder({ payload, callback }, { call, put }) {
      const response = yield call(queryRefundOrder, payload)
      if (response.success) {
        yield put({
          type: 'setRefundOrderDetail',
          payload: {
            detail: response.data
          },
        })
        callback && callback(response.data)
      }
    },
    *clearRefundOrderDetail({ payload }, { put }) {
      yield put({
        type: 'removeRefundOrderDetail',
        payload: { payload },
      });
    },
    *applyRefundOrder({ payload, callback }, { call }) {
      const response = yield call(applyRefundOrder, payload);
      if (response.success) {
        message.success(response.msg)
        callback(true)
      } else {
        callback(false)
      }
    },
    *closeOrder({ payload, callback }, { call }) {
      const response = yield call(closeOrder, payload);
      if (response.success) {
        message.success(response.msg)
        callback(true)
      } else {
        callback(false)
      }
    }
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    saveCalcPriceDetail(state, action) {
      return {
        ...state,
        costDetail: action.payload.costDetail,
      };
    },
    saveUpdateOrderPriceDetail(state, action) {
      return {
        ...state,
        updatePriceDetail: action.payload.detail
      }
    },
    setRefundOrderDetail(state, action) {
      return {
        ...state,
        refundOrderDetail: action.payload.detail
      }
    },
    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    },
    removeRefundOrderDetail(state) {
      return {
        ...state,
        refundOrderDetail: {}
      }
    }
  },
};

export const TRAVELRECORD_NAMESPACE = 'travelrecord';

export function TRAVELRECORD_LIST(payload) {
  return {
    type: `${TRAVELRECORD_NAMESPACE}/fetchList`,
    payload,
  };
}

export function TRAVELRECORD_DETAIL(tripNo) {
  return {
    type: `${TRAVELRECORD_NAMESPACE}/fetchDetail`,
    payload: { tripNo },
  };
}

export function TRAVELRECORD_CLEAR_DETAIL() {
  return {
    type: `${TRAVELRECORD_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export const DRIVERRECRUITCONFIG_NAMESPACE = 'driverrecruitconfig';

export function DRIVER_RECRUIT_CONFIG_PAGE(payload, callback) {
  return {
    type: `${DRIVERRECRUITCONFIG_NAMESPACE}/page`,
    payload,
    callback
  };
}
export function DRIVER_RECRUIT_CONFIG_SUBMIT(payload, callback) {
  return {
    type: `${DRIVERRECRUITCONFIG_NAMESPACE}/submit`,
    payload,
    callback
  };
}
export function DRIVER_RECRUIT_CONFIG_OPEN(payload, callback) {
  return {
    type: `${DRIVERRECRUITCONFIG_NAMESPACE}/open`,
    payload,
    callback
  };
}
export function DRIVER_RECRUIT_CONFIG_CLOSE(payload, callback) {
  return {
    type: `${DRIVERRECRUITCONFIG_NAMESPACE}/close`,
    payload,
    callback
  };
}
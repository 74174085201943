import { message } from 'antd';
import router from 'umi/router';
import { COMMON_TAB_INDEX_NAMESPACE } from '../actions/commontabindex';

export default {
  namespace: COMMON_TAB_INDEX_NAMESPACE,
  state: {
    vehicleTabIndex: 1,
    orderProofreadInsideTabIndex: 1,
    operateConfigListTabIndex: '1',
    driverOperationListTabIndex: '2',
    intercityWithdrawalConfigTabIndex: '1',
    voiceConfigDriveTabIndex: '2',
  },
  effects: {
    *setVehicleTabIndex({ payload }, { call, put }) {
      yield put({
        type: 'saveVehicleTabIndex',
        payload,
      });
    },
    *setOrderProofreadInsideTabIndex({ payload }, { call, put }) {
      yield put({
        type: 'saveOrderProofreadInsideTabIndex',
        payload,
      });
    },
    *setOperateConfigListTabIndex({ payload }, { put }) {
      yield put({
        type: 'saveOperateConfigListTabIndex',
        payload
      })
    },
    *setDriverOperationListTabIndex({ payload }, { put }) {
      yield put({
        type: 'saveDriverOperationListTabIndex',
        payload
      })
    },
    *setIntercityWithdrawalConfigTabIndex({ payload }, { put }) {
      yield put({
        type: 'saveIntercityWithdrawalConfigTabIndex',
        payload
      })
    },
    *setVoiceConfigDriveTabIndex({ payload }, { put }) {
      yield put({
        type: 'saveVoiceConfigDriveTabIndex',
        payload
      })
    },
  },
  reducers: {
    saveVehicleTabIndex(state, action) {
      return {
        ...state,
        vehicleTabIndex: action.payload.index,
      };
    },
    saveOrderProofreadInsideTabIndex(state, action) {
      return {
        ...state,
        orderProofreadInsideTabIndex: action.payload.index,
      };
    },
    saveOperateConfigListTabIndex(state, action) {
      return {
        ...state,
        operateConfigListTabIndex: action.payload.index
      }
    },
    saveDriverOperationListTabIndex(state, action) {
      return {
        ...state,
        driverOperationListTabIndex: action.payload.index
      }
    },
    saveIntercityWithdrawalConfigTabIndex(state, action) {
      return {
        ...state,
        intercityWithdrawalConfigTabIndex: action.payload.index
      }
    },
    saveVoiceConfigDriveTabIndex(state, action) {
      return {
        ...state,
        voiceConfigDriveTabIndex: action.payload.index
      }
    },
  }
};

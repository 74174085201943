/* eslint-disable no-unused-expressions */
import { message } from 'antd';
import router from 'umi/router';
import { DRIVERBASE_NAMESPACE } from '../actions/driverbase';
import { list,driverDelete, submit, detail, baseDetail, remove, move, driverDispatchBan, driverRemoveDispatchBan, driverBlockAccount, driverRemoveAccount, driverLeave } from '../services/driverbase';

export default {
  namespace: DRIVERBASE_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: {},
    baseDetail: {},
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },

    // 删除司机
    *fetchDelete({ payload,callback }, { call, put }) {
      const response = yield call(driverDelete, payload);
      if (response.success) {
        message.success('删除成功');
      }
      callback && callback(response)
    },

    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
    *fetchBaseDetail({ payload, callback }, { call, put }) {
      const response = yield call(baseDetail, payload);
      if (response.success) {
        yield put({
          type: 'saveBaseDetail',
          payload: {
            detail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
    *clearDetail({ payload }, { put }) {
      yield put({
        type: 'removeDetail',
        payload: { payload },
      });
    },
    *clearBaseDetail({ payload }, { put }) {
      yield put({
        type: 'removeBaseDetail',
        payload: { payload },
      });
    },
    *submit({ payload }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('提交成功');
        router.goBack();
      } else {
        message.error(response.msg);
      }
    },
    *move({ payload }, { call }) {
      const {
        data,
        success,
      } = payload;
      const response = yield call(move, data);
      if (response.success) {
        success();
      }
    },
    *remove({ payload }, { call }) {
      const {
        data: { keys },
        success,
      } = payload;
      const response = yield call(remove, { ids: keys });
      if (response.success) {
        success();
      }
    },
    *driverRemoveDispatchFn({ payload, callback }, { call }) {
      const response = yield call(driverRemoveDispatchBan, payload)
      if (response.success) {
        callback && callback()
      }
      message[response.success ? 'success' : 'warining'](response.msg)
    },
    *driverDispatchFn ({payload, callback}, {call}) {
      const response = yield call(driverDispatchBan, payload)
      if (response.success) {
        callback && callback()
      }
      message[response.success ? 'success' : 'warining'](response.msg)
    },
    *driverBlockAccountFn ({payload, callback}, {call}) {
      const response = yield call(driverBlockAccount, payload)
      if (response.success) {
        callback && callback()
      }
      message[response.success ? 'success' : 'warining'](response.msg)
    },
    *driverRemoveBlockAccountFn ({payload, callback}, {call}) {
      const response = yield call(driverRemoveAccount, payload)
      if (response.success) {
        callback && callback()
      }
      message[response.success ? 'success' : 'warining'](response.msg)
    },
    *driverLeaveFn ({payload, callback}, {call}) {
      const response = yield call(driverLeave, payload)
      if (response.success) {
        callback && callback()
      }
      message[response.success ? 'success' : 'warining'](response.msg)
    }
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    saveBaseDetail(state, action) {
      return {
        ...state,
        baseDetail: action.payload.detail,
      };
    },
    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    },
    removeBaseDetail(state) {
      return {
        ...state,
        baseDetail: {},
      };
    },
  },
};

import { message } from 'antd';
import { DRIVERRECRUITCONFIG_NAMESPACE } from '../actions/driverrecruitconfig';
import {
  page,
  submit,
  open,
  close,
} from '../services/driverrecruitconfig';

export default {
  namespace: DRIVERRECRUITCONFIG_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: {},
  },
  effects: {
    *page({ payload }, { call, put }) {
      const response = yield call(page, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *submit({ payload, callback }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('保存成功');
        callback(response.data)
      }
    },
    *open({ payload, callback }, { call }) {
      const response = yield call(open, payload);
      if (response.success) {
        callback(payload.id)
      }
    },
    *close({ payload, callback }, { call }) {
      const response = yield call(close, payload);
      if (response.success) {
        callback(payload.id)
      }
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    clearDetail(state) {
      return {
        ...state,
        detail: {}
      }
    }
  },
};

import { stringify } from 'qs';
import request from '../utils/request';

export async function statisticsNumber(params) {
  return request('/api/bmkp-backend/driverRegister/statisticsAuditNum', {
    method: 'POST',
    body: params,
  });
  // return request('/api/bmkp-backend/asset/driver/statisticsAuditNum', {
  //   method: 'POST',
  //   body: params,
  // });
  // return request(`/api/bmkp-backend/asset/driver/statisticsAuditNum?${stringify(params)}`);
}
export async function list(params) {
  return request(`/api/bmkp-backend/driverRegister/page?${stringify(params)}`);
  // return request(`/api/bmkp-backend/asset/driver/auditPage?${stringify(params)}`);
}

export async function audit(params) {
  return request('/api/bmkp-backend/driverRegister/audit', {
    method: 'POST',
    body: params,
  });
  // return request('/api/bmkp-backend/asset/driver/audit', {
  //   method: 'POST',
  //   body: params,
  // });
}

export async function detail(params) {
  return request('/api/bmkp-backend/driverRegister/detail', {
    method: 'POST',
    body: params,
  });
  // return request(`/api/bmkp-backend/asset/driver/auditDetail?${stringify(params)}`);
}

export async function supplementList(params) {
  return request(`/api/bmkp-backend/driverSupplementInfo/page?${stringify(params)}`);
}
export async function supplementDetail(params) {
  return request('/api/bmkp-backend/driverSupplementInfo/detail', {
    method: 'POST',
    body: params,
  });
}
export async function supplementAudit(params) {
  return request('/api/bmkp-backend/driverSupplementInfo/audit', {
    method: 'POST',
    body: params,
  });
}
export async function batchImportHistory(params) {
  return request(`/api/bmkp-backend/assetDriverImport/page?${stringify(params)}`);
}
export async function batchImportDetail(params) {
  return request(`/api/bmkp-backend/assetDriverImportDetail/page?${stringify(params)}`);
}
export const JIGUANGCONFIG_NAMESPACE = 'jiguangConfig';

export function JIGUANGCONFIG_LIST(payload) {
  return {
    type: `${JIGUANGCONFIG_NAMESPACE}/fetchList`,
    payload,
  };
}

export function JIGUANGCONFIG_DETAIL(id) {
  return {
    type: `${JIGUANGCONFIG_NAMESPACE}/fetchDetail`,
    payload: { id },
  };
}

export function JIGUANGCONFIG_CLEAR_DETAIL() {
  return {
    type: `${JIGUANGCONFIG_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function JIGUANGCONFIG_SUBMIT(payload) {
  return {
    type: `${JIGUANGCONFIG_NAMESPACE}/submit`,
    payload,
  };
}

export function JIGUANGCONFIG_REMOVE(payload) {
  return {
    type: `${JIGUANGCONFIG_NAMESPACE}/remove`,
    payload,
  };
}

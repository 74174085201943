export const SERVICE_PHONE_NAMESPACE = 'servicePhone';

export function SERVICE_PHONE_DETAIL(callback) {
  return {
    type: `${SERVICE_PHONE_NAMESPACE}/fetchDetail`,
    payload: null,
    callback
  };
}

export function SERVICE_PHONE_SUBMIT(payload) {
  return {
    type: `${SERVICE_PHONE_NAMESPACE}/submit`,
    payload,
  };
}

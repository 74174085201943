import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

export async function list(params) {
  return request(`/api/bmkp-backend/asset/driverbindcar/page?${stringify(params)}`);
}

export async function bindHistoryList(params) {
  return request(`/api/bmkp-backend/asset/record/page?${stringify(params)}`);
}

export async function bindErrorDetail(params) {
  return request(`/api/bmkp-backend/asset/record/detail?${stringify(params)}`);
}

export async function bind(params) {
  return request('/api/bmkp-backend/asset/driverbindcar/bind', {
    method: 'POST',
    body: params,
  });
}

export async function bindBatch(params) {
  return request('/api/bmkp-backend/asset/driverbindcar/importBatchBind', {
    method: 'POST',
    body: params,
  });
}

// export async function unbound(params) {
//   return request('/api/bmkp-backend/asset/driverbindcar/unbound', {
//     method: 'POST',
//     body: func.toFormData(params)
//   });
// }
export async function unbound(params) {
  return request(`/api/bmkp-backend/asset/driverbindcar/unbound?${stringify(params)}`);
}

export async function detail(params) {
  return request(`/api/bmkp-backend/asset/car/detail?${stringify(params)}`);
}

export async function remove(params) {
  return request('/api/bmkp-backend/asset/car/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}

export const MESSAGE_MOBILE_NAMESPACE = 'messageMobile';

export function  MESSAGE_MOBILE_LIST(payload) {
  return {
    type: `${MESSAGE_MOBILE_NAMESPACE}/fetchList`,
    payload,
  };
}
export function  MOBILE_MESSAGE_DETAIL(id, callback) {
  return {
    type: `${MESSAGE_MOBILE_NAMESPACE}/fetchDetail`,
    payload: { id },
    callback
  };
}

export function  MOBILE_MESSAGE_SUBMIT(payload, callback) {
  return {
    type: `${MESSAGE_MOBILE_NAMESPACE}/submit`,
    payload,
    callback
  };
}
export function  MOBILE_MESSAGE_UPDATE(payload) {
  return {
    type: `${MESSAGE_MOBILE_NAMESPACE}/update`,
    payload
  };
}

export function  MOBILE_MESSAGE_REMOVE(payload) {
  return {
    type: `${MESSAGE_MOBILE_NAMESPACE}/remove`,
    payload,
  };
}

export const DRIVERHAILINGLICENSE_NAMESPACE = 'driverHailingLicense';

export function DRIVERHAILINGLICENSE_LIST(payload) {
  return {
    type: `${DRIVERHAILINGLICENSE_NAMESPACE}/fetchList`,
    payload,
  };
}

export function DRIVERHAILINGLICENSE_DETAIL(id, callback) {
  return {
    type: `${DRIVERHAILINGLICENSE_NAMESPACE}/fetchDetail`,
    payload: { driverId: id },
    callback
  };
}

export function DRIVERHAILINGLICENSE_CLEAR_DETAIL() {
  return {
    type: `${DRIVERHAILINGLICENSE_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function DRIVERHAILINGLICENSE_SUBMIT(payload) {
  return {
    type: `${DRIVERHAILINGLICENSE_NAMESPACE}/submit`,
    payload,
  };
}

export function DRIVERHAILINGLICENSE_REMOVE(payload) {
  return {
    type: `${DRIVERHAILINGLICENSE_NAMESPACE}/remove`,
    payload,
  };
}

export const CANCEL_RULE_NAMESPACE = 'cancelRule';

export function CANCEL_RULE_DETAIL(id, callback) {
  return {
    type: `${CANCEL_RULE_NAMESPACE}/fetchDetail`,
    payload: { serviceId: id },
    callback
  };
}

export function CANCEL_RULE_SUBMIT(payload, callback) {
  return {
    type: `${CANCEL_RULE_NAMESPACE}/submit`,
    payload,
    callback
  };
}

export function CANCEL_RULE_LIST_INSIDE(payload, callback) {
  return {
    type: `${CANCEL_RULE_NAMESPACE}/fetchListInside`,
    payload,
    callback
  };
}

export function CANCEL_RULE_SUBMIT_INSIDE(payload, callback) {
  return {
    type: `${CANCEL_RULE_NAMESPACE}/submitInside`,
    payload,
    callback
  };
}

import request from '../utils/request';

export async function list(params) {
  return request('/api/bmkp-wyc/wyc/app/config/page', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request('/api/bmkp-wyc/wyc/app/config/query', {
    method: 'POST',
    body: params,
  });
}

export async function add(params) {
  return request('/api/bmkp-wyc/wyc/app/config/add', {
    method: 'POST',
    body: params,
  });
}

export async function update(params) {
  return request('/api/bmkp-wyc/wyc/app/config/update', {
    method: 'POST',
    body: params,
  });
}

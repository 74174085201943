import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

export async function list(params) {
  return request(`/api/bmkp-backend/asset/car/page?${stringify(params)}`);
}

// 删除车辆
export async function carDelete(params) {
  console.log(params,"params")
  return request(`/api/bmkp-backend/asset/car/remove`,{
    method: 'POST',
    body: params,
  })
}

// 可用车辆
export async function usableCarList(params) {
  return request('/api/bmkp-backend/asset/car/getUsableCarList', {
    method: 'POST',
    body: params
  });
}

export async function submit(params) {
  return request('/api/bmkp-backend/asset/car/submit', {
    method: 'POST',
    body: params,
  });
}

// 车辆基础信息
export async function detail(params) {
  return request(`/api/bmkp-backend/asset/car/detail?${stringify(params)}`);
}

export async function remove(params) {
  return request('/api/bmkp-backend/asset/car/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}

// 道路运营证信息接口
export async function getCartransport(params) {
  return request(`/api/bmkp-backend/asset/cartransport/detail?${stringify(params)}`)
}

export async function submitCartransport(params) {
  return request('/api/bmkp-backend/asset/cartransport/submit', {
    method: 'POST',
    body: params,
  });
}

// 车辆行驶证信息接口
export async function getCarlicense(params) {
  return request(`/api/bmkp-backend/asset/carlicense/detail?${stringify(params)}`)
}

export async function submitCarlicense(params) {
  return request('/api/bmkp-backend/asset/carlicense/submit', {
    method: 'POST',
    body: params,
  });
}

// 网约车运输证信息接口
export async function getCarhailinglicense(params) {
  return request(`/api/bmkp-backend/asset/carhailinglicense/detail?${stringify(params)}`)
}

export async function submitCarhailinglicense(params) {
  return request('/api/bmkp-backend/asset/carhailinglicense/submit', {
    method: 'POST',
    body: params,
  });
}

// 网约车驾驶证信息接口
export async function getDriverhailinglicense(params) {
  return request(`/api/bmkp-backend/asset/driverhailinglicense//detail?${stringify(params)}`)
}

export async function submitDriverhailinglicense(params) {
  return request('/api/bmkp-backend/asset/driverhailinglicense/submit', {
    method: 'POST',
    body: params,
  });
}

// 提交车辆其他信息接口
export async function submitOtherInfo(params) {
  return request('/api/bmkp-backend/asset/car/submitOtherInfo', {
    method: 'POST',
    body: params,
  });
}

// 车辆导入历史分页
export async function importHistoryList(params) {
  return request(`/api/bmkp-backend/assetCarImport/page?${stringify(params)}`)
}

// 车辆导入错误详情
export async function importErrorDetail(params) {
  return request(`/api/bmkp-backend/assetCarImportDetail/page?${stringify(params)}`)
}

// 商业险&交强险
export async function detailCarinsurance(params) {
  return request(`/api/bmkp-backend/asset/carinsurance/detail?${stringify(params)}`)

  // return request('/api/bmkp-backend/asset/carinsurance/detail', {
  //   method: 'POST',
  //   body: params,
  // });
}

export async function submitCarinsurance(params) {
  return request('/api/bmkp-backend/asset/carinsurance/submit', {
    method: 'POST',
    body: params,
  });
}

import request from '../utils/request';

// 分页查询
export async function list(params) {
  return request('/api/bmkp-wyc/wyc/rules/page/v1', {
    method: 'POST',
    body: params,
  });
}

// 查询计价详情
export async function detail(params) {
  return request('/api/bmkp-wyc/wyc/rules/detail/v1', {
    method: 'POST',
    body: params,
  });
}

// 计价规则设置详情
export async function submit(params) {
  return request('/api/bmkp-wyc/wyc/rules/save/v1', {
    method: 'POST',
    body: params,
  });
}

// 分页查询计价变更记录
export async function changeList(params) {
  return request('/api/bmkp-wyc/wyc/rules/change/page/v1', {
    method: 'POST',
    body: params,
  });
}
// 计价规则设置钱查询开城配置是否完成
export async function checkConfig(params) {
  return request('/api/bmkp-wyc/wyc/rules/config/check', {
    method: 'POST',
    body: params,
  });
}

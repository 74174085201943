export const COMMON_DICT_NAMESPACE = 'commonDict';

export function BIZ_TYPE_DICT(callback = null) {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchBizTypeDict`,
    payload: {code: 'bizType'},
    callback
  };
}
export function SERVICE_TYPE_DICT(callback) {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchServiceTypeDict`,
    payload: {code: 'serviceType'},
    callback
  };
}
// 数据对接服务类型
export function COMMERCIAL_TYPE_DICT(callback) {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchCommercialTypeDict`,
    payload: {code: 'commercialType'},
    callback
  };
}
export function SUPPLIER_TYPE_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchSupplierTypeDict`,
    payload: {code: 'supplierType'},
  };
}
export function BIZ_CAR_BELONG_TYPE_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchCarBelongTypeDict`,
    payload: {code: 'carBelongType'},
  };
}
export function CAR_COLOR_TYPE_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchCarColorTypeDict`,
    payload: {code: 'carColorType'},
  };
}

// 车辆使用性质
export function CAR_USE_TYPE_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchCarUseTypeDict`,
    payload: {code: 'carUseType'},
  };
}

// 车辆类型
export function CAR_TYPE_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchCarTypeDict`,
    payload: {code: 'carType'},
  };
}

export function VEHICLETYPE_LEVEL_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchVerhicleLevelDict`,
    payload: {code: 'vehicleLevel'},
  };
}
export function VEHICLETYPE_SEAT_NUMBER_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchVehicleSeatNumberDict`,
    payload: {code: 'vehicleSeatNumber'},
  };
}

export function CAR_ENERGY_TYPE_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchEnergyTypeDict`,
    payload: {code: 'energyType'}
  }
}

export function VEHICLETYPE_IMG_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchVerhicleImgDict`,
    payload: {code: 'vehicleImg'},
  };
}
export function DRIVERTYPE_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchDriverTypeDict`,
    payload: {code: 'driverType'},
  };
}
export function SEX_TYPE_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchSexTypeDict`,
    payload: {code: 'sex'},
  };
}
export function COUPON_TYPE_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchCouponTypeDict`,
    payload: {code: 'couponType'},
  };
}
export function REAL_TIME_TYPE_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchRealTimeTypeDict`,
    payload: {code: 'realTimeType'},
  };
}
export function VEHICLE_LICENSE_TYPE_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchVehicleLicenseTypeDict`,
    payload: {code: 'vehicleLicenseType'},
  };
}
export function MOBILE_MESSAGE_TARGET_TYPE_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchMobileMessageTargetTypeDict`,
    payload: {code: 'moblieMessageTargetType'},
  };
}
export function PROVINCE_VEHICLE_NUMBER_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchProvinceVehicleNumberTypeDict`,
    payload: {code: 'provinceVehicleNumber'},
  };
}
export function SPECIAL_DISTRICT_INFO_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchSpecialDistrictInfoDict`,
    payload: {code: 'specailDistrictInfo'},
  };
}
export function ID_CARD_REJECT_REASON_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchIdCardRejectReasonDict`,
    payload: {code: 'idCardRejectReason'},
  };
}
export function DRIVER_LICENSE_REJECT_REASON_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchDriverLicenseRejectReasonDict`,
    payload: {code: 'driverLicenseRejectReason'},
  };
}
export function CAR_LICENSE_REJECT_REASON_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchCarLicenseRejectReasonDict`,
    payload: {code: 'carLicenseRejectReason'},
  };
}
export function DRIVER_CERTIFICATE_TYPE_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchDriverCertificateTypeDict`,
    payload: {code: 'driverCertificateType'},
  };
}
export function VEHICLE_CHANNEL_TYPE_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchVehicleChannelTypeDict`,
    payload: {code: 'vehicleChannelType'},
  };
}
export function VEHICLE_CHANNEL_CAR_TYPE_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchVehicleChannelCarTypeDict`,
    payload: {code: 'vehicleChannelCarType'},
  };
}

// 订单操作-责任判定
export function ORDER_ACTION_RESPONSIBILITY_TYPE_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchResponsibilityTypeDict`,
    payload: {code: 'responsibilityType'},
  }
}

export function DRIVER_ACTIVITY_INSIDE_TYPE_DICT(callback) {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchDriverActivityInsideTypeDict`,
    payload: {code: 'driverActivityInsideType'},
    callback
  }
}
// 城际乘客活动类型
export function PASSENGER_ACTIVITY_INTERCITY_TYPE_DICT(callback) {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchPassengerActivityIntercityTypeDict`,
    payload: {code: 'passengerActivityIntercityType'},
    callback
  }
}
// 城际订单下单来源
export function ORDER_SOURCE_TYPE_DICT(callback) {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchOrderSourceTypeDict`,
    payload: {code: 'orderSourceType'},
    callback
  }
}
// 活动费用分担类型
export function ACTIVITY_COST_SHARE_TYPE_DICT(callback) {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchActivityCostShareTypeDict`,
    payload: {code: 'activityCostShareType'},
    callback
  }
}
// 活动参与用户类型
export function ACTIVITY_USER_TYPE_DICT(callback) {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchActivityUserTypeDict`,
    payload: {code: 'activityUserType'},
    callback
  }
}
// 优惠券发放类型
export function COUPON_SEND_TYPE_DICT(callback) {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchCouponSendTypeDict`,
    payload: {code: 'couponSendType'},
    callback
  }
}
// 乘客取消原因分类  与自己有关 与平台有关 与司机有关
export function PASSENGER_CANCEL_REASON_DICT(callback) {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchPassengerCancelReasonDict`,
    payload: {code: 'passengerCancelReason'},
    callback
  }
}
// 车牌颜色
export function LICENSE_PLATE_COLOR_DICT() {
  return {
    type: `${COMMON_DICT_NAMESPACE}/fetchLicensePlateColorDict`,
    payload: {code: 'licensePlateColor'},
  }
}
import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

// 分页查询供应商列表
export async function page(params) {
  return request('/api/bmkp-backend/capacity/supplier/page', {
    method: 'POST',
    body: params,
  });
}

// 根据条件查询供应商列表
export async function list(params) {
  return request('/api/bmkp-backend/capacity/supplier/list', {
    method: 'POST',
    body: params,
  });
}

export async function submit(params) {
  return request('/api/bmkp-backend/capacity/supplier/submit', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request(`/api/bmkp-backend/capacity/supplier/detail?${stringify(params)}`);
}

export async function remove(params) {
  return request('/api/bmkp-backend/capacity/supplier/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}

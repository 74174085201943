export const CAPACITYMAP_NAMESPACE = 'capacitymap'

export function CAPACITYMAP_LIST(payload, callback) {
  return {
    type: `${CAPACITYMAP_NAMESPACE}/fetchList`,
    payload,
    callback
  };
}

export function CAPACITYMAP_CITY_LIST(payload, callback) {
  return {
    type: `${CAPACITYMAP_NAMESPACE}/fetchCityList`,
    payload,
    callback
  };
}

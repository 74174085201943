import { stringify } from 'qs';
import request from '../utils/request';

// 司机证件
export async function page(params) {
  return request(`/api/bmkp-backend/asset/driver/licencePage?${stringify(params)}`);
}

export async function licenceExport(params) {
  return request(`/api/bmkp-backend/asset/driver/licenceExport?${stringify(params)}`);
}

export async function detailDriver(params) {
  return request(`/api/bmkp-backend/asset/driver/detail?${stringify(params)}`);
}

export async function updateIdCard(params) {
  return request('/api/bmkp-backend/asset/driver/updateIdCard', {
    method: 'POST',
    body: params,
  });
}
// 驾驶证详情
export async function detailLicense(params) {
  return request(`/api/bmkp-backend/asset/driverlicense/detail?${stringify(params)}`);
}
export async function submitLicense(params) {
  return request('/api/bmkp-backend/asset/driverlicense/submit', {
    method: 'POST',
    body: params,
  });
}

// 网约车驾驶证详情
export async function detailHailingLicense(params) {
  return request(`/api/bmkp-backend/asset/driverhailinglicense/detail?${stringify(params)}`);
}
export async function submitHailinglicense(params) {
  return request('/api/bmkp-backend/asset/driverhailinglicense/submit', {
    method: 'POST',
    body: params,
  });
}
// 提交其他信息
export async function submitOtherInfo(params) {
  return request('/api/bmkp-backend/asset/driver/submitOtherInfo', {
    method: 'POST',
    body: params,
  });
}

// 提交合同信息
export async function submitContract(params) {
  return request('/api/bmkp-backend/asset/driver/submitContract', {
    method: 'POST',
    body: params,
  });
}

import { stringify } from 'qs';
import request from '../utils/request';

// 线索司机 
export async function page(params) {
  return request(`/api/bmkp-backend/asset/driver/cluePage?${stringify(params)}`);
}

export async function exportCluePage(params) {
  return request(`/api/bmkp-backend//asset/driver/exportCluePage?${stringify(params)}`);
}

import { message } from 'antd';
import router from 'umi/router';
import { LINECONFIG_NAMESPACE } from '../actions/lineconfig';
import {
  newList,
  list,
  listBySupplier,
  listByAgent,
  getLineByCode,
  originList,
  destinationList,
  submit,
  update,
  detail,
  remove,
  lineOperationConfigDetail,
  lineOperationStatus,
  updateBatch,
  updateOperationStatus,
  getCitysInfo
} from '../services/lineconfig';

export default {
  namespace: LINECONFIG_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    supplierLineList: [],
    agentLineList: [],
    originData: {
      list: [],
      pagination: false,
    },
    destinationData: {
      list: [],
      pagination: false,
    },
    detail: null,
    lineOperationConfigDetail: null, // 线路运营配置
    lineOperationStatus: null, // 线路运营状态
  },
  effects: {
    *fetchListNew({ payload, callback }, { call, put }) {
      const response = yield call(newList, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
        callback && callback(response.data.records)
      }
    },
    *fetchList({ payload, callback }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
        callback && callback(response.data.records)
      }
    },
    *fetchListBySupplier({ payload }, { call, put }) {
      const response = yield call(listBySupplier, payload);
      if (response.success) {
        yield put({
          type: 'saveSupplierLineList',
          payload: {
            list: response.data
          },
        });
      }
    },
    *fetchListByAgent({ payload }, { call, put }) {
      const response = yield call(listByAgent, payload);
      if (response.success) {
        yield put({
          type: 'saveAgentLineList',
          payload: {
            list: response.data
          },
        });
      }
    },
    *fetchDetailByCode({ payload, callback }, { call, put }) {
      const response = yield call(getLineByCode, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data
          },
        });
        callback(response.data)
      }
    },
    *fetchOriginList({ payload }, { call, put }) {
      const response = yield call(originList, payload);
      if (response.success) {
        yield put({
          type: 'saveOriginList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDestinationList({ payload }, { call, put }) {
      const response = yield call(destinationList, payload);
      if (response.success) {
        yield put({
          type: 'saveDestinationList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
        // eslint-disable-next-line no-unused-expressions
        callback && callback(response.data)
      }
    },
    *fetchCitysInfo({ payload, callback }, { call }) {
      const response = yield call(getCitysInfo, payload)
      if (response.success) {
        const citysInfo = []
        const { operateCity } = response.data
        if (operateCity) {
          for (const key in operateCity) {
            citysInfo.push({
              groupName: key,
              children: operateCity[key].map(k => {
                return {
                  adcode: k.origin || k.destination,
                  name: k.originName || k.destinationName
                }
              })
            })
          }
        }
        callback(citysInfo)
      }
    },
    *updateBatch({ payload, callback }, { call }) {
      const response = yield call(updateBatch, payload);
      if (response.success) {
        callback(response.data)
      }
    },
    *clearDetail({ payload }, { put }) {
      yield put({
        type: 'removeDetail',
        payload: { payload },
      });
    },
    *submit({ payload }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('提交成功');
        router.push('/intercity_config/line_config');
      }
    },
    *update({ payload, callback}, { call }) {
      const response = yield call(update, payload);
      if (response.success) {
        message.success('更新成功');
        // eslint-disable-next-line no-unused-expressions
        callback && callback(true)
      }
    },
    *updateOperationStatus({ payload, callback}, { call }) {
      const response = yield call(updateOperationStatus, payload);
      if (response.success) {
        message.success('更新成功');
        // eslint-disable-next-line no-unused-expressions
        callback && callback(true)
      }
    },
    *remove({ payload }, { call }) {
      const {
        data: { keys },
        success,
      } = payload;
      const response = yield call(remove, { ids: keys });
      if (response.success) {
        success();
      }
    },
    *fetchLineOperationConfigDetail({ payload }, { call, put }) {
      const response = yield call(lineOperationConfigDetail, payload);
      if (response.success) {
        yield put({
          type: 'saveLineOperationConfigDetail',
          payload: {
            detail: response.data,
          },
        });
      }
    },
    *checkLineOperationStatus({ payload }, { call, put }) {
      const response = yield call(lineOperationStatus, payload);
      if (response.success) {
        yield put({
          type: 'saveLineOperationStatus',
          payload: {
            detail: response.data,
          },
        });
        window.localStorage.removeItem("lineName")
        window.localStorage.removeItem("lineOriginName",response.data.originName)
        window.localStorage.removeItem("destinationName",response.data.destinationName)
        window.sessionStorage.setItem("lineName",response.data.lineName)
        // TODO 此处接口需要加上信息
        // window.sessionStorage.setItem("originAdcode",response.data.origin) // 起点adcode编码
        // window.sessionStorage.setItem("destinationAdcode",response.data.destination) // 终点adcode编码 
      }
    },
    *clearLineOperationStatus({ payload }, { call, put }) {
        yield put({
          type: 'saveLineOperationStatus',
          payload: {
            detail: null,
          },
        });
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveSupplierLineList(state, action) {
      return {
        ...state,
        supplierLineList: action.payload.list,
      };
    },
    saveAgentLineList(state, action) {
      return {
        ...state,
        agentLineList: action.payload.list,
      };
    },
    saveOriginList(state, action) {
      return {
        ...state,
        originData: action.payload,
      };
    },
    saveDestinationList(state, action) {
      return {
        ...state,
        destinationData: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    },
    saveLineOperationConfigDetail(state, action) {
      return {
        ...state,
        lineOperationConfigDetail: action.payload.detail,
      };
    },
    saveLineOperationStatus(state, action) {
      return {
        ...state,
        lineOperationStatus: action.payload.detail,
      };
    },
  },
};

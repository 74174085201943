import moment from 'moment';
import RequestForm from '@/utils/RequestForm';

/**
 * 通用工具类
 */
export default class Func {
  /**
   * 不为空
   * @param val
   * @returns {boolean}
   */
  static notEmpty(val) {
    return !this.isEmpty(val);
  }

  /**
   * 为空
   * @param val
   * @returns {boolean}
   */
  static isEmpty(val) {
    if (
      val === null ||
      typeof val === 'undefined' ||
      (typeof val === 'string' && val === '' && val !== 'undefined')
    ) {
      return true;
    }
    return false;
  }

  /**
   * 强转int型
   * @param val
   * @param defaultValue
   * @returns {number}
   */
  static toInt(val, defaultValue) {
    if (this.isEmpty(val)) {
      return defaultValue === undefined ? -1 : defaultValue;
    }
    const num = parseInt(val, 0);
    return Number.isNaN(num) ? (defaultValue === undefined ? -1 : defaultValue) : num;
  }

  /**
   * Json强转为Form类型
   * @param obj
   * @returns {RequestForm}
   */
  static toFormData(obj) {
    return new RequestForm(obj);
  }

  /**
   * 传入城市编码, 转为6位标准的格式, 如果不到6位, 缺少的将自动补0, 超过就只取前6位  例如 4201  =>  420100
   * @param {*} str 传入的字符串编码
   * @param {*} length 显示长度, 默认6位
   * @param {*} defaultValue 缺空值  默认补充0
   * @returns 格式化后的城市编码
   */
  static fillNumber(str, length = 6, defaultValue = '0') {
    let newStr = str
    if (typeof newStr !== 'string') {
      return newStr
    }
    if (newStr && newStr.length > length) {
      newStr = newStr.substr(0, length)
    } else if (newStr) {
      let count = length - newStr.length
      let tmp = ""
      do {
        tmp += defaultValue
        count -= 1
      } while (count > 0)
      newStr += tmp
    }
    return newStr
  }

  /**
   * 字符串转为date类
   * @param date
   * @param format
   * @returns {any}
   */
  static moment(date, format = 'YYYY-MM-DD HH:mm:ss') {
    return date ? moment(date, format) : null;
  }

  /**
   * date类转为字符串格式
   * @param date
   * @param format
   * @returns {null}
   */
  static format(date, format = 'YYYY-MM-DD HH:mm:ss') {
    return date ? date.format(format) : null;
  }

  /**
   * 根据逗号联合
   * @param arr
   * @returns {string}
   */
  static join(arr) {
    return arr ? arr.join(',') : '';
  }

  /**
   * 根据逗号分隔
   * @param str
   * @returns {string}
   */
  static split(str) {
    return str ? String(str).split(',') : '';
  }
}

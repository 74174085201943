export const ADJUSTMENT_ACCOUNT_NAMESPACE = 'adjustmentaccount';

export function ADJUSTMENT_ACCOUNT_LIST(payload) {
  return {
    type: `${ADJUSTMENT_ACCOUNT_NAMESPACE}/fetchList`,
    payload,
  };
}
export function ADJUSTMENT_ACCOUNT_DETAIL(id, callback) {
  return {
    type: `${ADJUSTMENT_ACCOUNT_NAMESPACE}/fetchDetail`,
    payload: { id },
    callback
  };
}

export function ADJUSTMENT_ACCOUNT_CLEAR_DETAIL() {
  return {
    type: `${ADJUSTMENT_ACCOUNT_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function ADJUSTMENT_ACCOUNT_SUBMIT(payload, callback) {
  return {
    type: `${ADJUSTMENT_ACCOUNT_NAMESPACE}/submit`,
    payload,
    callback
  };
}

export function ADJUSTMENT_ACCOUNT_CANCEL(payload, callback) {
  return {
    type: `${ADJUSTMENT_ACCOUNT_NAMESPACE}/cancel`,
    payload,
    callback
  };
}

export function ADJUSTMENT_ACCOUNT_REJECT(payload, callback) {
  return {
    type: `${ADJUSTMENT_ACCOUNT_NAMESPACE}/reject`,
    payload,
    callback
  };
}

export function ADJUSTMENT_ACCOUNT_PASS(payload, callback) {
  return {
    type: `${ADJUSTMENT_ACCOUNT_NAMESPACE}/pass`,
    payload,
    callback
  };
}
import { message } from 'antd';
import { PASSENGERCANCEL_NAMESPACE } from '../actions/passengercancel';
import {
  page,
  query,
  add,
  save,
  update,
  close,
  move
} from '../services/passengercancel';

export default {
  namespace: PASSENGERCANCEL_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: {},
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(page, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *query({ payload, callback }, { call }) {
      const response = yield call(query, payload);
      if (response.success) {
        callback(response.data)
      }
    },
    *add({ payload, callback }, { call }) {
      const response = yield call(add, payload);
      if (response.success) {
        callback && callback()
      }
      message[response.success ? 'success' : 'warning'](response.msg);
    },
    *save({ payload, callback }, { call }) {
      const response = yield call(save, payload);
      if (response.success) {
        callback(response.data)
      }
      message[response.success ? 'success' : 'warning'](response.msg);
    },
    *update({ payload, callback }, { call }) {
      const response = yield call(update, payload);
      if (response.success) {
        callback && callback(response)
      }
      message[response.success ? 'success' : 'warning'](response.msg);
    },
    *close({ payload, callback }, { call }) {
      const response = yield call(close, payload);
      if (response.success) {
        callback && callback()
      }
      message[response.success ? 'success' : 'warning'](response.msg);
    },
    *move({ payload, callback }, { call }) {
      const response = yield call(move, payload);
      if (response.success) {
        callback(response.data)
      }
      message[response.success ? 'success' : 'warning'](response.msg);
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
  },
};

export const LINE_SERVICE_NAMESPACE = 'lineService';

export function LINE_SERVICE_DETAIL(id, callback) {
  return {
    type: `${LINE_SERVICE_NAMESPACE}/fetchDetail`,
    payload: { lineId: id },
    callback
  };
}

export function LINE_SERVICE_SUBMIT(payload, callback) {
  return {
    type: `${LINE_SERVICE_NAMESPACE}/submit`,
    payload,
    callback
  };
}

export function LINE_SERVICE_VERHICLE(id, callback) {
  return {
    type: `${LINE_SERVICE_NAMESPACE}/fetchServiceVehicle`,
    payload: { lineId: id },
    callback
  };
}

export const DRIVERBASE_NAMESPACE = 'driverBase';

export function DRIVERBASE_LIST(payload) {
  return {
    type: `${DRIVERBASE_NAMESPACE}/fetchList`,
    payload,
  };
}

// 删除司机
export function DRIVERBASE_DELETE(payload,callback) {
  return {
    type: `${DRIVERBASE_NAMESPACE}/fetchDelete`,
    payload,
    callback
  };
}

export function DRIVERBASE_DETAIL(payload, callback) {
  return {
    type: `${DRIVERBASE_NAMESPACE}/fetchDetail`,
    payload,
    callback
  };
}
export function DRIVERBASE_BASE_DETAIL(payload, callback) {
  return {
    type: `${DRIVERBASE_NAMESPACE}/fetchBaseDetail`,
    payload,
    callback
  };
}

export function DRIVERBASE_CLEAR_DETAIL() {
  return {
    type: `${DRIVERBASE_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function DRIVERBASE_CLEAR_BASE_DETAIL() {
  return {
    type: `${DRIVERBASE_NAMESPACE}/clearBaseDetail`,
    payload: {},
  };
}


export function DRIVERBASE_SUBMIT(payload) {
  return {
    type: `${DRIVERBASE_NAMESPACE}/submit`,
    payload,
  };
}


export function DRIVERBASE_MOVE(payload) {
  return {
    type: `${DRIVERBASE_NAMESPACE}/move`,
    payload,
  };
}

export function DRIVERBASE_REMOVE(payload) {
  return {
    type: `${DRIVERBASE_NAMESPACE}/remove`,
    payload,
  };
}

export function DRIVERBASE_DISPATCHBAN(payload, callback) {
  return {
    type: `${DRIVERBASE_NAMESPACE}/driverDispatchFn`,
    payload,
    callback
  };
}

export function DRIVERBASE_REMOVEDISPATCHBAN(payload, callback) {
  return {
    type: `${DRIVERBASE_NAMESPACE}/driverRemoveDispatchFn`,
    payload,
    callback
  };
}

export function DRIVERBASE_BLOCKACCOUNT(payload, callback) {
  return {
    type: `${DRIVERBASE_NAMESPACE}/driverBlockAccountFn`,
    payload,
    callback
  };
}

export function DRIVERBASE_REMOVEBLOCKACCOUNT(payload, callback) {
  return {
    type: `${DRIVERBASE_NAMESPACE}/driverRemoveBlockAccountFn`,
    payload,
    callback
  };
}

export function DRIVERBASE_LEAVE(payload, callback) {
  return {
    type: `${DRIVERBASE_NAMESPACE}/driverLeaveFn`,
    payload,
    callback
  };
}
import { message} from 'antd';
import { BRAND_SERIES_NAMESPACE } from '../actions/brandAndSeries';
import {
  list,
  page,
  remove,
  add,
  seriesList,
  addSeries,
  batchAddSeries,
  removeSeries,
} from '../services/brandAndSeries';

export default {
  namespace: BRAND_SERIES_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    list: [],
    detail: []
  },
  effects: {
    *fetchPage({ payload }, { call, put }) {
      const response = yield call(page, payload);
      if (response.success) {
        yield put({
          type: 'savePage',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: response.data
        });
      }
    },
    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(seriesList, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: response.data
        });
        // eslint-disable-next-line no-unused-expressions
        callback?.(response.data)
      }
    },
    *clearDetail(_, { put }) {
      yield put({
        type: 'saveDetail',
        payload: []
      });
    },
    *remove({ payload, callback }, { call }) {
      const response = yield call(remove, payload);
      if (response.success) {
        message.success('删除成功')
        // eslint-disable-next-line no-unused-expressions
        callback?.()
      }
    },
    *add({ payload, callback }, { call }) {
      const response = yield call(add, payload);
      if (response.success) {
        message.success('新增成功');
        // eslint-disable-next-line no-unused-expressions
        callback?.()
      }
    },
    *removeSeries({ payload, callback }, { call }) {
      const response = yield call(removeSeries, payload);
      if (response.success) {
        message.success('移除成功');
        // eslint-disable-next-line no-unused-expressions
        callback?.()
      }
    },
    *batchAddSeries({ payload, callback }, { call }) {
      const response = yield call(batchAddSeries, payload);
      if (response.success) {
        message.success('新增成功');
        // eslint-disable-next-line no-unused-expressions
        callback?.()
      }
    },
    *addSeries({ payload, callback }, { call }) {
      const response = yield call(addSeries, payload);
      if (response.success) {
        message.success('新增成功');
        // eslint-disable-next-line no-unused-expressions
        callback?.()
      }
    },
  },
  reducers: {
    savePage(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveList(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload,
      };
    },
  },
};

export const BILLING_CONFIG_NAMESPACE = 'billingconfig';

// 分页查询
export function BILLING_CONFIG_LIST(payload) {
  return {
    type: `${BILLING_CONFIG_NAMESPACE}/fetchList`,
    payload,
  };
}

// 查询计价详情
export function BILLING_CONFIG_DETAIL(payload, callback) {
  return {
    type: `${BILLING_CONFIG_NAMESPACE}/fetchDetail`,
    payload,
    callback
  };
}

// 清除计价详情
export function BILLING_CONFIG_CLEAR_DETAIL(id, callback) {
  return {
    type: `${BILLING_CONFIG_NAMESPACE}/clearDetail`,
    payload: { id },
    callback
  };
}

// 快速填充计价详情
export function BILLING_CONFIG_QUICK_SETTING() {
  return {
    type: `${BILLING_CONFIG_NAMESPACE}/quickSetting`,
    payload: { 
      
    },
  };
}

// 新增/修改计价规则设置
export function BILLING_CONFIG_SUBMIT(payload) {
  return {
    type: `${BILLING_CONFIG_NAMESPACE}/submit`,
    payload
  };
}

// 分页查询计价变更记录
export function BILLING_CONFIG_CHANGE_LIST(payload) {
  return {
    type: `${BILLING_CONFIG_NAMESPACE}/changeList`,
    payload
  };
}

// 计价规则设置钱查询开城配置是否完成
export function BILLING_CONFIG_CHECK_CONFIG(payload, callback) {
  return {
    type: `${BILLING_CONFIG_NAMESPACE}/checkConfig`,
    payload,
    callback
  };
}

export const DRIVEROPERATION_NAMESPACE = 'driveroperation';

export function DRIVEROPERATION_LIST(payload) {
  return {
    type: `${DRIVEROPERATION_NAMESPACE}/fetchList`,
    payload,
  };
}

export function DRIVEROPERATION_DETAIL(payload, callback) {
  return {
    type: `${DRIVEROPERATION_NAMESPACE}/fetchDetail`,
    payload,
    callback
  };
}

export function DRIVEROPERATION_UPDATE(payload, callback) {
  return {
    type: `${DRIVEROPERATION_NAMESPACE}/update`,
    payload,
    callback
  };
}

export function DRIVEROPERATION_SAVE(payload, callback) {
  return {
    type: `${DRIVEROPERATION_NAMESPACE}/save`,
    payload,
    callback
  };
}

export function DRIVEROPERATION_SAVE_LINK(payload, callback) {
  return {
    type: `${DRIVEROPERATION_NAMESPACE}/saveLink`,
    payload,
    callback
  };
}

export function DRIVEROPERATION_UPDATE_LINK(payload, callback) {
  return {
    type: `${DRIVEROPERATION_NAMESPACE}/updateLink`,
    payload,
    callback
  };
}

export function DRIVEROPERATION_REMOVE_LINK(payload, callback) {
  return {
    type: `${DRIVEROPERATION_NAMESPACE}/removeLink`,
    payload,
    callback
  };
}

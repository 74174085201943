import { message, notification} from 'antd';
import router from 'umi/router';
import { ACTIVITY_NAMESPACE } from '../actions/activity';
import {
  list,
  submit,
  pass,
  reject,
  detail,
  remove,
  start,
  stop,
  reApply,
  update,
  
  listInside,
  submitInside,
  updateInside,
  openInside,
  closeInside,
  removeInside,
  detailInside,
  driverAwardBillInside,

  listPassenger,
  submitPassenger,
  detailPassenger,
  openPassenger,
  closePassenger,
  removePassenger,
  updatePassenger,
  deleteLinkCouponPassenger,
  statisticsPassenger,
  activitynewcouponbill,
  activityInvitationList,
  getDriverInvitePage,
  getDriverInviteDetail,
  activityInvitationDelete,
  activityInvitationAdd,
  activityInvitationUpdate,
  activityInvitationClose,
  activityInvitationOpen
} from '../services/activity';

export default {
  namespace: ACTIVITY_NAMESPACE,
  state: {
    // 城际司机活动列表
    data: {
      list: [],
      pagination: false,
    },
    detail: null, // 城际司机活动详情
  
    dataInside: {
      list: [],
      pagination: false,
    },
    detailInside: null,
    driverAwardBillDataInside: {
      list: [],
      pagination: false,
    },
    activitynewcouponbill: {
      list: [],
      pagination: false,
    },
    // 城际乘客活动列表
    dataPassenger: {
      list: [],
      pagination: false,
    },
    detailPassenger: null, // 城际乘客活动详情
    statisticsPassengerData: {}, // 城际乘客活动数据统计
    // 城内邀请活动
    invitationList: {
      list: [],
      pagination: false,
    },
    invitationDetail: null,
    // 城内司机邀请活动
    driverInvitationData: {
      list: [],
      pagination: false
    },
    driverInvitationDetail: {}
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },

    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
    *clearDetail({ payload }, { put }) {
      yield put({
        type: 'removeDetail',
        payload: { payload },
      });
    },
    *pass({ payload, callback }, { call }) {
      const response = yield call(pass, payload);
      if (response.success) {
        message.success('通过成功');
        callback && callback()
      } else {
        // message.error(response.msg);
        notification.error({
          message: '错误提示',
          description: response.msg
        })
      }
    },
    *reject({ payload, callback }, { call }) {
      const response = yield call(reject, payload);
      if (response.success) {
        message.success('驳回成功');
        callback && callback()
      } else {
        // message.error(response.msg);
        notification.error({
          message: '错误提示',
          description: response.msg
        })
      }
    },
    *submit({ payload }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('提交成功');
        router.push('/activity/intercity/list');
      } else {
        // const { msg } = response
        // notification.error({
        //   message: '错误提示',
        //   description: msg
        // })
      }
    },

    *update({ payload }, { call }) {
      const response = yield call(update, payload);
      if (response.success) {
        message.success('更新成功');
        router.push('/activity/intercity/list');
      } else {
        // message.error(response.msg);
        // notification.error({
        //   message: '错误提示',
        //   description: response.msg
        // })
      }
    },

    *reApply({ payload, callback }, { call }) {
      const response = yield call(reApply, payload);
      if (response.success) {
        message.success('提交成功');
        callback && callback()
      } 
      // else {
      //   // message.error(response.msg);
      //   notification.error({
      //     message: '错误提示',
      //     description: response.msg
      //   })
      // }
    },
    *remove({ payload }, { call }) {
      const response = yield call(remove, payload);
      if (response.success) {
        message.success('删除成功');
        router.push('/activity/intercity/list');
      } 
      // else {
      //   // message.error(response.msg);
      //   notification.error({
      //     message: '错误提示',
      //     description: response.msg
      //   })
      // }
    },
    *start({ payload, callback }, { call }) {
      const response = yield call(start, payload);
      if (response.success) {
        callback && callback()
      } 
      // else {
      //   // message.error(response.msg);
      //   notification.error({
      //     message: '错误提示',
      //     description: response.msg
      //   })
      // }
    },
    *stop({ payload, callback }, { call }) {
      const response = yield call(stop, payload);
      if (response.success) {
        callback && callback()
      } 
      // else {
      //   // message.error(response.msg);
      //   notification.error({
      //     message: '错误提示',
      //     description: response.msg
      //   })
      // }
    },

    // 城内活动
    *fetchListInside({ payload, callback }, { call, put }) {
      const response = yield call(listInside, payload);
      if (response.success) {
        yield put({
          type: 'saveListInside',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
        callback && callback(response.data.records)
      }
    },
    *submitInside({ payload, callback }, { call }) {
      const response = yield call(submitInside, payload);
      if (response.success) {
        message.success('提交成功');
        callback && callback()
      } 
      // else {
      //   // message.error(response.msg);
      //   notification.error({
      //     message: '错误提示',
      //     description: response.msg
      //   })
      // }
    },
    *updateInside({ payload, callback }, { call }) {
      const response = yield call(updateInside, payload);
      if (response.success) {
        message.success('更新成功');
        callback && callback()
      } 
      // else {
      //   // message.error(response.msg);
      //   notification.error({
      //     message: '错误提示',
      //     description: response.msg
      //   })
      // }
    },
    *openInside({ payload, callback }, { call }) {
      const response = yield call(openInside, payload);
      if (response.success) {
        callback && callback()
      } 
      // else {
      //   // message.error(response.msg);
      //   notification.error({
      //     message: '错误提示',
      //     description: response.msg
      //   })
      // }
    },
    *closeInside({ payload, callback }, { call }) {
      const response = yield call(closeInside, payload);
      if (response.success) {
        callback && callback()
      } 
      // else {
      //   // message.error(response.msg);
      //   notification.error({
      //     message: '错误提示',
      //     description: response.msg
      //   })
      // }
    },
    *fetchDetailInside({ payload, callback }, { call, put }) {
      const response = yield call(detailInside, payload);
      if (response.success) {
        yield put({
          type: 'saveDetailInside',
          payload: {
            detail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
    *removeInside({ payload, callback }, { call }) {
      const response = yield call(removeInside, payload);
      if (response.success) {
        callback && callback()
      } 
      // else {
      //   // message.error(response.msg);
      //   notification.error({
      //     message: '错误提示',
      //     description: response.msg
      //   })
      // }
    },
    *clearDetailInside({ payload }, { put }) {
      yield put({
        type: 'removeDetailInside',
        payload: { payload },
      });
    },
    *fetchDriverAwardBillInside({ payload }, { call, put }) {
      const response = yield call(driverAwardBillInside, payload);
      if (response.success) {
        yield put({
          type: 'saveDriverAwardBillDataInside',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },

    // 城际活动列表(乘客)
    *fetchListPassenger({ payload }, { call, put }) {
      const response = yield call(listPassenger, payload);
      if (response.success) {
        yield put({
          type: 'saveListPassenger',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    // 城际乘客活动详情
    *fetchDetailPassenger({ payload, callback }, { call, put }) {
      const response = yield call(detailPassenger, payload);
      if (response.success) {
        yield put({
          type: 'saveDetailPassenger',
          payload: {
            detail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
    // 清除城际乘客活动
    *clearDetailPassenger({}, { put }) {
      yield put({
        type: 'saveDetailPassenger',
        payload: {
          detail: {},
        },
      });
    },
    // 城际活动创建(乘客)
    *submitPassenger({ payload }, { call }) {
      const response = yield call(submitPassenger, payload);
      if (response.success) {
        message.success('提交成功');
        router.goBack();
      } else {
        const { msg } = response
        // notification.error({
        //   message: '错误提示',
        //   description: msg
        // })
      }
    },
    // 城际活动更新(乘客)
    *updatePassenger({ payload }, { call }) {
      const response = yield call(updatePassenger, payload);
      if (response.success) {
        message.success('更新成功');
        router.goBack();
      } else {
        // message.error(response.msg);
        // notification.error({
        //   message: '错误提示',
        //   description: response.msg
        // })
      }
    },
    // 城际活动优惠券删除(乘客)
    *deleteLinkCouponPassenger({ payload, callback }, { call }) {
      const response = yield call(deleteLinkCouponPassenger, payload);
      if (response.success) {
        message.success('操作成功');
        callback(true)
      } else {
        callback(false)
        // message.error(response.msg);
        notification.error({
          message: '错误提示',
          description: response.msg
        })
      }
    },
    // 开启城际乘客活动
    *openPassenger({ payload, callback }, { call }) {
      const response = yield call(openPassenger, payload);
      if (response.success) {
        callback && callback()
      } else {
        // message.error(response.msg);
        // notification.error({
        //   message: '错误提示',
        //   description: response.msg
        // })
      }
    },
    // 关闭城际乘客活动
    *closePassenger({ payload, callback }, { call }) {
      const response = yield call(closePassenger, payload);
      if (response.success) {
        callback && callback()
      } else {
        // message.error(response.msg);
        notification.error({
          message: '错误提示',
          description: response.msg
        })
      }
    },
    // 删除城际乘客活动
    *removePassenger({ payload, callback }, { call }) {
      const response = yield call(removePassenger, payload);
      if (response.success) {
        callback && callback()
      } else {
        notification.error({
          message: '错误提示',
          description: response.msg
        })
      }
    },
    // 城际乘客活动数据统计
    *statisticsPassenger({ payload, callback }, { call, put }) {
      const response = yield call(statisticsPassenger, payload);
      if (response.success) {
        yield put({
          type: 'saveStatisticsPassenger',
          payload: {
            detail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
    // 城际乘客活动账单
    *activitynewcouponbill({ payload, callback }, { call, put }) {
      const response = yield call(activitynewcouponbill, payload);
      if (response.success) {
        yield put({
          type: 'saveActivitynewcouponbill',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
        callback && callback(response.data)
      }
    },
    // 城内邀请活动列表
    *fetchInvitationList({payload,callback}, {call, put}) {
      const response = yield call (activityInvitationList, payload)
      if (response.success) {
        yield put({
          type: 'saveInvitationList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          }
        })
        callback && callback(response.data)
      }
    },
    // 城内司机邀请活动列表
    *fetchDriverInvitationList({payload}, {call, put}) {
      const response = yield call (getDriverInvitePage, payload)
      if (response.success) {
        yield put({
          type: 'saveDriverInvitationList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          }
        })
      }
    },
    // 城内邀请活动详情
    *fetchDriverInvitationDetail({payload}, {call, put}) {
      const response = yield call (getDriverInviteDetail, payload)
      yield put({
        type: 'saveDriverInvitationDetail',
        payload: response.data
      })
    },
    *driverInvitationOpen({payload, callback}, {call}) {
      const response = yield call (activityInvitationOpen, payload)
      if (response.success) {
        callback && callback()
      }
    },
    *driverInvitationClose({payload, callback}, {call}) {
      const response = yield call (activityInvitationClose, payload)
      if (response.success) {
        callback && callback()
      }
    },
    *driverInvitationUpdate({payload, callback}, {call}) {
      const response = yield call (activityInvitationUpdate, payload)
      if (response.success) {
        message.success('修改成功');
        callback && callback(response.data)
      }
    },
    *driverInvitationAdd({payload, callback}, {call}) {
      const response = yield call (activityInvitationAdd, payload)
      if (response.success) {
        message.success('添加成功');
        callback && callback(response.data)
      }
    },
    *driverInvitationDelete({payload, callback}, {call}) {
      const response = yield call (activityInvitationDelete, payload)
      if (response.success) {
        callback && callback()
      }
    },
     // 清除城际乘客活动
    *clearDriverInvitationDetail({ payload }, { put }) {
      yield put({
        type: 'saveDriverInvitationDetail',
        payload
      });
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
   
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },

    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    },
   
    saveListInside(state, action) {
      return {
        ...state,
        dataInside: action.payload,
      };
    },
    saveDetailInside(state, action) {
      return {
        ...state,
        detailInside: action.payload.detail,
      };
    },
    removeDetailInside(state) {
      return {
        ...state,
        detailInside: {},
      };
    },
    saveDriverAwardBillDataInside(state, action) {
      return {
        ...state,
        driverAwardBillDataInside: action.payload
      };
    },
    saveListPassenger(state, action) {
      return {
        ...state,
        dataPassenger: action.payload,
      };
    },
    saveDetailPassenger(state, action) {
      return {
        ...state,
        detailPassenger: action.payload.detail,
      };
    },
    saveStatisticsPassenger(state, action) {
      return {
        ...state,
        statisticsPassengerData: action.payload.detail,
      };
    },
    saveActivitynewcouponbill(state, action) {
      return {
        ...state,
        activitynewcouponbill: action.payload,
      };
    },
    saveInvitationList(state, action) {
      return {
        ...state,
        invitationList: action.payload
      }
    },
    saveDriverInvitationList(state, action) {
      return {
        ...state,
        driverInvitationData: action.payload
      }
    },
    saveDriverInvitationDetail(state, action) {
      return {
        ...state,
        driverInvitationDetail: action.payload
      }
    }
  },
};

export const DISTRICTDISPATCH_NAMESPACE = 'districtdispatch';

// 区域调度列表分页查询
export function DISTRICTDISPATCH_LIST(payload) {
  return {
    type: `${DISTRICTDISPATCH_NAMESPACE}/fetchList`,
    payload,
  };
}

// 添加
export function DISTRICTDISPATCH_ADD(payload, callback) {
  return {
    type: `${DISTRICTDISPATCH_NAMESPACE}/add`,
    payload,
    callback
  };
}

// 详情
export function DISTRICTDISPATCH_DETAIL(payload, callback) {
  return {
    type: `${DISTRICTDISPATCH_NAMESPACE}/fetchDetail`,
    payload,
    callback
  };
}

// 清除详情
export function DISPATCHRULE_CLEAR_DETAIL() {
  return { type: `${DISTRICTDISPATCH_NAMESPACE}/clearDetail` };
}

// 修改
export function DISTRICTDISPATCH_UPDATE(payload, callback) {
  return {
    type: `${DISTRICTDISPATCH_NAMESPACE}/update`,
    payload,
    callback
  };
}

export const INTERCITY_COMMON_CONFIG_NAMESPACE = 'intercityCommonConfig';

export function INTERCITY_COMMON_CONFIG_DETAIL(callback) {
  return {
    type: `${INTERCITY_COMMON_CONFIG_NAMESPACE}/fetchDetail`,
    payload: null,
    callback
  };
}

export function INTERCITY_COMMON_CONFIG_SUBMIT(payload) {
  return {
    type: `${INTERCITY_COMMON_CONFIG_NAMESPACE}/submit`,
    payload,
  };
}

import { stringify } from 'qs';
import request from '../utils/request';

// 查询乘客隐私号(滴滴侧)
export async function getPrivacyNumber(params) {
  return request(`/api/bmkp-wyc/wyc/customer/getOpenPid?${stringify(params)}`, {
    method: 'POST',
  });
}

// 绑定解绑虚拟号
export async function setPrivacyNumberStatus(params) {
  return request('/api/bmkp-wyc/wyc/customer/proxyArk', {
    method: 'POST',
    body: params,
  });
}

import { message } from 'antd';
import { DRIVEROPERATION_NAMESPACE } from '../actions/driveroperation';
import {
  list,
  detail,
  update,
  save,
  saveLink,
  updateLink,
  removeLink
} from '../services/driveroperation';

export default {
  namespace: DRIVEROPERATION_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: {},
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
        callback(response.data)
      }
    },
    *save({ payload, callback }, { call }) {
      const response = yield call(save, payload);
      if (response.success) {
        message.success('保存成功');
        callback(response.data)
      }
    },
    *update({ payload, callback }, { call }) {
      const response = yield call(update, payload);
      if (response.success) {
        callback(payload.id)
      }
    },
    *saveLink({ payload, callback }, { call }) {
      const response = yield call(saveLink, payload);
      if (response.success) {
        message.success('提交成功');
        callback(true)
      } else {
        callback(false)
      }
    },
    *updateLink({ payload, callback }, { call }) {
      const response = yield call(updateLink, payload);
      if (response.success) {
        message.success('修改成功');
        callback(true)
      } else {
        callback(false)
      }
    },
    *removeLink({ payload, callback }, { call }) {
      const response = yield call(removeLink, payload);
      if (response.success) {
        message.success('提交成功');
        callback(true)
      } else {
        callback(false)
      }
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    clearDetail(state) {
      return {
        ...state,
        detail: {}
      }
    }
  },
};

import { message } from 'antd';
import router from 'umi/router';
import { LINE_SERVICE_NAMESPACE } from '../actions/lineservice';
import {
  detail,
  submit,
  serviceVehicle
} from '../services/lineservice';

export default {
  namespace: LINE_SERVICE_NAMESPACE,
  state: {
    detail: {
      carpool: 0,
      carpools: [],
      carpoolOffline: 0,
      carpoolOfflines: [],
      charter: 0,
      charters: []
    },
    serviceVehicle: null
  },
  effects: {
    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
        // eslint-disable-next-line no-unused-expressions
        callback && callback(response.data)
      }
    },
    *submit({ payload, callback }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('提交成功');
        // eslint-disable-next-line no-unused-expressions
        callback && callback(true)
      }
    },

    *fetchServiceVehicle({ payload, callback }, { call, put }) {
      const response = yield call(serviceVehicle, payload);
      if (response.success) {
        yield put({
          type: 'saveServiceVehicle',
          payload: {
            detail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
  },
  reducers: {
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    saveServiceVehicle(state, action) {
      return {
        ...state,
        serviceVehicle: action.payload.detail,
      };
    }
  },
};

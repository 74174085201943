export const ADJUSTMENT_NAMESPACE = 'djadjustment';

/**
 * 
 * @param payload 
 * 调账
 * @returns 
 */
export function ADJUSTMENT_LIST(payload) {
  return {
    type: `${ADJUSTMENT_NAMESPACE}/fetchList`,
    payload,
  };
}

export function ADJUSTMENT_ADD(payload, callback) {
  return {
    type: `${ADJUSTMENT_NAMESPACE}/add`,
    payload,
    callback
  };
}

export function ADJUSTMENT_CANCEL(payload, callback) {
  return {
    type: `${ADJUSTMENT_NAMESPACE}/cancel`,
    payload,
    callback
  };
}

export function ADJUSTMENT_DETAIL(payload) {
  return {
    type: `${ADJUSTMENT_NAMESPACE}/detail`,
    payload,
  };
}

export function ADJUSTMENT_DETAIL_CLEARN() {
  return {
    type: `${ADJUSTMENT_NAMESPACE}/detailClearn`
  };
}

export function ADJUSTMENT_REJECT(payload, callback) {
  return {
    type: `${ADJUSTMENT_NAMESPACE}/reject`,
    payload,
    callback
  };
}

export function ADJUSTMENT_PASS(payload, callback) {
  return {
    type: `${ADJUSTMENT_NAMESPACE}/pass`,
    payload,
    callback
  };
}
import { stringify } from 'qs';
import request from '../utils/request';

// 代理商关联供应商
export async function relevance(params) {
  return request('/api/bmkp-backend/capacity/agentsupplier/relevance', {
    method: 'POST',
    body: params,
  });
}

// 解除关联
export async function removeConnect(params) {
  return request(`/api/bmkp-backend/capacity/agentsupplier/remove?${stringify(params)}`);
}

// 查询代理商关联的所有供应商
export async function getRelevanceSuppliers(params) {
  return request(`/api/bmkp-backend/capacity/agent/getRelevanceSuppliers?${stringify(params)}`);
}



import { message } from 'antd';
import router from 'umi/router';
import { LINE_SERVICE_SCOPE } from '../actions/lineservicescope';
import {
  list,
  submit,
  update,
  detail,
  remove,
  removeone,
  specialLineFenceMatchList,
  specialLineFenceMatchSubmit,
  specialLineFenceMatchRemoveOne
} from '../services/lineservicescope';

export default {
  namespace: LINE_SERVICE_SCOPE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    fenceList: [],
    specialLineFenceMatchList: {
      list: [],
      pagination: false,
    }
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDetail({ payload }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
      }
    },
    *resetDetail(payload, { put }) {
      yield put({
        type: 'saveDetail',
        payload: {
          detail: [],
        },
      });
    },
    *clearDetail({ payload }, { put }) {
      yield put({
        type: 'removeDetail',
        payload: { payload },
      });
    },
    *submit({ payload, callback }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('提交成功');
        callback && callback(true)
      } else {
        callback && callback(false)
      }
    },
    *update({ payload, callback }, { call }) {
      const response = yield call(update, payload);
      if (response.success) {
        message.success('更新成功');
        callback && callback(true)
      } else {
        callback && callback(false)
      }
    },
    *remove({ payload }, { call }) {
      const {
        data: { keys },
        success,
      } = payload;
      const response = yield call(remove, { ids: keys });
      if (response.success) {
        message.success('删除成功');
        success(true);
      } else {
        success(false);
      }
    },
    *removeone({ payload }, { call }) {
      const {
        id,
        success,
      } = payload;
      const response = yield call(removeone, { id });
      if (response.success) {
        message.success('删除成功');
        success(true);
      } else {
        success(false);
      }
    },
    // 专线围栏对列表
    *fetchSpecialLineFenceMatchList({ payload }, { call, put }) {
      const response = yield call(specialLineFenceMatchList, payload);
      if (response.success) {
        yield put({
          type: 'saveSpecialLineFenceMatchList',
          payload: {
            list: response.data,
            pagination: false,
          }
        });
      }
    },
    *specialLineFenceMatchSubmit({ payload, callback }, { call }) {
      const response = yield call(specialLineFenceMatchSubmit, payload);
      if (response.success) {
        message.success('提交成功');
        callback && callback(true)
      } else {
        callback && callback(false)
      }
    },
    *specialLineFenceMatchRemoveOne({ payload, callback }, { call }) {
      const {
        id,
      } = payload;
      const response = yield call(specialLineFenceMatchRemoveOne, { id });
      if (response.success) {
        message.success('删除成功');
        callback && callback(true);
      } else {
        callback && callback(false);
      }
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        fenceList: action.payload.detail,
      };
    },
    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    },
    saveLineOperationConfigDetail(state, action) {
      return {
        ...state,
        lineOperationConfigDetail: action.payload.detail,
      };
    },
    saveLineOperationStatus(state, action) {
      return {
        ...state,
        lineOperationStatus: action.payload.detail,
      };
    },
    saveSpecialLineFenceMatchList(state, action) {
      return {
        ...state,
        specialLineFenceMatchList: action.payload,
      };
    },
  },
};

export const INSIDEORDERTRANSACTION_NAMESPACE = 'insideordertransaction';

export function INSIDEORDERTRANSACTION_LIST(payload) {
  return {
    type: `${INSIDEORDERTRANSACTION_NAMESPACE}/fetchList`,
    payload,
  };
}

export function INSIDEORDERTRANSACTION_DETAIL(orderNo) {
  return {
    type: `${INSIDEORDERTRANSACTION_NAMESPACE}/fetchDetail`,
    payload: { orderNo },
  };
}

export function INSIDEORDERTRANSACTION_CLEAR_DETAIL() {
  return {
    type: `${INSIDEORDERTRANSACTION_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

import { message } from 'antd';
import router from 'umi/router';
import { SCHEDULE_NAMESPACE } from '../actions/schedule';
import {
  submit,
  detail,
  switchActivedType,
  removeSomedaySchedule,
  queryShiftbyline
} from '../services/schedule';

export default {
  namespace: SCHEDULE_NAMESPACE,
  state: {
    detail: {
      beforePickUp: 0,
      beforeStart: 0,
      shiftConfigs: []
    },
    shiftbylineList: []
    // detail: {
    //   lineName: '',
    //   operationType: 0,
    //   days: [],
    //   list: [],
    //   orderBeforeType: 0,
    //   orderBeforeMinutes: 30,
    //   customDate: [{
    //     id: 1,
    //     date: null,
    //     list: [{
    //       id: 1,
    //       pickupTime: '',
    //       departureTime: '',
    //       limitSeatType: 0,
    //       limitSeatCount: 2,
    //       limitSeatCountShare: 3,
    //       limitSeatTotalCount: 4,
    //       applyScope: 1,
    //       saleStatus: false,
    //     }]
    //   }]
    // },
    
  },
  effects: {
    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
        // eslint-disable-next-line no-unused-expressions
        callback && callback(response.data)
      }
    },
    *fetchShiftbyline({ payload }, { call, put }) {
      const response = yield call(queryShiftbyline, payload);
      if (response.success) {
        yield put({
          type: 'saveShiftbyline',
          payload: {
            data: response.data,
          },
        });
      }
    },
    *submit({ payload, callback }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('提交成功');
        // eslint-disable-next-line no-unused-expressions
        callback && callback(true)
      }
    },
    *switchActivedType({ payload, callback }, { call }) {
      const response = yield call(switchActivedType, payload);
      if (response.success) {
        message.success('切换成功');
        // eslint-disable-next-line no-unused-expressions
        callback && callback(true)
      }
    },
    *removeSomedaySchedule({ payload, callback }, { call }) {
      const response = yield call(removeSomedaySchedule, payload);
      if (response.success) {
        message.success('删除成功');
        // eslint-disable-next-line no-unused-expressions
        callback && callback(true)
      }
    },
    *remove({ payload }, { call }) {
      const {
        data: { keys },
        success,
      } = payload;
      const response = yield call(remove, { ids: keys });
      if (response.success) {
        success();
      }
    },
  },
  reducers: {
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    saveShiftbyline(state, action) {

      const list = action.payload.data
      
      const shiftbylineList = list.map(item => ({ 
        value: item.shiftDate,
        label: item.shiftDate,
        children: item.configs.map(c => ({
          value: c.startTime,
          label: c.startTime
        }))
      }))

      return {
        ...state,
        shiftbylineList,
      };
    },
  },
};

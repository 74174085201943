export const ORDER_NAMESPACE = 'djorder';

/**
 *
 * @param payload
 * 代驾订单
 * @returns
 */
export function ORDER_LIST(payload) {
  return {
    type: `${ORDER_NAMESPACE}/fetchList`,
    payload,
  };
}

export function ORDER_Add(payload, callback) {
  return {
    type: `${ORDER_NAMESPACE}/add`,
    payload,
    callback
  };
}

export function ORDER_EDIT(payload) {
  return {
    type: `${ORDER_NAMESPACE}/edit`,
    payload,
  };
}

export function ORDER_CANCEL(payload, callback) {
  return {
    type: `${ORDER_NAMESPACE}/cancel`,
    payload,
    callback
  };
}
export function ORDER_CHANGE_REPLY(payload, callback) {
  return {
    type: `${ORDER_NAMESPACE}/changeReply`,
    payload,
    callback
  };
}

export function ORDER_CITY_LIST(payload) {
  return {
    type: `${ORDER_NAMESPACE}/getCityList`,
    payload,
  };
}

export function ORDER_PRICE(payload, callback) {
  return {
    type: `${ORDER_NAMESPACE}/getPrice`,
    payload,
    callback
  };
}
export function ORDER_DRIVER_LIST(payload, callback?) {
  return {
    type: `${ORDER_NAMESPACE}/getNearDriver`,
    payload,
    callback
  };
}


export function QUERY_ORDER_LIST(payload,callback=null) {
  return {
    type: `${ORDER_NAMESPACE}/queryOrderList`,
    payload,
    callback
  };
}

export function UPDATE_PRICE(payload, callback) {
  return {
    type: `${ORDER_NAMESPACE}/updatePrice`,
    payload,
    callback
  };
}

export function UPDATE_STATUS(payload, callback) {
  return {
    type: `${ORDER_NAMESPACE}/updateStatus`,
    payload,
    callback
  };
}

export function UPDATE_PRICE_RECORD(payload) {
  return {
    type: `${ORDER_NAMESPACE}/updatePriceRecord`,
    payload,
  };
}

export function UPDATE_STATUS_RECORD(payload) {
  return {
    type: `${ORDER_NAMESPACE}/updateStatusRecord`,
    payload,
  };
}
export function ORDER_DETAIL(payload) {
  return {
    type: `${ORDER_NAMESPACE}/detail`,
    payload,
  };
}

export function ORDER_DETAIL_CLEAR() {
  return {
    type: `${ORDER_NAMESPACE}/detailClear`
  };
}

export function ORDER_CITY_CONFIG(payload, callback = null) {
  return {
    type: `${ORDER_NAMESPACE}/cityConfig`,
    payload,
    callback
  };
}

export function ORDER_ALL_CITY_LIST(payload, callback = null) {
  return {
    type: `${ORDER_NAMESPACE}/getAllCityList`,
    payload,
    callback
  };
}


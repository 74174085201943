import { message } from 'antd';
import router from 'umi/router';
import { CAPACITYSUPPLIEROPERATECONFIG_NAMESPACE } from '../actions/capacitysupplieroperateconfig';
import { submit, detail, remove, insideDetail, insideSubmit } from '../services/capacitysupplieroperateconfig';

export default {
  namespace: CAPACITYSUPPLIEROPERATECONFIG_NAMESPACE,
  state: {
    list: [],
    insideList: [],
    detail: [],
    insideDetail: [],
    djDetail: [],
    djList: [],
  },
  effects: {
    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        const list = response.data.filter(k => k.lineName)
        yield put({
          type: 'saveList',
          payload: list,
        });
        callback && callback(list)
      }
    },
    *setDetail({ payload }, { call, put }) {
      yield put({
        type: 'saveDetail',
        payload,
      });
    },
    *fetchInsideDetail({ payload, callback }, { call, put }) {
      const response = yield call(insideDetail, payload);
      if (response.success) {
        yield put({
          type: 'saveInsideList',
          payload: response.data,
        });
        callback(response.data)
      }
    },
    *setInsideDetail({ payload }, { call, put }) {
      yield put({
        type: 'saveInsideDetail',
        payload,
      });
    },
    *fetchDjDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        const list = response.data
        yield put({
          type: 'saveDjList',
          payload: list,
        });
        callback && callback(list)
      }
    },
    *setDjDetail({ payload }, { call, put }) {
      yield put({
        type: 'saveDjDetail',
        payload,
      });
    },
    *clearDetail({ payload }, { put }) {
      yield put({
        type: 'removeDetail',
        payload: { payload },
      });
    },
    *submit({ payload, callback }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('提交成功');
        callback(true)
      } else {
        callback(false)
      }
    },
    *insideSubmit({ payload, callback }, { call }) {
      const response = yield call(insideSubmit, payload);
      if (response.success) {
        message.success('提交成功');
        callback()
      }
    },
    *remove({ payload }, { call }) {
      const {
        data: { keys },
        success,
      } = payload;
      const response = yield call(remove, { ids: keys });
      if (response.success) {
        success();
      }
    },
  },
  reducers: {
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload
      };
    },
    saveList(state, action) {
      return {
        ...state,
        list: action.payload
      };
    },
    saveInsideList(state, action) {
      return {
        ...state,
        insideList: action.payload
      };
    },
    saveDjList(state, action) {
      return {
        ...state,
        djList: action.payload
      };
    },
    saveDjDetail(state, action) {
      return {
        ...state,
        djDetail: action.payload
      };
    },
    saveInsideDetail(state, action) {
      return {
        ...state,
        insideDetail: action.payload
      };
    },
    removeDetail(state) {
      return {
        ...state,
        list: [],
      };
    },
  },
}

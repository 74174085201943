export const CAPACITYCOMMISSIONCONFIG_NAMESPACE = 'capacitycommissionconfig';

export function CAPACITYCOMMISSIONCONFIG_LIST(payload) {
  return {
    type: `${CAPACITYCOMMISSIONCONFIG_NAMESPACE}/fetchList`,
    payload,
  };
}

export function CAPACITYCOMMISSIONCONFIG_DETAIL(configId) {
  return {
    type: `${CAPACITYCOMMISSIONCONFIG_NAMESPACE}/fetchDetail`,
    payload: { configId },
  };
}

export function CAPACITYCOMMISSIONCONFIG_CLEAR_DETAIL() {
  return {
    type: `${CAPACITYCOMMISSIONCONFIG_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function CAPACITYCOMMISSIONCONFIG_SUBMIT(payload) {
  return {
    type: `${CAPACITYCOMMISSIONCONFIG_NAMESPACE}/submit`,
    payload,
  };
}

export function CAPACITYCOMMISSIONCONFIG_REMOVE(payload) {
  return {
    type: `${CAPACITYCOMMISSIONCONFIG_NAMESPACE}/remove`,
    payload,
  };
}

// 获取司乘取消结算配置
export function CAPACITYCOMMISSIONCONFIG_QUERY_ORDER_CANCEL_RATIO(payload) {
  return {
    type: `${CAPACITYCOMMISSIONCONFIG_NAMESPACE}/orderCancelRatio`,
    payload,
  };
}

// 设置司乘取消结算配置
export function CAPACITYCOMMISSIONCONFIG_SET_ORDER_CANCEL_RATIO(payload) {
  return {
    type: `${CAPACITYCOMMISSIONCONFIG_NAMESPACE}/setOrderCancelRatio`,
    payload,
  };
}

// 批量关联运力公司
export function CAPACITYCOMMISSIONCONFIG_BATCH_BINDENTERPRISE(payload, callback) {
  return {
    type: `${CAPACITYCOMMISSIONCONFIG_NAMESPACE}/batchBindEnterprise`,
    payload,
    callback
  };
}

// 批量关联运力公司 代驾
export function CAPACITYCOMMISSIONCONFIG_BATCH_BINDDJ(payload, callback) {
  return {
    type: `${CAPACITYCOMMISSIONCONFIG_NAMESPACE}/djBatchBindEnterprise`,
    payload,
    callback
  };
}

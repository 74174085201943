export default {
  'desk.notice.title': '通知標題',
  'desk.notice.title.placeholder': '請輸入通知標題',
  'desk.notice.title.validation': '請輸入通知標題',
  'desk.notice.category': '通知類型',
  'desk.notice.category.placeholder': '請選擇通知類型',
  'desk.notice.category.validation': '請選擇通知類型',
  'desk.notice.date': '通知日期',
  'desk.notice.date.placeholder': '請選擇通知日期',
  'desk.notice.date.validation': '請選擇通知日期',
  'desk.notice.date.start': '開始日期',
  'desk.notice.date.end': '結束日期',
  'desk.notice.content': '通知內容',
  'desk.notice.content.placeholder': '請輸入通知內容',
  'desk.notice.content.validation': '請輸入通知內容',
};

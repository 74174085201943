export const CAPACITYSUPPLIER_NAMESPACE = 'capacitysupplier';

export function CAPACITYSUPPLIER_LIST(payload, callback) {
  return {
    type: `${CAPACITYSUPPLIER_NAMESPACE}/fetchList`,
    payload,
    callback
  };
}

export function CAPACITYSUPPLIER_PAGE(payload) {
  return {
    type: `${CAPACITYSUPPLIER_NAMESPACE}/fetchPage`,
    payload,
  };
}

export function CAPACITYSUPPLIER_DETAIL(supplierId, callback) {
  return {
    type: `${CAPACITYSUPPLIER_NAMESPACE}/fetchDetail`,
    payload: { supplierId, callback },
  };
}

export function CAPACITYSUPPLIER_CLEAR_DETAIL() {
  return {
    type: `${CAPACITYSUPPLIER_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function CAPACITYSUPPLIER_SUBMIT(payload, callback) {
  return {
    type: `${CAPACITYSUPPLIER_NAMESPACE}/submit`,
    payload,
    callback
  };
}

export function CAPACITYSUPPLIER_REMOVE(payload) {
  return {
    type: `${CAPACITYSUPPLIER_NAMESPACE}/remove`,
    payload,
  };
}

export const DRIVER_INSIDE_NAMESPACE = 'driverInside';

export function DRIVER_APPLY_INSIDE_NUMBER(payload, callback) {
  return {
    type: `${DRIVER_INSIDE_NAMESPACE}/fetchStatisticsNumber`,
    payload,
    callback
  };
}
export function DRIVER_APPLY_INSIDE_LIST(payload) {
  return {
    type: `${DRIVER_INSIDE_NAMESPACE}/fetchList`,
    payload,
  };
}

export function DRIVER_APPLY_INSIDE_DETAIL(id) {
  return {
    type: `${DRIVER_INSIDE_NAMESPACE}/fetchDetail`,
    payload: { id },
  }
}

export function DRIVER_APPLY_INSIDE_AUDIT(payload, callback) {
  return {
    type: `${DRIVER_INSIDE_NAMESPACE}/audit`,
    payload,
    callback
  }
}

export function DRIVER_SUPPLEMENT_INSIDE_LIST(payload) {
  return {
    type: `${DRIVER_INSIDE_NAMESPACE}/fetchSupplementList`,
    payload,
  };
}

export function DRIVER_SUPPLEMENT_INSIDE_DETAIL(id,callback) {
  return {
    type: `${DRIVER_INSIDE_NAMESPACE}/fetchSupplementDetail`,
    payload: { id },
    callback
  }
}
export function DRIVER_SUPPLEMENT_INSIDE_AUDIT(payload, callback) {
  return {
    type: `${DRIVER_INSIDE_NAMESPACE}/auditSupplement`,
    payload,
    callback
  }
}
export function DRIVER_BATCH_IMPORT_HISTORY(payload, callback) {
  return {
    type: `${DRIVER_INSIDE_NAMESPACE}/fetchBatchImportHistory`,
    payload,
    callback
  }
}
export function DRIVER_BATCH_IMPORT_DETAIL(payload, callback) {
  return {
    type: `${DRIVER_INSIDE_NAMESPACE}/fetchBatchImportDetail`,
    payload,
    callback
  }
}

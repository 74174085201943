import { stringify } from 'qs';
import request from '../utils/request';

// 司机招募配置 
export async function page(params) {
  return request(`/api/bmkp-backend/driverRegisterConfig/page?${stringify(params)}`);
}

export async function submit(params) {
  return request('/api/bmkp-backend/driverRegisterConfig/save', {
    method: 'POST',
    body: params,
  });
}
export async function open(params) {
  return request('/api/bmkp-backend/driverRegisterConfig/open', {
    method: 'POST',
    body: params,
  });
}
export async function close(params) {
  return request('/api/bmkp-backend/driverRegisterConfig/close', {
    method: 'POST',
    body: params,
  });
}
export const ORDERDISPATCH_NAMESPACE = 'orderdispatch';

export function ORDERDISPATCH_LIST(payload) {
  return {
    type: `${ORDERDISPATCH_NAMESPACE}/fetchList`,
    payload,
  };
}

export function ORDERDISPATCH_ORDER_LIST(payload) {
  return {
    type: `${ORDERDISPATCH_NAMESPACE}/fetchOrderList`,
    payload,
  };
}

export function ORDERDISPATCH_DRIVER_LIST(payload) {
  return {
    type: `${ORDERDISPATCH_NAMESPACE}/fetchDriverList`,
    payload,
  };
}

export function ORDERDISPATCH_ORDER_DETAIL(orderNo) {
  return {
    type: `${ORDERDISPATCH_NAMESPACE}/fetchOrderDetail`,
    payload: { orderNo },
  };
}

export function ORDERDISPATCH_CLEAR_ORDER_DETAIL() {
  return {
    type: `${ORDERDISPATCH_NAMESPACE}/clearOrderDetail`,
    payload: {},
  }
}

export function ORDERDISPATCH_SET_ORDER_ALLOT(payload) {
  return {
    type: `${ORDERDISPATCH_NAMESPACE}/setOrderAllot`,
    payload,
  };
}

export function ORDERDISPATCH_AUDIO_ORDER_LIST(payload) {
  return {
    type: `${ORDERDISPATCH_NAMESPACE}/fetchAudioOrders`,
    payload
  };
}

export function ORDERDISPATCH_CLEAR_LINE_DOT(lineId) {
  return {
    type: `${ORDERDISPATCH_NAMESPACE}/removeAlreadyReadDot`,
    payload: { lineId }
  };
}

export function ORDERDISPATCH_QUERY_PLAY_STATE(callback) {
  return {
    type: `${ORDERDISPATCH_NAMESPACE}/queryPlaytState`,
    callback
  };
}

export function ORDERDISPATCH_UPDATE_PLAY_STATE(payload, callback) {
  return {
    type: `${ORDERDISPATCH_NAMESPACE}/setPlayState`,
    payload,
    callback
  };
}


import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

// 司机管理列表分页
export async function list(params) {
  return request(`/api/bmkp-backend/asset/driver/page?${stringify(params)}`);
}

// 删除司机
export async function driverDelete(params) {
  return request(`/api/bmkp-backend/asset/driver/remove`,{
    method: 'POST',
    body: params,
  })
}

export async function submit(params) {
  return request('/api/bmkp-backend/asset/driver/submit', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request(`/api/bmkp-backend/asset/driver/detail?${stringify(params)}`);
}
export async function baseDetail(params) {
  return request(`/api/bmkp-backend/asset/driver/baseDetail?${stringify(params)}`);
}

export async function remove(params) {
  return request('/api/blade-asset/assetdriverbase/remove', {
    method: 'POST',
    body: func.toFormData(params),
  });
}
/**
 * 司机状态  调度解封
 * @param {*} params 
 * @returns 
 */
export async function driverDispatchBan(params) {
  return request('/api/bmkp-backend/asset/driver/dispatchBan', {
    method: 'POST',
    body: params,
  });
}

/**
 * 司机状态  解除调度解封
 * @param {*} params 
 * @returns 
 */
 export async function driverRemoveDispatchBan(params) {
  return request('/api/bmkp-backend/asset/driver/removeDispatchBan', {
    method: 'POST',
    body: params,
  });
}

/**
 * 司机状态  封号
 * @param {*} params 
 * @returns 
 */
 export async function driverBlockAccount(params) {
  return request('/api/bmkp-backend/asset/driver/blockAccount', {
    method: 'POST',
    body: params,
  });
}

/**
 * 司机状态  解除封号
 * @param {*} params 
 * @returns 
 */
 export async function driverRemoveAccount(params) {
  return request('/api/bmkp-backend/asset/driver/removeBlockAccount', {
    method: 'POST',
    body: params,
  });
}

/**
 * 司机状态  离职
 * @param {*} params 
 * @returns 
 */
 export async function driverLeave(params) {
  return request('/api/bmkp-backend/asset/driver/leave', {
    method: 'POST',
    body: params,
  });
}

// 运力迁移
export async function move(params) {
  return request('/api/bmkp-backend/asset/driver/move', {
    method: 'POST',
    body: params,
  });
}
import { message } from 'antd';
import { router } from 'umi';
import { DRIVER_INSIDE_NAMESPACE } from '../actions/driverinside';
import { statisticsNumber, list, detail, audit,
  supplementList, supplementDetail, supplementAudit,
  batchImportHistory, batchImportDetail} from '../services/driverinside';

export default {
  namespace: DRIVER_INSIDE_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    supplementData: {
      list: [],
      pagination: false,
    },
    batchImportHistoryData: {
      list: [],
      pagination: false,
    },
    batchImportDetailData: {
      list: [],
      pagination: false,
    },
    detail: {},
    supplementDetail: {},
  },
  effects: {
    *fetchStatisticsNumber({ payload, callback}, { call, put }) {
      const response = yield call(statisticsNumber, payload);
      if (response.success) {
        callback && callback(response.data)
      }
    },
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchSupplementList({ payload }, { call, put }) {
      const response = yield call(supplementList, payload);
      if (response.success) {
        yield put({
          type: 'saveSupplementList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDetail({ payload }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
      }
    },
    *fetchSupplementDetail({ payload ,callback}, { call, put }) {
      const response = yield call(supplementDetail, payload);
      if (response.success) {
        yield put({
          type: 'saveSupplementDetail',
          payload: {
            detail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
    *audit({ payload, callback}, { call }) {
      const response = yield call(audit, payload);
      if (response.success) {
        message.success(response.msg)
        callback && callback()
      } else {
        message.warn(response.msg)
      }
    },
    *auditSupplement({ payload, callback}, { call }) {
      const response = yield call(supplementAudit, payload);
      if (response.success) {
        message.success(response.msg)
        callback && callback()
      } else {
        message.warn(response.msg)
      }
    },
    *fetchBatchImportHistory({ payload }, { call, put }) {
      const response = yield call(batchImportHistory, payload);
      if (response.success) {
        yield put({
          type: 'saveBatchImportHistory',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchBatchImportDetail({ payload }, { call, put }) {
      const response = yield call(batchImportDetail, payload);
      if (response.success) {
        yield put({
          type: 'saveBatchImportDetail',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveSupplementList(state, action) {
      return {
        ...state,
        supplementData: action.payload,
      };
    },
    saveBatchImportHistory(state, action) {
      return {
        ...state,
        batchImportHistoryData: action.payload,
      };
    },
    saveBatchImportDetail(state, action) {
      return {
        ...state,
        batchImportDetailData: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    saveSupplementDetail(state, action) {
      return {
        ...state,
        supplementDetail: action.payload.detail,
      };
    },
  },
};

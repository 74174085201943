import { TRAVELRECORD_NAMESPACE } from '../actions/travelrecord';
import { list, detail } from '../services/travelrecord';

export default {
  namespace: TRAVELRECORD_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    details: [],
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDetail({ payload }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: response.data.details,
        });
      }
    },
    *clearDetail({ payload }, { put }) {
      yield put({
        type: 'removeDetail',
        payload: { payload },
      });
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        details: action.payload,
      };
    },
    removeDetail(state) {
      return {
        ...state,
        details: [],
      };
    },
  },
};

import { message } from 'antd';
import router from 'umi/router';
import { INVOICE_NAMESPACE } from '../actions/invoice';
import { list, detail, sendMail } from '../services/invoice';

export default {
  namespace: INVOICE_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    hasMore: true,
    detail: {},
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDetail({ payload }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
      }
    },
    *clearDetail({ payload }, { put }) {
      yield put({
        type: 'removeDetail',
        payload: { payload },
      });
    },
    *sendMail({ payload }, { call }) {
      const response = yield call(sendMail, payload);
      if (response.success) {
        message.success('发送成功');
      }
    },
    // *remove({ payload }, { call }) {
    //   const {
    //     data: { keys },
    //     success,
    //   } = payload;
    //   const response = yield call(remove, { ids: keys });
    //   if (response.success) {
    //     success();
    //   }
    // },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    },
  },
};

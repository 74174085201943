import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

// 新增景点
export async function addAttraction(params) {
  return request('/api/bmkp-backend/attraction/attraction/save', {
    method: 'POST',
    body: params
  })
}

// 编辑景点
export async function editAttraction(params) {
  return request('/api/bmkp-backend/attraction/attraction/update', {
    method: 'POST',
    body: params
  })
}

// 删除景点
export async function removeAttraction(params) {
  return request('/api/bmkp-backend/attraction/attraction/remove', {
    method: 'POST',
    body: params
  })
}

// 景点分页
export async function attractionPages(params) {
  return request(`/api/bmkp-backend/attraction/attraction/page?${stringify(params)}`);
}

// 景点详情
export async function attractionDetail(params) {
  return request(`/api/bmkp-backend/attraction/attraction/detail?${stringify(params)}`);
}

// 景点车型列表(暂时没用到)
export async function vehicleConfigList(params) {
  return request(`/api/bmkp-backend/attraction/attractionvehicleprice/page?${stringify(params)}`);
}

// 景点车型详情
export async function vehicleConfigDetail(params) {
  return request(`/api/bmkp-backend/attraction/attractionvehicleprice/attractionVehiclePriceDetail?${stringify(params)}`);
}

// 新增景点车型
export async function addVehicleConfig(params) {
  return request('/api/bmkp-backend/attraction/attractionvehicleprice/save', {
    method: 'POST',
    body: params
  })
}

// 编辑景点车型
export async function editVehicleConfig(params) {
  return request('/api/bmkp-backend/attraction/attractionvehicleprice/update', {
    method: 'POST',
    body: params
  })
}

// 删除景点车型
export async function removeVehicleConfig(params) {
  return request(`/api/bmkp-backend/attraction/attractionvehicleprice/remove?${stringify(params)}`, {
    method: 'POST',
    // body: func.toFormData(params),
  })
}

// 后台创建订单
export async function createOrder(params) {
  return request('/api/bmkp-wyc/wyc/order/operation/backCreateAttractionOrder', {
    method: 'POST',
    body: params
  })
}

// 待派单景点列表
export async function getWaitDispatchOrderList(params) {
  return request('/api/bmkp-wyc/wyc/order/operation/attrationOrder/waitDispatchAttractionList', {
    method: 'POST',
    body: params
  })
}

// 景点详情
export async function getAttractionDetail(params) {
  return request(`/api/bmkp-wyc/wyc/order/operation/attractionDetail?${stringify(params)}`)
}


// 改派指派司机列表查询
export async function driverList(params) {
  return request('/api/bmkp-wyc/wyc/order/operation/attractionOrderDispatchDriverList', {
    method: 'POST',
    body: params
  })
}

// 指定司机
export async function allotDriver(params) {
  return request('/api/bmkp-wyc/wyc/order/operation/allotDriver/attratiocnOrder', {
    method: 'POST',
    body: params
  })
}

// 景点包车订单分页
export async function getOrderPage(params) {
  return request('/api/bmkp-wyc/wyc/order/attraction/page', {
    method: 'POST',
    body: params
  })
}




export const CARSERIES_NAMESPACE = 'carseries';

export function CARSERIES_LIST(payload) {
  return {
    type: `${CARSERIES_NAMESPACE}/fetchList`,
    payload,
  };
}

export function CARSERIES_PAGE(payload) {
  return {
    type: `${CARSERIES_NAMESPACE}/fetchPage`,
    payload,
  };
}

export function CARSERIES_DETAIL(id) {
  return {
    type: `${CARSERIES_NAMESPACE}/fetchDetail`,
    payload: { id },
  };
}

export function CARSERIES_CLEAR_DETAIL() {
  return {
    type: `${CARSERIES_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function CARSERIES_SUBMIT(payload) {
  return {
    type: `${CARSERIES_NAMESPACE}/submit`,
    payload,
  };
}

export function CARSERIES_REMOVE(payload) {
  return {
    type: `${CARSERIES_NAMESPACE}/remove`,
    payload,
  };
}

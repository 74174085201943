export const VOICECONFIG_NAMESPACE = 'voiceconfig';

export function VOICECONFIG_PAGE_DRIVER(payload, callback) {
  return {
    type: `${VOICECONFIG_NAMESPACE}/pageDriver`,
    payload,
    callback
  };
}
export function VOICECONFIG_ADD_DRIVER(payload, callback) {
  return {
    type: `${VOICECONFIG_NAMESPACE}/addDriver`,
    payload,
    callback
  };
}
export function VOICECONFIG_UPDATE_DRIVER(payload, callback) {
  return {
    type: `${VOICECONFIG_NAMESPACE}/updateDriver`,
    payload,
    callback
  };
}
export function VOICECONFIG_CHANGESTATUS_DRIVER(payload, callback) {
  return {
    type: `${VOICECONFIG_NAMESPACE}/changeStatusDriver`,
    payload,
    callback
  };
}
export function VOICECONFIG_GETCONFIG_DRIVER(payload, callback) {
  return {
    type: `${VOICECONFIG_NAMESPACE}/getConfig`,
    payload,
    callback
  };
}

export function VOICECONFIG_SAVEDETAIL_DRIVER(payload, callback) {
  return {
    type: `${VOICECONFIG_NAMESPACE}/setDetail`,
    payload,
    callback
  }
}
export function VOICECONFIG_CLEARDETAIL_DRIVER(payload, callback) {
  return {
    type: `${VOICECONFIG_NAMESPACE}/setClearDetail`,
    payload,
    callback
  }
}

import { message } from 'antd';
import { FINANCE_NAMESPACE } from '../actions/finance';
import { driverBalancePage, driverWalletRecord, driverExportDetail } from '../services/finance';

export default {
  namespace: FINANCE_NAMESPACE,
  state: {
    balanceData: {
      list: [],
      pagination: false,
    },
    walletData: {
      list: [],
      pagination: false,
    },
  },
  effects: {
    *fetchBalanceList({ payload }, { call, put }) {
      const response = yield call(driverBalancePage, payload);
      if (response.success) {
        yield put({
          type: 'saveBalanceList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchWalletList({ payload }, { call, put }) {
      const response = yield call(driverWalletRecord, payload);
      if (response.success) {
        yield put({
          type: 'saveWalletList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
  },
  reducers: {
    saveBalanceList(state, action) {
      return {
        ...state,
        balanceData: action.payload,
      };
    },
    saveWalletList(state, action) {
      return {
        ...state,
        walletData: action.payload,
      };
    },
  },
};

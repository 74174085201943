export const CHANNEL_NAMESPACE = 'channel';

export function CHANNEL_LIST(payload) {
  return {
    type: `${CHANNEL_NAMESPACE}/fetchList`,
    payload,
  };
}

export function CHANNEL_SAVE(payload, callback) {
  return {
    type: `${CHANNEL_NAMESPACE}/submit`,
    payload,
    callback
  };
}

export function CHANNEL_DEL(payload) {
  return {
    type: `${CHANNEL_NAMESPACE}/delete`,
    payload,
  };
}

export function CHANNEL_INFO(payload, callback) {
  return {
    type: `${CHANNEL_NAMESPACE}/info`,
    payload,
    callback
  };
}
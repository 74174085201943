import { stringify } from 'qs';
import request from '../utils/request'

// 司机余额列表
export async function driverBalancePage(params) {
  return request('/api/bmkp-backend/finance/driver/balance/page', {
    method: 'POST',
    body: params,
  })
}

// 司机钱包明细
export async function driverWalletRecord(params) {
  return request('/api/bmkp-backend/customize/line/save', {
    method: 'POST',
    body: params,
  });
}

// 城内订单改价记录分页
export async function insideOrderChangePricePage(params) {
  return request('/api/bmkp-wyc/wyc/order/change/price/page', {
    method: 'POST',
    body: params
  })
}

import { stringify } from 'qs';
import request from '../utils/request';

// 乘客列表
export async function list(params) {
  return request('/api/bmkp-backend/asset/passenger/page', {
    method: 'POST',
    body: params,
  });
}

// 乘客详细信息
export async function detail(params) {
  return request(`/api/bmkp-backend/asset/passenger/getDetail?${stringify(params)}`, {
    method: 'POST',
  });
}

// 修改乘客状态 封号 解封
export async function updateStatus(params) {
  const { id, status } = params
  return request(`/api/bmkp-backend/asset/passenger/updateStatus?id=${id}`, {
    method: 'POST',
    body: { status },
  });
}

// 取消乘客实名认证
export async function cancelIdentity(params) {
  return request('/api/bmkp-backend/asset/passenger/cancelIdentity', {
    method: 'POST',
    body: params,
  });
}



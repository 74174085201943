export const ASSETDRIVER_APPLY_NAMESPACE = 'assetdriverapply';

export function ASSETDRIVER_APPLY_LIST(payload) {
  return {
    type: `${ASSETDRIVER_APPLY_NAMESPACE}/fetchList`,
    payload,
  };
}

export function ASSETDRIVER_APPLY_DETAIL(id) {
  return {
    type: `${ASSETDRIVER_APPLY_NAMESPACE}/fetchDetail`,
    payload: { driverId: id },
  }
}

export function ASSETDRIVER_APPLY_UPDATE_STATUS(payload) {
  return {
    type: `${ASSETDRIVER_APPLY_NAMESPACE}/updateStatus`,
    payload,
  }
}

import { message } from 'antd';
import { DRIVERCERTIFICATES_NAMESPACE } from '../actions/drivercertificates';
import {
  page,
  licenceExport,
  updateIdCard,
  detailDriver,
  detailLicense,
  detailHailingLicense,
  submitLicense,
  submitHailinglicense,
  submitOtherInfo,
  submitContract
} from '../services/drivercertificates';

export default {
  namespace: DRIVERCERTIFICATES_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: {},
    licenseDetail: {},
    hailingLicenseDetail: {},
  },
  effects: {
    *fetchPage({ payload }, { call, put }) {
      const response = yield call(page, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *exportCluePage({ payload, callback }, { call }) {
      const response = yield call(licenceExport, payload);
      if (response.success) {
        message.success('导出');
        callback()
      }
    },
    *driverDetail({ payload, callback }, { call, put }) {
      const response = yield call(detailDriver, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: response.data,
        });
        callback && callback(response.data)
      }
    },
    *idCardUpdate({ payload }, { call }) {
      const response = yield call(updateIdCard, payload);
      if (response.success) {
        message.success('提交成功');
      }
    },
    *licenseDetail({ payload, callback }, { call, put }) {
      const response = yield call(detailLicense, payload);
      if (response.success) {
        yield put({
          type: 'saveLicenseDetail',
          payload: response.data,
        });
        callback && callback(response.data)
      }
    },
    *hailingLicenseDetail({ payload, callback }, { call, put }) {
      const response = yield call(detailHailingLicense, payload);
      if (response.success) {
        yield put({
          type: 'saveHailingLicenseDetail',
          payload: response.data,
        });
        callback && callback(response.data)
      }
    },
    *licenseSubmit({ payload, callback }, { call }) {
      const response = yield call(submitLicense, payload);
      if (response.success) {
        message.success('提交成功');
      }
      callback && callback(response.data)
    },
    *hailinglicenseSubmit({ payload, callback }, { call }) {
      const response = yield call(submitHailinglicense, payload);
      if (response.success) {
        message.success('提交成功');
      }
      callback && callback(response.data)
    },
    *otherInfoSubmit({ payload, callback }, { call }) {
      const response = yield call(submitOtherInfo, payload);
      if (response.success) {
        message.success('提交成功');
      }
      callback && callback(response.data)
    },
    *contractSubmit({ payload, callback }, { call }) {
      const response = yield call(submitContract, payload);
      if (response.success) {
        message.success('提交成功');
      }
      callback && callback(response.data)
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload,
      };
    },
    clearDetail(state) {
      return {
        ...state,
        detail: {}
      }
    },
    saveLicenseDetail(state, action) {
      return {
        ...state,
        licenseDetail: action.payload,
      };
    },
    saveHailingLicenseDetail(state, action) {
      return {
        ...state,
        hailingLicenseDetail: action.payload,
      };
    },
  },
};

export const ORDER_NAMESPACE = 'order';

export function ORDER_LIST(payload) {
  return {
    type: `${ORDER_NAMESPACE}/fetchList`,
    payload,
  };
}

export function ORDER_DETAIL(orderNo, callback) {
  return {
    type: `${ORDER_NAMESPACE}/fetchDetail`,
    payload: { orderNo },
    callback
  };
}

export function ORDER_CLEAR_DETAIL() {
  return {
    type: `${ORDER_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function ORDER_CREATE(payload) {
  return {
    type: `${ORDER_NAMESPACE}/create`,
    payload,
  };
}

export function ORDER_CANCEL(payload, callback) {
  return {
    type: `${ORDER_NAMESPACE}/cancel`,
    payload,
    callback
  };
}

// 计算订单价格
export function ORDER_CALC_PRICE(payload) {
  return {
    type: `${ORDER_NAMESPACE}/calcPrice`,
    payload,
  };
}

export function QUERY_ORDER_UPDATE_PRICE_DETAIL(payload, callback) {
  return {
    type: `${ORDER_NAMESPACE}/queryUpdateOrderPrice`,
    payload,
    callback
  };
}

export function SAVE_ORDER_UPDATE_PRICE_DETAIL(payload, callback) {
  return {
    type: `${ORDER_NAMESPACE}/saveUpdateOrderPrice`,
    payload,
    callback
  };
}

export function QUERY_REFUND_ORDER(payload, callback) {
  return {
    type: `${ORDER_NAMESPACE}/queryRefundOrder`,
    payload,
    callback
  };
}

export function REFUND_ORDER_CLEAR_DETAIL() {
  return {
    type: `${ORDER_NAMESPACE}/clearRefundOrderDetail`,
    payload: {},
  };
}

export function APPLY_REUND_ORDER(payload, callback) {
  return {
    type: `${ORDER_NAMESPACE}/applyRefundOrder`,
    payload,
    callback
  };
}

export function INTERCITY_CLOSE_ORDER(payload, callback) {
  return {
    type: `${ORDER_NAMESPACE}/closeOrder`,
    payload,
    callback
  };
}
export const LINE_DISPATCH_RULE_NAMESPACE = 'lineDispatchRule';

export function LINE_DISPATCH_RULE_DETAIL(id, callback) {
  return {
    type: `${LINE_DISPATCH_RULE_NAMESPACE}/fetchDetail`,
    payload: { lineId: id },
    callback
  };
}

export function LINE_DISPATCH_RULE_SUBMIT(payload) {
  return {
    type: `${LINE_DISPATCH_RULE_NAMESPACE}/submit`,
    payload
  };
}

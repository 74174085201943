import { message } from 'antd';
import router from 'umi/router';
import { INTERCITY_COMMON_CONFIG_NAMESPACE } from '../actions/intercitycommonconfig';
import {
  detail,
  submit
} from '../services/intercitycommonconfig';

export default {
  namespace: INTERCITY_COMMON_CONFIG_NAMESPACE,
  state: {
    detail: {
      status: 0,
      name: '',
      minutes: 10
    }
  },
  effects: {
    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
        callback && callback(response.data)
      }
    },
    *submit({ payload }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('提交成功');
        // router.push('/customize/lineConfig');
      }
    },
  },
  reducers: {
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    }
  },
};

export const LINECONFIG_NAMESPACE = 'lineConfig';

export function LINECONFIG_LIST_NEW(payload, callback = null) {
  return {
    type: `${LINECONFIG_NAMESPACE}/fetchListNew`,
    payload,
    callback
  };
}

export function LINECONFIG_LIST(payload, callback = null) {
  return {
    type: `${LINECONFIG_NAMESPACE}/fetchList`,
    payload,
    callback
  };
}
export function LINECONFIG_LIST_SUPPLIER(payload) {
  return {
    type: `${LINECONFIG_NAMESPACE}/fetchListBySupplier`,
    payload,
  };
}
export function LINECONFIG_LIST_AGENT(payload) {
  return {
    type: `${LINECONFIG_NAMESPACE}/fetchListByAgent`,
    payload,
  };
}
export function LINECONFIG_ORIGIN_LIST(payload) {
  return {
    type: `${LINECONFIG_NAMESPACE}/fetchOriginList`,
    payload,
  };
}
export function LINECONFIG_DESTINATION_LIST(payload) {
  return {
    type: `${LINECONFIG_NAMESPACE}/fetchDestinationList`,
    payload,
  };
}

export function LINECONFIG_DETAIL(id, callback) {
  return {
    type: `${LINECONFIG_NAMESPACE}/fetchDetail`,
    payload: { id },
    callback
  };
}

export function LINECONFIG_DETAIL_BY_CODE(payload, callback) {
  return {
    type: `${LINECONFIG_NAMESPACE}/fetchDetailByCode`,
    payload,
    callback
  };
}

export function LINECONFIG_CITYS_INFO_LIST(payload, callback) {
  return {
    type: `${LINECONFIG_NAMESPACE}/fetchCitysInfo`,
    payload,
    callback
  }
}

export function LINECONFIG_CLEAR_DETAIL() {
  return {
    type: `${LINECONFIG_NAMESPACE}/clearDetail`,
    payload: null,
  };
}

export function LINECONFIG_SUBMIT(payload) {
  return {
    type: `${LINECONFIG_NAMESPACE}/submit`,
    payload
  };
}
export function LINECONFIG_UPDATE(payload, callback) {
  return {
    type: `${LINECONFIG_NAMESPACE}/update`,
    payload,
    callback
  };
}
export function LINECONFIG_UPDATE_OPERATION_STATUS(payload, callback) {
  return {
    type: `${LINECONFIG_NAMESPACE}/updateOperationStatus`,
    payload,
    callback
  };
}
export function LINECONFIG_UPDATE_BATCH(payload, callback) {
  return {
    type: `${LINECONFIG_NAMESPACE}/updateBatch`,
    payload,
    callback
  };
}

export function LINECONFIG_REMOVE(payload) {
  return {
    type: `${LINECONFIG_NAMESPACE}/remove`,
    payload,
  };
}

export function LINECONFIG_LINE_OPERATION_CONFIG_DETAIL(id) {
  return {
    type: `${LINECONFIG_NAMESPACE}/fetchLineOperationConfigDetail`,
    payload: { id },
  };
}

export function CHECK_LINE_OPERATION_STATUS(id) {
  return {
    type: `${LINECONFIG_NAMESPACE}/checkLineOperationStatus`,
    payload: { id },
  };
}

export function CLEAR_LINE_OPERATION_STATUS() {
  return {
    type: `${LINECONFIG_NAMESPACE}/clearLineOperationStatus`,
    payload: null,
  };
}

export function LINECONFIG_UPDATTE_BATCH(payload, callback) {
  return {
    type: `${LINECONFIG_NAMESPACE}/updateBatchline`,
    payload,
    callback
  };
}


import func from '../utils/Func';
import request from '../utils/request';

export async function list(params) {
  return request('/api/bmkp-wyc/wyc/order/page', {
    method: 'POST',
    body: params,
  });
}

// 创建订单
export async function submit(params) {
  return request('/api/bmkp-backend/blade-order/order/create', {
    method: 'POST',
    body: params,
  });
}

// 计算订单价格
// export async function calcPrice(params) {
//   return request('/api/bmkp-backend/blade-order/order/calculate', {
//     method: 'POST',
//     body: params,
//   });
// }

export async function detail(params) {
  return request('/api/bmkp-wyc/wyc/order/detail', {
    method: 'POST',
    body: params,
  });
}

// export async function remove(params) {
//   return request('/api/bmkp-backend/blade-order/order/remove', {
//     method: 'POST',
//     body: func.toFormData(params),
//   });
// }

// 取消订单
// export async function cancel(params) {
//   return request('/api/bmkp-backend/blade-order/order/cancel', {
//     method: 'POST',
//     body: params
//   });
// }

// 改派订单
export async function changeOrder(params) {
  return request('/api/bmkp-wyc/wyc/order/operation/change/order', {
    method: 'POST',
    body: params
  });
}

// 关闭订单
export async function closeOrder(params) {
  return request('/api/bmkp-wyc/wyc/order/operation/close/order', {
    method: 'POST',
    body: params
  });
}

// 结束行程
export async function finishOrder(params) {
  return request('/api/bmkp-wyc/wyc/order/operation/finish/trip', {
    method: 'POST',
    body: params
  });
}

// 退款申请
export async function refundApply(params) {
  return request('/api/bmkp-wyc/wyc/order/operation/refund/apply', {
    method: 'POST',
    body: params
  });
}

// 修改金额
export async function updateFee(params) {
  return request('/api/bmkp-wyc/wyc/order/operation/update/price', {
    method: 'POST',
    body: params
  });
}

// 停止校验乘客手机号
export async function stopCheckPassengerPhone(params) {
  return request('/api/bmkp-wyc/wyc/order/operation/stop/check/phone', {
    method: 'POST',
    body: params
  })
}

// 获取行程录音
export async function queryRecording(params) {
  return request('/api/bmkp-wyc/wyc/order/operation/query/recording', {
    method: 'POST',
    body: params
  })
}

// 修改金额前查询金额
export async function queryPriceWithUpdate(params) {
  return request('/api/bmkp-wyc/wyc/order/operation/update/price/query/price', {
    method: 'POST',
    body: params
  })
}

// 结束行程前查询金额
export async function queryPriceWithTrip(params) {
  return request('/api/bmkp-wyc/wyc/order/operation/finish/trip/query/price', {
    method: 'POST',
    body: params
  })
}

// 退款申请前查询金额
export async function queryPriceWithApply(params) {
  return request('/api/bmkp-wyc/wyc/order/operation/refund/apply/query/price', {
    method: 'POST',
    body: params
  })
}

// 结束行程前根据修改的公里数和时间获取行程费用
export async function calcPriceWithTrip(params) {
  return request('/api/bmkp-wyc/wyc/order/operation/finish/trip/get/trip/price', {
    method: 'POST',
    body: params
  })
}

// 修改金额前根据修改的公里数和时间获取行程费用
export async function calcPriceWithUpdate(params) {
  return request('/api/bmkp-wyc/wyc/order/operation/update/price/get/trip/price', {
    method: 'POST',
    body: params
  })
}
export const LINE_GRABS_RULE_NAMESPACE = 'lineGrabsRule';

export function LINE_GRABS_RULE_DETAIL(id, callback) {
  return {
    type: `${LINE_GRABS_RULE_NAMESPACE}/fetchDetail`,
    payload: { lineId: id },
    callback
  };
}
export function LINE_GRABS_RULE_SUBMIT(payload) {
  return {
    type: `${LINE_GRABS_RULE_NAMESPACE}/submit`,
    payload
  };
}

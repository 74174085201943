export const ASSETCAR_NAMESPACE = 'assetCar';

export function ASSETCAR_LIST(payload) {
  return {
    type: `${ASSETCAR_NAMESPACE}/fetchList`,
    payload,
  };
}

// 删除车辆
export function ASSETCAR_DELETE(payload,callback) {
  return {
    type: `${ASSETCAR_NAMESPACE}/fetchDelete`,
    payload,
    callback
  };
}

export function ASSET_USABLED_CAR_LIST(payload, callback) {
  return {
    type: `${ASSETCAR_NAMESPACE}/fetchUsableCarList`,
    payload,
    callback
  };
}

export function ASSETCAR_DETAIL(id, callback) {
  return {
    type: `${ASSETCAR_NAMESPACE}/fetchDetail`,
    payload: { carId: id },
    callback
  };
}

export function ASSETCAR_CLEAR_DETAIL() {
  return {
    type: `${ASSETCAR_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

// 行驶证
export function ASSETCAR_CLEAR_CAR_LICENSE_DETAIL() {
  return {
    type: `${ASSETCAR_NAMESPACE}/clearCarlicenseDetail`,
    payload: {},
  };
}

// 网约车运输证
export function ASSETCAR_CLEAR_CAR_HAILINGLICENSE_DETAIL() {
  return {
    type: `${ASSETCAR_NAMESPACE}/clearCarhailinglicenseDetail`,
    payload: {},
  };
}

// 网约车驾驶证
export function ASSETCAR_CLEAR_DRIVER_HAILINGLICENSE_DETAIL() {
  return {
    type: `${ASSETCAR_NAMESPACE}/clearDriverhailinglicenseDetail`,
    payload: {},
  };
}

// 道路运营证
export function ASSETCAR_CLEAR_CAR_TRANSPORT_DETAIL() {
  return {
    type: `${ASSETCAR_NAMESPACE}/clearCartransportDetail`,
    payload: {},
  };
}

export function ASSETCAR_SUBMIT(payload, callback) {
  return {
    type: `${ASSETCAR_NAMESPACE}/submit`,
    payload,
    callback
  };
}

export function ASSETCAR_REMOVE(payload) {
  return {
    type: `${ASSETCAR_NAMESPACE}/remove`,
    payload,
  };
}

export function ASSETCAR_CAR_FETCH_CARHAILINGLICENSE(payload,callback) {
  return {
    type: `${ASSETCAR_NAMESPACE}/fetchCarhailinglicense`,
    payload,
    callback
  };
}

export function ASSETCAR_CAR_SUBMIT_CARHAILINGLICENSE(payload) {
  return {
    type: `${ASSETCAR_NAMESPACE}/submitCarhailinglicense`,
    payload,
  };
}

export function ASSETCAR_CAR_FETCH_DRIVERHAILINGLICENSE(payload,callback) {
  return {
    type: `${ASSETCAR_NAMESPACE}/fetchDriverhailinglicense`,
    payload,
    callback
  };
}

export function ASSETCAR_CAR_SUBMIT_DRIVERHAILINGLICENSE(payload) {
  return {
    type: `${ASSETCAR_NAMESPACE}/submitDriverhailinglicense`,
    payload,
  };
}

export function ASSETCAR_CAR_FETCH_CARINFO(payload) {
  return {
    type: `${ASSETCAR_NAMESPACE}/fetchCarInfo`,
    payload,
  };
}

export function ASSETCAR_CAR_SUBMIT_CARINFO(payload) {
  return {
    type: `${ASSETCAR_NAMESPACE}/submitCarInfo`,
    payload,
  };
}

export function ASSETCAR_CAR_FETCH_CARLICENSE(payload) {
  return {
    type: `${ASSETCAR_NAMESPACE}/fetchCarlicense`,
    payload,
  };
}

export function ASSETCAR_CAR_FETCH_CARTRANSPORT(payload, callback) {
  return {
    type: `${ASSETCAR_NAMESPACE}/fetchCartransport`,
    payload,
    callback
  };
}

export function ASSETCAR_CAR_SUBMIT_CARLICENSE(payload) {
  return {
    type: `${ASSETCAR_NAMESPACE}/submitCarlicense`,
    payload,
  };
}

export function ASSETCAR_CAR_SUBMIT_CARTRANSPORT(payload) {
  return {
    type: `${ASSETCAR_NAMESPACE}/submitCartransport`,
    payload,
  };
}

export function ASSETCAR_IMPORT_HISTORY_LIST(payload) {
  return {
    type: `${ASSETCAR_NAMESPACE}/importHistory`,
    payload,
  };
}
export function ASSETCAR_IMPORT_ERROR_DETAIL(payload, callback) {
  return {
    type: `${ASSETCAR_NAMESPACE}/fetchBindErrorDetail`,
    payload,
    callback
  };
}

// 商业险
export function ASSETCAR_CARINSURANCE_DETAIL(payload, callback) {
  return {
    type: `${ASSETCAR_NAMESPACE}/fetchCarinsuranceDetail`,
    payload,
    callback
  };
}
export function ASSETCAR_CARINSURANCE_SUBMIT(payload, callback) {
  return {
    type: `${ASSETCAR_NAMESPACE}/carinsuranceSubmit`,
    payload,
    callback
  };
}

// 其他信息提交
export function ASSETCAR_OTHER_INFO_SUBMIT(payload, callback) {
  return {
    type: `${ASSETCAR_NAMESPACE}/otherInfoSubmit`,
    payload,
    callback
  };
}
import { INSIDEREFUNDAPPLY_NAMESPACE } from '../actions/insiderefundapply';
import { list, detail, record } from '../services/insiderefundapply';

export default {
  namespace: INSIDEREFUNDAPPLY_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: { },
    record: {}
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchDetail({ payload }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
      }
    },
    *fetchRecord({ payload, callback }, { call, put }) {
      const response = yield call(record, payload);
      if (response.success) {
        yield put({
          type: 'saveRecord',
          payload: {
            record: response.data,
          },
        });
        callback(response.data)
      }
    },
    *clearDetail({ payload }, { put }) {
      yield put({
        type: 'removeDetail',
        payload: { payload },
      });
    },
    *clearRecord({ payload }, { put }) {
      yield put({
        type: 'removeRecord',
        payload: { payload },
      });
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    saveRecord(state, action) {
      return {
        ...state,
        record: action.payload.record,
      };
    },
    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    },
    removeRecord(state) {
      return {
        ...state,
        record: {},
      };
    },
  },
};

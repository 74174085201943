export const DRVIER_BINDING_NAMESPACE = 'driverBinding';

export function DRVIER_BINDING_LIST(payload) {
  return {
    type: `${DRVIER_BINDING_NAMESPACE}/fetchList`,
    payload,
  };
}

export function DRVIER_BINDING_HISTORY_LIST(payload) {
  return {
    type: `${DRVIER_BINDING_NAMESPACE}/fetchBindHistroyList`,
    payload,
  };
}
export function DRVIER_BINDING_ERROR_DETAIL(payload, callback) {
  return {
    type: `${DRVIER_BINDING_NAMESPACE}/fetchBindErrorDetail`,
    payload,
    callback
  };
}

export function DRVIER_BINDING_DETAIL(id, callback) {
  return {
    type: `${DRVIER_BINDING_NAMESPACE}/fetchDetail`,
    payload: { carId: id },
    callback
  };
}

export function DRVIER_BINDING_CLEAR_DETAIL() {
  return {
    type: `${DRVIER_BINDING_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function DRVIER_BINDING_SUBMIT(payload) {
  return {
    type: `${DRVIER_BINDING_NAMESPACE}/bind`,
    payload,
  };
}
export function DRVIER_UNBINDING_SUBMIT(payload, callback) {
  return {
    type: `${DRVIER_BINDING_NAMESPACE}/unbound`,
    payload,
    callback
  };
}

export function DRVIER_BINDING_REMOVE(payload) {
  return {
    type: `${DRVIER_BINDING_NAMESPACE}/remove`,
    payload,
  };
}

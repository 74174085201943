export const CUSTOMER_SERVICE_NAMESPACE = 'customerservice';

export function CUSTOMER_SERVICE_GET_PRIVACY_NUMBER(payload) {
  return {
    type: `${CUSTOMER_SERVICE_NAMESPACE}/fetchPrivacyNumber`,
    payload,
  };
}

export function CUSTOMER_SERVICE_SET_PRIVACY_NUMBER_STAUTS(id, callback) {
  return {
    type: `${CUSTOMER_SERVICE_NAMESPACE}/savePrivacyNumberStatus`,
    payload: { id },
    callback
  };
}
export const DISPATCHER_AND_LINE_NAMESPACE = 'dispatcherAndLine';

export function DISPATCHER_AND_LINE_LIST(payload) {
  return {
    type: `${DISPATCHER_AND_LINE_NAMESPACE}/fetchList`,
    payload,
  };
}
export function DISPATCHER_AND_LINE_DETAIL(id, callback) {
  return {
    type: `${DISPATCHER_AND_LINE_NAMESPACE}/fetchDetail`,
    payload: { id },
    callback
  };
}

export function DISPATCHER_AND_LINE_SUBMIT(payload, callback) {
  return {
    type: `${DISPATCHER_AND_LINE_NAMESPACE}/submit`,
    payload,
    callback
  };
}
export function DISPATCHER_AND_LINE_UPDATE(payload) {
  return {
    type: `${DISPATCHER_AND_LINE_NAMESPACE}/update`,
    payload
  };
}

export function DISPATCHER_AND_LINE_REMOVE(payload) {
  return {
    type: `${DISPATCHER_AND_LINE_NAMESPACE}/remove`,
    payload,
  };
}

export const PASSENGERCANCEL_NAMESPACE = 'passengercancel';

export function PASSENGERCANCEL_FETCHLIST(payload, callback) {
  return {
    type: `${PASSENGERCANCEL_NAMESPACE}/fetchList`,
    payload,
    callback
  };
}

export function PASSENGERCANCEL_QUERY(payload, callback) {
  return {
    type: `${PASSENGERCANCEL_NAMESPACE}/query`,
    payload,
    callback
  };
}

export function PASSENGERCANCEL_ADD(payload, callback) {
  return {
    type: `${PASSENGERCANCEL_NAMESPACE}/add`,
    payload,
    callback
  };
}

export function PASSENGERCANCEL_SAVE(payload, callback) {
  return {
    type: `${PASSENGERCANCEL_NAMESPACE}/save`,
    payload,
    callback
  };
}

export function PASSENGERCANCEL_UPDATE(payload, callback) {
  return {
    type: `${PASSENGERCANCEL_NAMESPACE}/update`,
    payload,
    callback
  };
}

export function PASSENGERCANCEL_CLOSE(payload, callback) {
  return {
    type: `${PASSENGERCANCEL_NAMESPACE}/close`,
    payload,
    callback
  };
}

export function PASSENGERCANCEL_MOVE(payload, callback) {
  return {
    type: `${PASSENGERCANCEL_NAMESPACE}/move`,
    payload,
    callback
  };
}

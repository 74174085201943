import { stringify } from 'qs';
import request from '../utils/request';

// 提现记录详情
export async function detail(params) {
  return request(`/api/bmkp-backend/finance/withdraw/detail?${stringify(params)}`);
}

// 提现记录查询
export async function list(params) {
  return request('/api/bmkp-backend/finance/withdraw/page', {
    method: 'POST',
    body: params,
  });
}

// 批量提现审批
export async function batchAudit(params) {
  return request('/api/bmkp-backend/finance/withdraw/batchAudit', {
    method: 'POST',
    body: params,
  });
}

export async function configPage(params) {
  return request('/api/bmkp-backend/finance/withdrawconfig/page', {
    method: 'POST',
    body: params,
  });
}


export async function configSubmit(params) {
  return request('/api/bmkp-backend/finance/withdrawconfig/submit', {
    method: 'POST',
    body: params,
  });
}

export async function configDetail(params) {
  return request(`/api/bmkp-backend/finance/withdrawconfig/detail?${stringify(params)}`);
}

export async function insideConfigOpen(params) {
  return request('/api/bmkp-backend/finance/withdrawconfig/open', {
    method: 'POST',
    body: params,
  });
}
export async function insideConfigClose(params) {
  return request('/api/bmkp-backend/finance/withdrawconfig/close', {
    method: 'POST',
    body: params,
  });
}

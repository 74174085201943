export const WITHDRAWAL_NAMESPACE = 'withdrawal';

export function WITHDRAWAL_LIST(payload) {
  return {
    type: `${WITHDRAWAL_NAMESPACE}/fetchList`,
    payload,
  };
}

export function WITHDRAWAL_DETAIL(withdrawCode) {
  return {
    type: `${WITHDRAWAL_NAMESPACE}/fetchDetail`,
    payload: { withdrawCode },
  };
}

export function WITHDRAWAL_CLEAR_DETAIL() {
  return {
    type: `${WITHDRAWAL_NAMESPACE}/clearDetail`,
    payload: {},
  };
}

export function WITHDRAWAL_BATCH_AUDIT(payload) {
  return {
    type: `${WITHDRAWAL_NAMESPACE}/batchAudit`,
    payload,
  };
}

export function WITHDRAW_CONFIG_LIST(payload) {
  return {
    type: `${WITHDRAWAL_NAMESPACE}/configFetchList`,
    payload,
  };
}

export function WITHDRAW_CONFIG_LIST_RESET(payload) {
  return {
    type: `${WITHDRAWAL_NAMESPACE}/configFetchListReset`,
    payload,
  };
}
//

// 某个提现配置详情
export function WITHDRAW_CONFIG_DETAIL(payload, callback) {
  return {
    type: `${WITHDRAWAL_NAMESPACE}/configDetail`,
    payload,
    callback
  };
}
// 重置配置详情
export function WITHDRAW_CONFIG_DETAIL_RESET(payload, callback) {
  return {
    type: `${WITHDRAWAL_NAMESPACE}/resetConfigDetail`,
    payload,
    callback
  };
}

// 新增/修改提现配置
export function WITHDRAW_CONFIG_SUBMIT(payload, callback) {
  return {
    type: `${WITHDRAWAL_NAMESPACE}/configSubmit`,
    payload,
    callback
  };
}

// 城内提现配置开启
export function WITHDRAW_CONFIG_OPEN(payload, callback) {
  return {
    type: `${WITHDRAWAL_NAMESPACE}/insideConfigOpen`,
    payload,
    callback
  };
}

// 城内提现配置关闭
export function WITHDRAW_CONFIG_CLOSE(payload, callback) {
  return {
    type: `${WITHDRAWAL_NAMESPACE}/insideConfigClose`,
    payload,
    callback
  };
}


import { stringify } from 'qs';
import func from '../utils/Func';
import request from '../utils/request';

export async function list(params) {
  return request('/api/bmkp-wyc/wyc/dispatch/rules/page/v1', {
    method: 'POST',
    body: params
  });
}

// 派单规则设置提交
export async function submit(params) {
  return request('/api/bmkp-wyc/wyc/dispatch/rules/submit/v1', {
    method: 'POST',
    body: params,
  });
}

// 查询派单详情
export async function detail(params) {
  return request('/api/bmkp-wyc/wyc/dispatch/rules/detail/v1', {
    method: 'POST',
    body: params,
  });
}

// 派单规则城市启用和关闭
export async function changeStatus(params) {
  return request('/api/bmkp-wyc/wyc/dispatch/rules/change/status/v1', {
    method: 'POST',
    body: params,
  });
}
import { message } from 'antd';
import router from 'umi/router';
import { DRIVERLICENSE_NAMESPACE } from '@/actions/driverlicense';
import { submit, detail } from '@/services/driverlicense';

export default {
  namespace: DRIVERLICENSE_NAMESPACE,
  state: {
    detail: {},
  },
  effects: {
    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data,
          },
        });
        // eslint-disable-next-line no-unused-expressions
        callback && callback(response.data)
      }
    },
    *submit({ payload }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('提交成功');
        router.push('/driver/base');
      }
    },
  },
  reducers: {
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    }
  },
};

import { message} from 'antd';
import router from 'umi/router';
import { BILLING_CONFIG_NAMESPACE } from '../actions/billingconfig';
import {
  list,
  detail,
  submit,
  changeList,
  checkConfig
} from '../services/billingconfig';

export default {
  namespace: BILLING_CONFIG_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    changeData: {
      list: [],
      pagination: false
    },
    detail: {}
  },
  effects: {
    *changeList({ payload }, { call, put }) {
      const response = yield call(changeList, payload);
      if (response.success) {
        yield put({
          type: 'saveChangList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (response.success) {
        if (response.data) {
          yield put({
            type: 'saveList',
            payload: {
              list: response.data.records,
              pagination: {
                total: response.data.total,
                current: response.data.current,
                pageSize: response.data.size,
              },
            },
          });
        } else {
          yield put({
            type: 'saveList',
            payload: {
              list: [],
              pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
              },
            },
          });
        }
      }
    },
    *quickSetting({ payload }, { put }) {
      yield put({
        type: 'saveDetail',
        payload: { detail: payload }
      })
    },
    *fetchDetail({ payload, callback }, { call, put }) {
      const response = yield call(detail, payload);
      if (response.success) {
        yield put({
          type: 'saveDetail',
          payload: {
            detail: response.data || {},
          },
        })
        callback(response.data)
      }
    },
    *checkConfig({ payload, callback }, { call }) {
      const response = yield call(checkConfig, payload)
      if (response.success) {
        callback(response.data)
      }
    },
    *clearDetail({ payload }, { put }) {
      yield put({
        type: 'removeDetail',
        payload: { payload },
      });
    },
    *submit({ payload }, { call }) {
      const response = yield call(submit, payload);
      if (response.success) {
        message.success('提交成功');
        router.goBack();
      }
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    saveChangList(state, action) {
      return {
        ...state,
        changeData: action.payload,
      };
    },
    removeDetail(state) {
      return {
        ...state,
        detail: {},
      };
    }
  },
};

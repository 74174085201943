export default {
  'menu.home': 'Home',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.register.result': 'Register Result',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Analysis',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Workplace',
  'menu.desk': 'desktop',
  'menu.desk.notice': 'notice',
  'menu.work': 'work',
  'menu.work.work_start': 'work start',
  'menu.work.work_claim': 'work claim',
  'menu.work.work_todo': 'work todo',
  'menu.work.work_send': 'work sent',
  'menu.work.work_done': 'work history',
  'menu.base': 'base',
  'menu.base.region': 'region',
  'menu.authority': 'authority',
  'menu.authority.role': 'role',
  'menu.authority.data_scope': 'data scope',
  'menu.authority.api_scope': 'api scope',
  'menu.system': 'system',
  'menu.system.user': 'user',
  'menu.system.dept': 'department',
  'menu.system.post': 'post',
  'menu.system.dict': 'dictionary',
  'menu.system.dictbiz': 'biz dictionary',
  'menu.system.menu': 'menu',
  'menu.system.topmenu': 'topmenu',
  'menu.system.param': 'parameter',
  'menu.system.tenant': 'tenant',
  'menu.system.client': 'client',
  'menu.monitor': 'monitor',
  'menu.monitor.log': 'log',
  'menu.monitor.log.log_usual': 'usual log',
  'menu.monitor.log.log_api': 'api log',
  'menu.monitor.log.log_error': 'error log',
  'menu.monitor.admin': 'service admin',
  'menu.monitor.doc': 'api doc',
  'menu.monitor.elk': 'ELK',
  'menu.monitor.zipkin': 'Zipkin',
  'menu.monitor.turbine': 'Turbine',
  'menu.monitor.sentinel': 'Sentinel',
  'menu.monitor.es': 'Elasticsearch',
  'menu.tool': 'develop',
  'menu.tool.code': 'code generate',
  'menu.tool.datasource': 'datasource',
  'menu.flow': 'workflow',
  'menu.flow.flow_model': 'flow model',
  'menu.flow.flow_deploy': 'flow deploy',
  'menu.flow.flow_manager': 'flow manager',
  'menu.flow.flow_follow': 'flow follow',
  'menu.report': 'report',
  'menu.report.report_setting': 'report setting',
  'menu.report.report_list': 'report list',
  'menu.report.report_notice': 'report notice',
  'menu.resource': 'resource',
  'menu.resource.oss': 'oss',
  'menu.resource.attach': 'attach',
  'menu.resource.sms': 'sms',
  'menu.resource.xxljob': 'xxljob',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.password': 'Modify Password',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.clear': 'Clear Cache',
  'menu.account.logout': 'Logout',
  'menu.editor': 'Graphic Editor',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',
};

import { message } from 'antd';
import { DRIVERECLUE_NAMESPACE } from '../actions/driverclue';
import {
  page,
  exportCluePage,
} from '../services/driverclue';

export default {
  namespace: DRIVERECLUE_NAMESPACE,
  state: {
    data: {
      list: [],
      pagination: false,
    },
    detail: {},
  },
  effects: {
    *page({ payload }, { call, put }) {
      const response = yield call(page, payload);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            list: response.data.records,
            pagination: {
              total: response.data.total,
              current: response.data.current,
              pageSize: response.data.size,
            },
          },
        });
      }
    },
    *exportCluePage({ payload, callback }, { call }) {
      const response = yield call(exportCluePage, payload);
      if (response.success) {
        message.success('导出');
        callback()
      }
    },
    // *open({ payload, callback }, { call }) {
    //   const response = yield call(open, payload);
    //   if (response.success) {
    //     callback(payload.id)
    //   }
    // },
    // *close({ payload, callback }, { call }) {
    //   const response = yield call(close, payload);
    //   if (response.success) {
    //     callback(payload.id)
    //   }
    // },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        detail: action.payload.detail,
      };
    },
    clearDetail(state) {
      return {
        ...state,
        detail: {}
      }
    }
  },
};

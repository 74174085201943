import { stringify } from 'qs';
// import func from '@/utils/Func';
import request from '@/utils/request';

export async function list(params) {
  return request('/api/bmkp-backend/dj/hireStore/page', {
    method: 'POST',
    body: params,
  });
}
export async function add(params) {
  return request('/api/bmkp-backend/dj/hireStore/save', {
    method: 'POST',
    body: params,
  });
}

export async function update(params) {
  return request('/api/bmkp-backend/dj/hireStore/update', {
    method: 'POST',
    body: params,
  });
}

export async function detail(params) {
  return request(`/api/bmkp-backend/dj/hireStore/detail?${stringify(params)}`);
}

export async function del(params) {
  return request(`/api/bmkp-backend/dj/hireStore/remove?${stringify(params)}`);
}

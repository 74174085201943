import { stringify } from 'qs';
import request from '../utils/request';

// 查询所有待派单订单线路
export async function list(params) {
  return request(`/api/bmkp-backend/blade-order/dispatcher/wait/allot?${stringify(params)}`, {
    method: 'POST'
  });
}


// 查询所有待派单订单分页
export async function orderList(params) {
  return request('/api/bmkp-backend/blade-order/dispatcher/page', {
    method: 'POST',
    body: params,
  });
}

// 派单列表的订单详情
export async function orderDetail(params) {
  return request('/api/bmkp-backend/blade-order/dispatcher/detail', {
    method: 'POST',
    body: params,
  });
}

// 派单
export async function orderAllot(params) {
  return request('/api/bmkp-backend/blade-order/dispatcher/allot', {
    method: 'POST',
    body: params,
  });
}

// 改派-指派司机列表查询
export async function driverList(params) {
  return request('/api/bmkp-backend/blade-order/dispatcher/dispatchDriverList', {
    method: 'POST',
    body: params,
  });
}

// 查询新订单播报
export async function audioOrder() {
  return request('/api/bmkp-backend/blade-order/dispatcher/wait/allot/new/order');
}

// 查询是否播报新订单 0 不播报 1 播报
export async function queryPlaytState() {
  return request('/api/bmkp-backend/blade-order/dispatcher/report/query');
}

// 设置是否播报新订单 0 不播报 1 播报
export async function setPlaytState(flag) {
  return request(`/api/bmkp-backend/blade-order/dispatcher/report/config?isReport=${flag}`);
}


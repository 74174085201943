import { stringify } from 'qs';
// import func from '@/utils/Func';
import request from '@/utils/request';

/**
 * 
 * @param params 
 * 司机列表
 * @returns 
 */
export async function list(params) {
  return request(`/api/bmkp-backend/asset/driver/page?${stringify(params)}`);
}

export async function detail(params) {
  return request(`/api/bmkp-backend/asset/driver/detail?${stringify(params)}`);
}

export async function updateClose(params) {
  return request('/api/bmkp-backend/asset/driver/blockAccount', {
    method: 'POST',
    body: params,
  });
}

export async function updateOpen(params) {
  return request('/api/bmkp-backend/asset/driver/removeBlockAccount', {
    method: 'POST',
    body: params,
  });
}

export async function submit(params) {
  return request('/api/bmkp-backend/asset/driver/djSubmit', {
    method: 'POST',
    body: params,
  });
}

/** 
 * 
 * 待审核司机
 * 
 * 
*/
export async function peddingList(params) {
  return request(`/api/bmkp-backend/djDriverRegister/page?${stringify(params)}`);
}

export async function peddingDetail(params) {
  return request(`/api/bmkp-backend/djDriverRegister/detail?${stringify(params)}`);
}

export async function updateReject(params, callback?) {
  return request('/api/bmkp-backend/djDriverRegister/reject', {
    method: 'POST',
    body: params,
    callback
  });
}
export async function updatePass(params, callback?) {
  return request('/api/bmkp-backend/djDriverRegister/pass', {
    method: 'POST',
    body: params,
    callback
  });
}

/**
 * 
 * 审核记录
 * 
 */
export async function checkList(params) {
  return request(`/api/bmkp-backend/djDriverCheckLog/page?${stringify(params)}`);
}
export const DRIVERLICENSE_NAMESPACE = 'driverLicense';


export function DRIVERLICENSE_DETAIL(id, callback) {
  return {
    type: `${DRIVERLICENSE_NAMESPACE}/fetchDetail`,
    payload: { driverId: id },
    callback
  }
}

export function DRIVERLICENSE_SUBMIT(payload) {
  return {
    type: `${DRIVERLICENSE_NAMESPACE}/submit`,
    payload,
  };
}
